import React from "react"

import { withStyles, Paper } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import Grid from "@material-ui/core/Grid"
import Table from "../../components/Table/Table"
import { get } from "../../util/usermanager"
import Edit from "@material-ui/icons/Edit"
import LoadingContent from "../../components/LoadingContent"
import Button from "../../components/CustomButtons/Button"
import AddIcon from "@material-ui/icons/Add"
import { Link } from "react-router-dom"
import {
    showNotification
} from "../../util/Constants"

class Usuario extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            usuarios: [],
            usuariosFiltered: []
        }

        this.getData = this.getData.bind(this)
        this.toggleLoading = this.toggleLoading.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.searchFilter = this.searchFilter.bind(this)
    }

    getData() {
        this.toggleLoading()
        get((error, data) => {
            this.toggleLoading()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                this.setState({
                    usuarios: data,
                    usuariosFiltered: data
                })
            }
        })
    }

    componentWillMount() {
        this.getData()
    }

    handleEdit(key) {
        this.props.history.push(
            "/usuario/edit/" + this.state.usuarios[key].id
        )
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    searchFilter(value) {
        let filtered = this.state.usuarios
        if (value !== "") {
            this.setState({
                search: true
            })
        } else {
            this.setState({
                search: false
            })
        }
        filtered = filtered.filter((filter) => {
            if (filter.nome !== null) {
                return filter.nome.indexOf(value.toUpperCase()) !== -1
            } else {
                return null
            }
        })
        this.setState({ usuariosFiltered: filtered })
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container spacing={32} direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>Controle de Usuários</h2>
                            </Grid>
                            <Grid item lg={2} xs={12}>
                                <Link
                                    to="/usuario/cadastro"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button block color="success" round>
                                        <AddIcon /> Adicionar
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <TextField
                            id="standard-search"
                            label="Pesquise pelo nome"
                            fullWidth
                            type="search"
                            name="search"
                            onChange={(event) => this.searchFilter(event.target.value)}
                            className={classes.textField}
                            margin="normal"
                        />
                        <LoadingContent isLoading={this.state.isLoading}>
                            <Paper className={classes.tableWrapper}>
                                <Table
                                    tableHeaderColor="primary"
                                    actions={[
                                        {
                                            title: "Editar",
                                            icon: (
                                                <Edit
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            ),
                                            onClick: this.handleEdit
                                        },
                                    ]}
                                    tableHead={[
                                        "Código",
                                        "Nome",
                                        "Cargo",
                                        "Funções"
                                    ]}
                                    tableData={(this.state.search ? this.state.usuariosFiltered : this.state.usuarios).map((item, key) => {
                                        return [
                                            item.id,
                                            item.nome,
                                            item.cargo,
                                            item.roles.map(role => {
                                                return `${role.display_name}  `
                                            }),
                                            key
                                        ]
                                    })}
                                />
                            </Paper>
                        </LoadingContent>
                    </CardBody>
                </Card>

            </div>
        )
    }
}
export default withStyles(contribuinteStyle)(Usuario)