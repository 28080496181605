import React from "react"

import { withStyles } from "@material-ui/core"
import List from "@material-ui/icons/List"
import contribuinteStyle from "../../../assets/css/layout/contribuinteStyle"
import MenuDashboard from "../../MenuDashboard"

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import theme from "../../../assets/css/theme"

import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { movimentoDividaTabChanged } from "../../../actions"

import AlterarVencimentoParcelaPag from "./AlterarVencimentoParcelaPag"
import AlterarVencimentoParcelaRefinanciamento from "./AlterarVencimentoParcelaRefinanciamento"

const tab = [
  {
    name: "Alterar Vencimento de Parcela de Pagamento",
    icon: <List />
  },
  {
    name: "Alterar Vencimento de Parcela de Refinanciamento",
    icon: <List />
  },

]

class MovimentoDivida extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      field: 0
    }
    this.handleFieldChange = this.handleFieldChange.bind(this)
  }

  handleFieldChange(value) {
    this.props.movimentoDividaTabChanged(value)
  }
  render() {
    const { classes, field } = this.props
    return (
      <div className={classes.container}>
        <MuiThemeProvider theme={theme}>
          <MenuDashboard
            names={tab}
            onChange={this.handleFieldChange}
            value={field}
          />
          {field === 0 && <AlterarVencimentoParcelaPag />}
          {field === 1 && <AlterarVencimentoParcelaRefinanciamento />}
        </MuiThemeProvider>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    field: state.tabLayout.movimentoDividaTabSelected
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ movimentoDividaTabChanged }, dispatch)
}

MovimentoDivida = connect(
  mapStateToProps,
  mapDispatchToProps
)(MovimentoDivida)
export default withStyles(contribuinteStyle)(MovimentoDivida)
