import React from "react"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import AddIcon from "@material-ui/icons/Add"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Button from "../../../components/CustomButtons/Button"
import secaoStyle from "../../../assets/css/layout/secaoStyle"
import { withStyles, MenuItem, Typography } from "@material-ui/core"
import { post } from "../../../util/lancamentosIPTU"
import { showNotification, mapErrors, showWaiting, hideWaiting } from "../../../util/Constants"
import { search as searchImovel } from "../../../util/imovel"
import AsyncSelect from "../../../components/AsyncSelect"
import InputAdornment from "@material-ui/core/InputAdornment"
import LoadingContent from "../../../components/LoadingContent"
import SearchFields from "../../../components/SearchFields"
import Mask from "./mask"

class Lancamento extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      reajuste: 0,
      exercicio: "",
      imovel_id: {},
      tipo: "",
      isLoading: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.toggleLoad = this.toggleLoad.bind(this)
    this.handleChangeReajuste = this.handleChangeReajuste.bind(this)
  }

  handleChangeReajuste(event) {
    let name = event.target.name
    let errors = this.state.errors
    let valor = event.target.value
    if(this.state.reajuste == 0)
      valor = valor[event.target.value.length-1]
    this.setState({ reajuste: Mask.decimalMask(valor),  errors: errors})
  }
  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: event.target.value,
      errors: errors
    })
  }
  toggleLoad() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let params = {
      tipo: this.state.tipo,
      exercicio: this.state.exercicio,
      reajuste: this.state.reajuste,
      imovel_id: this.state.imovel_id.value
    }
    this.toggleLoad()
    let response = error => {
      this.toggleLoad()
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Lançamento realizado com sucesso",
          "success"
        )
        this.props.history.push("/iptu/movimentos")
      }
    }
    post(params, response)
  }

  loadOptions(inputValue, callback) {
    searchImovel(
      inputValue,
      this.state.filterField || "id",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let imoveis = data.data.map(imovel => {
            return {
              value: imovel.id,
              label: `Insc. Cart.: ${imovel.inscricao_cartografica} Logr.: ${imovel.logradouro == null ? "" : imovel.logradouro.nome} Nº: ${imovel.numero}`
            }
          })
          callback(imoveis)
        }
      }
    )
  }

  render() {
    const { classes } = this.props
    const fields = [
      { value: "id", label: "Inscrição" },
      { value: "inscricao_cartografica", label: "Inscrição Cartográfica" },
      { value: "contribuinte_id", label: "Código do contribuinte" },
      {
        value: "contribuinte_nome_razao_social",
        label: "Nome/Razão Social do Contribuinte"
      },
      { value: "contribuinte_cpf_cnpj", label: "Documento do Contribuinte" },
      { value: "bairro", label: "Bairro" },
      { value: "contribuinte_endereco", label: "Endereço do Contribuinte" },
      { value: "logradouro_id", label: "Código do logradouro" },
      { value: "secao_id", label: "Código da Seção" },
      { value: "logradouro", label: "Logradouro" },
      { value: "complemento", label: "Complemento" },
      { value: "quadra", label: "Quadra" },
      { value: "lote", label: "Lote" },
      { value: "loteamento", label: "Loteamento" }
    ]
    return (
      <div className={classes.container}>
        <Card>
          <form onSubmit={this.handleSave}>
            <CardHeader color="primary">
              <Grid container direction="row">
                <Grid item lg={10} xs={12}>
                  <h2 className={classes.cardTitleWhite}>Lançamento de IPTU</h2>
                </Grid>
              </Grid>
            </CardHeader>
            <CardBody>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <TextField
                    label="Exercício"
                    fullWidth
                    required
                    value={this.state.exercicio}
                    onChange={this.handleChange}
                    name="exercicio"
                    error={this.state.errors.exercicio}
                    helperText={this.state.errors.exercicio}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      disabled: this.state.isLoading
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    label="Reajuste"
                    fullWidth
                    required
                    value={this.state.reajuste}
                    onChange={this.handleChangeReajuste}
                    name="reajuste"
                    error={this.state.errors.reajuste}
                    helperText={this.state.errors.reajuste}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      disabled: this.state.isLoading,
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    name="tipo"
                    label="Método de lançamento"
                    fullWidth
                    required
                    value={this.state.tipo}
                    error={this.state.errors.tipo}
                    helperText={this.state.errors.tipo}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      disabled: this.state.isLoading
                    }}
                  >
                    <MenuItem value="ano_anterior">
                      Baseado no exercício anterior
                    </MenuItem>
                    <MenuItem value="codigo_tributario">
                      Baseado no código tributário
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography color="textSecondary" variant="caption">
                    Selecione o campo do imóvel que deseja pesquisar
                  </Typography>
                  <SearchFields
                    name="filterField"
                    value={this.state.filterField || "id"}
                    onChangeFields={this.handleChange}
                    fields={fields}
                  />
                  <AsyncSelect
                    className={
                      `form-control form-control-alternative form-select Select-menu-outer ${
                      this.props.error ? "has-danger" : ""
                      }`
                    }
                    value={this.state.imovel_id}
                    onChange={this.handleChange}
                    loadOptions={this.loadOptions}
                    error={this.state.errors.imovel_id}
                    helperText={this.state.errors.imovel_id}
                    name="imovel_id"
                    placeholder="Pesquise o imóvel"
                    disabled={this.state.isLoading ? "disabled" : ""}
                    message="Pesquise o Imóvel PS:Deixar vazio para contemplar todos os imóveis"
                  />
                </Grid>
              </Grid>
            </CardBody>
            <CardFooter>
              <Grid container justify="center">
                <Grid item lg={2}>
                  <LoadingContent isLoading={this.state.isLoading}>
                    <Button block color="info" round type="submit">
                      <AddIcon /> Iniciar Lançamento
                    </Button>
                  </LoadingContent>
                </Grid>
                {this.state.isLoading && (
                  <Grid container justify="center">
                    <Grid item lg={4} className={classes.loadingText}>
                      <strong>
                        Por favor aguarde, esta tarefa pode durar vários minutos
                      </strong>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </CardFooter>
          </form>
        </Card>
      </div>
    )
  }
}

export default withStyles(secaoStyle)(Lancamento)
