import React from "react"

import withStyles from "@material-ui/core/styles/withStyles"
import loginStyle from "../../assets/css/layout/loginStyle"
import Button from "../../components/CustomButtons/Button"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import Grid from "@material-ui/core/Grid"

import Lens from "@material-ui/icons/Lens"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import Close from "@material-ui/icons/Close"
import GridContainer from "../../components/Grid/GridContainer"
import {Paper, Typography} from "@material-ui/core"
import Print from "@material-ui/icons/Print"
import TextField from "@material-ui/core/TextField"
import AddIcon from "@material-ui/icons/Add"
import {
    showNotification,
    showWaiting,
    hideWaiting
} from "../../util/Constants"
import Table from "../../components/Table/Table";
import Edit from "@material-ui/core/SvgIcon/SvgIcon";
import numeral from "../../util/numeral";
import moment from "moment";

import {listaImprimirPublico, getDamsAlvara, dam} from "../../util/damAlvara"
import TablePagination from "@material-ui/core/TablePagination";
import LoadingContent from "../../components/LoadingContent";
import {Link} from "react-router-dom";
import Mask from "./MaskCpfCnpj";

export class AlvaraPublico extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: {},
            documento: "",
            alvaras: [],
            dams: [],
            dialogDam: false,
            show: false,
            imoveis: [],
            imovel_id: {},
            lancamentos: [],
            parcelas: [],
            control: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.listar = this.listar.bind(this)
        this.listarDams = this.listarDams.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.gerarDam = this.gerarDam.bind(this)
    }

    toggleLoad() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    floaToCurrecy = (valor) => {
        if (valor == null || valor == 0) {
            return "R$ 0,00"
        }
        return "R$ " + (parseFloat(valor)).toLocaleString('pt-BR')
    }

    handleClose(callBack) {
        this.setState({dialogDam: false}, () => {
            if (callBack) {
                callBack()
            }
        })
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        let valor = event.target.value
        errors[name] = undefined
        errors.contribuinte_id = undefined
        this.setState({
            documento: Mask.CpfCnpjMask(valor),
            errors: errors
        }, () => {

        })
    }

    // imprimirCarne() {
    //     showWaiting()
    //     imprimirCarneIptu(this.state.lancamentos.id, (error, data) => {
    //         hideWaiting()
    //         if (error) {
    //             const reader = new FileReader()
    //             reader.addEventListener("loadend", e => {
    //                 const text = e.srcElement.result
    //                 try {
    //                     let error = JSON.parse(text)
    //                     showNotification("top", "center", error.message, "warning")
    //                 } catch (e) {
    //                     showNotification(
    //                         "top",
    //                         "center",
    //                         "Ocorreram erros, verifique sua conexão com a internet",
    //                         "danger"
    //                     )
    //                 }
    //             })
    //
    //             reader.readAsText(error.response.data)
    //         } else {
    //             var url = URL.createObjectURL(data)
    //             let tempLink = document.createElement("a")
    //             tempLink.setAttribute("target", "_blank")
    //             tempLink.setAttribute("href", `${url}`)
    //             tempLink.setAttribute("style", "display:none;")
    //             document.body.appendChild(tempLink)
    //             tempLink.click()
    //             document.body.removeChild(tempLink)
    //
    //         }
    //
    //     })
    // }

    listarDams(key) {
        let alvara = this.state.alvaras[key]
        getDamsAlvara(alvara.id, (error, data) => {
            this.setState({dialogDam: true})
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                this.setState({
                    dams: data.dams,
                })
            }
        })
    }


    gerarDam(key) {
        showWaiting()
        let response = (error, data) => {
            if (error) {
                const reader = new FileReader()
                reader.addEventListener("loadend", e => {
                    const text = e.srcElement.result
                    let error = JSON.parse(text)
                    hideWaiting()
                    showNotification(
                        "top",
                        "center",
                        `Ocorreram erros! ${error.message}`,
                        "danger"
                    )
                })

                reader.readAsText(error.response.data)
            } else {
                hideWaiting()
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        dam(this.state.dams[key].id, response)
    }



    listar() {
        let val = this.state.documento
        this.setState({isLoading: true})
        listaImprimirPublico(Mask.limparMask(val), (error, data) => {
            this.setState({isLoading: false})
            if (error) {
                if (error.response.status === 422) {
                    showNotification(
                        "top",
                        "center",
                        error.response.data,
                        "danger"
                    )
                } else {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                }
            } else {
                this.setState({
                    alvaras: data,
                    dados_estabelecimento: data.dados_estabelecimento,
                    control: true,
                })
                console.log(data)
            }
        })
    }


    render() {
        const {classes} = this.props
        return (
            <div className={classes.container}>
                <div className={classes.background}/>
                <div>
                    <form>
                        <GridContainer alignItems="center" justify="center">
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                <Card>
                                    <CardHeader className={classes.textCenter} color="primary">
                                        <h2 className={classes.cardTitleWhite}>
                                            Imprima seu DAM de Alvará
                                        </h2>
                                        <h4 className={classes.cardTitleWhite}>
                                            Insira seu CPF ou CNPJ sem pontos e/ou espaços
                                        </h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Grid container spacing={16} alignItems="center">
                                            <Grid item xs={12} sm={12} md={8} lg={9} xl={10}>
                                                <TextField
                                                    id="outlined-name"
                                                    placeholder="CPF ou CNPJ"
                                                    fullWidth
                                                    name="documento"
                                                    type="text"
                                                    onChange={this.handleChange}
                                                    error={this.state.errors.documento}
                                                    helperText={this.state.errors.documento}
                                                    value={this.state.documento}
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                                                <Button onClick={this.listar} color="info" fullWidth>
                                                    Procurar
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        {this.state.control === false && <Typography variant="caption" color="error">
                                            Para o funcionamento correto desta ferramenta, é recomendado que você
                                            desabilite o AdBlocker do seu navegador.
                                        </Typography>}

                                        {this.state.control && <LoadingContent isLoading={this.state.isLoading}>
                                            <Paper className={classes.tableWrapper}>
                                                <Table
                                                    tableHeaderColor="primary"
                                                    actions={[
                                                        {
                                                            title: "Imprimir",
                                                            icon: (
                                                                <Print
                                                                    className={
                                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                                    }
                                                                />
                                                            ),
                                                            onClick: key => this.listarDams(key)
                                                        }]}
                                                    tableHead={[
                                                        "Código do Alvará",
                                                        "Código do Contribuinte",
                                                        "Código da Atividade",
                                                        "Descrição da Atividade",
                                                    ]}
                                                    tableData={this.state.alvaras.map((item, key) => {
                                                        return [
                                                            item.id,
                                                            item.contribuinte_id,
                                                            item.tipo.id,
                                                            item.tipo.nome,
                                                            key
                                                        ]
                                                    })}
                                                />
                                            </Paper>
                                        </LoadingContent>}
                                    </CardBody>
                                </Card>
                            </Grid>
                        </GridContainer>
                    </form>
                </div>

                <Dialog
                    id="body"
                    open={this.state.dialogDam}
                    maxWidth="md"
                    fullWidth
                    titleStyle={{textAlign: "center"}}
                    onClose={(e) => this.handleClose()}
                    aria-labelledby="form-dialog-title"
                    style={{zIndex: "4"}}
                >
                        <DialogTitle id="form-dialog-title">DAM</DialogTitle>
                        <DialogContent>
                            <Grid
                                className={classes.legend}
                                container
                                direction="row"
                                style={{marginBottom: "2%"}}
                            >
                            </Grid>
                            <form onSubmit={this.handleSaveParcela}>
                                <Paper className={classes.tableWrapper}>
                                    <Table
                                        tableHeaderColor="primary"
                                        fullWidth
                                        maxWidth={'md'}
                                        actions={[
                                            {
                                                title: "Imprimir",
                                                icon: (
                                                    <Print
                                                        className={
                                                            classes.tableActionButtonIcon +
                                                            " " +
                                                            classes.edit
                                                        }
                                                    />
                                                ),
                                                onClick: this.gerarDam
                                            }
                                        ]}
                                        tableHead={["#", "Competência", "Valor", "Juros", "Multa"]}
                                        tableData={this.state.dams.map((item, key) => {
                                            return [item.id + "", item.competencia, this.floaToCurrecy(item.valor), this.floaToCurrecy(item.juros), this.floaToCurrecy(item.multa), key]
                                        })}
                                    />
                                </Paper>
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={(e) => this.handleClose()} color="danger">
                                Voltar
                            </Button>
                        </DialogActions>
                </Dialog>}
            </div>
        )
    }
}

export default withStyles(loginStyle)(AlvaraPublico)
