import React from "react"
import { withRouter } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import AsyncSelect from "../../components/AsyncSelect"
import Typography from "@material-ui/core/Typography"
import { search as searchContribuinte } from "../../util/contribuinte"
import { showNotification, mapErrors } from "../../util/Constants"
import SearchFields from "../../components/SearchFields"
import { withStyles } from "@material-ui/core"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import secaoStyle from "../../assets/css/layout/secaoStyle"
import Button from "../../components/CustomButtons/Button"
import { showWaiting, hideWaiting } from "../../util/Constants"
import { postAverbacao } from "../../util/certidaonegativa"
import CardFooter from "../../components/Card/CardFooter"
import AdBlockDetect from "react-ad-block-detect"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import housecall from "housecall"
import SelectCustom from "../../components/SelectCustom"

class RelatorioAverbacao extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id_contribuinte: "",
            observacao: "",
            errors: {},
            messages: [],
            imoveis: [],
            contribuintes: "",
            imoveis_all: [],
            id_imovel: ""
        }
        this.loadOptions = this.loadOptions.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.imprimir = this.imprimir.bind(this)
        this.getContribuinteById = this.getContribuinteById.bind(this)
        this.queue = housecall({ concurrency: 1, cooldown: 0 })
        this.setLoadingFalse = this.setLoadingFalse.bind(this)
    }

    componentDidMount() {
        this.queue.on("idle", this.setLoadingFalse)
    }

    setLoadingFalse() {
        this.setState({
            isLoading: false
        })
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        errors[name] = undefined

        this.setState({
            [name]: event.target.value,
            errors: errors
        }, () => {
            if (this.state.id_contribuinte && name === "id_contribuinte") {
                let contr = this.getContribuinteById(this.state.id_contribuinte.value)
                let select = contr.imoveis.map(atrib => {
                    return {
                        value: atrib.id,
                        label:
                            "Insc. Cart.: " +
                            atrib.inscricao_cartografica +
                            `Logr.: ${atrib.logradouro ? atrib.logradouro.nome : ""}`

                    }
                })
                this.setState({
                    imoveis: select
                })
            }
        })
    }

    getContribuinteById(id) {
        return this.state.contribuintes.filter(item => {
            return id === item.id
        })[0]
    }

    handleSave(event) {
        event.preventDefault()
        showWaiting()
        let response = (error, data) => {
            if (error) {
                hideWaiting()
                const reader = new FileReader()

                reader.addEventListener("loadend", e => {
                    const text = e.srcElement.result
                    let error = JSON.parse(text)
                    hideWaiting()
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros! Verifique o formulário.",
                        "danger"
                    )
                    this.setState({
                        errors: mapErrors({ response: { data: error } })
                    }, () => {
                        let array = Object.keys(this.state.errors).map((keyName, i) => {
                            return this.state.errors[keyName]
                        })
                        this.setState({
                            messages: array
                        })
                    })
                })
                reader.readAsText(error.response.data)
            } else {
                hideWaiting()
                showNotification(
                    "top",
                    "center",
                    "Relatorio emitido com sucesso",
                    "success"
                )
                this.imprimir(data)
            }
        }
        postAverbacao(this.state.id_imovel.value, response)
    }

    imprimir(data) {
        var url = window.URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)

    }

    loadOptions(inputValue, callback) {
        searchContribuinte(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                    callback([])
                } else {
                    let contribuintes = data.data.map(contribuinte => {
                        return {
                            value: contribuinte.id,
                            label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                            ""} - ${contribuinte.cpf_cnpj || ""}`
                        }
                    })
                    this.setState({
                        contribuintes: data.data
                    })
                    callback(contribuintes)
                }
            }
        )
    }

    render() {
        const { classes } = this.props
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "Código do contribuinte" },
            { value: "cpf_cnpj", label: "Documento" },
            { value: "endereco", label: "Endereço" },
            { value: "nome_fantasia", label: "Nome Fantasia" },
            { value: "nome_razao_social", label: "Razão Social" }
        ]
        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}> Relatório de Averbação </h2>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <SearchFields
                                    name="filterField"
                                    value={this.state.filterField || ""}
                                    onChangeFields={this.handleChange}
                                    fields={fields}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <AsyncSelect
                                    className={
                                        `form-control form-control-alternative form-select select-custom ${
                                            this.props.error ? "has-danger" : ""
                                        }`
                                    }
                                    value={this.state.id_contribuinte}
                                    name="id_contribuinte"
                                    onChange={this.handleChange}
                                    error={this.state.errors.contribuinte_id}
                                    helperText={this.state.errors.contribuinte_id}
                                    loadOptions={this.loadOptions}
                                    placeholder="Contribuinte"
                                    message="Pesquise o contribuinte"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} alignItems="center" justify="center">
                                <SelectCustom
                                    value={this.state.id_imovel}
                                    name="id_imovel"
                                    isMulti={false}
                                    onChange={this.handleChange}
                                    error={this.state.errors.imovel_id}
                                    helperText={this.state.errors.imovel_id}
                                    options={this.state.imoveis}
                                    placeholder="Imóvel"
                                    message="Pesquise o imóvel"
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={16}>
                        </Grid>
                    </CardBody>
                    <CardFooter>
                        <Grid container justify="center">
                            <Grid item lg={2}>
                                <Button block color="info" round onClick={this.handleSave}>
                                    Gerar
                                </Button>
                            </Grid>
                        </Grid>
                    </CardFooter>
                </Card>
                <AdBlockDetect>
                    <Dialog open={true} aria-labelledby="form-dialog-title">
                        <form onSubmit={this.handleSave}>
                            <DialogContent>
                                <Grid
                                    container
                                    justify="center"
                                    alignItems="center"
                                    direction="row"
                                >
                                    <Grid item xs={12} sm={12} md={8} lg={8}>
                                        <Typography variant="h5" gutterBottom>
                                            Por favor, desabilite o ADBlock para impressão de certidão.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </form>
                    </Dialog>
                </AdBlockDetect>
            </div>
        )
    }
}
RelatorioAverbacao = withRouter(RelatorioAverbacao)
export default withStyles(secaoStyle)(RelatorioAverbacao)