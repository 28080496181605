import React from "react"

import { withStyles } from "@material-ui/core"
import contribuinteStyle from "../../../assets/css/layout/contribuinteStyle"
import MenuDashboard from "../../MenuDashboard"

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import theme from "../../../assets/css/theme"
import { alvaraRelatoriosTabChanged } from "../../../actions"
import Assessment from "@material-ui/icons/Assessment"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import RelatorioCadastro from "./RelatorioCadastro"
import RelatorioVeiculos from "./RelatorioVeiculos"

const tab = [
    {
        name: "Relatório de Cadastro de Alvará",
        icon: <Assessment />
    },
    {
        name: "Relatório Alvará de Veículos",
        icon: <Assessment />
    }
]

class RelatorioAlvara extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: "",
            error: "",
            pageCount: 0,
            perPage: 0,
            page: 1,
            contribuintes: [],
            field: 0,
            refAlvara: null
        }
        this.handleFieldChange = this.handleFieldChange.bind(this)
    }

    handlePageClick(data) { }

    handleChange = event => {
        this.setState({ value: event.target.value })
    }

    handleChangePage = (event, page) => {
        if ((page || 1) !== this.state.page) {
            this.setState({ page }, () => { this.getData(this.state.page) })
        }
    }

    handleFieldChange(value) {
        this.props.alvaraRelatoriosTabChanged(value)
    }

    render() {
        const { classes, field } = this.props
        return (
            <div className={classes.container}>
                <MuiThemeProvider theme={theme}>
                    <MenuDashboard
                        names={tab}
                        onChange={this.handleFieldChange}
                        value={field}
                    />
                    {field === 0 && <RelatorioCadastro />}
                    {field === 1 && <RelatorioVeiculos />}
                </MuiThemeProvider>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        field: state.tabLayout.alvaraRelatoriosTabSelected
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ alvaraRelatoriosTabChanged }, dispatch)
}
RelatorioAlvara = connect(
    mapStateToProps,
    mapDispatchToProps
)(RelatorioAlvara)
export default withStyles(contribuinteStyle)(RelatorioAlvara)
