import React from "react";

import Grid from "@material-ui/core/Grid";
import Fade from "react-reveal/Fade";
import TextField from "@material-ui/core/TextField";
import {MenuItem, withStyles} from "@material-ui/core";
import logradouroStyle from "../../../assets/css/layout/logradouroStyle";
import Button from "../../../components/CustomButtons/Button";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import {
    showNotification,
    mapErrors,
    hideWaiting,
    showWaiting
} from "../../../util/Constants";
import AsyncSelect from "../../../components/AsyncSelect";
import {search, baixaManualRef, searchBaixa, destroy} from "../../../util/refinanciamento";
import {getField} from "../../../util/baixaItbi";
import SearchFields from "../../../components/SearchFields";
import Typography from "@material-ui/core/Typography";
import CardFooter from "../../../components/Card/CardFooter";
import moment from "moment";
import numeral from "../../../util/numeral";
import DialogCustom from "../../DialogCustom"
import Delete from "@material-ui/icons/Delete"
import NumberFormatCustom from "../../../components/NumberFormatCustom"

const initialState = {
    dam_itbi: [],
    id_dam_itbi: [],
    errors: {},
    filterField: "",
    data_vencimento: "",
    refinanciamentos: [],
    id_refinanciamento: 0,
    divida: [],
    id_divida: "",
    parcelas: [],
    id_parcela: "",
    refin_divida: [],
    id_refin_divida: "",
    valor: "",
    receita_id: "",
    competencia: "",
    data_deposito: new Date(),
    data_pagamento: new Date(),
    valor_pago: "",
    multa: "",
    id_agente: 0,
    agencia: "",
    conta: "",
    transmitente: {},
    modal: false,
    openConfirm: false,
    filterFieldDialog: ""
};

class BaixaRefisForm extends React.Component {
    constructor(props) {
        super(props);
        initialState.agentes = []
        this.state = initialState;

        this.loadOptions = this.loadOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeFilters = this.handleChangeFilters.bind(this);
        this.getRefinanciamento = this.getRefinanciamento.bind(this);
        this.init = this.init.bind(this);
        this.getAgenciaConta = this.getAgenciaConta.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.getDivida = this.getDivida.bind(this)
        this.getRefinDivida = this.getRefinDivida.bind(this)
        this.getParcela = this.getParcela.bind(this)

        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.loadBaixa = this.loadBaixa.bind(this)
        this.handleOpenConfirm = this.handleOpenConfirm.bind(this)
        this.handleCloseConfirm = this.handleCloseConfirm.bind(this)
    }

    handleOpenConfirm() {
        this.setState({
            openConfirm: true
        })
    }

    handleCloseConfirm() {
        this.setState({
            openConfirm: false
        })
    }

    handleOpen() {
        this.setState({
            modal: true
        })
    }

    handleDelete() {
        showWaiting()
        let response = error => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                );
                this.setState({
                    errors: mapErrors(error)
                });
            } else {
                showNotification(
                    "top",
                    "center",
                    `Baixa deletada com sucesso`,
                    "success"
                );
                this.handleClose()
                this.handleCloseConfirm()
            }
        };
        destroy(this.state.codigo.value, response)
    }

    handleClose() {
        this.setState({
            modal: false
        })
    }

    loadBaixa(inputValue, callback) {
        searchBaixa(inputValue, this.state.filterFieldDialog, undefined, (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
                callback([])
            } else {
                let baixa = data.data.map(item => {
                    return {
                        value: item.id,
                        label: `Código Baixa: ${item.dam.refinanciamento_id} - 
            Agência: ${item.agencia} Conta: ${item.conta} - Nº Parcela: ${item.dam.numero_da_parcela}
            Dt.Pagamento: ${moment(item.data_pagamento).format("DD/MM/YYYY")} - 
            Dt.Deposito: ${moment(item.data_deposito).format("DD/MM/YYYY")}`
                    }
                })
                callback(baixa)
            }
        })
    }

    componentWillMount() {
        this.init();
    }

    loadOptions(inputValue, callback) {
        search(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    );
                    callback([]);
                } else {
                    let ref = data.data.map(refin => {
                        return {
                            value: refin.id,
                            label: `${refin.id} - Contribuinte: ${refin.contribuinte
                                .nome_razao_social || ""} Documento: ${refin.contribuinte
                                .cpf_cnpj || ""}`
                        };
                    });
                    this.setState({
                        refinanciamentos: data.data
                    });
                    callback(ref);
                }
            }
        );
    }

    getRefinanciamento(id) {
        return this.state.refinanciamentos.filter(item => {
            return id === item.id;
        })[0];
    }

    getParcela(id) {
        return this.state.parcelas.filter(item => {
            return id === item.id;
        })[0];
    }

    getRefinDivida(id) {
        return this.state.refin_divida.filter(item => {
            return id === item.id;
        })[0];
    }

    handleChangeFilters = event => {
        this.setState({filterField: event.target.value});
    };

    init() {
        let response = (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                );
            } else {
                this.setState({
                    agentes: data.agentes
                });

                this.setState(data);
            }
        };
        getField(response);
    }

    getAgenciaConta(id) {
        return this.state.agentes.filter(item => {
            return id === item.id;
        })[0];
    }

    handleChange(event) {
        let name = event.target.name;
        let errors = this.state.errors;
        errors[name] = undefined;

        this.setState(
            {
                [name]: event.target.value,
                errors: errors
            },
            () => {
                if (this.state.id_refinanciamento && name === "id_refinanciamento") {
                    let refinanciamento = this.getRefinanciamento(this.state.id_refinanciamento.value)
                    this.setState({
                        divida: refinanciamento.parcelas,
                        parcelas: refinanciamento.parcelas,
                        data_vencimento: ''
                    });
                }
                if (this.state.id_parcela && name === "id_parcela") {
                    let parc = this.getParcela(this.state.id_parcela)
                    this.setState({
                        data_vencimento: parc.vencimento
                    })
                }
            }
        );
    }

    getDivida(id) {
        return this.state.divida.filter(item => {
            return id === item.id;
        })[0]
    }

    handleSave(event) {
        event.preventDefault();
        showWaiting();
        let params = {
            dam_id: this.state.id_parcela,
            agente_recebedor_id: this.state.id_agente,
            data_pagamento: moment(this.state.data_pagamento).format("YYYY-MM-DD") + "",
            data_deposito: moment(this.state.data_deposito).format("YYYY-MM-DD") + "",
            valor_pago: this.state.valor_pago
        };
        let response = (error, data) => {
            if (error) {
                if (error.response.data.errors.dam_id) {
                    hideWaiting()
                    showNotification(
                        "top",
                        "center",
                        error.response.data.errors.dam_id,
                        "danger"
                    )
                } else {
                    hideWaiting()
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique o formulário e tente novamente",
                        "danger"
                    )
                }
                this.setState({
                    errors: mapErrors(error)
                })
            } else {
                hideWaiting();
                showNotification(
                    "top",
                    "center",
                    `Baixa de Refinanciamento realizada com sucesso`,
                    "success"
                );
                this.setState(initialState);
                this.init()
                this.props.history.push("/divida/cadastro")
            }
        };
        baixaManualRef(params, response);
    }

    render() {
        const {classes} = this.props;
        const fields = [
            {value: "", label: "Todos os campos"},
            {value: "id", label: "Código Refinanciamento"},
            {value: "contribuinte_id", label: "Código do Contribuinte"},
            {value: "contribuinte_nome_razao_social", label: "Nome/Razão Social Contribuinte"},
            {value: "refis_id", label: "Código Refis"}
        ];
        const fieldsDialog = [
            {value: "", label: "Todos os campos"},
            {value: "refinanciamento_id", label: "Código Baixa"},
            {value: "data_pagamento", label: "Data Pagamento"},
            {value: "numero_parcela", label: "Nº Parcela"},
            {value: "data_deposito", label: "Data Depósito"}
        ]
        return (
            <div>
                <Card>
                    <CardHeader color="primary">
                        <Grid container direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>Baixa de Refinanciamento</h2>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <Fade bottom>
                        <form onSubmit={this.handleSave}>
                            <CardBody>
                                <Grid container direction="row" spacing={16}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography color="textSecondary" variant="caption">
                                            Pesquise o Refinanciamento
                                        </Typography>
                                        <SearchFields
                                            name="filterField"
                                            value={this.state.filterField}
                                            onChangeFields={this.handleChangeFilters}
                                            fields={fields}
                                        />
                                        <AsyncSelect
                                            className={
                                                `form-control form-control-alternative form-select Select-menu-outer ${
                                                    this.props.error ? "has-danger" : ""
                                                }`
                                            }
                                            value={this.state.id_refinanciamento}
                                            onChange={this.handleChange}
                                            loadOptions={this.loadOptions}
                                            name="id_refinanciamento"
                                            error={this.state.errors.dam_id}
                                            placeholder="Refinanciamento"
                                            message="Pesquise o Refinanciamento"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={16}>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            required
                                            name="id_parcela"
                                            label="Parcela"
                                            fullWidth
                                            value={this.state.id_parcela}
                                            error={this.state.errors.id_parcela}
                                            helperText={this.state.errors.id_parcela}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                        >
                                            {this.state.parcelas.map((option, key) => (
                                                <MenuItem key={key} value={option.id}>
                                                    Nº parcela: {option.numero_da_parcela}
                                                    {" - R$ " +
                                                    numeral(parseFloat(option.valor))
                                                        .format("0.0,00")
                                                        .slice(0, -2)}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="outlined-select-currency"
                                            name="data_vencimento"
                                            label="Data do Vencimento"
                                            fullWidth
                                            type="date"
                                            disabled
                                            value={moment(this.state.data_vencimento).format("YYYY-MM-DD")}
                                            error={this.state.errors.data_vencimento}
                                            helperText={this.state.errors.data_vencimento}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={16}>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField
                                            id="outlined-select-currency"
                                            name="data_deposito"
                                            label="Data do Depósito"
                                            fullWidth
                                            type="date"
                                            error={this.state.errors.data_deposito}
                                            helperText={this.state.errors.data_deposito}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField
                                            id="outlined-select-currency"
                                            name="data_pagamento"
                                            label="Data do Pagamento"
                                            fullWidth
                                            type="date"
                                            error={this.state.errors.data_pagamento}
                                            helperText={this.state.errors.data_pagamento}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField
                                            id="outlined-select-currency"
                                            label="Valor Pago"
                                            fullWidth
                                            name="valor_pago"
                                            value={this.state.valor_pago}
                                            error={this.state.errors.valor_pago}
                                            helperText={this.state.errors.valor_pago}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            defaultValue=""
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={16}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            required
                                            name="id_agente"
                                            label="Agente Recebedor"
                                            fullWidth
                                            value={this.state.id_agente}
                                            error={this.state.errors.id_agente}
                                            helperText={this.state.errors.id_agente}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                        >
                                            {this.state.agentes.map((option, key) => (
                                                <MenuItem key={key} value={option.id}>
                                                    {option.nome}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                </Grid>
                            </CardBody>
                            <CardFooter>
                                <Grid container justify="center">
                                    <Grid item lg={2}>
                                        <Button block color="info" round type="submit">
                                            Gerar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardFooter>
                        </form>
                    </Fade>
                </Card>

                <DialogCustom
                    open={this.state.modal}
                    nameSearch="filterFieldDialog"
                    valueSearch={this.state.filterFieldDialog || ""}
                    onChangeFields={this.handleChange}
                    fields={fieldsDialog}
                    onChange={this.handleChange}
                    optionsAsync={this.loadBaixa}
                    nameAsync="codigo"
                    error={this.state.errors.text}
                    helperText={this.state.errors.text}
                    message="Pesquise a Baixa de Refinanciamento"
                    placeholder="Baixa de Refinanciamento"
                    handleClose={this.handleClose}
                    isDisabled={this.state.codigo ? false : true}
                    handleSave={this.handleDelete}
                    handleCloseConfirm={this.handleCloseConfirm}
                    handleOpenConfirm={this.handleOpenConfirm}
                    openConfirm={this.state.openConfirm}
                />
            </div>
        );
    }
}

export default withStyles(logradouroStyle)(BaixaRefisForm);
