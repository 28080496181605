
const INITIAL_STATE = {
    contribuinte: undefined
}
export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case "CONTRIBUINTE_CHANGED":
            return Object.assign({}, state, {
                contribuinte: action.payload
            })
        default:
            return state
    }
}
