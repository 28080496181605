import React from "react"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import AddIcon from "@material-ui/icons/Add"
import InputAdornment from "@material-ui/core/InputAdornment"
import AsyncSelect from "../../../components/AsyncSelect"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Button from "../../../components/CustomButtons/Button"
import secaoStyle from "../../../assets/css/layout/secaoStyle"
import { withStyles } from "@material-ui/core"
import { getFields, post, edit, put } from "../../../util/secaologradouro"

import { showNotification, mapErrors, showWaiting, hideWaiting } from "../../../util/Constants"

import { search } from "../../../util/logradouro"
import NumberFormatCustom from "../../../components/NumberFormatCustom"
import LoadingContent from "../../../components/LoadingContent"

class SecaoLogradouroForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      //   LOGRADOURO SELECT
      cod_logradouro: "",
      logradouros: [],
      tipo_logradouro: "",
      sufixo: "",
      nome_logradouro: "",
      cod_secao: "",
      valor_terreno: "",
      ////SELECT
      guia_e_sarjeta: [],
      id_guia: "",
      //
      coleta_de_lixo: [],
      id_coleta: "",
      //
      rede_telefone: [],
      id_rede_telefone: "",
      //
      rede_eletrica: [],
      id_rede_eletrica: "",
      //
      rede_de_agua: [],
      id_rede_agua: "",
      //
      rede_sanitaria: [],
      id_rede_sanitaria: "",
      //
      galeria_pluvial: [],
      id_galeria_pluvial: "",
      //
      iluminacao_publica: [],
      id_iluminacao_publica: "",
      //
      tipo_pavimentacao: [],
      id_pavimentacao: ""
      //
    }
    this.getFields = this.getFields.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
  }

  componentWillMount() {
    this.getFields()
  }

  loadOptions(inputValue, callback) {
    search(inputValue, undefined, undefined, (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
        callback([])
      } else {
        let logradouros = data.data.map(logradouros => {
          return { value: logradouros.id, label: logradouros.nome }
        })
        callback(logradouros)
      }
    })
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: event.target.value,
      errors: errors
    })
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let params = {
      logradouro_id: this.state.cod_logradouro ? this.state.cod_logradouro.value : undefined,
      secao: this.state.cod_secao,
      valor_m2: this.state.valor_terreno,
      tipo_iluminacao_id: this.state.id_iluminacao_publica,
      tipo_pavimentacao_id: this.state.id_pavimentacao,
      rede_de_agua_id: this.state.id_rede_agua,
      rede_telefonica_id: this.state.id_rede_telefone,
      rede_eletrica_id: this.state.id_rede_eletrica,
      galeria_pluvial_id: this.state.id_galeria_pluvial,
      coleta_de_lixo_id: this.state.id_coleta,
      rede_sanitaria_id: this.state.id_rede_sanitaria,
      guia_e_sarjeta_id: this.state.id_guia
    }
    let response = error => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          `Seção de Logradouros ${
          this.props.match.params.id ? "editada" : "incluída"
          } com sucesso`,
          "success"
        )
        this.props.history.push("/iptu/cadastro")
      }
    }
    this.props.match.params.id
      ? put(this.props.match.params.id, params, response)
      : post(params, response)
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  getFields() {
    this.toggleLoading()
    let response = (error, data) => {
      this.toggleLoading()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        if (this.props.match.params.id) {
          data.secao.cod_logradouro = {
            value: data.secao.logradouro_id,
            label: data.secao.logradouro.nome
          }
          data.secao.logradouros = [
            {
              value: data.secao.logradouro_id,
              label: data.secao.logradouro.nome
            }
          ]
          data.secao.cod_secao = data.secao.secao
          data.secao.valor_terreno = data.secao.valor_m2
          data.secao.id_iluminacao_publica = data.secao.tipo_iluminacao_id
          data.secao.id_pavimentacao = data.secao.tipo_pavimentacao_id
          data.secao.id_rede_agua = data.secao.rede_de_agua_id
          data.secao.id_rede_telefone = data.secao.rede_telefonica_id
          data.secao.id_rede_eletrica = data.secao.rede_eletrica_id
          data.secao.id_galeria_pluvial = data.secao.galeria_pluvial_id
          data.secao.id_coleta = data.secao.coleta_de_lixo_id
          data.secao.id_rede_sanitaria = data.secao.rede_sanitaria_id
          data.secao.id_guia = data.secao.guia_e_sarjeta_id
        }

        let guias = data.guia_e_sarjeta.map(guia => {
          return { value: guia.id, label: guia.nome }
        })
        let redeSanitaria = data.rede_sanitaria.map(rede => {
          return { value: rede.id, label: rede.nome }
        })
        let iluminacaoPublica = data.iluminacao_publica.map(iluminacao => {
          return { value: iluminacao.id, label: iluminacao.nome }
        })
        let galeriaPluvial = data.galeria_pluvial.map(galeria => {
          return { value: galeria.id, label: galeria.nome }
        })
        let redeEletrica = data.rede_eletrica.map(eletrica => {
          return { value: eletrica.id, label: eletrica.nome }
        })
        let tipoPavimentacao = data.tipo_pavimentacao.map(pavimentacao => {
          return { value: pavimentacao.id, label: pavimentacao.nome }
        })
        let coletaLixo = data.coleta_de_lixo.map(coleta => {
          return { value: coleta.id, label: coleta.nome }
        })
        let redeTelefone = data.rede_telefonica.map(telefone => {
          return { value: telefone.id, label: telefone.nome }
        })
        let redeAgua = data.rede_de_agua.map(agua => {
          return { value: agua.id, label: agua.nome }
        })

        let newState = {
          guia_e_sarjeta: guias,
          rede_sanitaria: redeSanitaria,
          iluminacao_publica: iluminacaoPublica,
          galeria_pluvial: galeriaPluvial,
          rede_eletrica: redeEletrica,
          tipo_pavimentacao: tipoPavimentacao,
          coleta_de_lixo: coletaLixo,
          rede_telefone: redeTelefone,
          rede_de_agua: redeAgua,
          ...data.secao
        }

        this.setState(newState)
      }
    }
    if (this.props.match.params.id) {
      edit(this.props.match.params.id, response)
    } else {
      getFields(response)
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <LoadingContent isLoading={this.state.isLoading}>
          <Card>
            <form onSubmit={this.handleSave}>
              <CardHeader color="primary">
                <Grid container direction="row">
                  <Grid item lg={10} xs={12}>
                    <h2 className={classes.cardTitleWhite}>
                      Cadastro de Seção de logradouros
                    </h2>
                  </Grid>
                </Grid>
              </CardHeader>
              <CardBody>
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <AsyncSelect
                      className={
                        `form-control form-control-alternative form-select Select-menu-outer ${
                        this.props.error ? "has-danger" : ""
                        }`
                      }
                      value={this.state.cod_logradouro}
                      onChange={this.handleChange}
                      loadOptions={this.loadOptions}
                      error={this.state.errors.logradouro_id}
                      helperText={this.state.errors.logradouro_id}
                      name="cod_logradouro"
                      placeholder="Logradouros"
                      message="Pesquise nome do Logradouro"
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-select-currency"
                      label="Código Seção"
                      fullWidth
                      required
                      value={this.state.cod_secao}
                      onChange={this.handleChange}
                      name="cod_secao"
                      error={this.state.errors.cod_secao}
                      helperText={this.state.errors.cod_secao}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      label="Valor Terreno"
                      fullWidth
                      required
                      value={this.state.valor_terreno}
                      onChange={this.handleChange}
                      name="valor_terreno"
                      error={this.state.errors.valor_m2}
                      helperText={this.state.errors.valor_m2}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">M²</InputAdornment>
                        ),
                        inputComponent: NumberFormatCustom
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-select-currency"
                      fullWidth
                      select
                      required
                      label="Guia e Sarjeta"
                      value={this.state.id_guia}
                      onChange={this.handleChange}
                      name="id_guia"
                      error={this.state.errors.guia_e_sarjeta_id}
                      helperText={this.state.errors.guia_e_sarjeta_id}
                      margin="normal"
                      variant="outlined"
                    >
                      {this.state.guia_e_sarjeta.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={16}>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      id="outlined-select-currency"
                      fullWidth
                      select
                      required
                      label="Rede Sanitária"
                      value={this.state.id_rede_sanitaria}
                      onChange={this.handleChange}
                      name="id_rede_sanitaria"
                      error={this.state.errors.rede_sanitaria_id}
                      helperText={this.state.errors.rede_sanitaria_id}
                      margin="normal"
                      variant="outlined"
                    >
                      {this.state.rede_sanitaria.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      id="outlined-select-currency"
                      fullWidth
                      select
                      required
                      label="Iluminação Pública"
                      value={this.state.id_iluminacao_publica}
                      onChange={this.handleChange}
                      name="id_iluminacao_publica"
                      error={this.state.errors.tipo_iluminacao_id}
                      helperText={this.state.errors.tipo_iluminacao_id}
                      margin="normal"
                      variant="outlined"
                    >
                      {this.state.iluminacao_publica.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      id="outlined-select-currency"
                      fullWidth
                      select
                      required
                      label="Galeria Pluvial"
                      value={this.state.id_galeria_pluvial}
                      onChange={this.handleChange}
                      name="id_galeria_pluvial"
                      error={this.state.errors.galeria_pluvial_id}
                      helperText={this.state.errors.galeria_pluvial_id}
                      margin="normal"
                      variant="outlined"
                    >
                      {this.state.galeria_pluvial.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      id="outlined-select-currency"
                      fullWidth
                      select
                      required
                      label="Energia Elétrica"
                      value={this.state.id_rede_eletrica}
                      onChange={this.handleChange}
                      name="id_rede_eletrica"
                      error={this.state.errors.rede_eletrica_id}
                      helperText={this.state.errors.rede_eletrica_id}
                      margin="normal"
                      variant="outlined"
                    >
                      {this.state.rede_eletrica.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={16}>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      id="outlined-select-currency"
                      fullWidth
                      select
                      required
                      label="Pavimentação"
                      value={this.state.id_pavimentacao}
                      onChange={this.handleChange}
                      name="id_pavimentacao"
                      error={this.state.errors.tipo_pavimentacao_id}
                      helperText={this.state.errors.tipo_pavimentacao_id}
                      margin="normal"
                      variant="outlined"
                    >
                      {this.state.tipo_pavimentacao.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      id="outlined-select-currency"
                      fullWidth
                      select
                      required
                      label="Coleta de Lixo"
                      value={this.state.id_coleta}
                      onChange={this.handleChange}
                      name="id_coleta"
                      error={this.state.errors.coleta_de_lixo_id}
                      helperText={this.state.errors.coleta_de_lixo_id}
                      margin="normal"
                      variant="outlined"
                    >
                      {this.state.coleta_de_lixo.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      id="outlined-select-currency"
                      fullWidth
                      select
                      label="Rede Telefônica"
                      value={this.state.id_rede_telefone}
                      onChange={this.handleChange}
                      name="id_rede_telefone"
                      error={this.state.errors.rede_telefonica_id}
                      helperText={this.state.errors.rede_telefonica_id}
                      margin="normal"
                      variant="outlined"
                    >
                      {this.state.rede_telefone.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      id="outlined-select-currency"
                      fullWidth
                      select
                      required
                      label="Rede de Água"
                      value={this.state.id_rede_agua}
                      onChange={this.handleChange}
                      name="id_rede_agua"
                      error={this.state.errors.rede_de_agua_id}
                      helperText={this.state.errors.rede_de_agua_id}
                      margin="normal"
                      variant="outlined"
                    >
                      {this.state.rede_de_agua.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </CardBody>
              <CardFooter>
                <Grid container justify="center">
                  <Grid item lg={2}>
                    <Button block color="info" round type="submit">
                      <AddIcon />{" "}
                      {this.props.match.params.id ? "Atualizar" : "Adicionar"}
                    </Button>
                  </Grid>
                </Grid>
              </CardFooter>
            </form>
          </Card>
        </LoadingContent>
      </div>
    )
  }
}

export default withStyles(secaoStyle)(SecaoLogradouroForm)
