import React, { Component } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import contribuinteStyle from "../assets/css/layout/contribuinteStyle";
import { withStyles } from "@material-ui/core";
class SearchFields extends Component {
  render() {
    return (
      <RadioGroup
        aria-label="position"
        name={this.props.name}
        value={this.props.value}
        onChange={this.props.onChangeFields}
        row
      >
        {this.props.fields.map((field, key) => (
          <FormControlLabel
            key={key}
            value={field.value}
            control={<Radio color="primary" />}
            label={field.label}
            labelPlacement="end"
          />
        ))}
      </RadioGroup>
    );
  }
}
export default withStyles(contribuinteStyle)(SearchFields);
