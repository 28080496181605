import React from "react"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"
import Delete from "@material-ui/icons/Delete"
import Edit from "@material-ui/icons/Edit"
import InputAdornment from "@material-ui/core/InputAdornment"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import moment from "moment"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Button from "../../../components/CustomButtons/Button"
import secaoStyle from "../../../assets/css/layout/secaoStyle"
import { withStyles } from "@material-ui/core"
import { post, edit, put } from "../../../util/parametro"
import { showNotification, mapErrors, showWaiting, hideWaiting } from "../../../util/Constants"
import NumberFormatCustom from "../../../components/NumberFormatCustom"
import LoadingContent from "../../../components/LoadingContent"
import numeral from "numeral"

import { put as putParcela, edit as editParcela, destroy } from "../../../util/parcela"
import InputMask from "react-input-mask"

class ParametrosForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      exercicio: "",
      mensagem: "",
      observacao: "",
      modal: false,
      //dialog
      descricao: "",
      ordem: 0,
      vencimento: "",
      desconto: "",
      valor_minimo: "",
      valor_maximo: "",
      //
      parcelas: [
        {
          descricao: "",
          ordem: 0,
          vencimento: moment(new Date()).format("YYYY-MM-DD"),
          desconto: "",
          valor_minimo: "",
          valor_maximo: ""
        }
      ],
      editingItem: 0,
      currentParcelas: [],
      isLoadingDialog: false,
      id_parcela: 0,
      confirm: false,
      id_delete: 0,
      index: 0
    }
    this.init = this.init.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.addItem = this.addItem.bind(this)
    this.removeItem = this.removeItem.bind(this)
    this.toggleParcela = this.toggleParcela.bind(this)
    this.getParcela = this.getParcela.bind(this)
    this.removeCurrentParcela = this.removeCurrentParcela.bind(this)
    this.toggleLoadingDialog = this.toggleLoadingDialog.bind(this)
    this.handleSaveParcela = this.handleSaveParcela.bind(this)
  }

  componentWillMount() {
    this.init()
  }

  handleSaveParcela(event) {
    event.preventDefault()
    let paramsF = {
      id: this.state.editingItem,
      descricao: this.state.descricao,
      ordem: this.state.ordem,
      vencimento: this.state.vencimento,
      desconto: this.state.desconto,
      valor_minimo: this.state.valor_minimo,
      valor_maximo: this.state.valor_maximo,
      parametros_id: this.state.id_parcela
    }
    let response = error => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        showNotification(
          "top",
          "center",
          `Parcela editada com sucesso`,
          "success"
        )
        this.handleClose()
        //this.reloadFaixa(paramsF)
        this.init()
      }
    }
    putParcela(this.state.editingItem, paramsF, response)
  }

  addItem() {
    var oldParcelas = this.state.parcelas
    let order
    if (this.props.match.params.id) {
      order = this.state.currentParcelas.length + this.state.parcelas.length
    } else {
      order = this.state.currentParcelas.length + this.state.parcelas.length + 1
    }

    oldParcelas.push({
      descricao: "",
      ordem: order,
      vencimento: moment(new Date()).format("YYYY-MM-DD"),
      desconto: "",
      valor_minimo: "",
      valor_maximo: ""
    })
    this.setState({
      parcelas: oldParcelas
    })
  }

  handleOpen = () => {
    this.setState({ modal: true })
  }

  handleOpenConfirm(event, index, item) {
    this.setState({
      confirm: true, index: index, id_delete: item
    })
  }

  handleClose = () => {
    this.setState({ modal: false, confirm: false })
  }

  toggleParcela(event, index, itemID) {
    this.setState(
      {
        editingItem: itemID,
        modal: !this.state.modal
      },
      () => {
        this.getParcela()
      }
    )
  }

  getParcela() {
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState(
          {
            descricao: data.descricao,
            ordem: data.ordem,
            vencimento: data.vencimento,
            desconto: data.desconto,
            valor_minimo: data.valor_minimo,
            valor_maximo: data.valor_maximo,
            id_parcela: data.parametros_id
          },
          () => {
            this.toggleLoadingDialog()
          }
        )
      }
    }
    this.toggleLoadingDialog()
    editParcela(this.state.editingItem, response)
  }

  removeItem() {
    this.setState(state => ({
      parcelas: state.parcelas.filter((_, key) => {
        return key !== state.parcelas.length - 1
      })
    }))
  }

  removeCurrentParcela(event) {
    event.preventDefault();

    var items = this.state.currentParcelas.slice()

    items.splice(this.state.index, 1)

    var sort = items.sort((a, b) => {
      return a.parcela < b.parcela ? -1 : 1;
    }).map((item, key) => {
      let ret = Object.assign({}, item, {
        parcela: key + 1
      })
      return ret
    }
    );

    this.setState({
      currentParcelas: sort
    });

    //request
    let response = error => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        );
        this.setState({
          errors: mapErrors(error)
        });
      } else {
        showNotification(
          "top",
          "center",
          `Desconto deletado com sucesso`,
          "success"
        );
        this.handleClose();
        this.init()
      }
    };
    destroy(this.state.id_delete, response)
  }

  toggleLoadingDialog() {
    this.setState(state => ({
      isLoadingDialog: !state.isLoadingDialog
    }))
  }

  reloadPreposto(newParcela) {
    var oldCurrentParcelas = this.state.currentParcelas
    oldCurrentParcelas.forEach((parcela, key) => {
      if (parcela.id === newParcela.id) {
        oldCurrentParcelas[key] = newParcela
      }
    })
    //this.toggleParcela()
  }

  handleChange(event) {
    let name = event.target.name
    let value = event.target.value
    if (name.split(".").length > 1) {
      let oldparcelas = [...this.state.parcelas]
      let components = name.split(".")
      name = components[0]
      oldparcelas[components[1]][components[2]] = value
      value = oldparcelas
    }
    this.setState(state => ({
      [name]: value,
      errors: { ...state.errors, [name]: undefined }
    }))
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let params = {
      exercicio: this.state.exercicio,
      mensagem: this.state.mensagem,
      observacao: this.state.observacao,
      tipos_de_parcelas: this.state.parcelas
    }
    let response = error => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          `Parâmetro ${
          this.props.match.params.id ? "editado" : "incluído"
          } com sucesso`,
          "success"
        )
        this.props.history.push("/iptu/cadastro")
      }
    }
    this.props.match.params.id
      ? put(this.props.match.params.id, params, response)
      : post(params, response)
  }

  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }

  init() {
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState(
          {
            exercicio: data.exercicio,
            mensagem: data.mensagem,
            observacao: data.observacao,
            currentParcelas: data.tipos_de_parcelas,
            parcelas: []
          },
          () => this.toggleLoading()
        )
        this.setState(data)
      }
    }
    if (this.props.match.params.id) {
      this.toggleLoading()
      edit(this.props.match.params.id, response)
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <LoadingContent isLoading={this.state.isLoading}>
          <Card>
            <form onSubmit={this.handleSave}>
              <CardHeader color="primary">
                <Grid container direction="row">
                  <Grid item lg={10} xs={12}>
                    <h2 className={classes.cardTitleWhite}>
                      Cadastro de Parâmetros de carnê
                    </h2>
                  </Grid>
                </Grid>
              </CardHeader>
              <CardBody>
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <InputMask
                      mask="9999"
                      value={this.state.exercicio}
                      onChange={this.handleChange}
                      maskChar=" "
                    >
                      {() => (
                        <TextField
                          id="outlined-select-currency"
                          label="Exercício"
                          fullWidth
                          required
                          value={this.state.exercicio}
                          onChange={this.handleChange}
                          name="exercicio"
                          error={this.state.errors.exercicio}
                          helperText={this.state.errors.exercicio}
                          margin="normal"
                          variant="outlined"
                        />
                      )}
                    </InputMask>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="outlined-select-currency"
                      label="Mensagem"
                      fullWidth
                      required
                      value={this.state.mensagem}
                      onChange={this.handleChange}
                      name="mensagem"
                      multiline
                      rowsMax="5"
                      error={this.state.errors.mensagem}
                      helperText={this.state.errors.mensagem}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="outlined-select-currency"
                      label="Observações"
                      fullWidth
                      multiline
                      rowsMax="5"
                      value={this.state.observacao}
                      onChange={this.handleChange}
                      name="observacao"
                      error={this.state.errors.observacao}
                      helperText={this.state.errors.observacao}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" style={{ marginTop: "2%" }}>
                  <Divider />
                  <Typography
                    className={classes.dividerFullWidth}
                    color="textSecondary"
                    variant="caption"
                  >
                    Parcelas
                  </Typography>
                </Grid>

                {this.state.currentParcelas.map((parcela, key) => {
                  return (
                    <div>
                      <Grid container direction="row" spacing={16} key={key}>
                        <Grid item xs={12} sm={12} md={2} lg={3}>
                          <TextField
                            fullWidth
                            disabled
                            margin="normal"
                            variant="outlined"
                            label="Descrição"
                            type="text"
                            value={parcela.descricao}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="date"
                            disabled
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            label="Vencimento"
                            defaultValue={moment(new Date()).format(
                              "YYYY-MM-DD"
                            )}
                            value={moment(parcela.vencimento).format(
                              "DD/MM/YYYY"
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={2}>
                          <TextField
                            fullWidth
                            disabled
                            margin="normal"
                            variant="outlined"
                            label="Desconto"
                            value={parcela.desconto ? numeral(parseFloat(parcela.desconto)).divide(100).format('0%') : ""}
                            defaulValue={parcela.desconto ? numeral(parseFloat(parcela.desconto)).divide(100).format('0%') : ""}
                            error={
                              this.state.errors[`parcelas.${key}.desconto`]
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={2}>
                          <TextField
                            fullWidth
                            disabled
                            margin="normal"
                            variant="outlined"
                            label="Valor Mínimo"
                            type="text"
                            value={parcela.valor_minimo}
                            defaultValue={parcela.valor_minimo}
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              inputComponent: NumberFormatCustom
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={2}>
                          <TextField
                            fullWidth
                            disabled
                            margin="normal"
                            variant="outlined"
                            label="Valor Máximo"
                            value={parcela.valor_maximo}
                            defaultValue={parcela.valor_maximo}
                            type="text"
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              inputComponent: NumberFormatCustom
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <Button
                            type="button"
                            fullWidth
                            onClick={event =>
                              this.handleOpenConfirm(event, key, parcela.id)
                            }
                            color="danger"
                          >
                            <Delete /> Remover Parcela
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <Button
                            fullWidth
                            type="button"
                            onClick={event =>
                              this.toggleParcela(event, key, parcela.id)
                            }
                            color="primary"
                          >
                            <Edit /> Editar Parcela
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  )
                })}

                {this.state.parcelas.map((parcela, key) => {
                  return (
                    <Grid container spacing={16} key={key}>
                      <Grid item xs={12} sm={12} md={2} lg={3}>
                        <TextField
                          fullWidth
                          required
                          margin="normal"
                          variant="outlined"
                          label="Descrição"
                          type="text"
                          name={`parcelas.${key}.descricao`}
                          onChange={this.handleChange}
                          error={this.state.errors[`tipos_de_parcelas.${key}.descricao`]}
                          helperText={this.state.errors[`tipos_de_parcelas.${key}.descricao`]}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <TextField
                          id="date"
                          type="date"
                          variant="outlined"
                          margin="normal"
                          label="Vencimento"
                          className={classes.datePicker}
                          error={
                            this.state.errors[`tipos_de_parcelas.${key}.vencimento`]
                          }
                          helperText={this.state.errors[`tipos_de_parcelas.${key}.vencimento`]}
                          defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                          name={`parcelas.${key}.vencimento`}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={2}>
                        <TextField
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          label="Desconto"
                          error={this.state.errors[`tipos_de_parcelas.${key}.desconto`]}
                          helperText={this.state.errors[`tipos_de_parcelas.${key}.desconto`]}
                          type="number"
                          name={`parcelas.${key}.desconto`}
                          onChange={this.handleChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={2}>
                        <TextField
                          error={
                            this.state.errors[`tipos_de_parcelas.${key}.valor_minimo`]
                          }
                          helperText={
                            this.state.errors[`tipos_de_parcelas.${key}.valor_minimo`]
                          }
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          label="Valor Mínimo"
                          name={`parcelas.${key}.valor_minimo`}
                          type="text"
                          onChange={event =>
                            this.handleChange({
                              target: {
                                name: `parcelas.${key}.valor_minimo`,
                                value: event.target.value
                              }
                            })
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={parcela.valor_minimo}
                          InputProps={{
                            inputComponent: NumberFormatCustom
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={2}>
                        <TextField
                          error={
                            this.state.errors[`tipos_de_parcelas.${key}.valor_maximo`]
                          }
                          helperText={
                            this.state.errors[`tipos_de_parcelas.${key}.valor_maximo`]
                          }
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          label="Valor Máximo"
                          name={`parcelas.${key}.valor_maximo`}
                          type="text"
                          onChange={event =>
                            this.handleChange({
                              target: {
                                name: `parcelas.${key}.valor_maximo`,
                                value: event.target.value
                              }
                            })
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            inputComponent: NumberFormatCustom
                          }}
                          defaulValue={parcela.valor_maximo}
                        />
                      </Grid>
                    </Grid>
                  )
                })}
                <Grid
                  container
                  direction="row"
                  style={{ marginTop: "2%" }}
                  spacing={16}
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Button
                      type="button"
                      fullWidth
                      onClick={() => this.removeItem()}
                      color="danger"
                    >
                      <Delete /> Remover Parcela
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Button
                      fullWidth
                      type="button"
                      onClick={this.addItem}
                      color="success"
                    >
                      <AddIcon /> Adicionar Parcela
                    </Button>
                  </Grid>
                </Grid>
              </CardBody>
              <CardFooter>
                <Grid container justify="center">
                  <Grid item lg={2}>
                    <Button block color="info" round type="submit">
                      <AddIcon /> Adicionar
                    </Button>
                  </Grid>
                </Grid>
              </CardFooter>
            </form>
          </Card>
        </LoadingContent>
        {/* MODAL */}
        <Dialog
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
        >
          <form onSubmit={this.handleSaveParcela}>
            <DialogTitle id="form-dialog-title">Atualiza Parcela</DialogTitle>
            <LoadingContent isLoading={this.state.isLoadingDialog}>
              <DialogContent>
                <Grid container spacing={16}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      required
                      margin="normal"
                      variant="outlined"
                      label="Descrição"
                      type="text"
                      value={this.state.descricao}
                      defaultValue={this.state.descricao}
                      name="descricao"
                      onChange={this.handleChange}
                      error={this.state.errors.descricao}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="date"
                      type="date"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      label="Vencimento"
                      error={this.state.errors.vencimento}
                      helperText={this.state.errors.vencimento}
                      name="vencimento"
                      defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                      value={this.state.vencimento}
                      onChange={this.handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={16}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      label="Desconto"
                      error={this.state.errors.desconto}
                      type="number"
                      name="desconto"
                      onChange={this.handleChange}
                      value={this.state.desconto}
                      defaultValue={this.state.desconto}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      error={this.state.errors.valor_minimo}
                      helperText={this.state.errors.valor_minimo}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      label="Valor Mínimo"
                      name="valor_minimo"
                      type="text"
                      onChange={this.handleChange}
                      value={this.state.valor_minimo}
                      defaultValue={this.state.valor_minimo}
                      InputProps={{
                        inputComponent: NumberFormatCustom
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      error={this.state.errors.valor_maximo}
                      helperText={this.state.errors.valor_maximo}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      label="Valor Máximo"
                      name="valor_maximo"
                      type="text"
                      onChange={this.handleChange}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom
                      }}
                      value={this.state.valor_maximo}
                      defaultValue={this.state.valor_maximo}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </LoadingContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="danger">
                Cancelar
              </Button>
              <Button type="submit" color="success">
                {this.state.id ? "Atualizar" : "Adicionar"}
              </Button>
            </DialogActions>
          </form>
        </Dialog>


        {/* dialog confirmacao */}
        <Dialog
          open={this.state.confirm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Você tem certeza que deseja remover essa parcela?</DialogTitle>

          <DialogActions>
            <Button onClick={this.handleClose} color="danger">
              Cancelar
              </Button>
            <Button onClick={this.removeCurrentParcela} color="success">
              Confirmar
              </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(secaoStyle)(ParametrosForm)
