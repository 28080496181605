import React from "react"
import { Link, withRouter } from "react-router-dom"
import { withStyles, Paper, MenuItem } from "@material-ui/core"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import Button from "../../components/CustomButtons/Button"

import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import InputMask from "react-input-mask"
import moment from "moment"
import Typography from "@material-ui/core/Typography"

import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import AddIcon from "@material-ui/icons/Add"
import NewReleases from "@material-ui/icons/NewReleases"
import Grid from "@material-ui/core/Grid"
import Table from "../../components/Table/Table"
import TablePagination from "@material-ui/core/TablePagination"
import Edit from "@material-ui/icons/Edit"
import Print from "@material-ui/icons/Print"
import Book from "@material-ui/icons/Book"
import Lens from "@material-ui/icons/Lens"
import {
  get,
  search,
  postDam,
  postDamAutomatico,
  getField,
  allDamOcupacao,
  imprimirDam
} from "../../util/ocupacao"
import { search as searchContribuinte } from "../../util/contribuinte"
import AsyncSelect from "../../components/AsyncSelect"
import SearchFields from "../../components/SearchFields"
import LoadingContent from "../../components/LoadingContent"
import Search from "../../components/Search"
import {
  showNotification,
  hideWaiting,
  showWaiting,
  mapErrors,
  removeMask
} from "../../util/Constants"
import housecall from "housecall"
class OcupacaoMain extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      pageCount: 0,
      perPage: 0,
      page: 0,
      ocupacoes: [],
      searchText: "",
      field: 0,

      //
      nome_dialog: "",
      errors: {},
      competencia: "",
      dias: "",
      local_id: "",
      vencimento: "",
      contribuinte_id: [],
      tipos: [],
      tipo_id: "",
      areas: [],
      area_id: "",
      dams: [],
      dam_id: ""
    }
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.getData = this.getData.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.filter = this.filter.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
    this.handleChange = this.handleChange.bind(this)

    //dialog
    this.gerarDam = this.gerarDam.bind(this)
    this.lancar = this.lancar.bind(this)
    this.automatico = this.automatico.bind(this)
    this.getFields = this.getFields.bind(this)
    this.lancamentoAutomatico = this.lancamentoAutomatico.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
    this.imprimirAllDam = this.imprimirAllDam.bind(this)
    this.getOcupacaoById = this.getOcupacaoById.bind(this)
    this.imprimirOnlyDam = this.imprimirOnlyDam.bind(this)
    this.setLoadingFalse = this.setLoadingFalse.bind(this)
  }
  setLoadingFalse() {
    this.setState({
      isLoading: false
    })
  }

  imprimirOnlyDam(key) {
    showWaiting()
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        const reader = new FileReader()
        reader.addEventListener("loadend", e => {
          const text = e.srcElement.result
          try {
            let error = JSON.parse(text)

            let status = error.status
            if (status === "pago") {
              showNotification(
                "top",
                "center",
                `Ocorreram erros! ${error.message}!`,
                "warning"
              )
            } else if(status === "vencido") {
              showNotification(
                "top",
                "center",
                `Ocorreram erros! ${error.message}!`,
                "warning"
              )
            }
          } catch (e) {
            showNotification(
              "top",
              "center",
              `Ocorreram erros! ${error.message}`,
              "danger"
            )
          }
        })

        reader.readAsText(error.response.data)
      } else {
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    imprimirDam(this.state.dams[key].id, response)
  }

  componentWillMount() {
    this.getData()
  }

  imprimirAllDam() {
    showWaiting()
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        hideWaiting()
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    allDamOcupacao(
      removeMask(this.state.competencia),
      this.state.area_id,
      this.state.contribuinte_id.value,
      response
    )
  }

  gerarDam(id) {
    showWaiting()
    let params = {
      competencia: removeMask(this.state.competencia),
      dias: this.state.dias,
      local_id: this.state.local_id,
      vencimento: this.state.vencimento
    }
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        if (error.response.data.errors) {
          this.setState({
            errors: mapErrors(error)
          })
        } else {
          showNotification(
            "top",
            "center",
            error.response.data.message,
            "danger"
          )
        }
      } else {
        hideWaiting()
        this.handleClose()
        showNotification("top", "center", `DAM emitido com sucesso`, "success")
      }
    }
    postDam(params, response)
  }

  lancar(key, nome) {
    this.setState({
      modal: true,
      local_id: this.state.ocupacoes[key].id,
      nome_dialog: nome
    })
  }

  handleClose = () => {
    this.setState({
      modal: false,
      competencia: "",
      dias: "",
      local_id: "",
      vencimento: "",
      contribuinte_id: [],
      tipos: [],
      tipo_id: ""
    })
  }

  getFields() {
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState({
          tipos: data.tipos,
          areas: data.areas
        })
      }
    }
    getField(response)
  }

  handlePageClick(data) {
    //this.getData(data.selected + 1);
  }

  handleChangeFilters = event => {
    this.setState({ filterField: event.target.value }, () => {
      if (this.state.searchActive) {
        this.filter({ target: { value: this.state.searchText } })
      }
    })
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: event.target.value,
      errors: errors
    })
  }

  automatico(nome) {
    this.setState(
      {
        modal: true,
        nome_dialog: nome
      },
      () => {
        this.getFields()
      }
    )
  }

  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  componentDidMount() {
    this.queue.on("idle", this.setLoadingFalse)
  }
  filter(event,pagina) {
    let searchText = event.target.value
    let newState=     {
      isLoading:true,
      searchText: searchText,
      searchActive: searchText !== ""
    }
    if(pagina == undefined){
      newState["page"] = 0
    }
    this.setState(
  newState,
      () => {
        if (this.state.searchActive) {
          let filtersCheckbox = this.state.filterField || ""
          this.queue.push(() =>
            search(searchText, filtersCheckbox, undefined, (error, data) => {
              this.setState({isLoading:true})
              if (error) {
                showNotification(
                  "top",
                  "center",
                  "Ocorreram erros, verifique sua conexão com a internet",
                  "danger"
                )
              } else {
                if (this.state.searchText) {
                  this.setState({
                    ocupacoes: data.data,
                    pageCount: data.total,
                    perPage: data.per_page,
                  })
                }
              }
            },pagina)
          )
        } else {
        this.getData()
       
        }
      }
    )
  }
  getData(page) {

    this.setState({isLoading:true})

    get(page, (error, data) => {
      this.setState({isLoading:false})
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState({
          ocupacoes: data.data,
          pageCount: data.total,
          perPage: data.per_page,

        })
      }
    })
  }

  getOcupacaoById(id) {
    console.log(id)
    return this.state.ocupacoes.filter(item => {
      return id === item.id
    })[0]
  }

  getDams(key, nome) {
    let dam = this.getOcupacaoById(this.state.ocupacoes[key].id)
    console.log("res: ", dam)
    this.setState(
      {
        modal: true,
        dams: dam.dams,
        nome_dialog: nome
      },
      () => {
        console.log("dam: ", this.state.dams)
      }
    )
  }

  lancamentoAutomatico() {
    showWaiting()
    let params = {
      competencia: removeMask(this.state.competencia),
      dias: this.state.dias,
      local_id: this.state.local_id,
      vencimento: this.state.vencimento,
      tipo_id: this.state.tipo_id,
      area_id: this.state.area_id,
      contribuinte_id: this.state.contribuinte_id.value
    }
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        if (error.response.data.errors) {
          this.setState({
            errors: mapErrors(error)
          })
        } else {
          showNotification(
            "top",
            "center",
            error.response.data.message,
            "danger"
          )
        }
      } else {
        hideWaiting()
        this.imprimirAllDam()
        this.handleClose()
        showNotification("top", "center", `DAM emitido com sucesso`, "success")
      }
    }
    postDamAutomatico(params, response)
  }


  handleChangePage = (event, page) => {
    var pagina;
    pagina = page + 1;
    this.setState({page: page})
    if(page < 0){
      return;
    }
    if (this.state.searchText.toString().trim() != "") {
      this.filter({target: {value: this.state.searchText}}, pagina, page)
      return;
    }
    



   
  };

  handleEdit(key) {
    this.props.history.push("/ocupacao/edit/" + this.state.ocupacoes[key].id)
  }

  handleFieldChange(value) {
    this.setState({
      field: value
    })
  }

  loadOptions(inputValue, callback) {
    searchContribuinte(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let contribuintes = data.data.map(contribuinte => {
            return {
              value: contribuinte.id,
              label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                ""} - Nº DOC: ${contribuinte.cpf_cnpj || ""}`
            }
          })
          callback(contribuintes)
        }
      }
    )
  }

  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código" },
      { value: "descricao", label: "Nome Ocupação" },
      { value: "contribuinte_id", label: "Código Contribuinte" },
      { value: "contribuinte_cpf_cnpj", label: "Documento Contribuinte" },
      { value: "contribuinte_nome_razao_social", label: "Nome Contribuinte" }
    ]
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container spacing={16} direction="row">
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <h2 className={classes.cardTitleWhite}>Ocupação de Imóvel</h2>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <Button
                  block
                  color="rose"
                  round
                  onClick={() => this.automatico("automatico")}
                >
                  <NewReleases /> DAM Automático
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <Link to="/ocupacao/create" style={{ textDecoration: "none" }}>
                  <Button block color="success" round>
                    <AddIcon /> Adicionar
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Search
              label="Pesquise aqui"
              value={this.state.filterField}
              onChangeText={this.filter}
              onChangeFields={this.handleChangeFilters}
              fields={fields}
            />
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  tableHeaderColor="primary"
                  actions={[
                    {
                      title: "Editar",
                      icon: (
                        <Edit
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      ),
                      onClick: this.handleEdit
                    },
                    {
                      title: "Emitir DAM",
                      icon: (
                        <Book
                          className={
                            classes.tableActionButtonIcon + " " + classes.book
                          }
                        />
                      ),
                      onClick: key => this.lancar(key, "manual")
                    },
                    {
                      title: "Gerar DAM",
                      icon: (
                        <Print
                          className={
                            classes.tableActionButtonIcon + " " + classes.ficha
                          }
                        />
                      ),
                      onClick: key => this.getDams(key, "dam")
                    }
                  ]}
                  tableHead={[
                    "Código",
                    "Contribuinte",
                    "CPF/CNPJ",
                    "Tipo Imóvel"
                  ]}
                  tableData={this.state.ocupacoes.map((item, key) => {
                    return [
                      item.id + "",
                      item.contribuinte.nome_razao_social,
                      item.contribuinte.cpf_cnpj,
                      item.tipo.nome,
                      key
                    ]
                  })}
                />
                { (
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[]}
                    count={this.state.pageCount}
                    rowsPerPage={this.state.perPage?this.state.perPage:0}
                    page={this.state.page?this.state.page:0}
                    backIconButtonProps={{
                      "aria-label": "Previous Page"
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page"
                    }}
                    SelectProps={{
                      native: true
                    }}

                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                )}
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>

        {/* MODAL */}
        <Dialog
          open={this.state.modal}
          onClose={this.handleClose}
          maxWidth="lg"
          aria-labelledby="form-dialog-title"
          fullWidth
          style={{ zIndex: "3" }}
        >
          <LoadingContent isLoading={this.state.isLoadingDialog}>
            {this.state.nome_dialog === "dam" && (
              <div>
                <DialogTitle id="form-dialog-title">DAM</DialogTitle>
                <DialogContent>
                  <Grid className={classes.legend} container direction="row">
                    <Lens className={classes.dot + " " + classes.dotDanger} />
                    DAM Lançado na dívida
                    <Lens className={classes.dot + " " + classes.dotSuccess} />
                    DAM Pago
                  </Grid>
                  <form onSubmit={this.handleSaveParcela}>
                    <Paper className={classes.tableWrapper}>
                      <Table
                        getRowColor={(key, classes) => {
                          if (this.state.dams[key].lancado_na_divida) {
                            return classes.dangerTableRow
                          } else if (this.state.dams[key].pago) {
                            return classes.successTableRow
                          } else {
                            return ""
                          }
                        }}
                        tableHeaderColor="primary"
                        fullWidth
                        actions={[
                          {
                            title: "Imprimir",
                            icon: (
                              <Print
                                className={
                                  classes.tableActionButtonIcon +
                                  " " +
                                  classes.edit
                                }
                              />
                            ),
                            onClick: key => this.imprimirOnlyDam(key)
                          }
                        ]}
                        tableHead={["#", "Competência"]}
                        tableData={this.state.dams.map((item, key) => {
                          return [item.id + "", item.competencia, key]
                        })}
                      />
                    </Paper>
                  </form>
                </DialogContent>
              </div>
            )}
            {this.state.nome_dialog === "manual" && (
              <div>
                <DialogTitle id="form-dialog-title">
                  Lançamento Manual
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={16} direction="row">
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <InputMask
                        mask="9999/99"
                        value={this.state.competencia}
                        onChange={this.handleChange}
                      >
                        {() => (
                          <TextField
                            id="outlined-name"
                            label="Competência"
                            name="competencia"
                            helperText={this.state.errors.competencia}
                            fullWidth
                            error={this.state.errors.competencia}
                            value={this.state.competencia}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        id="outlined-select-currency"
                        name="vencimento"
                        label="Data do Vencimento"
                        fullWidth
                        type="date"
                        value={this.state.vencimento || ""}
                        error={this.state.errors.vencimento}
                        helperText={this.state.errors.vencimento}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                        defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        id="outlined-select-currency"
                        name="dias"
                        label="Dias"
                        fullWidth
                        type="number"
                        value={this.state.dias || ""}
                        error={this.state.errors.dias}
                        helperText={this.state.errors.dias}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
              </div>
            )}
            {this.state.nome_dialog === "automatico" && (
              <div>
                <DialogTitle id="form-dialog-title">
                  Lançamento Automático
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={16} direction="row">
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputMask
                        mask="9999/99"
                        value={this.state.competencia}
                        onChange={this.handleChange}
                      >
                        {() => (
                          <TextField
                            id="outlined-name"
                            label="Competência"
                            name="competencia"
                            helperText={this.state.errors.competencia}
                            fullWidth
                            error={this.state.errors.competencia}
                            value={this.state.competencia}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="outlined-select-currency"
                        name="dias"
                        label="Dias"
                        fullWidth
                        type="number"
                        value={this.state.dias || ""}
                        error={this.state.errors.dias}
                        helperText={this.state.errors.dias}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Typography
                    className={classes.dividerFullWidth}
                    color="textSecondary"
                    variant="caption"
                    style={{ marginTop: "2%" }}
                  >
                    Os campos a seguir não são obrigatórios para realização do
                    lançamento automático
                  </Typography>
                  <Grid container direction="row" spacing={16}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ marginTop: "2%" }}
                    >
                      <SearchFields
                        name="filterField"
                        value={this.state.filterField || ""}
                        onChangeFields={this.handleChange}
                        fields={fields}
                      />
                      <AsyncSelect
                        className={
                          `form-control form-control-alternative form-select Select-menu-outer ${
                            this.props.error ? "has-danger" : ""
                            }`
                        }
                        value={this.state.contribuinte_id}
                        onChange={this.handleChange}
                        loadOptions={this.loadOptions}
                        error={this.state.errors.contribuinte_id}
                        helperText={this.state.errors.contribuinte_id}
                        name="contribuinte_id"
                        placeholder="Contribuinte"
                        message="Pesquise o contribuinte"
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        name="tipo_id"
                        label="Tipo do Imóvel"
                        fullWidth
                        value={this.state.tipo_id}
                        error={this.state.errors.tipo_id}
                        helperText={this.state.errors.tipo_id}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      >
                        {this.state.tipos.map((option, key) => (
                          <MenuItem key={key} value={option.id}>
                            {option.nome}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        name="area_id"
                        label="Área"
                        fullWidth
                        value={this.state.area_id}
                        error={this.state.errors.area_id}
                        helperText={this.state.errors.area_id}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      >
                        {this.state.areas.map((option, key) => (
                          <MenuItem key={key} value={option.id}>
                            {option.nome}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <TextField
                        id="outlined-select-currency"
                        name="vencimento"
                        label="Data do Vencimento"
                        fullWidth
                        type="date"
                        value={this.state.vencimento || ""}
                        error={this.state.errors.vencimento}
                        helperText={this.state.errors.vencimento}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                        defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
              </div>
            )}
          </LoadingContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="danger">
              Cancelar
            </Button>
            <Button
              onClick={
                this.state.nome_dialog === "manual"
                  ? this.gerarDam
                  : this.lancamentoAutomatico
              }
              color="success"
            >
              Gerar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
OcupacaoMain = withRouter(OcupacaoMain)
export default withStyles(contribuinteStyle)(OcupacaoMain)
