import axios from "axios"
import { defaultHeaders } from "./Constants"


export function login(params, callback) {
  let address = process.env.REACT_APP_API.split("api/").join("oauth/token")
  params.grant_type = "password"
  params.client_id = process.env.REACT_APP_CLIENT_ID
  params.client_secret = process.env.REACT_APP_CLIENT_SECRET
  axios
    .post(address, params)
    .then(response => {
      localStorage.token = response.data.access_token
      me((error, data) => {
        if (error) {
          callback(error)
        } else {
          localStorage.me = JSON.stringify(data)
          callback(undefined, response.data)
        }
      })
      
    })
    .catch(error => {
      callback(error)
    })
}

export function me(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url =
    process.env.REACT_APP_API +
    "me"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}