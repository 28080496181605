import React from "react";

import Grid from "@material-ui/core/Grid";
import Fade from "react-reveal/Fade";
import TextField from "@material-ui/core/TextField";
import { MenuItem, withStyles } from "@material-ui/core";

import logradouroStyle from "../../assets/css/layout/logradouroStyle";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import {
    showNotification,
    mapErrors,
    hideWaiting,
    showWaiting
} from "../../util/Constants";
import AsyncSelect from "../../components/AsyncSelect";
import { search } from "../../util/emissaoitbi";
import { post, getField, destroy, searchBaixa } from "../../util/baixaItbi";
import SearchFields from "../../components/SearchFields";
import Typography from "@material-ui/core/Typography";
import CardFooter from "../../components/Card/CardFooter";
import moment from "moment";
import numeral from "../../util/numeral";

import DialogCustom from "../DialogCustom"
import Delete from "@material-ui/icons/Delete"
import NumberFormatCustom from "../../components/NumberFormatCustom"

const initialState = {
    dam_itbi: [],
    id_dam_itbi: [],
    errors: {},
    filterField: "",
    dam: [],
    id_dam: 0,
    data_vencimento: new Date(),
    valor: "",
    data_deposito: new Date(),
    data_pagamento: new Date(),
    valor_pago: "",
    multa: "",
    agentes: [],
    id_agente: 0,
    agencia: "",
    conta: "",

    transmitente: {},
    modal: false,
    openConfirm: false,
    filterFieldDialog: ""
};

class BaixaItbiForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;

        this.loadOptions = this.loadOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeFilters = this.handleChangeFilters.bind(this);
        this.getDamById = this.getDamById.bind(this);
        this.init = this.init.bind(this);
        this.getAgenciaConta = this.getAgenciaConta.bind(this);
        this.handleSave = this.handleSave.bind(this);

        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.loadBaixa = this.loadBaixa.bind(this)
        this.handleOpenConfirm = this.handleOpenConfirm.bind(this)
        this.handleCloseConfirm = this.handleCloseConfirm.bind(this)
    }

    loadBaixa(inputValue, callback) {
        searchBaixa(inputValue, this.state.filterFieldDialog, undefined, (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
                callback([])
            } else {
                let baixa = data.data.map(item => {
                    return {
                        value: item.id,
                        label: `Código Baixa: ${item.dam_id} - 
            Agência: ${item.agencia} Conta: ${item.conta} - 
            Dt.Pagamento: ${moment(item.data_pagamento).format("DD/MM/YYYY")} - 
            Dt.Deposito: ${moment(item.data_deposito).format("DD/MM/YYYY")}`
                    }
                })
                callback(baixa)
            }
        })
    }

    handleOpenConfirm() {
        this.setState({
            openConfirm: true
        })
    }

    handleCloseConfirm() {
        this.setState({
            openConfirm: false
        })
    }

    handleOpen() {
        this.setState({
            modal: true
        })
    }

    handleDelete() {
        showWaiting()
        let response = error => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                );
                this.setState({
                    errors: mapErrors(error)
                });
            } else {
                showNotification(
                    "top",
                    "center",
                    `Baixa Manual deletada com sucesso`,
                    "success"
                );
                this.handleClose()
                this.handleCloseConfirm()
            }
        };
        destroy(this.state.codigo.value, response)
    }

    handleClose() {
        this.setState({
            modal: false
        })
    }

    componentWillMount() {
        this.init();
    }

    loadOptions(inputValue, callback) {
        search(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    );
                    callback([]);
                } else {
                    let dam_itbi = data.data.map(dam => {
                        return {
                            value: dam.id,
                            label: `${dam.id} - Transmitente: ${dam.transmitente
                                .nome_razao_social || ""} Adquirente: ${dam.adquirente
                                .nome_razao_social || ""}`
                        };
                    });
                    this.setState({
                        dam_itbi: data.data
                    });
                    callback(dam_itbi);
                }
            }
        );
    }

    getDamById(id) {
        return this.state.dam_itbi.filter(item => {
            return id === item.id;
        })[0];
    }

    handleChangeFilters = event => {
        this.setState({ filterField: event.target.value });
    };

    init() {
        let response = (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                );
            } else {
                this.setState({
                    agentes: data.agentes
                });

                this.setState(data);
            }
        };
        getField(response);
    }

    getAgenciaConta(id) {
        return this.state.agentes.filter(item => {
            return id === item.id;
        })[0];
    }

    handleChange(event) {
        let name = event.target.name;
        let errors = this.state.errors;
        errors[name] = undefined;

        this.setState(
            {
                [name]: event.target.value,
                errors: errors
            },
            () => {
                if (this.state.id_dam_itbi && name === "id_dam_itbi") {
                    let dam = this.getDamById(this.state.id_dam_itbi.value)
                    this.setState({
                        data_vencimento: dam.vencimento,
                        valor: dam.valor,
                        valor_pago: dam.valor,
                        multa: dam.multa
                    });
                }
            }
        );
    }

    handleSave(event) {
        event.preventDefault();
        showWaiting();
        let params = {
            dam_id: this.state.id_dam_itbi.value,
            agente_recebedor_id: this.state.id_agente,
            data_pagamento:
                moment(this.state.data_pagamento).format("YYYY-MM-DD") + "",
            data_deposito: moment(this.state.data_deposito).format("YYYY-MM-DD") + "",
            valor_pago: this.state.valor_pago
        };
        let response = (error, data) => {
            if (error) {
                hideWaiting();
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                );
                this.setState({
                    errors: mapErrors(error)
                });
            } else {
                hideWaiting();
                showNotification(
                    "top",
                    "center",
                    `Baixa de ITBI realizada com sucesso`,
                    "success"
                );
                this.setState(initialState);
                this.props.history.push("/itbi/movimentos");
            }
        };
        post(params, response);
    }

    render() {
        const { classes } = this.props;
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "Código DAM ITBI" },
            { value: "transmitente_id", label: "Código do Transmitente" },
            { value: "adquirente_id", label: "Código do Adquirente" },
            { value: "transmitente_nome_razao_social", label: "Nome Transmitente" },
            { value: "adquirente_nome_razao_social", label: "Nome Adquirente" }
        ];
        const fieldsDialog = [
            { value: "", label: "Todos os campos" },
            { value: "dam_id", label: "Código Baixa" },
            { value: "data_pagamento", label: "Data Pagamento" },
            { value: "data_deposito", label: "Data Depósito" }
        ]
        return (
            <div>
                <Card>
                    <CardHeader color="primary">
                        <Grid container direction="row">
                            <Grid item lg={10} xs={10}>
                                <h2 className={classes.cardTitleWhite}>Baixa de ITBI</h2>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <Fade bottom>
                        <form onSubmit={this.handleSave}>
                            <CardBody>
                                <Grid container direction="row" spacing={16}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <SearchFields
                                            name="filterField"
                                            value={this.state.filterField}
                                            onChangeFields={this.handleChangeFilters}
                                            fields={fields}
                                        />
                                        <AsyncSelect
                                            className={
                                                `form-control form-control-alternative form-select Select-menu-outer ${
                                                    this.props.error ? "has-danger" : ""
                                                }`
                                            }
                                            value={this.state.id_dam_itbi}
                                            onChange={this.handleChange}
                                            loadOptions={this.loadOptions}
                                            name="id_dam_itbi"
                                            placeholder="DAM ITBI"
                                            message="Pesquise o DAM"
                                            error={this.state.errors.dam_id}
                                            helperText={this.state.errors.dam_id}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={16}>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField
                                            id="outlined-select-currency"
                                            name="vencimento"
                                            label="Data do Vencimento"
                                            fullWidth
                                            type="date"
                                            disabled
                                            value={this.state.data_vencimento}
                                            error={this.state.errors.data_vencimento}
                                            helperText={this.state.errors.data_vencimento}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField
                                            id="outlined-select-currency"
                                            label="Valor"
                                            fullWidth
                                            disabled
                                            value={
                                                "R$" +
                                                numeral(parseFloat(this.state.valor))
                                                    .format("0.0,00")
                                                    .slice(0, -2)
                                            }
                                            error={this.state.errors.valor}
                                            helperText={this.state.errors.valor}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            defaultValue=""
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField
                                            id="outlined-select-currency"
                                            label="Multa"
                                            fullWidth
                                            disabled
                                            value={
                                                "R$" +
                                                numeral(parseFloat(this.state.multa))
                                                    .format("0.0,00")
                                                    .slice(0, -2)
                                            }
                                            error={this.state.errors.multa}
                                            helperText={this.state.errors.multa}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            defaultValue=""
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={16}>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="outlined-select-currency"
                                            name="data_deposito"
                                            label="Data do Depósito"
                                            fullWidth
                                            type="date"
                                            error={this.state.errors.data_deposito}
                                            helperText={this.state.errors.data_deposito}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="outlined-select-currency"
                                            name="data_pagamento"
                                            label="Data do Pagamento"
                                            fullWidth
                                            type="date"
                                            error={this.state.errors.data_pagamento}
                                            helperText={this.state.errors.data_pagamento}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                        />
                                    </Grid>

                                </Grid>
                                <Grid container direction="row" spacing={16}>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="outlined-select-currency"
                                            label="Valor Pago"
                                            fullWidth
                                            name="valor_pago"
                                            value={this.state.valor_pago}
                                            error={this.state.errors.valor_pago}
                                            helperText={this.state.errors.valor_pago}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            defaultValue=""
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            required
                                            name="id_agente"
                                            label="Agente Recebedor"
                                            fullWidth
                                            value={this.state.id_agente}
                                            error={this.state.errors.id_agente}
                                            helperText={this.state.errors.id_agente}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                        >
                                            {this.state.agentes.map((option, key) => (
                                                <MenuItem key={key} value={option.id}>
                                                    {option.nome}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                </Grid>
                            </CardBody>
                            <CardFooter>
                                <Grid container justify="center">
                                    <Grid item lg={2}>
                                        <Button block color="info" round type="submit">
                                            Gerar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardFooter>
                        </form>
                    </Fade>
                </Card>

                <DialogCustom
                    open={this.state.modal}
                    nameSearch="filterFieldDialog"
                    valueSearch={this.state.filterFieldDialog || ""}
                    onChangeFields={this.handleChange}
                    fields={fieldsDialog}
                    onChange={this.handleChange}
                    optionsAsync={this.loadBaixa}
                    nameAsync="codigo"
                    message="Pesquise a Baixa Manual ITBI"
                    placeholder="Baixa Manual"
                    handleClose={this.handleClose}
                    isDisabled={this.state.codigo ? false : true}
                    handleSave={this.handleDelete}
                    handleCloseConfirm={this.handleCloseConfirm}
                    handleOpenConfirm={this.handleOpenConfirm}
                    openConfirm={this.state.openConfirm}
                />
            </div>
        );
    }
}

export default withStyles(logradouroStyle)(BaixaItbiForm);
