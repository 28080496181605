import React, { Component } from "react"
import Select from "react-select"
export default class SelectCustom extends Component {
  shouldComponentUpdate(nextProps) {
    var shouldUpdate = false

    Object.keys(nextProps).forEach(key => {
      var currentProp = this.props[key]
      var nextProp = nextProps[key]
      if (shouldUpdate === false) {
        shouldUpdate = !(nextProp === currentProp)
      }
    })
    return shouldUpdate
  }

  render() {
    var options = this.props.options || []
    return (
      <div className="form-group">
        <label className="form-control-label" htmlFor={this.props.name}>
          {this.props.label}
        </label>
        <Select
          className={`form-control form-control-alternative form-select Select-menu-outer ${
            this.props.error ? "has-danger" : ""
            }`}
          style={{ zIndex: "3" }}
          isDisabled={this.props.disabled}
          isClearable={true}
          placeholder={this.props.placeholder}
          noResultsText="Nenhum resultado"
          options={options}
          value={this.props.value}
          loadingMessage={() => "Carregando..."}
          noOptionsMessage={() => this.props.message}
          isMulti={this.props.isMulti === false ? false : true}
          errors={this.props.errors}
          isSearchable={this.props.isSearchable || true}
          onChange={selected =>
            this.props.onChange({
              target: { value: selected, name: this.props.name }
            })
          }
        />

        {this.props.error && (
          <span className="text-danger error-span">{this.props.error}</span>
        )}
      </div>
    )
  }
}
