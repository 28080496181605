import React, { Component } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import dashboardStyle from "../../assets/css/layout/dashboardStyle";
// import image from "../../assets/img/pacatuba_brasao.png";
import image from "../../assets/img/bg.svg";
import logo from "../../assets/img/favicon.ico";
import { withStyles } from "@material-ui/core";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/maps";
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history) {
      if (e.history.location.pathname !== e.location.pathname) {
        this.mainPanel.scrollTop = 0;
        if (this.state.mobileOpen) {
          this.setState({ mobileOpen: false });
        }
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }

  getTitle() {
    if (window.location.pathname.includes("/dashboard")) {
      return "Dashboard";
    }
    if (window.location.pathname.includes("/cadastro")) {
      return "Cadastro";
    }
    if (window.location.pathname.includes("/processamentos")) {
      return "processamentos";
    }
    if (window.location.pathname.includes("/movimentos")) {
      return "movimentos";
    }
    if (window.location.pathname.includes("/dam")) {
      return "DAM";
    }
    if (window.location.pathname.includes("/demonstrativos")) {
      return "Demonstrativos";
    }
    if (window.location.pathname.includes("/pesquisas")) {
      return "pesquisas";
    }
    if (window.location.pathname.includes("/relatorios")) {
      return "relatorios";
    }
    if (window.location.pathname.includes("/ferramentas")) {
      return "ferramentas";
    }
    if (window.location.pathname.includes("/sistema")) {
      return "sistema";
    }
  }

  render() {
    const { classes, ...rest } = this.props;
    let menuItens = [
      {
        path: "/dashboard",
        sidebarName: "Início",
        navbarName: "Início",
        icon: "home"
      },
      {
        path: "/gerais",
        sidebarName: "Cadastros Gerais",
        navbarName: "Cadastros Gerais",
        icon: "format_list_bulleted"
      },
      {
        path: "/movimentos",
        sidebarName: "Movimentos Gerais",
        navbarName: "Movimentos Gerais",
        icon: "cached"
      },
      {
        path: "/relatorios",
        sidebarName: "Relatórios Gerais",
        navbarName: "Relatórios Gerais",
        icon: "assessment"
      },
      {
        path: "/protocolo",
        sidebarName: "Protocolo Interno",
        navbarName: "Protocolo Interno",
        icon: "assignment"
      },
      {
        path: "/contribuintes/cadastro",
        sidebarName: "Contribuintes",
        navbarName: "Contribuintes",
        icon: "group",
        // subItems: [
        //   {
        //     path: "/contribuintes/cadastro",
        //     icon: "format_list_bulleted",
        //     name: "Cadastro"
        //   }
        // ]
      },
      {
        path: "/iptu",
        sidebarName: "IPTU",
        navbarName: "IPTU",
        icon: "ballot",
        subItems: [
          {
            path: "/iptu/cadastro",
            icon: "format_list_bulleted",
            name: "Cadastro IPTU"
          },
          {
            path: "/iptu/movimentos",
            icon: "360",
            name: "Movimentos"
          },
          {
            path: "/iptu/relatorios",
            icon: "insert_chart_outlined",
            name: "Relatórios"
          }
        ]
      },
      {
        path: "/alvara",
        sidebarName: "Alvará",
        navbarName: "Alvará",
        icon: "class",
        subItems: [
          {
            path: "/alvara/cadastro",
            icon: "format_list_bulleted",
            name: "Cadastro Alvará"
          },
          {
            path: "/alvara/movimentos",
            icon: "360",
            name: "Movimentos"
          },
          {
            path: "/alvara/relatorios",
            icon: "insert_chart_outlined",
            name: "Relatórios"
          }
        ]
      },
      {
        path: "/itbi",
        sidebarName: "ITBI",
        navbarName: "ITBI",
        icon: "swap_horizontal",
        subItems: [
          {
            path: "/itbi/movimentos",
            icon: "360",
            name: "Movimentos"
          },
          {
            path: "/itbi/relatorios",
            icon: "insert_chart_outlined",
            name: "Relatórios"
          }
        ]
      },
      {
        path: "/divida",
        sidebarName: "Dívida Ativa",
        navbarName: "Dívida Ativa",
        icon: "attach_money",
        subItems: [
          {
            path: "/divida/cadastro",
            icon: "format_list_bulleted",
            name: "Cadastro"
          },
          {
            path: "/divida/movimento",
            icon: "360",
            name: "Movimento"
          },
          {
            path: "/divida/processamento",
            icon: "loop",
            name: "Processamentos"
          },
          {
            path: "/divida/relatorios",
            icon: "insert_chart_outlined",
            name: "Relatórios"
          }
        ]
      },
      {
        path: "/damavulso",
        sidebarName: "DAM Avulso",
        navbarName: "DAM Avulso",
        icon: "insert_drive_file",
        subItems: [
          {
            path: "/damavulso/cadastro",
            icon: "format_list_bulleted",
            name: "Cadastro"
          },
          {
            path: "/damavulso/relatorios",
            icon: "insert_chart_outlined",
            name: "Relatórios"
          }
        ]
      },
      {
        path: "/baixaautomatica",
        sidebarName: "Baixas Automáticas",
        navbarName: "Baixas Automáticas",
        icon: "restore"
      },
      {
        path: "/projeto",
        sidebarName: "Projeto de Construção Civil",
        navbarName: "Projeto de Construção Civil",
        icon: "build"
      },
      {
        path: "/ocupacao",
        sidebarName: "Ocupação de Imóvel",
        navbarName: "Ocupação de Imóvel",
        icon: "location_city"
      }
    ];
    return (
      <div ref="mainPanel">
        <Sidebar
          routes={menuItens}
          logoText={`Sistema de arrecadacão Municipal Prefeitura de ${process.env.REACT_APP_PREFEITURA}`}
          logo={logo}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="as"
          {...rest}
        />
        <div className={classes.mainPanel} ref="mainPainel">
          <Header
            routes={menuItens}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          <div className={classes.content}>
            <div className={classes.container}>{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

//Layout = withRouter(Layout)
export default withStyles(dashboardStyle)(Layout);
