import {defaultHeaders} from "./Constants";
import axios from "axios";

export function get(params, callBack) {
    let config = {
        headers: defaultHeaders()
        ,
        responseType: "blob"
    }

    let url =
        process.env.REACT_APP_API + `relatorios/fechamentoDaArrecadacao?`

    Object.keys(params).map(key=>{
        url += `${key}=${params[key]}`
    })
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}
