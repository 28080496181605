import React from "react"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import AddIcon from "@material-ui/icons/Add"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Button from "../../../components/CustomButtons/Button"
import secaoStyle from "../../../assets/css/layout/secaoStyle"
import { withStyles } from "@material-ui/core"
import { post, edit, put } from "../../../util/motivoiptu"
import { showNotification, mapErrors, showWaiting, hideWaiting } from "../../../util/Constants"
import LoadingContent from "../../../components/LoadingContent"

class MotivosIptuForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      descricao: "",
      artigo: "",
      inciso: ""
    }
    this.init = this.init.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  componentWillMount() {
    this.init()
  }
  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: event.target.value,
      errors: errors
    })
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let params = {
      descricao: this.state.descricao,
      artigo: this.state.artigo,
      inciso: this.state.inciso
    }
    let response = error => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          `Motivo ${
          this.props.match.params.id ? "editada" : "incluída"
          } com sucesso`,
          "success"
        )
        this.props.history.push("/iptu/cadastro")
      }
    }
    this.props.match.params.id
      ? put(this.props.match.params.id, params, response)
      : post(params, response)
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  init() {
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.toggleLoading()
        this.setState({
          descricao: data.descricao,
          artigo: data.artigo,
          inciso: data.inciso
        })
        this.setState(data)
      }
    }
    if (this.props.match.params.id) {
      this.toggleLoading()
      edit(this.props.match.params.id, response)
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <LoadingContent isLoading={this.state.isLoading}>
          <Card>
            <form onSubmit={this.handleSave}>
              <CardHeader color="primary">
                <Grid container direction="row">
                  <Grid item lg={10} xs={12}>
                    <h2 className={classes.cardTitleWhite}>
                      Cadastro de Motivos de isenção IPTU
                    </h2>
                  </Grid>
                </Grid>
              </CardHeader>
              <CardBody>
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="outlined-select-currency"
                      label="Descrição"
                      fullWidth
                      required
                      value={this.state.descricao}
                      onChange={this.handleChange}
                      name="descricao"
                      error={this.state.errors.descricao}
                      helperText={this.state.errors.descricao}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={16}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="outlined-select-currency"
                      fullWidth
                      label="Artigo"
                      value={this.state.artigo}
                      onChange={this.handleChange}
                      name="artigo"
                      error={this.state.errors.artigo}
                      helperText={this.state.errors.artigo}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="outlined-select-currency"
                      fullWidth
                      label="Inciso"
                      value={this.state.inciso}
                      onChange={this.handleChange}
                      name="inciso"
                      error={this.state.errors.inciso}
                      helperText={this.state.errors.inciso}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardBody>
              <CardFooter>
                <Grid container justify="center">
                  <Grid item lg={2}>
                    <Button block color="info" round type="submit">
                      <AddIcon />{" "}
                      {this.props.match.params.id ? "Atualizar" : "Adicionar"}
                    </Button>
                  </Grid>
                </Grid>
              </CardFooter>
            </form>
          </Card>
        </LoadingContent>
      </div>
    )
  }
}

export default withStyles(secaoStyle)(MotivosIptuForm)
