import axios from "axios"
import { defaultHeaders } from "./Constants"

export function get(page, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "ultilizacaoDeVeiculos"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function post(params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "ultilizacaoDeVeiculos"
  axios
    .post(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getCreate(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "ultilizacaoDeVeiculos/create"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function put(id, params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "ultilizacaoDeVeiculos/" + id
  axios
    .put(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
export function edit(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `ultilizacaoDeVeiculos/${id}/edit`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

// export function search(searchText, field, onStart, callBack) {
//   let config = {
//     headers: defaultHeaders(),
//     data: { a: "a" }
//   }
//   let url =
//     process.env.REACT_APP_API +
//     `search/taxas?text=${searchText}&fields=${field}`
//   if (onStart) {
//     onStart()
//   }
//   return axios
//     .get(url, config)
//     .then(response => {
//       callBack(undefined, response.data)
//     })
//     .catch(error => {
//       callBack(error)
//     })
// }
