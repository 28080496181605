import React from "react"

import { withStyles } from "@material-ui/core"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import MenuDashboard from "../MenuDashboard"
import GetApp from "@material-ui/icons/GetApp"
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import theme from "../../assets/css/theme"
import Imoveis from "./Imoveis/Imoveis"
import Logradouro from "./Logradouro/Logradouro"
import Bairros from "./Bairros"
import Distritos from "./Distritos"
import Loteamentos from "./Loteamentos"
import SecaoLogradouro from "./Secao/SecaoLogradouro"
import MotivosIptu from "./Motivoiptu/MotivosIptu"
import Parametros from "./Parametros/Parametros"
import { connect } from "react-redux"
import { cadastrosTabChanged } from "../../actions"
import Home from "@material-ui/icons/Home"
import Assessment from "@material-ui/icons/Assessment"
import Gavel from "@material-ui/icons/Gavel"
import Room from "@material-ui/icons/Room"
import Assignment from "@material-ui/icons/Assignment"
import Subject from "@material-ui/icons/Subject"
import PictureInPicture from "@material-ui/icons/PictureInPicture"
import { bindActionCreators } from "redux"
import BaixaIptu from "./BaixaIptu";

const tab = [
  {
    name: "Imóveis",
    icon: <Home />
  },

  {
    name: "Seção de Logradouros",
    icon: <Assignment />
  },
  {
    name: "Logradouros",
    icon: <Subject />
  },
  {
    name: "Bairros",
    icon: <Room />
  },
  {
    name: "Distritos",
    icon: <Room />
  },
  {
    name: "Loteamentos",
    icon: <PictureInPicture />
  },
  {
    name: "Parâmetros do carnê",
    icon: <Assessment />
  },
  {
    name: "Motivos de Isenção",
    icon: <Gavel />
  },
  {
    name: "Baixas",
    icon: <GetApp />
  }
]

class Iptu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      pageCount: 0,
      perPage: 0,
      page: 1,
      contribuintes: [],
      field: 0
    }
    this.handleFieldChange = this.handleFieldChange.bind(this)
  }

  handlePageClick(data) { }

  handleChange = event => {
    this.setState({ value: event.target.value })
  }

  handleChangePage = (event, page) => {
    if ((page || 1) !== this.state.page) {
      this.setState({ page }, () => { this.getData(this.state.page) })
    }
  }

  handleFieldChange(value) {
    this.props.cadastrosTabChanged(value)
  }
  render() {
    const { classes, field } = this.props
    return (
      <div className={classes.container}>
        <MuiThemeProvider theme={theme}>
          <MenuDashboard
            names={tab}
            onChange={this.handleFieldChange}
            value={field}
          />
          {field === 0 && <Imoveis />}
          {field === 1 && <SecaoLogradouro />}
          {field === 2 && <Logradouro />}
          {field === 3 && <Bairros />}
          {field === 4 && <Distritos />}
          {field === 5 && <Loteamentos />}
          {field === 6 && <Parametros />}
          {field === 7 && <MotivosIptu />}
          {field === 8 && <BaixaIptu />}
        </MuiThemeProvider>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    field: state.tabLayout.cadastrosTabSelected
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ cadastrosTabChanged }, dispatch)
}
Iptu = connect(
  mapStateToProps,
  mapDispatchToProps
)(Iptu)
export default withStyles(contribuinteStyle)(Iptu)
