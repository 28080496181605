import React from "react"

import secaoStyle from "../../../assets/css/layout/secaoStyle"
import { withStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import FormHelperText from "@material-ui/core/FormHelperText"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

import AsyncSelect from "../../../components/AsyncSelect"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Button from "../../../components/CustomButtons/Button"
import SearchFields from "../../../components/SearchFields"
import { search as searchContribuinte } from "../../../util/contribuinte"
import { relatorioDeEmissao } from "../../../util/emissaoitbi"
import { showWaiting, hideWaiting, showNotification, mapErrors } from "../../../util/Constants"
import moment from "moment"

class RelatorioDeEmissaoDeITBIForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      transmitente_contribuintes: [],
      transmitente_id: "",
      adquirente_id: "",
      imoveis: [],
      observacoes: "",
      id_imoveis: [],
      imoveis_all: [],
      natureza: [],
      id_natureza: 0,
      vencimento: moment(new Date()).format("YYYY-MM-DD"),
      modal: false,
      historico: "",

      // imovel
      info_imovel: [
        {
          financiamento: false,
          imovel_id: 0,
          valor_venal: 0,
          valor_financiado: 0,
          valor_declarado: 0,
          valor_avaliado: 0,
          valor_a_vista: 0
        }
      ]
    }
    this.loadOptions = this.loadOptions.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
  }


  loadOptions(inputValue, callback, name) {
    searchContribuinte(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let contribuintes = data.data.map(contribuinte => {
            return {
              value: contribuinte.id,
              label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                ""} - ${contribuinte.cpf_cnpj || ""}`
            }
          })
          this.setState({
            [name]: data.data
          })
          callback(contribuintes)
        }
      }
    )
  }

  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    let value = event.target.value
    errors[name] = undefined

    if (event.target.type === "checkbox") {
      value = event.target.checked
    }

    this.setState({
      [name]: value,
      errors: errors
    })
  }

  onSubmit(event) {
    event.preventDefault()
    showWaiting()
    let adquirente = this.state.adquirente_id ? this.state.adquirente_id.value : undefined
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    relatorioDeEmissao(adquirente, response)
  }

  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do contribuinte" },
      { value: "cpf_cnpj", label: "Documento" },
      { value: "endereco", label: "Endereço" },
      { value: "nome_fantasia", label: "Nome Fantasia" },
      { value: "nome_razao_social", label: "Razão Social" }
    ]

    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Emissão de ITBI</h2>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Grid container direction="row" style={{ marginTop: "2%" }}>
              <Divider />
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                variant="caption"
              >
                Pesquise o adquirente por
              </Typography>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <SearchFields
                  name="filterField"
                  value={this.state.filterField || ""}
                  onChangeFields={this.handleChange}
                  fields={fields}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AsyncSelect
                className={
                  `form-control form-control-alternative form-select Select-menu-outer ${
                    this.props.error ? "has-danger" : ""
                    }`
                }
                  value={this.state.adquirente_id}
                  onChange={this.handleChange}
                  loadOptions={this.loadOptions}
                  error={this.state.errors.adquirente_id}
                  helperText={this.state.errors.adquirente_id}
                  name="adquirente_id"
                  placeholder="Adquirente"
                  message="Pesquise o contribuinte"
                />
                <FormHelperText>Escolha o Adquirente or deixe vazio para selecionar TODOS</FormHelperText>
              </Grid>
            </Grid>
          </CardBody>
          <CardFooter>
            <Grid container justify="center">
              <Grid item lg={2}>
                <Button block color="info" round onClick={this.onSubmit}>
                  Emitir
                </Button>
              </Grid>
            </Grid>
          </CardFooter>
        </Card>

        {/* DIALOG */}
        <Dialog
          id="body"
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          style={{ zIndex: "1" }}
        >
          <form onSubmit={this.onSubmit}>
            <DialogTitle id="form-dialog-title">
              ITBI EMITIDO COM SUCESSO!
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={16} justify="center">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button onClick={this.imprimirDam} color="success" fullWidth>
                    Imprimir DAM
                  </Button>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="outlined-select-currency"
                    multiline
                    name="historico"
                    label="Histórico"
                    fullWidth
                    rowsMax={7}
                    error={this.state.errors.historico}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </form>
        </Dialog>
      </div>
    )
  }
}
export default withStyles(secaoStyle)(RelatorioDeEmissaoDeITBIForm)
