import React from "react";
import { Link, withRouter } from "react-router-dom";
import { withStyles, Paper } from "@material-ui/core";
import logradouroStyle from "../../../assets/css/layout/logradouroStyle";
import Button from "../../../components/CustomButtons/Button";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import { showNotification, mapErrors } from "../../../util/Constants";
import Table from "../../../components/Table/Table";
import TablePagination from "@material-ui/core/TablePagination";
import Edit from "@material-ui/icons/Edit";
import { get, post, put, search } from "../../../util/secaologradouro";
import LoadingContent from "../../../components/LoadingContent";
import Search from "../../../components/Search";
import housecall from "housecall";

class SecaoLogradouro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      error: "",
      errors: {},
      nome: "",
      pageCount: 0,
      perPage: 0,
      isSearch:false,
      searchText:"",
      page: 0,
      secaologradouros: [],
      field: 0,
      modal: false,
      id: 0
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.getData = this.getData.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.filter = this.filter.bind(this);
    this.queue = housecall({ concurrency: 1, cooldown: 0 });
    this.setLoadingFalse = this.setLoadingFalse.bind(this)
  }

  setLoadingFalse() {
    this.setState({
      isLoading: false
    })
}

  handleEdit(key) {
    this.props.history.push(
      "/secoes/edit/" + this.state.secaologradouros[key].id
    );
  }
  componentDidMount() {
    this.queue.on("idle", this.setLoadingFalse);
  }
  componentWillMount() {
    this.getData();
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }));
  }
  handlePageClick(data) {
    //this.getData(data.selected + 1);
  }

  handleChange(event) {
    let name = event.target.name;
    let errors = this.state.errors;
    errors[name] = undefined;
    this.setState({
      [name]: event.target.value,
      errors: errors
    });
  }

  handleOpen = () => {
    this.setState({ modal: true });
  };

  handleClose = () => {
    this.setState({ modal: false }, () => {
      this.getData(this.state.page);
    });
  };

  handleSave(event) {
    event.preventDefault();
    let params = {
      nome: this.state.nome
    };
    let response = error => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        );
        this.setState({
          errors: mapErrors(error)
        });
      } else {
        showNotification(
          "top",
          "center",
          `Logradouro ${this.props.edit ? "editado" : "incluído"} com sucesso`,
          "success"
        );
        this.handleClose();
      }
    };
    this.state.id
      ? put(this.state.id, params, response)
      : post(params, response);
  }

  getData(page) {
    this.setState({isLoading:true})
  
    get(page, (error, data) => {
      this.setState({isLoading:false})
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        this.setState({
          secaologradouros: data.data,
          pageCount: data.total,
          perPage: data.per_page
        });
      }
    });
  }
  handleChangePage = (event, page) => {
    var pagina;
    pagina = page + 1;
    this.setState({page: page})
    if(page < 0){
      return;
    }
    if (this.state.searchText.toString().trim() != "") {
      this.filter({target: {value: this.state.searchText}}, pagina, page)
      return;
    }
    this.getData(pagina)
  };

  handleChangeFilters = event => {
    this.setState({ filterField: event.target.value }, () => {
      if (this.state.searchActive) {
        this.filter({ target: { value: this.state.searchText } });
      }
    });
  };

  filter(event,pagina) {

    let searchText = event.target.value;
    var newState =    {
      isLoading:true,
      searchText: searchText,
      searchActive: searchText !== ""
    }
    if(pagina == null){
      newState["page"] = 0
    }
    this.setState(
      newState
   ,
      () => {
        if (this.state.searchActive) {
    
          let filtersCheckbox = this.state.filterField || "";
          this.queue.push(() =>
            search(searchText, filtersCheckbox, undefined, (error, data) => {
              this.setState({isLoading:false})
              if (error) {
                showNotification(
                  "top",
                  "center",
                  "Ocorreram erros, verifique sua conexão com a internet",
                  "danger"
                );
              } else {
                if(pagina == undefined ) {
                  this.setState({page: 0})
                }
                if(this.state.searchText !== "") {
                   this.setState({
                     secaologradouros: data.data,
                     pageCount: data.total,
                     
                     perPage: data.per_page
                  });
                }
                
              }
            },pagina)
          );
        } else {
          this.getData();
        }
      }
    );
  }

  handleFieldChange(value) {
    this.setState({
      field: value
    });
  }

  render() {
    const { classes } = this.props;
    const fields = [
      { value: "", label: "Todos os campos"},
      { value: "id", label: "Código da Seção" },
      { value: "secao", label: "Nº da seção" },
      { value: "logradouro_nome", label: "Logradouro" },
      { value: "logradouro_id", label: "Código do Logradouro" }
    ];
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Seção de Logradouro</h2>
              </Grid>
              <Grid item lg={2} xs={12}>
                <Link to="/secoes/create" style={{ textDecoration: "none" }}>
                  <Button block color="success" round>
                    <AddIcon /> Adicionar
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Search
              label="Pesquise aqui"
              value={this.state.filterField}
              onChangeText={this.filter}
              onChangeFields={this.handleChangeFilters}
              fields={fields}
            />
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  tableHeaderColor="primary"
                  actions={[
                    {
                      title: "Editar",
                      icon: (
                        <Edit
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      ),
                      onClick: this.handleEdit
                    }
                  ]}
                  tableHead={["#", "Nº Seção", "Logradouro"]}
                  tableData={this.state.secaologradouros.map((item, key) => {
                    return [item.id + "", item.secao, item.logradouro.nome, key];
                  })}
                />
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[]}
                  count={this.state.pageCount}
                  rowsPerPage={this.state.perPage?this.state.perPage:0}
                  page={this.state.page?this.state.page:0}
                  backIconButtonProps={
         
                        {"aria-label": "Previous Page"}}
                  nextIconButtonProps={ {
                    "aria-label": "Next Page",
                  }}
                  SelectProps={{
                    native: true
                  }}

                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>
      </div>
    );
  }
}
SecaoLogradouro = withRouter(SecaoLogradouro);
export default withStyles(logradouroStyle)(SecaoLogradouro);
