import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import CardFooter from "../../../components/Card/CardFooter";
import Button from "../../../components/CustomButtons/Button";
import secaoStyle from "../../../assets/css/layout/secaoStyle";
import {MenuItem, withStyles} from "@material-ui/core";
import { postBaixas as post, editBaixas, putBaixas as put } from "../../../util/refinanciamento";
import {showNotification, mapErrors, showWaiting, hideWaiting} from "../../../util/Constants";
import NumberFormatCustom from "../../../components/NumberFormatCustom";
import LoadingContent from "../../../components/LoadingContent";
import DecimalFormatCustom from "../../../components/DecimalFormatCustom";
import moment from "moment";


class BaixaRefisEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contribuinte_id: 0,
            dam_id: 0,
            agente_recebedor_id: 0,
            data_pagamento: moment(new Date()).format("YYYY-MM-DD"),
            data_deposito: moment(new Date()).format("YYYY-MM-DD"),
            valor_pago: 0,
            parcela_id: 0,
            agentes:[],

            errors: {},
            modal: false,
            edit: false,
            editingItem: 0,
            isLoadingDialog: false
        };
        this.init = this.init.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.toggleLoadingDialog = this.toggleLoadingDialog.bind(this)
    }

    componentWillMount() {
        this.init();
    }

    handleOpen = () => {
        this.setState({modal: true});
    };

    handleClose = (params) => {
        this.setState({modal: false, ...params});
    };

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState(state => ({
            [name]: value,
            errors: {...state.errors, [name]: undefined}
        }));
    }

    handleSave(event) {
        showWaiting()
        event.preventDefault();
        let params = {
            contribuinte_id: this.state.contribuinte_id,
            dam_id: this.state.dam_id,
            agente_recebedor_id: this.state.agente_recebedor_id,
            valor_pago: this.state.valor_pago,
            data_pagamento: moment(this.state.data_pagamento).format("YYYY-MM-DD"),
            data_deposito: moment(this.state.data_deposito).format("YYYY-MM-DD"),
            parcela_id: this.state.parcela_id
        };
        let response = error => {
            if (error) {
                hideWaiting()
                if(error.response.data.errors.dam_id) {
                    showNotification(
                        "top",
                        "center",
                        error.response.data.errors.dam_id,
                        "danger"
                    );
                }else {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique o formulário e tente novamente",
                        "danger"
                    )};
                this.setState({
                    errors: mapErrors(error)
                });
            } else {
                hideWaiting()
                showNotification(
                    "top",
                    "center",
                    `Baixa ${
                        this.props.match.params.id ? "editada" : "incluída"
                    } com sucesso`,
                    "success"
                );
                this.props.history.push("/divida/cadastro");
            }
        };
        this.props.match.params.id
            ? put(this.props.match.params.id, params, response)
            : post(params, response);
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }));
    }

    toggleLoadingDialog() {
        this.setState(state => ({
            isLoadingDialog: !state.isLoadingDialog
        }));
    }

    init() {
        let response = (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                );
            } else {
                if (this.props.match.params.id) {
                    console.log(this.props.match.params.id)
                    this.setState({
                        contribuinte_id: data.baixa.id,
                        dam_id: data.baixa.dam_id,
                        agente_recebedor_id: data.baixa.agente_recebedor_id,
                        data_pagamento: data.baixa.data_pagamento,
                        data_deposito: data.baixa.data_deposito,
                        valor_pago: data.baixa.valor_pago,
                        agentes: data.agentes
                    }, () => {
                        this.toggleLoading();
                    });
                }
                this.setState(data);
            }
        };
        if (this.props.match.params.id) {
            this.toggleLoading();
            editBaixas(this.props.match.params.id, response);
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.container}>
                <LoadingContent isLoading={this.state.isLoading}>
                    <Card>
                        <form onSubmit={this.handleSave}>
                            <CardHeader color="primary">
                                <Grid container direction="row">
                                    <Grid item lg={10} xs={12}>
                                        <h2 className={classes.cardTitleWhite}>
                                            Baixas de Refinanciamento
                                        </h2>
                                    </Grid>
                                </Grid>
                            </CardHeader>
                            <CardBody>
                                <Grid container direction="row" spacing={16}>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            required
                                            name="agente_recebedor_id"
                                            label="Agente Recebedor"
                                            fullWidth
                                            value={this.state.agente_recebedor_id}
                                            error={this.state.errors.agente_recebedor_id}
                                            helperText={this.state.errors.agente_recebedor_id}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        >
                                            {this.state.agentes.map((option, key) => (
                                                <MenuItem key={key} value={option.id}>
                                                    {option.id + " - " + option.nome}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField
                                            id="outlined-select-currency"
                                            label="Valor Pago"
                                            fullWidth
                                            required
                                            onChange={this.handleChange}
                                            value={parseFloat(this.state.valor_pago)}
                                            defaultValue={parseFloat(this.state.valor_pago)}
                                            name="valor_pago"
                                            error={this.state.errors.valor_pago}
                                            helperText={this.state.errors.valor_pago}
                                            margin="normal"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={16}>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField
                                            id="outlined-select-currency"
                                            name="data_pagamento"
                                            label="Data do Pagamento"
                                            fullWidth
                                            type="date"
                                            value={this.state.data_pagamento || ""}
                                            error={this.state.errors.data_pagamento}
                                            helperText={this.state.errors.data_pagamento}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <TextField
                                            id="outlined-select-currency"
                                            name="data_deposito"
                                            label="Data do Depósito"
                                            fullWidth
                                            type="date"
                                            value={this.state.data_deposito || ""}
                                            error={this.state.errors.data_deposito}
                                            helperText={this.state.errors.data_deposito}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardBody>
                            <CardFooter>
                                <Grid container justify="center">
                                    <Grid item lg={2}>
                                        <Button block color="info" round type="submit">
                                            <AddIcon/> Atualizar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardFooter>
                        </form>
                    </Card>
                </LoadingContent>
            </div>
        );
    }
}

export default withStyles(secaoStyle)(BaixaRefisEdit);
