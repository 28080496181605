import React, { Component } from "react"
import { imoveisParaAvaliar, avaliar } from "../../util/emissaoitbi"
import { showNotification, showWaiting, mapErrors, hideWaiting } from "../../util/Constants"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardFooter from "../../components/Card/CardFooter"
import CardBody from "../../components/Card/CardBody"
import { withStyles, TextField, Switch, FormControlLabel, Grid, Dialog, DialogTitle, DialogContent } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import FormHelperText from "@material-ui/core/FormHelperText"
import Button from "../../components/CustomButtons/Button"
import { withRouter } from "react-router-dom"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import NumberFormatCustom from "../../components/NumberFormatCustom"
import LoadingContent from "../../components/LoadingContent"
import { damItbi } from "../../util/dam"
class AvaliacaoForm extends Component {
    constructor() {
        super()
        this.state = {
            imoveisRurais: [],
            imoveisUrbanos: [],
            errors: {},
            imoveis_rurais: [],
            imoveis: []
        }
        this.toggleLoading = this.toggleLoading.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.getFields = this.getFields.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.imprimirDam = this.imprimirDam.bind(this)
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }
    componentDidMount() {
        this.getFields()
    }

    imprimirDam(event) {
        event.preventDefault()
        showWaiting()
        let response = (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
                hideWaiting()
            } else {
                hideWaiting()
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
                this.props.history.push("/itbi/movimentos")
            }
        }
        damItbi(this.state.id, this.state.historico, response)
    }


    handleSave(event) {
        event.preventDefault()
        showWaiting()
        let params = {
            imoveis: this.state.imoveis.map((imovel) => {
                return {
                    ...imovel,
                    valor_financiado: imovel.financiamento ? (imovel.valor_financiado || 0) : null,
                    valor_declarado: imovel.valor_declarado || 0,
                    valor_avaliado: imovel.valor_avaliado || 0,
                    valor_a_vista: imovel.valor_a_vista || 0
                }
            }),
            imoveis_rurais: this.state.imoveis_rurais.map((imovel) => {
                return {
                    ...imovel,
                    valor_financiado: imovel.financiamento ? (imovel.valor_financiado || 0) : null,
                    valor_declarado: imovel.valor_declarado || 0,
                    valor_avaliado: imovel.valor_avaliado || 0,
                    valor_a_vista: imovel.valor_a_vista || 0
                }
            })
        }
        let response = (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
                this.setState({
                    errors: mapErrors(error)
                }, () => { hideWaiting() })
            } else {
                hideWaiting()
                showNotification(
                    "top",
                    "center",
                    "ITBI emitido com sucesso",
                    "success"
                )
                this.setState({
                    modal: true,
                    id: data.id
                })
            }
        }
        avaliar(this.props.match.params.id, params, response)
    }
    getFields() {
        this.toggleLoading()
        let response = (error, data) => {
            this.toggleLoading()
            if (error) {
                if (error.response.data.message) {
                    showNotification(
                        "top",
                        "center",
                        error.response.data.message,
                        "danger"
                    )
                } else {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                }
            } else {
                this.setState({
                    imoveis_rurais: data.filter((imovel) => imovel.imovel_id === null),
                    imoveis: data.filter((imovel) => imovel.imovel_id !== null),
                })

            }
        }
        imoveisParaAvaliar(this.props.match.params.id, response)
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        let value = event.target.value
        errors[name] = undefined

        if (event.target.type === "checkbox") {
            value = event.target.checked
        }

        if (name.split(".").length > 1 && name.includes("imoveis_rurais")) {
            let oldinfo = [...this.state.imoveis_rurais]
            let components = name.split(".")
            name = components[0]
            oldinfo[components[1]][components[2]] = value
            value = oldinfo
        } else if (name.split(".").length > 1) {
            let oldinfo = [...this.state.imoveis]
            let components = name.split(".")
            name = components[0]
            oldinfo[components[1]][components[2]] = value
            value = oldinfo
        }
        console.log(value)

        this.setState(
            {
                [name]: value,
                errors: errors
            },
            () => {
                if (this.state.rural && name === "rural") {
                    this.setState({
                        imoveis_rurais: [{
                            financiamento: false,
                            valor_venal: 0,
                            valor_financiado: 0,
                            valor_declarado: 0,
                            valor_avaliado: 0,
                            valor_a_vista: 0
                        }]
                    })
                }
            }
        )
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>Avaliação de ITBI</h2>
                            </Grid>
                        </Grid>
                    </CardHeader>
                </Card>
                <Card>
                    <LoadingContent isLoading={this.state.isLoading}>
                        <CardBody>
                            <Grid container direction="row" spacing={16}>
                                {this.state.imoveis_rurais.map((item, key) => {
                                    return (
                                        <>
                                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                                <Card>
                                                    <div className={classes.container}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    name={`imoveis_rurais.${key}.financiamento`}
                                                                    defaultChecked={item.financiamento}
                                                                    checked={
                                                                        this.state[`imoveis_rurais.${key}.financiamento`]
                                                                    }
                                                                    onChange={this.handleChange}
                                                                    value={`imoveis_rurais.${key}.financiamento`}
                                                                />
                                                            }
                                                            label="Ativar Financimento"
                                                        />
                                                        <Grid container spacing={16}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <TextField
                                                                    id="outlined-select-currency"
                                                                    label="Valor Venal"
                                                                    fullWidth
                                                                    disabled
                                                                    defaultValue={item.valor_venal}
                                                                    value={item.valor_venal}
                                                                    onChange={this.handleChange}
                                                                    name={`imoveis_rurais.${key}.valor_venal`}
                                                                    error={
                                                                        this.state.errors[`imoveis_rurais.${key}.valor_venal`]
                                                                    }
                                                                    helperText={
                                                                        this.state.errors[`imoveis_rurais.${key}.valor_venal`]
                                                                    }
                                                                    margin="normal"
                                                                    variant="outlined"

                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={16}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <TextField
                                                                    id="outlined-select-currency"
                                                                    label="Valor Declarado"
                                                                    fullWidth
                                                                    disabled={item.financiamento}
                                                                    defaultValue={item.valor_declarado}
                                                                    value={item.valor_declarado}
                                                                    onChange={this.handleChange}
                                                                    name={`imoveis_rurais.${key}.valor_declarado`}
                                                                    error={
                                                                        this.state.errors[
                                                                        `imoveis_rurais.${key}.valor_declarado`
                                                                        ]
                                                                    }
                                                                    helperText={
                                                                        this.state.errors[
                                                                        `imoveis_rurais.${key}.valor_declarado`
                                                                        ]
                                                                    }
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        inputComponent: NumberFormatCustom
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={16}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <TextField
                                                                    id="outlined-select-currency"
                                                                    label="Valor Avaliado"
                                                                    fullWidth
                                                                    disabled={item.financiamento}
                                                                    defaultValue={item.valor_avaliado}
                                                                    value={item.valor_avaliado}
                                                                    onChange={this.handleChange}
                                                                    name={`imoveis_rurais.${key}.valor_avaliado`}
                                                                    error={
                                                                        this.state.errors[
                                                                        `imoveis_rurais.${key}.valor_avaliado`
                                                                        ]
                                                                    }
                                                                    helperText={
                                                                        this.state.errors[
                                                                        `imoveis_rurais.${key}.valor_avaliado`
                                                                        ]
                                                                    }
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        inputComponent: NumberFormatCustom
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={16}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <TextField
                                                                    id="outlined-select-currency"
                                                                    label="Valor Financiado"
                                                                    fullWidth
                                                                    disabled={!item.financiamento}
                                                                    value={item.valor_financiado}
                                                                    defaultValue={item.valor_financiado}
                                                                    onChange={this.handleChange}
                                                                    name={`imoveis_rurais.${key}.valor_financiado`}
                                                                    error={
                                                                        this.state.errors[
                                                                        `imoveis_rurais.${key}.valor_financiado`
                                                                        ]
                                                                    }
                                                                    helperText={
                                                                        this.state.errors[
                                                                        `imoveis_rurais.${key}.valor_financiado`
                                                                        ]
                                                                    }
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        inputComponent: NumberFormatCustom
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={16}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <TextField
                                                                    id="outlined-select-currency"
                                                                    label="Valor a vista"
                                                                    fullWidth
                                                                    disabled={!item.financiamento}
                                                                    defaultValue={item.valor_a_vista}
                                                                    value={item.valor_a_vista}
                                                                    onChange={this.handleChange}
                                                                    name={`imoveis_rurais.${key}.valor_a_vista`}
                                                                    error={
                                                                        this.state.errors[
                                                                        `imoveis_rurais.${key}.valor_a_vista`
                                                                        ]
                                                                    }
                                                                    helperText={
                                                                        this.state.errors[
                                                                        `imoveis_rurais.${key}.valor_a_vista`
                                                                        ]
                                                                    }
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        inputComponent: NumberFormatCustom
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Card>
                                            </Grid>
                                        </>
                                    )
                                })}
                            </Grid>
                            <Grid container direction="row" spacing={16}>
                                {this.state.imoveis.map((item, key) => {
                                    return (
                                        <>
                                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                                <Card>
                                                    <div className={classes.container}>
                                                        <Typography color="error" variant="caption" style={{ marginTop: "1%" }}>{this.state.errors[`imoveis.${key}.imovel_id`]}</Typography>
                                                        <FormHelperText>
                                                            Inscrição Cartográfica:{" "}
                                                            {item.inscricao_cartografica}
                                                        </FormHelperText>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    name={`imovel.${key}.financiamento`}
                                                                    defaultChecked={item.financiamento}
                                                                    checked={
                                                                        this.state[`imovel.${key}.financiamento`]
                                                                    }
                                                                    onChange={this.handleChange}
                                                                    value={`imovel.${key}.financiamento`}
                                                                />
                                                            }
                                                            label="Ativar Financimento"
                                                        />
                                                        <Grid container spacing={16}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <TextField
                                                                    id="outlined-select-currency"
                                                                    label="Valor Venal"
                                                                    fullWidth
                                                                    disabled
                                                                    defaultValue={item.valor_venal}
                                                                    value={item.valor_venal}
                                                                    onChange={this.handleChange}
                                                                    name={`imovel.${key}.valor_venal`}
                                                                    error={
                                                                        this.state.errors[`imovel.${key}.valor_venal`]
                                                                    }
                                                                    helperText={
                                                                        this.state.errors[`imovel.${key}.valor_venal`]
                                                                    }
                                                                    margin="normal"
                                                                    variant="outlined"

                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={16}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <TextField
                                                                    id="outlined-select-currency"
                                                                    label="Valor Declarado"
                                                                    fullWidth
                                                                    disabled={item.financiamento}
                                                                    defaultValue={item.valor_declarado}
                                                                    value={item.valor_declarado}
                                                                    onChange={this.handleChange}
                                                                    name={`imovel.${key}.valor_declarado`}
                                                                    error={
                                                                        this.state.errors[
                                                                        `imovel.${key}.valor_declarado`
                                                                        ]
                                                                    }
                                                                    helperText={
                                                                        this.state.errors[
                                                                        `imovel.${key}.valor_declarado`
                                                                        ]
                                                                    }
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        inputComponent: NumberFormatCustom
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={16}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <TextField
                                                                    id="outlined-select-currency"
                                                                    label="Valor Avaliado"
                                                                    fullWidth
                                                                    disabled={item.financiamento}
                                                                    defaultValue={item.valor_avaliado}
                                                                    value={item.valor_avaliado}
                                                                    onChange={this.handleChange}
                                                                    name={`imovel.${key}.valor_avaliado`}
                                                                    error={
                                                                        this.state.errors[
                                                                        `imovel.${key}.valor_avaliado`
                                                                        ]
                                                                    }
                                                                    helperText={
                                                                        this.state.errors[
                                                                        `imovel.${key}.valor_avaliado`
                                                                        ]
                                                                    }
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        inputComponent: NumberFormatCustom
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={16}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <TextField
                                                                    id="outlined-select-currency"
                                                                    label="Valor Financiado"
                                                                    fullWidth
                                                                    disabled={!item.financiamento}
                                                                    value={item.valor_financiado}
                                                                    defaultValue={item.valor_financiado}
                                                                    onChange={this.handleChange}
                                                                    name={`imovel.${key}.valor_financiado`}
                                                                    error={
                                                                        this.state.errors[
                                                                        `imovel.${key}.valor_financiado`
                                                                        ]
                                                                    }
                                                                    helperText={
                                                                        this.state.errors[
                                                                        `imovel.${key}.valor_financiado`
                                                                        ]
                                                                    }
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        inputComponent: NumberFormatCustom
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={16}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                <TextField
                                                                    id="outlined-select-currency"
                                                                    label="Valor a vista"
                                                                    fullWidth
                                                                    disabled={!item.financiamento}
                                                                    defaultValue={item.valor_a_vista}
                                                                    value={item.valor_a_vista}
                                                                    onChange={this.handleChange}
                                                                    name={`imovel.${key}.valor_a_vista`}
                                                                    error={
                                                                        this.state.errors[
                                                                        `imovel.${key}.valor_a_vista`
                                                                        ]
                                                                    }
                                                                    helperText={
                                                                        this.state.errors[
                                                                        `imovel.${key}.valor_a_vista`
                                                                        ]
                                                                    }
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        inputComponent: NumberFormatCustom
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Card>
                                            </Grid>
                                        </>
                                    )
                                })}
                            </Grid>
                        </CardBody>
                        <CardFooter>
                            <Grid container justify="center">
                                <Grid item lg={2}>
                                    <Button block color="info" type="submit" round onClick={this.handleSave}>
                                        Avaliar
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardFooter>
                    </LoadingContent>
                </Card>
                <Dialog
                    id="body"
                    open={this.state.modal}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    style={{ zIndex: "1" }}
                >
                    <form onSubmit={this.handleSave}>
                        <DialogTitle id="form-dialog-title">
                            ITBI AVALIADO COM SUCESSO!
            </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={16} justify="center">
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Button onClick={this.imprimirDam} color="success" fullWidth>
                                        Imprimir DAM
                  </Button>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Button onClick={() => this.props.history.push("/itbi/cadastro")} color="warning" fullWidth>
                                        Voltar para listagem
                  </Button>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        id="outlined-select-currency"
                                        multiline
                                        name="historico"
                                        label="Histórico"
                                        fullWidth
                                        rowsMax={7}
                                        error={this.state.errors.historico}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </form>
                </Dialog>
            </div>
        )

    }
}
AvaliacaoForm = withRouter(AvaliacaoForm)

export default withStyles(contribuinteStyle)(AvaliacaoForm)