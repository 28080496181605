import React from "react"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import Button from "../../../components/CustomButtons/Button"
import CardFooter from "../../../components/Card/CardFooter"
import logradouroStyle from "../../../assets/css/layout/logradouroStyle"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

import {
  showNotification,
  showWaiting,
  hideWaiting
} from "../../../util/Constants"
import { gerarRemessa } from "../../../util/arquivoremessa"
import Fade from "react-reveal/Fade"

import InputMask from "react-input-mask"
class ArquivoRemessa extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      exercicio: "",
      id_imovel: [],
      group: "todas",
      condicao: false,
      parcelas: "",
      modal: false,
      email: ""
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState(
      {
        [name]: event.target.value,
        errors: errors
      },
      () => {
        
        if (this.state.group && name === "group") {
          this.setState({
            condicao: !this.state.condicao
          })
        }
      }
    )
  }

  handleOpen = () => {
    this.setState({ modal: true })
  }

  handleClose = () => {
    this.setState({ modal: false })
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()

    let response = error => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Arquivo enviado com sucesso",
          "success"
        )
        this.handleClose()
      }
    }
    gerarRemessa(
      this.state.exercicio,
      this.state.email,
      this.state.parcelas,
      response
    )
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <Card>
          
            <CardHeader color="primary">
              <Grid container direction="row">
                <Grid item lg={10} xs={12}>
                  <h2 className={classes.cardTitleWhite}>
                    Remessa IPTU - Formulário Centauro
                  </h2>
                </Grid>
              </Grid>
            </CardHeader>
            <CardBody>
              <Grid container direction="row" style={{ marginTop: "2%" }}>
                <Divider />
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  variant="caption"
                >
                  Insira o Exercício
                </Typography>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <InputMask
                    mask="9999"
                    value={this.state.exercicio}
                    onChange={this.handleChange}
                    maskChar=" "
                  >
                    {() => (
                      <TextField
                        id="outlined-name"
                        label="Exercício"
                        name="exercicio"
                        helperText={this.state.errors.exercicio}
                        fullWidth
                        required
                        error={this.state.errors.exercicio}
                        value={this.state.exercicio}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                  </InputMask>
                </Grid>
              </Grid>
              <Grid container direction="row" style={{ marginTop: "2%" }}>
                <Divider />
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  variant="caption"
                >
                  Quantidade de parcelas
                </Typography>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <RadioGroup
                    aria-label="position"
                    name="group"
                    value={this.state.group}
                    onChange={this.handleChange}
                    row
                  >
                    <FormControlLabel
                      value="todas"
                      control={<Radio color="primary" />}
                      label="Todas"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="especificar"
                      control={<Radio color="primary" />}
                      label="Especificar"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Fade bottom when={this.state.condicao}>
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="outlined-name"
                      label="Quantidade de parcelas"
                      fullWidth
                      name="parcelas"
                      error={this.state.errors.parcelas}
                      helperText={this.state.errors.parcelas}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Fade>
            </CardBody>
            <CardFooter>
              <Grid container justify="center">
                <Grid item lg={2}>
                  <Button block color="info" round onClick={this.handleOpen}>
                    Exportar
                  </Button>
                </Grid>
              </Grid>
            </CardFooter>
        </Card>

        {/* dialog */}
        <Dialog
          open={this.state.modal}
          fullWidth
          onClose={this.handleClose}
          style={{ zIndex: "3" }}
        >
          <DialogTitle>Arquivo de Remessa</DialogTitle>

          <DialogContent>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="row"
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  name="email"
                  label="Email para enviar o arquivo de remessa"
                  required
                  fullWidth
                  error={this.state.errors.email}
                  helperText={this.state.errors.email}
                  type="email"
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="danger">
              Cancelar
            </Button>
            <Button onClick={this.handleSave} color="success">
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
export default withStyles(logradouroStyle)(ArquivoRemessa)
