import React from "react"
import { withRouter } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import AddIcon from "@material-ui/icons/Add"
import AsyncSelect from "../../components/AsyncSelect"
import { search as searchProjeto } from "../../util/projeto"
import { showNotification, mapErrors } from "../../util/Constants"
import numeral from "../../util/numeral"
import { MenuItem, withStyles } from "@material-ui/core"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import secaoStyle from "../../assets/css/layout/secaoStyle"
import Button from "../../components/CustomButtons/Button"
import { showWaiting, hideWaiting } from "../../util/Constants"
import { post, getField, destroy, searchBaixa } from "../../util/baixaProjeto"
import moment from "moment"
import CardFooter from "../../components/Card/CardFooter"
import SearchFields from "../../components/SearchFields"
import Typography from "@material-ui/core/Typography"
import DialogCustom from "../DialogCustom"
import Delete from "@material-ui/icons/Delete"
import NumberFormatCustom from "../../components/NumberFormatCustom"

const newState = {
  ocupacoes: [],
  id_ocupacao: 0,
  errors: {},

  data_pagamento: new Date(),
  data_deposito: new Date(),
  agencia: "",
  conta: "",
  agentes: [],
  agente_recebedor_id: 0,
  arquivo_de_baixa: "",
  messages: [],
  dams: [],
  id_dam: "",
  valor_pago: "",
  valor: "",

  modal: false,
  openConfirm: false,
  filterFieldDialog: ""
}

class BaixaManualProjeto extends React.Component {
  constructor(props) {
    super(props)
    this.state = newState
    this.loadImovel = this.loadImovel.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getImovelById = this.getImovelById.bind(this)
    this.getParcelaLancById = this.getParcelaLancById.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.getFields = this.getFields.bind(this)
    this.getAgenciaConta = this.getAgenciaConta.bind(this)
    this.getDamById = this.getDamById.bind(this)
    this.getDam = this.getDam.bind(this)

    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.loadBaixa = this.loadBaixa.bind(this)
    this.handleOpenConfirm = this.handleOpenConfirm.bind(this)
    this.handleCloseConfirm = this.handleCloseConfirm.bind(this)
  }

  handleOpenConfirm() {
    this.setState({
      openConfirm: true
    })
  }

  handleCloseConfirm() {
    this.setState({
      openConfirm: false
    })
  }

  handleOpen() {
    this.setState({
      modal: true
    })
  }

  handleDelete() {
    showWaiting()
    let response = error => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        );
        this.setState({
          errors: mapErrors(error)
        });
      } else {
        showNotification(
          "top",
          "center",
          `Baixa Manual deletada com sucesso`,
          "success"
        );
        this.handleClose()
        this.handleCloseConfirm()
      }
    };
    destroy(this.state.codigo.value, response)
  }

  handleClose() {
    this.setState({
      modal: false
    })
  }

  loadBaixa(inputValue, callback) {
    searchBaixa(inputValue, this.state.filterFieldDialog || "", undefined, (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
        callback([])
      } else {
        let baixa = data.data.map(item => {
          return {
            value: item.id,
            label: `Código Baixa: ${item.dam_id} - 
            Agência: ${item.agencia} Conta: ${item.conta} - 
            Dt.Pagamento: ${moment(item.data_pagamento).format("DD/MM/YYYY")} - 
            Dt.Deposito: ${moment(item.data_deposito).format("DD/MM/YYYY")}`
          }
        })
        callback(baixa)
      }
    })
  }

  componentWillMount() {
    this.getFields()
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState(
      {
        [name]: event.target.value,
        errors: errors
      },
      () => {
        if (this.state.id_ocupacao && name === "id_ocupacao") {
          let dam = this.getDamById(this.state.id_ocupacao.value)
          this.setState({
            dams: dam.dams
          })
        }
        if (this.state.id_dam && name === "id_dam") {
          let damEscolhido = this.getDam(this.state.id_dam)
          this.setState({
            data_vencimento: damEscolhido.vencimento,
            valor: damEscolhido.valor,
            multa: damEscolhido.multa
          });
        }
      }
    )
  }

  getDamById(id) {
    return this.state.ocupacoes.filter(item => {
      return id === item.id;
    })[0];
  }

  handleChangeFilters = event => {
    this.setState({ filterField: event.target.value });
  };

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let params = {}
    params = {
      dam_id: this.state.id_dam,
      agente_recebedor_id: this.state.id_agente,
      data_pagamento: moment(this.state.data_pagamento).format("YYYY-MM-DD"),
      data_deposito: moment(this.state.data_deposito).format("YYYY-MM-DD"),
      valor_pago: this.state.valor_pago
    }

    let response = (error, data) => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else if (!data["message"]) {
        var array = Object.keys(data).map(item => {
          return data[item][0][0]
        })
        this.setState(
          {
            messages: array
          },
          () => {
            hideWaiting()
          }
        )
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Baixa de Ocupação realizada com sucesso",
          "success"
        )
        this.setState(newState)
        this.getFields()
      }
    }
    post(params, response)
  }

  getFields() {
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState({
          agentes: data.agentes
        })

        this.setState(data)
      }
    }
    getField(response)
  }

  loadImovel(inputValue, callback) {
    searchProjeto(inputValue, this.state.filterField || "", undefined, (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
        callback([])
      } else {
        let projetos = data.data.map(projeto => {
          return {
            value: projeto.id,
            label: `${projeto.id} - Proprietário: ${projeto.proprietario.nome_razao_social ||
              ""} - Localização: ${projeto.localizacao} - Valor Obra: R$${numeral(parseFloat(projeto.valor_da_obra))
                .format("0.0,00")
                .slice(0, -2)}`
          }
        })
        this.setState({
          ocupacoes: data.data
        })
        callback(projetos)
      }
    })
  }

  getImovelById(id) {
    return this.state.ocupacoes.filter(item => {
      return id === item.id
    })[0]
  }

  getParcelaLancById(id) {
    return this.state.lancamentos.filter(item => {
      return id === item.id
    })[0]
  }

  getDam(id) {
    return this.state.dams.filter(item => {
      return id === item.id
    })[0]
  }

  getAgenciaConta(id) {
    return this.state.agentes.filter(item => {
      return id === item.id
    })[0]
  }

  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do Projeto" },
      { value: "localizacao", label: "Localização" },
      { value: "proprietario_cpf_cnpj", label: "Documento" },
      { value: "proprietario_id", label: "Código do Proprietário" },
      { value: "proprietario_nome_razao_social", label: "Razão Social" }
    ]
    const fieldsDialog = [
      { value: "", label: "Todos os campos" },
      { value: "dam_id", label: "Código Baixa" },
      { value: "data_pagamento", label: "Data Pagamento" },
      { value: "data_deposito", label: "Data Depósito" }
    ]
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Baixa Manual DAM de Projetos de Construção Civil</h2>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <Button onClick={this.handleOpen} block color="warning" round>
                  <Delete /> Deletar Baixa
                </Button>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <div>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography color="textSecondary" variant="caption">
                    Pesquise DAM de Projetos
                    </Typography>
                  <SearchFields
                    name="filterField"
                    value={this.state.filterField}
                    onChangeFields={this.handleChangeFilters}
                    fields={fields}
                  />
                  <AsyncSelect
                    className={
                      `form-control form-control-alternative form-select Select-menu-outer ${
                      this.props.error ? "has-danger" : ""
                      }`
                    }
                    value={this.state.id_ocupacao}
                    name="id_ocupacao"
                    onChange={this.handleChange}
                    loadOptions={this.loadImovel}
                    helperText={this.state.errors.ocupacao_id}
                    error={this.state.errors.ocupacao_id}
                    placeholder="DAM de Projeto"
                    message="Pesquise o DAM"
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    required
                    name="id_dam"
                    label="Escolha o DAM"
                    fullWidth
                    value={this.state.id_dam}
                    error={this.state.errors.dam_id}
                    helperText={this.state.errors.dam_id}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  >
                    {this.state.dams.map((option, key) => (
                      <MenuItem key={key} value={option.id}>
                        {option.competencia}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    id="outlined-select-currency"
                    name="vencimento"
                    label="Data do Vencimento"
                    fullWidth
                    type="date"
                    disabled
                    value={this.state.data_vencimento}
                    error={this.state.errors.data_vencimento}
                    helperText={this.state.errors.data_vencimento}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    id="outlined-select-currency"
                    label="Valor"
                    fullWidth
                    disabled
                    value={
                      "R$" +
                      numeral(parseFloat(this.state.valor))
                        .format("0.0,00")
                        .slice(0, -2)
                    }
                    error={this.state.errors.valor}
                    helperText={this.state.errors.valor}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    defaultValue=""
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    id="outlined-select-currency"
                    label="Multa"
                    fullWidth
                    disabled
                    value={
                      "R$" +
                      numeral(parseFloat(this.state.multa))
                        .format("0.0,00")
                        .slice(0, -2)
                    }
                    error={this.state.errors.multa}
                    helperText={this.state.errors.multa}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    defaultValue=""
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    id="outlined-select-currency"
                    name="data_deposito"
                    label="Data do Depósito"
                    fullWidth
                    type="date"
                    error={this.state.errors.data_deposito}
                    helperText={this.state.errors.data_deposito}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    defaultValue={moment(this.state.data_deposito).format("YYYY-MM-DD")}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    id="outlined-select-currency"
                    name="data_pagamento"
                    label="Data do Pagamento"
                    fullWidth
                    type="date"
                    error={this.state.errors.data_pagamento}
                    helperText={this.state.errors.data_pagamento}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    defaultValue={moment(this.state.data_pagamento).format("YYYY-MM-DD")}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    id="outlined-select-currency"
                    label="Valor Pago"
                    fullWidth
                    name="valor_pago"
                    value={this.state.valor_pago}
                    error={this.state.errors.valor_pago}
                    helperText={this.state.errors.valor_pago}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    defaultValue=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    required
                    name="id_agente"
                    label="Agente Recebedor"
                    fullWidth
                    value={this.state.id_agente}
                    error={this.state.errors.agente_recebedor_id}
                    helperText={this.state.errors.agente_recebedor_id}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                  >
                    {this.state.agentes.map((option, key) => (
                      <MenuItem key={key} value={option.id}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </div>
          </CardBody>
          <CardFooter>
            <Grid container justify="center">
              <Grid item lg={2}>
                <Button block color="info" round onClick={this.handleSave}>
                  <AddIcon /> Finalizar
                </Button>
              </Grid>
            </Grid>
          </CardFooter>
        </Card>

        <DialogCustom
          open={this.state.modal}
          nameSearch="filterFieldDialog"
          valueSearch={this.state.filterFieldDialog || ""}
          onChangeFields={this.handleChange}
          fields={fieldsDialog}
          onChange={this.handleChange}
          optionsAsync={this.loadBaixa}
          nameAsync="codigo"
          message="Pesquise a Baixa Manual Ocupação"
          placeholder="Baixa Manual"
          handleClose={this.handleClose}
          isDisabled={this.state.codigo ? false : true}
          handleSave={this.handleDelete}
          handleCloseConfirm={this.handleCloseConfirm}
          handleOpenConfirm={this.handleOpenConfirm}
          openConfirm={this.state.openConfirm}
        />
      </div>
    )
  }
}
BaixaManualProjeto = withRouter(BaixaManualProjeto)
export default withStyles(secaoStyle)(BaixaManualProjeto)
