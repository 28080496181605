import React from "react"
import PropTypes from "prop-types"
import IntlCurrencyInput from "react-intl-currency-input"

function getConfig(style) {
  return {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "decimal",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      }
    }
  }
}
function format (text){
  var valor = text.replace(".","")
  valor = valor.replace(",",".");
  return parseFloat(valor)
}
function DecimalFormatCustom(props) {
  const { onChange, defaultValue, value, name, ...other } = props
  return (
    <IntlCurrencyInput
      {...other}
        onKeyPress={(e,v)=>   onChange({
          target: { value: format(e.target.value), name: name }
        })}
      defaultValue={defaultValue || 0}
      config={getConfig()}
      currency="BRL"
      value={value || 0}

    />
  )
}

DecimalFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}

export default DecimalFormatCustom
