import React from "react"

import Loop from "@material-ui/icons/Loop"
import { withStyles } from "@material-ui/core"
import contribuinteStyle from "../../../assets/css/layout/contribuinteStyle"
import MenuDashboard from "../../MenuDashboard"
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import theme from "../../../assets/css/theme"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { processamentoTabChanged } from "../../../actions"
// import LancamentoDivida from "./LancamentoDivida"
import LancamentoLivroMain from "./LancamentoLivroMain"
import LancamentoCda from "./LancamentoCda"
import NotificacaoMain from "./NotificacaoMain"


const tab = [
  // {
  //   name: "Lançamento na Dívida",
  //   icon: <Loop />
  // },
  {
    name: "Lançamento Livro",
    icon: <Loop />
  },
  {
    name: "Certidão Dívida Ativa",
    icon: <Loop />
  },
  {
    name: "Notificação",
    icon: <Loop />
  }
]

class Processamento extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      field: 0
    }

    this.handleFieldChange = this.handleFieldChange.bind(this)
  }

  handleFieldChange(value) {
    this.props.processamentoTabChanged(value)
  }

  render() {
    const { classes, field } = this.props
    return (
      <div className={classes.container}>
        <MuiThemeProvider theme={theme}>
          <MenuDashboard
            names={tab}
            onChange={this.handleFieldChange}
            value={field}
          />
          {/* {field === 0 && <LancamentoDivida />} */}
          {field === 0 && <LancamentoLivroMain />}
          {field === 1 && <LancamentoCda />}
          {field === 2 && <NotificacaoMain />}
        </MuiThemeProvider>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    field: state.tabLayout.processamentoTabSelected
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ processamentoTabChanged }, dispatch)
}

Processamento = connect(
  mapStateToProps,
  mapDispatchToProps
)(Processamento)

export default withStyles(contribuinteStyle)(Processamento)
