import React from "react";
import { withStyles, Card } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography"
import ErrorOutline from "@material-ui/icons/ErrorOutline"

import contribuinteStyle from "../assets/css/layout/contribuinteStyle";
import { post } from "../util/baixa";
import CardHeader from "../components/Card/CardHeader";
import CardBody from "../components/Card/CardBody";
import CardFooter from "../components/Card/CardFooter";
import Button from "../components/CustomButtons/Button";
import Fade from "react-reveal/Fade"


import {
  showWaiting,
  hideWaiting,
  showNotification,
  mapErrors
} from "../util/Constants";


class BaixaAutomatica extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      arquivo_de_baixa: "",
      tipoBaixa: "automatica",
      errors: {},
      success: "",
      show: false,
    };
    this.handleSave = this.handleSave.bind(this);
  }

  handleSave(event) {
    event.preventDefault();
    showWaiting();
    let params = {};
    params = new FormData();
    params.append("arquivo_de_baixa", this.state.arquivo_de_baixa);

    let response = (error, data) => {
      if (error) {
        hideWaiting();
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        );
        console.log(error)
        this.setState({
          errors: mapErrors(error)
        });
      } else if (!data["message"]) {
        var array = Object.keys(data.errors).map(item => {
          return data.errors[item][0][0];
        });

        this.setState(
          {
            messages: array,
            show: true,
            success: data.sucessos_count
          },
          () => {
            hideWaiting();
          }
        );
      } else {
        hideWaiting();

        showNotification(
          "top",
          "center",
          "Baixa realizada com sucesso",
          "success"
        );
      }
    };
    post(params, response);
  }

  handleselectedFile = event => {
    this.setState({
      arquivo_de_baixa: event.target.files[0]
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Baixa Automática</h2>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Fade bottom>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    type="file"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    helperText={this.state.errors.arquivo_de_baixa}
                    error={this.state.errors.arquivo_de_baixa}
                    name="arquivo_de_baixa"
                    onChange={event => this.handleselectedFile(event)}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Fade when={this.state.show}>
                    <Typography paragraph={true} color="primary" align="justify" variant="subtitle1">
                      Quantidade de baixas cadastradas com sucesso: {this.state.success}
                    </Typography>
                  </Fade>

                  {this.state.messages.map((option, key) => (
                    <Typography paragraph={true} key={key} color="error" align="justify" variant="subtitle1">
                      <ErrorOutline colorError /> {option}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            </Fade>
          </CardBody>
          <CardFooter>
            <Grid container justify="center">
              <Grid item lg={2}>
                <Button block color="info" round onClick={this.handleSave}>
                  Gerar
                </Button>
              </Grid>
            </Grid>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

export default withStyles(contribuinteStyle)(BaixaAutomatica);
