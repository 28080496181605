import React from "react"

import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import CardFooter from "../../components/Card/CardFooter"
import Button from "../../components/CustomButtons/Button"
import LoadingContent from "../../components/LoadingContent"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import { withStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import SelectCustom from "../../components/SelectCustom"
import FormHelperText from "@material-ui/core/FormHelperText"
import { getField, post, put, edit } from "../../util/usermanager"
import { showNotification, mapErrors, showWaiting, hideWaiting } from "../../util/Constants"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"

class UserCadastro extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: {},
            roles: [],
            roles_names: [],
            bloqueado: false,
            username: "",
            nome: "",
            password: "",
            password_confirmation: "",
            cargo: "",
            change_password: true

        }
        this.handleChange = this.handleChange.bind(this)
        this.getData = this.getData.bind(this)
        this.toggleLoading = this.toggleLoading.bind(this)
        this.filterArray = this.filterArray.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        let value = event.target.value
        errors[name] = undefined

        console.log("name: ", name)
        console.log("value: ", value)

        if (name === "bloqueado" || name === "change_password") {
            value = event.target.checked
        }

        this.setState({
            [name]: value,
            errors: errors
        }, () => {
            console.log(this.filterArray(this.state.roles_names))
        })
    }

    filterArray(array) {
        return array.map(item => item.value)
    }

    componentWillMount() {
        this.getData()
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    getData() {
        this.toggleLoading()
        let response = (error, data) => {
            this.toggleLoading()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                let role = data.roles.map(item => {
                    return {
                        value: item.id,
                        label: `${item.display_name} - ${item.description}`
                    }
                })
                this.setState({
                    roles: role
                })
                if (this.props.match.params.id) {
                  data.user.bloqueado = (data.user.bloqueado === 0) ? false : true
                  data.user.roles_names = data.user.roles.map(item => {
                        return {
                            value: item.id,
                            label: `${item.display_name} - ${item.description}`
                        }
                    })
                    delete data.user.roles
                }
                this.setState(data.user)
            }
        }
        if (this.props.match.params.id) {
            this.setState({

            })
            edit(this.props.match.params.id, response)
        } else {
            getField(response)
        }
        
    }

    handleSave(event) {
        event.preventDefault()
        if (this.state.password !== this.state.password_confirmation) {
            showNotification(
                "top",
                "center",
                "Senhas não correspondem. Verifique a igualdade entre senhas!",
                "warning"
            )
        } else {
            showWaiting()
            let params = {}
            this.state.change_password ?
            params = {
                username: this.state.username,
                password: this.state.password,
                password_confirmation: this.state.password_confirmation,
                nome: this.state.nome,
                roles: this.filterArray(this.state.roles_names),
                bloqueado: this.state.bloqueado ? 1 : 0,
                cargo: this.state.cargo
            } : 
            params = {
                username: this.state.username,
                nome: this.state.nome,
                roles: this.filterArray(this.state.roles_names),
                bloqueado: this.state.bloqueado ? 1 : 0,
                cargo: this.state.cargo
            }
            let response = (error, data) => {
                hideWaiting()
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique o formulário e tente novamente",
                        "danger"
                    )
                    this.setState({
                        errors: mapErrors(error)
                    })
                } else {
                    showNotification(
                        "top",
                        "center",
                        `Usuário ${
                        this.props.match.params.id ? "editado" : "incluído"
                        } com sucesso`,
                        "success"
                    )
                    this.props.history.push("/usuario")
                }
            }
            this.props.match.params.id
                ? put(this.props.match.params.id, params, response)
                : post(params, response)
        }
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.container}>
                <LoadingContent isLoading={this.state.isLoading}>
                    <Card>
                        <CardHeader color="primary">
                            <Grid container direction="row">
                                <Grid item lg={10} xs={12}>
                                    <h2 className={classes.cardTitleWhite}>
                                        Cadastro de Usuários
                                    </h2>
                                </Grid>
                            </Grid>
                        </CardHeader>
                        <CardBody>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        name="nome"
                                        label="Nome Completo"
                                        fullWidth
                                        error={this.state.errors.nome}
                                        helperText={this.state.errors.nome}
                                        onChange={this.handleChange}
                                        value={this.state.nome || ""}
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" style={{ marginTop: "2%" }}>
                                <Divider />
                                <Typography
                                    className={classes.dividerFullWidth}
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Informação para acesso do usuário
                                </Typography>
                            </Grid>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.bloqueado}
                                                    name="bloqueado"
                                                    error={this.state.errors.bloqueado}
                                                    helperText={this.state.errors.bloqueado}
                                                    onChange={this.handleChange}
                                                    value={true}
                                                />
                                            }
                                            label="Bloquear usuário"
                                        />
                                    </FormGroup>
                                </Grid>
                                {this.props.match.params.id && (
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={this.state.change_password}
                                                        name="change_password"
                                                        error={this.state.errors.change_password}
                                                        helperText={this.state.errors.change_password}
                                                        onChange={this.handleChange}
                                                        value={true}
                                                    />
                                                }
                                                label="Mudar Senha"
                                            />
                                        </FormGroup>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField
                                        name="username"
                                        fullWidth
                                        label="Nome usuário"
                                        value={this.state.username || ""}
                                        error={this.state.errors.username}
                                        helperText={this.state.errors.username}
                                        margin="normal"
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField
                                        name="password"
                                        fullWidth
                                        label="Senha"
                                        onChange={this.handleChange}
                                        type="password"
                                        disabled={!this.state.change_password}
                                        value={this.state.password || ""}
                                        error={this.state.errors.password}
                                        helperText={this.state.errors.password}
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField
                                        name="password_confirmation"
                                        fullWidth
                                        label="Confirme a Senha"
                                        disabled={!this.state.change_password}
                                        error={this.state.errors.password_confirmation}
                                        helperText={this.state.errors.password_confirmation}
                                        onChange={this.handleChange}
                                        type="password"
                                        value={this.state.password_confirmation || ""}
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                    <SelectCustom
                                        value={this.state.roles_names}
                                        name="roles_names"
                                        isMulti={true}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={this.handleChange}
                                        error={this.state.errors.roles}
                                        helperText={this.state.errors.roles}
                                        options={this.state.roles}
                                        placeholder="Função"
                                        message="Pesquise a Função"
                                    />
                                    <FormHelperText>Selecione a(s) Função(ões)</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField
                                        name="cargo"
                                        fullWidth
                                        label="Cargo"
                                        error={this.state.errors.cargo}
                                        helperText={this.state.errors.cargo}
                                        onChange={this.handleChange}
                                        value={this.state.cargo || ""}
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </CardBody>
                        <CardFooter>
                            <Grid container justify="center">
                                <Grid item lg={2}>
                                    <Button block color="info" round onClick={this.handleSave}>
                                        {this.props.match.params.id ? "Atualizar" : "Adicionar"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardFooter>
                    </Card>
                </LoadingContent>
            </div>
        )
    }
}
export default withStyles(contribuinteStyle)(UserCadastro)