import React from "react"

import List from "@material-ui/icons/List"
import Lens from "@material-ui/icons/Lens"
import numeral from "../util/numeral"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Grid from "@material-ui/core/Grid"
import CardBody from "../components/Card/CardBody"
import secaoStyle from "../assets/css/layout/secaoStyle"
import { withStyles, AppBar, Tabs, Tab, Paper, DialogTitle, DialogContent, Dialog, DialogActions } from "@material-ui/core"
import ValoresDemonstrativo from "./ValoresDemonstrativo";
import DetalheDemonstrativo from "./DetalheDemonstrativo";
import moment from 'moment'
import Card from "./Card/Card";
import Button from "./CustomButtons/Button";
import CardHeader from "./Card/CardHeader";
import CardFooter from "./Card/CardFooter";
import Table from "./Table/Table";
import AlterarVencimentoParcelaPag from '../views/Divida/Movimento/AlterarVencimentoParcelaPag'
import AlterarVencimentoParcelaRefinanciamento from '../views/Divida/Movimento/AlterarVencimentoParcelaRefinanciamento'
class DemonstrativoTab extends React.Component {

    state = {
        value: 0,
        showParcelasModal: false
    };

    handleChange = (event, value) => {
        this.setState({ value }, () => { console.log("value: ", this.state.value) });
    };

    showParcelasModal = (key) => {
        let newState = {
            ['showParcelasModal.' + key]: true
        }
        console.log(newState)
        this.setState(newState)
    }
    showAlterarVencimentoModal = (key) => {
        this.setState({
            ['showAlterarVencimentoModal.' + key]: true
        })
    }
    showAlterarVencimentoRefinanciamentoModal = (key) => {
        this.setState({
            ['showAlterarVencimentoRefinanciamentoModal.' + key]: true
        })
    }
    closeParcelasModal = (key) => {
        this.setState({
            ['showParcelasModal.' + key]: false
        })
    }
    closeAlterarVencimentoModal = (key) => {
        this.setState({
            ['showAlterarVencimentoModal.' + key]: false
        }, () => {
            if (this.props.onUpdate) {
                this.props.onUpdate()
            }
        })
    }
    closeAlterarVencimentoRefinanciamentoModal = (key) => {
        this.setState({
            ['showAlterarVencimentoRefinanciamentoModal.' + key]: false
        }, () => {
            if (this.props.onUpdate) {
                this.props.onUpdate()
            }
        })
    }
    getTabColor(item, classes) {
        switch (item.situacao_id) {
            case 1:
                if (item.pago) {
                    return classes.successTableRow
                } else if (item.em_pagamento) {
                    return classes.purpleTableRow
                } else {
                    return ""
                }
            case 2:
                if (item.pago) {
                    return classes.successTableRow
                } else if (item.em_pagamento) {
                    return classes.purpleTableRow
                } else {
                    return classes.secondaryTableRow
                }
            case 3:
                if (item.pago) {
                    return classes.successTableRow
                } else if (item.em_pagamento) {
                    return classes.purpleTableRow
                } else {
                    return classes.infoTableRow
                }
            case 4:
                return classes.warningTableRow
            case 5:
                return classes.dangerTableRow
            case 6:
                return classes.primaryTableRow
            case 7:
                return classes.grayTableRow
            case 8:
                return classes.roseTableRow
            default:
                if (item.pago) {
                    return classes.successTableRow
                }
                if (item.em_pagamento) {
                    return classes.purpleTableRow
                }
        }
        return ""
    }
    render() {
        const { item, classes } = this.props
        return (
            <ExpansionPanel className={this.getTabColor(item, classes)}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {item.competencia} Receita: {item.receita_id} {item.receita.nome} -
                    Cód. Origem: {item.codigo_de_origem} -
                             Valor:
                    {`R$ ${numeral(parseFloat(item.valor))
                        .format("0.0,00")
                        .slice(0, -2)}`}  -
                    Multa: {`R$ ${numeral(parseFloat(item.multa))
                        .format("0.0,00")
                        .slice(0, -2)}`} -
                    Juros: {`R$ ${numeral(parseFloat(item.juros))
                        .format("0.0,00")
                        .slice(0, -2)}`} -
                    Valor Corrigido: {`R$ ${numeral(parseFloat(item.correcao_monetaria) + parseFloat(item.saldo_devedor))
                        .format("0.0,00")
                        .slice(0, -2)}`}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container direction="row" spacing={8}>
                        <AppBar style={{ zIndex: 800 }} position="static" color="default">
                            <Tabs value={this.state.value} onChange={this.handleChange}>
                                <Tab label="Valores" />
                                <Tab label="Detalhes" />
                                <Tab label="Refinanciamento" />
                                <Tab label="Pagamentos" />
                            </Tabs>
                        </AppBar>

                        {this.state.value === 0 && (
                            <Paper className={classes.rootPaper}>
                                <CardBody>
                                    <ValoresDemonstrativo
                                        divida={item} />
                                </CardBody>
                            </Paper>
                        )}
                        {this.state.value === 1 && (
                            <Paper className={classes.rootPaper}>
                                <CardBody>
                                    <DetalheDemonstrativo
                                        situacao_nome={item.situacao.nome}
                                        codigo_de_origem={item.codigo_de_origem}
                                        competencia={item.competencia}
                                        historico={item.historico}
                                        divida={item} />
                                </CardBody>
                            </Paper>
                        )}
                        {this.state.value === 2 && (
                            <Paper className={classes.rootPaper}>
                                <CardBody>
                                    {(item.refinanciamentos || []).map((itemRefi, keyR) => {
                                        return (
                                            <Card >
                                                <CardHeader color="primary">
                                                    <h3 className="text-white">Código de baixa: {itemRefi.id}</h3>
                                                </CardHeader>
                                                <CardBody>
                                                    <Grid alignItems="center" container direction="row" spacing={8}>
                                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                                            <strong className="text-black">Iniciado em: </strong>
                                                            <span className="text-black font-regular">
                                                                {moment(itemRefi.created_at).format("DD/MM/YYYY")}
                                                            </span><br />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                                            <strong className="text-black">Código do REFIS: </strong>
                                                            <span className="text-black font-regular">
                                                                {itemRefi.refis_id}
                                                            </span>
                                                            <strong className="text-black">
                                                                {` (Válido de ${moment(itemRefi.inicio_validade).format("DD/MM/YYYY")} até ${moment(itemRefi.fim_validade).format("DD/MM/YYYY")})`}
                                                            </strong><br />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                                            <strong className="text-black">Quantidade de parcelas: </strong>
                                                            <span className="text-black font-regular">{itemRefi.parcelas.length}  </span><br />
                                                            <strong className="text-success">Quantidade de parcelas pagas: </strong>
                                                            <span className="text-black font-regular">{itemRefi.parcelas.filter((parcela) => parcela.pago).length}  </span><br />
                                                            <strong className="text-danger">Quantidade de parcelas vencidas: </strong>
                                                            <span className="text-black font-regular">{itemRefi.parcelas.filter((parcela) => parcela.vencido && !parcela.pago).length}  </span>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                            <strong className="text-black">Historico: </strong>
                                                            <span className="text-black font-regular">
                                                                {itemRefi.historico}
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </CardBody>
                                                <CardFooter style={{ textAlign: 'center', justifyContent: 'center' }}>
                                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                                        <Button block color="info" round onClick={(e) => this.showParcelasModal(keyR)}>
                                                            <List /> Vizualizar detalhamento das parcelas
                                                    </Button>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                                        <Button block color="warning" round onClick={(e) => this.showAlterarVencimentoRefinanciamentoModal(keyR)}>
                                                            <List /> Alterar vencimento de parcela
                                                    </Button>
                                                    </Grid>
                                                </CardFooter>
                                                <Dialog
                                                    maxWidth={'xl'}
                                                    open={this.state[`showParcelasModal.${keyR}`]}
                                                    onClose={(e) => this.closeParcelasModal(keyR)}
                                                    aria-labelledby="form-dialog-title"
                                                    fullWidth
                                                    style={{ zIndex: "900" }}
                                                >
                                                    <DialogTitle id="form-dialog-title">Parcelas</DialogTitle>
                                                    <DialogContent>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            style={{ marginBottom: "2%" }}
                                                        >
                                                        </Grid>
                                                        <Lens className={classes.dot + " " + classes.dotSuccess} />
                                                        Parcela Paga
                                                        <Lens className={classes.dot + " " + classes.dotDanger} />
                                                        Parcela vencida
                                                        <Paper className={classes.tableWrapper}>
                                                            <Table
                                                                tableHeaderColor="primary"
                                                                fullWidth
                                                                getRowColor={(keyC, classes) => {
                                                                    if (itemRefi.parcelas[keyC].pago) {
                                                                        return classes.successTableRow
                                                                    } else if (itemRefi.parcelas[keyC].vencido) {
                                                                        return classes.dangerTableRow
                                                                    } else {
                                                                        return ""
                                                                    }
                                                                }}
                                                                tableHead={[
                                                                    "#",
                                                                    "Valor",
                                                                    "Multa",
                                                                    "Juros",
                                                                    "Juros adicionais",
                                                                    "Vencimento",
                                                                    "Pago em",
                                                                ]}
                                                                tableData={itemRefi.parcelas.map((itemP, keyP) => {
                                                                    return [
                                                                        itemP.numero_da_parcela + "",
                                                                        "R$" +
                                                                        numeral(parseFloat(itemP.valor))
                                                                            .format("0.0,00")
                                                                            .slice(0, -2),
                                                                        "R$" +
                                                                        numeral(parseFloat(itemP.multa))
                                                                            .format("0.0,00")
                                                                            .slice(0, -2),
                                                                        "R$" +
                                                                        numeral(parseFloat(itemP.juros))
                                                                            .format("0.0,00")
                                                                            .slice(0, -2),
                                                                        "R$" +
                                                                        numeral(parseFloat(itemP.juros_adicionais))
                                                                            .format("0.0,00")
                                                                            .slice(0, -2),
                                                                        moment(itemP.vencimento).format("DD/MM/YYYY"),
                                                                        itemP.baixa ? moment(itemP.baixa.data_pagamento).format("DD/MM/YYYY") : 'Não Pago',
                                                                        keyP
                                                                    ]
                                                                })}
                                                            />
                                                        </Paper>
                                                    </DialogContent>
                                                    <DialogActions >
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            style={{ marginBottom: "2%", justifyContent: "center" }}
                                                        >
                                                            <Button color="danger" onClick={(e) => this.closeParcelasModal(keyR)}>
                                                                Fechar
                                                            </Button>
                                                        </Grid>
                                                    </DialogActions>
                                                </Dialog>
                                                <Dialog
                                                    open={this.state[`showAlterarVencimentoRefinanciamentoModal.${keyR}`]}
                                                    onClose={(e) => this.closeAlterarVencimentoRefinanciamentoModal(keyR)}
                                                    aria-labelledby="form-dialog-title"
                                                    fullWidth
                                                    maxWidth="lg"
                                                    style={{ zIndex: "900" }}>
                                                    <DialogContent>
                                                        <AlterarVencimentoParcelaRefinanciamento callBack={(e) => this.closeAlterarVencimentoRefinanciamentoModal(keyR)} refinanciamento={itemRefi} />
                                                    </DialogContent>

                                                    <DialogActions >
                                                        <Grid container direction="row" style={{ marginBottom: "2%", justifyContent: "center" }}>
                                                            <Button color="danger" onClick={(e) => this.closeAlterarVencimentoRefinanciamentoModal(keyR)}>
                                                                Fechar
                                                            </Button>
                                                        </Grid>
                                                    </DialogActions>
                                                </Dialog>
                                            </Card>
                                        )
                                    })}

                                </CardBody>
                            </Paper>
                        )}
                        {this.state.value === 3 && (
                            <Paper className={classes.rootPaper}>
                                <CardBody>
                                    {(item.pagamentos || []).map((itemPag, keyR) => {
                                        return (
                                            <Card >
                                                <CardHeader color="primary">
                                                    <h3 className="text-white">Código de baixa: {itemPag.id}</h3>
                                                </CardHeader>
                                                <CardBody>
                                                    <Grid alignItems="center" container direction="row" spacing={8}>
                                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                                            <strong className="text-black">Iniciado em: </strong>
                                                            <span className="text-black font-regular">
                                                                {moment(itemPag.created_at).format("DD/MM/YYYY")}
                                                            </span><br />
                                                            <strong className="text-black">Quantidade de parcelas: </strong>
                                                            <span className="text-black font-regular">{itemPag.parcelas.length}  </span><br />

                                                        </Grid>

                                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                                            <strong className="text-success">Quantidade de parcelas pagas: </strong>
                                                            <span className="text-black font-regular">{itemPag.parcelas.filter((parcela) => parcela.pago).length}  </span><br />
                                                            <strong className="text-danger">Quantidade de parcelas vencidas: </strong>
                                                            <span className="text-black font-regular">{itemPag.parcelas.filter((parcela) => parcela.vencido).length}  </span>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                                            <strong className="text-black">Historico: </strong>
                                                            <span className="text-black font-regular">
                                                                {itemPag.historico}
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </CardBody>
                                                <CardFooter style={{ textAlign: 'center', justifyContent: 'center' }}>
                                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                                        <Button block color="info" round onClick={(e) => this.showParcelasModal(keyR)}>
                                                            <List /> Vizualizar detalhamento das parcelas
                                                    </Button>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                                        <Button block color="warning" round onClick={(e) => this.showAlterarVencimentoModal(keyR)}>
                                                            <List /> Alterar vencimento de parcela
                                                    </Button>
                                                    </Grid>
                                                </CardFooter>
                                                <Dialog
                                                    open={this.state[`showParcelasModal.${keyR}`]}
                                                    onClose={(e) => this.closeParcelasModal(keyR)}
                                                    aria-labelledby="form-dialog-title"
                                                    fullWidth
                                                    maxWidth={'xl'}
                                                    style={{ zIndex: "900" }}
                                                >
                                                    <DialogTitle id="form-dialog-title">Parcelas</DialogTitle>
                                                    <DialogContent>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            style={{ marginBottom: "2%" }}
                                                        >
                                                        </Grid>
                                                        <Lens className={classes.dot + " " + classes.dotSuccess} />
                                                        Parcela Paga
                                                        <Lens className={classes.dot + " " + classes.dotDanger} />
                                                        Parcela vencida
                                                        <Paper className={classes.tableWrapper}>
                                                            <Table
                                                                tableHeaderColor="primary"
                                                                fullWidth
                                                                getRowColor={(keyC, classes) => {
                                                                    if (itemPag.parcelas[keyC].pago) {
                                                                        return classes.successTableRow
                                                                    } else if (itemPag.parcelas[keyC].vencido) {
                                                                        return classes.dangerTableRow
                                                                    } else {
                                                                        return ""
                                                                    }
                                                                }}
                                                                tableHead={[
                                                                    "#",
                                                                    "Valor",
                                                                    "Multa",
                                                                    "Juros",
                                                                    "Vencimento",
                                                                    "Pago em",
                                                                ]}
                                                                tableData={itemPag.parcelas.map((itemP, keyP) => {
                                                                    return [
                                                                        itemP.numero + "",
                                                                        "R$" +
                                                                        numeral(parseFloat(itemP.valor))
                                                                            .format("0.0,00")
                                                                            .slice(0, -2),
                                                                        "R$" +
                                                                        numeral(parseFloat(itemP.multa))
                                                                            .format("0.0,00")
                                                                            .slice(0, -2),
                                                                        "R$" +
                                                                        numeral(parseFloat(itemP.juros))
                                                                            .format("0.0,00")
                                                                            .slice(0, -2),
                                                                        moment(itemP.vencimento).format("DD/MM/YYYY"),
                                                                        itemP.baixa ? moment(itemP.baixa.data_pagamento).format("DD/MM/YYYY") : 'Não Pago',
                                                                        keyP
                                                                    ]
                                                                })}
                                                            />
                                                        </Paper>
                                                    </DialogContent>
                                                    <DialogActions >
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            style={{ marginBottom: "2%", justifyContent: "center" }}
                                                        >
                                                            <Button color="danger" onClick={(e) => this.closeParcelasModal(keyR)}>
                                                                Fechar
                                                            </Button>
                                                        </Grid>
                                                    </DialogActions>
                                                </Dialog>
                                                <Dialog
                                                    open={this.state[`showAlterarVencimentoModal.${keyR}`]}
                                                    onClose={(e) => this.closeAlterarVencimentoModal(keyR)}
                                                    aria-labelledby="form-dialog-title"
                                                    fullWidth
                                                    maxWidth="lg"
                                                    style={{ zIndex: "900" }}
                                                >
                                                    <DialogContent>
                                                        <AlterarVencimentoParcelaPag callBack={(e) => this.closeAlterarVencimentoModal(keyR)} pagamento={itemPag} />
                                                    </DialogContent>

                                                    <DialogActions >
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            style={{ marginBottom: "2%", justifyContent: "center" }}
                                                        >
                                                            <Button color="danger" onClick={(e) => this.closeAlterarVencimentoModal(keyR)}>
                                                                Fechar
                                                            </Button>
                                                        </Grid>
                                                    </DialogActions>
                                                </Dialog>
                                            </Card>
                                        )
                                    })}

                                </CardBody>
                            </Paper>
                        )}

                    </Grid>
                </ExpansionPanelDetails>

            </ExpansionPanel>
        )
    }
}

export default withStyles(secaoStyle)(DemonstrativoTab)
