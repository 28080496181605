import axios from "axios"
import { defaultHeaders } from "./Constants"

export function get(page, callBack) {
    let config = {
      headers: defaultHeaders()
    }
    let url =  process.env.REACT_APP_API + `notificacoes${page ? `?page=${page}` : ""}`
    axios
      .get(url, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
}

export function search(searchText, field, onStart, callBack) {
  let config = {
    headers: defaultHeaders(),
    data: { a: "a" }
  }
  let url =
    process.env.REACT_APP_API +
    `search/notificacoes?text=${searchText}&fields=${field}`
  if (onStart) {
    onStart()
  }
  return axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function imprimirNotificacao(id, callBack) {
    let headers = defaultHeaders()
    let config = {
      headers: headers,
      responseType: "blob"
    }
    let url = process.env.REACT_APP_API + `notificacoes/${id}`
    axios
      .get(url, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
}

export function imprimirTodasNotificacoes(id, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `printNotificacoes?contribuintes=${id}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

  export function post(params, callBack) {
    let config = {
      headers: defaultHeaders()
    }
    let url =  process.env.REACT_APP_API + "notificacoes"
    axios
      .post(url, params, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
  }