import React from "react"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"
import Print from "@material-ui/icons/Print"

import InputMask from "react-input-mask"

import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import CardFooter from "../../components/Card/CardFooter"
import Button from "../../components/CustomButtons/Button"

import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import { withStyles } from "@material-ui/core"
import { getFieldContribuinte, post, edit, put, ficha } from "../../util/contribuinte"
import { getCep } from "../../util/cep"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import { showNotification, mapErrors, telefones, removeMask, showWaiting, hideWaiting } from "../../util/Constants"

import LoadingContent from "../../components/LoadingContent"

class ContribuinteForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      tipo_documento: 1,
      tipo_contribuinte: 1,
      documentos: [],
      tipoContribuinte: [],
      razao_social: "",
      numero_documento: "",
      rg: "",
      nome_fantasia: "",
      endereco: "",
      bairro: "",
      cep: "",
      cidade: "",
      uf: "",
      fone: "",
      nit: "",
      insc_municipal: "",
      insc_estadual: "",
      numero_dependentes: "",
      email: "",
      observacao: "",
      masks: [ "99.999.999/9999-99", "999.999.999-99", ""]
    }
    this.getFields = this.getFields.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.getInfoCep = this.getInfoCep.bind(this)
    this.imprimirFicha = this.imprimirFicha.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClose() {
    this.setState({
      showFichaModal: false
    }, () => {
      this.props.history.push("/contribuintes/cadastro")
    })
  }

  componentWillMount() {
    this.getFields()
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState(
      {
        [name]: event.target.value,
        errors: errors
      }, () => {
        if (this.state.cep && name === "cep") {
          this.getInfoCep(this.state.cep)
        }
      }
    )
  }

  getInfoCep(cep) {
    getCep(cep, (error, data) => {
      if (!(error)) {
        if (!(data.erro === true)) {
          this.setState({
            endereco: data.logradouro,
            bairro: data.bairro,
            uf: data.uf,
            cidade: data.localidade
          })
        }
      }
    })
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    if ((removeMask(this.state.fone) || "").length > 11) {
      this.setState({
        fone: this.state.fone.substr(0, this.state.fone.length - 1)
      })
    }

    let params = {
      tipo_de_documento_id: this.state.tipo_documento,
      tipo_contribuinte_id: this.state.tipo_contribuinte,
      nome_razao_social: this.state.razao_social,
      cpf_cnpj: this.state.numero_documento !== null ? removeMask(this.state.numero_documento) : "",
      rg: this.state.rg,
      nome_fantasia: this.state.nome_fantasia,
      endereco: this.state.endereco,
      bairro: this.state.bairro,
      cep: this.state.cep !== null ? removeMask(this.state.cep) : "",
      cidade: this.state.cidade,
      uf: this.state.uf,
      telefone: this.state.fone !== null ? removeMask(this.state.fone) : "",
      nit: this.state.nit,
      inscricao_municipal: this.state.insc_municipal,
      inscricao_estadual: this.state.insc_estadual,
      numero_dependentes: this.state.numero_dependentes,
      email: this.state.email,
      observacoes: this.state.observacao
    }
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        showNotification(
          "top",
          "center",
          `Contribuinte ${
          this.props.match.params.id ? "editado" : "incluído"
          } com sucesso`,
          "success"
        )
        this.setState({
          contribuinte_id: data.id,
          showFichaModal: true
        })
      }
    }
    this.props.match.params.id
      ? put(this.props.match.params.id, params, response)
      : post(params, response)
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }

  imprimirFicha() {
    showWaiting()
    let response = (error, data) => {
      hideWaiting()
      if (error) {

        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {

        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        this.props.history.push("/contribuintes/cadastro")
      }
    }
    let contribuinte = this.state.contribuinte_id
    ficha(contribuinte, response)
  }


  getFields() {
    this.toggleLoading()
    let response = (error, data) => {
      this.toggleLoading()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState({
          tipoContribuinte: data.tipos_de_contribuinte,
          documentos: data.tipos_de_documento
        })
        if (this.props.match.params.id) {
          data.contribuinte.razao_social = data.contribuinte.nome_razao_social

          data.contribuinte.numero_documento = data.contribuinte.cpf_cnpj

          if (data.contribuinte.telefone === null) {
            data.contribuinte.fone = telefones("")
          } else {
            data.contribuinte.fone = telefones(data.contribuinte.telefone)
          }

          data.contribuinte.insc_municipal =
            data.contribuinte.inscricao_municipal
          data.contribuinte.insc_estadual =
            data.contribuinte.inscricao_estadual
          data.contribuinte.tipo_contribuinte =
            data.contribuinte.tipo_contribuinte_id
          data.contribuinte.tipo_documento =
            data.contribuinte.tipo_de_documento_id
          data.contribuinte.observacao = data.contribuinte.observacoes
        }
        this.setState(data.contribuinte)
      }
    }
    if (this.props.match.params.id) {
      edit(this.props.match.params.id, response)
    } else {
      getFieldContribuinte(response)
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <LoadingContent isLoading={this.state.isLoading}>
          <Card>
            <form onSubmit={this.handleSave}>
              <CardHeader color="primary">
                <Grid container direction="row">
                  <Grid item lg={10} xs={12}>
                    <h2 className={classes.cardTitleWhite}>
                      Cadastro de contribuintes
                    </h2>
                  </Grid>
                </Grid>
              </CardHeader>
              <CardBody>
                <Grid container direction="row" style={{ marginTop: "2%" }}>
                  <Divider />
                  <Typography
                    className={classes.dividerFullWidth}
                    color="textSecondary"
                    variant="caption"
                  >
                    Informações
                  </Typography>
                </Grid>
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <TextField
                      id="outlined-name"
                      label="Razão social"
                      name="razao_social"
                      required
                      fullWidth
                      value={this.state.razao_social}
                      onChange={this.handleChange}
                      error={this.state.errors.razao_social}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-select-currency"
                      label="Nome Fantasia"
                      name="nome_fantasia"
                      fullWidth
                      value={this.state.nome_fantasia}
                      onChange={this.handleChange}
                      error={this.state.errors.nome_fantasia !== undefined}
                      helperText={this.state.errors.nome_fantasia}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      name="tipo_documento"
                      label="Tipo de documento"
                      fullWidth
                      value={this.state.tipo_documento}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    >
                      {this.state.documentos.map((option, key) => (
                        <MenuItem key={key} value={option.id}>
                          {option.nome}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputMask
                      mask={this.state.masks[this.state.tipo_documento - 1]}
                      value={this.state.numero_documento}
                      onChange={this.handleChange}
                    >
                      {() => (
                        <TextField
                          id="outlined-name"
                          label="Nº documento"
                          name="numero_documento"
                          helperText={this.state.errors.cpf_cnpj}
                          fullWidth
                          error={this.state.errors.cpf_cnpj}
                          value={this.state.numero_documento}
                          onChange={this.handleChange}
                          margin="normal"
                          variant="outlined"
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-select-currency"
                      name="rg"
                      label="RG"
                      fullWidth
                      value={this.state.rg}
                      error={this.state.errors.rg}
                      helperText={this.state.errors.rg}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" style={{ marginTop: "2%" }}>
                  <Divider />
                  <Typography
                    className={classes.dividerFullWidth}
                    color="textSecondary"
                    variant="caption"
                  >
                    Localização
                  </Typography>
                </Grid>

                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputMask
                      mask="99999-999"
                      value={this.state.cep}
                      onChange={this.handleChange}
                    >
                      {() => (
                        <TextField
                          id="outlined-select-currency"
                          name="cep"
                          label="CEP"
                          error={this.state.errors.cep}
                          helperText={this.state.errors.cep}
                          fullWidth
                          value={this.state.cep}
                          onChange={this.handleChange}
                          margin="normal"
                          variant="outlined"
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-name"
                      label="Bairro"
                      name="bairro"
                      error={this.state.errors.bairro}
                      helperText={this.state.errors.bairro}
                      required
                      fullWidth
                      value={this.state.bairro}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-select-currency"
                      name="cidade"
                      label="Cidade"
                      error={this.state.errors.cidade}
                      helperText={this.state.errors.cidade}
                      required
                      fullWidth
                      value={this.state.cidade}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-select-currency"
                      name="endereco"
                      label="Endereço"
                      error={this.state.errors.endereco}
                      helperText={this.state.errors.endereco}
                      required
                      fullWidth
                      value={this.state.endereco}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-name"
                      required
                      label="UF"
                      name="uf"
                      fullWidth
                      value={this.state.uf}
                      error={this.state.errors.uf}
                      helperText={this.state.errors.uf}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputMask
                      value={telefones(this.state.fone)}
                      onChange={this.handleChange}
                    >
                      {() => (
                        <TextField
                          id="outlined-select-currency"
                          name="fone"
                          label="Telefone"
                          error={this.state.errors.fone}
                          helperText={this.state.errors.fone}
                          fullWidth
                          value={telefones(this.state.fone)}
                          onChange={this.handleChange}
                          margin="normal"
                          variant="outlined"
                        />
                      )}
                    </InputMask>
                  </Grid>
                </Grid>

                <Grid container direction="row" style={{ marginTop: "2%" }}>
                  <Divider />
                  <Typography
                    className={classes.dividerFullWidth}
                    color="textSecondary"
                    variant="caption"
                  >
                    Adicionais
                  </Typography>
                </Grid>

                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-select-currency"
                      name="nit"
                      label="NIT"
                      error={this.state.errors.nit}
                      helperText={this.state.errors.nit}
                      fullWidth
                      value={this.state.nit}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-name"
                      select
                      label="Tipo Contribuinte"
                      name="tipo_contribuinte"
                      fullWidth
                      error={this.state.errors.tipo_contribuinte}
                      helperText={this.state.errors.tipo_contribuinte}
                      value={this.state.tipo_contribuinte}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    >
                      {this.state.tipoContribuinte.map((option, key) => (
                        <MenuItem key={key} value={option.id}>
                          {option.nome}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-select-currency"
                      name="insc_municipal"
                      error={this.state.errors.insc_municipal}
                      helperText={this.state.errors.insc_municipal}
                      label="Inscrição Municipal"
                      fullWidth
                      value={this.state.insc_municipal}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-select-currency"
                      name="insc_estadual"
                      error={this.state.errors.insc_estadual}
                      helperText={this.state.errors.insc_estadual}
                      label="Inscrição Estadual"
                      fullWidth
                      value={this.state.insc_estadual}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-name"
                      label="Nº Dependentes"
                      error={this.state.errors.numero_dependentes}
                      helperText={this.state.errors.numero_dependentes}
                      name="numero_dependentes"
                      fullWidth
                      value={this.state.numero_dependentes}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-select-currency"
                      name="email"
                      label="E-mail"
                      helperText={this.state.errors.email}
                      fullWidth
                      value={this.state.email}
                      error={this.state.errors.email}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="outlined-select-currency"
                      name="observacao"
                      label="Observação"
                      fullWidth
                      error={this.state.errors.observacao}
                      helperText={this.state.errors.observacao}
                      multiline
                      rowsMax="4"
                      value={this.state.observacao}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardBody>
              <CardFooter>
                <Grid container justify="center">
                  <Grid item lg={2}>
                    <Button block color="info" round type="submit">
                      <AddIcon />{" "}
                      {this.props.match.params.id ? "Atualizar" : "Adicionar"}
                    </Button>
                  </Grid>
                </Grid>
              </CardFooter>
            </form>
          </Card>
          <Dialog
            open={this.state.showFichaModal}
            onClose={this.closeModal}
            aria-labelledby="form-dialog-title"
            fullWidth
            style={{ zIndex: "901" }}
          >
            <DialogTitle id="form-dialog-title">Deseja imprimir a ficha do contribuinte?</DialogTitle>
            <DialogContent>
              <DialogContent>
                <Grid container justify="center">
                  <Grid item lg={4}>
                    <Button
                      block
                      color="info"
                      round
                      onClick={this.imprimirFicha}
                    >
                      <Print /> Imprimir Ficha
                      </Button>
                  </Grid>
                </Grid>
              </DialogContent>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="danger">
                Voltar
            </Button>
            </DialogActions>
          </Dialog>
        </LoadingContent>
      </div>
    )
  }
}

export default withStyles(contribuinteStyle)(ContribuinteForm)
