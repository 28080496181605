import React from "react"

import Grid from "@material-ui/core/Grid"
import moment from "moment"

class DetalheDemonstrativo extends React.Component {
    render() {
        return (
            <div>
                <Grid container direction="row" spacing={8}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <strong className="text-black">Situação: </strong><span className="text-black font-regular">{this.props.divida.situacao.nome}  </span> <br />
                        <strong className="text-black">Data de lançamento: </strong><span className="text-black font-regular">{moment(this.props.divida.created_at).format(
                            "DD/MM/YYYY"
                        )}  </span>
                    </Grid>
                </Grid>
                <Grid container direction="row" spacing={8}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <strong className="text-black">Histórico: </strong><span className="text-black font-regular">{this.props.divida.historico}  </span>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default DetalheDemonstrativo