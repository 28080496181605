import React from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"
import { withStyles, Paper } from "@material-ui/core"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Grow from "@material-ui/core/Grow"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Hidden from "@material-ui/core/Hidden"
import Poppers from "@material-ui/core/Popper"
import Typography from '@material-ui/core/Typography'
import { connect } from "react-redux"
import Person from "@material-ui/icons/Person"
import Button from "../CustomButtons/Button"
import Fade from "react-reveal/Fade"
import headerLinksStyle from "../../assets/css/components/headerLinksStyle"
import { withRouter } from "react-router-dom"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import moment from "moment"
import Card from "../../components/Card/Card"
import CardBody from "../../components/Card/CardBody"
import { unlock, lock } from "../../util/bloqueio"
import { hideWaiting, showWaiting, showNotification } from "../../util/Constants";

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      modal: false,
      data_inicial: new Date(),
      errors: {},
      after: false
    }
    this.handleSave = this.handleSave.bind(this)
    this.changeModal = this.changeModal.bind(this)
    this.blockAfter = this.blockAfter.bind(this)
    this.blockNow = this.blockNow.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleDi = this.handleDi.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }))
  };

  handleClose() {
    this.setState({ open: false })
  }
  handleDi() {
    this.setState({
      modal: false
    })
  }
  changeModal() {
    this.setState({ modal: true })
  }
  logout() {
    delete localStorage.token;
    delete localStorage.me;
    this.props.history.push('/login')
  }

  handleSave() {
    showWaiting()
    unlock(moment(this.state.data_inicial).format("YYYY-MM-DD"), (error, data) => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        showNotification(
          "top",
          "center",
          "Desbloqueio do sistema agendado com sucesso!",
          "success"
        )
        this.handleDi()
      }
    })
  }

  blockAfter() {
    this.setState({
      after: !this.state.after
    })
  }

  blockNow() {
    showWaiting()
    lock((error, data) => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        showNotification(
          "top",
          "center",
          "Sistema bloqueado com sucesso!",
          "success"
        )
        this.handleDi()
      }
    })
  }


  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState(
      {
        [name]: event.target.value,
        errors: errors
      }
    )
  }

  render() {
    const { classes, roles } = this.props;
    const { open } = this.state;
    return (
      <div>
        <div className={classes.manager}>
          <Button
            buttonRef={node => {
              this.anchorEl = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={open ? "menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggle}
            className={classes.buttonLink}
          >
            <Person className={classes.icons} />
            <Hidden mdUp implementation="css">
              <p onClick={this.handleClick} className={classes.linkText}>
                Conta
              </p>
            </Hidden>
          </Button>
          <Poppers
            open={open}
            anchorEl={this.anchorEl}
            transition
            placement="left"
            disablePortal
            className={
              classNames({ [classes.popperClose]: !open }) +
              " " +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      <Link
                        to="/configuracao"
                        style={{ textDecoration: "none" }}
                      >
                        <MenuItem
                          className={classes.dropdownItem}
                        >
                          Conta
                      </MenuItem>
                      </Link>
                      {(roles.filter(item => (item.id === 1)).length > 0) && (
                        <MenuItem
                          className={classes.dropdownItem}
                          onClick={this.changeModal}
                        >
                          Bloquear Sistema
                      </MenuItem>

                      )}
                      {(roles.filter(item => (item.id === 1 || item.id === 2)).length > 0) && (
                        <Link
                          to="/usuario"
                          style={{ textDecoration: "none" }}
                        >
                          <MenuItem
                            className={classes.dropdownItem}
                          >
                            Usuários
                      </MenuItem>
                        </Link>
                      )}
                      <MenuItem
                        onClick={() => this.logout()}
                        className={classes.dropdownItem}
                      >
                        Sair
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>

        <Dialog
          open={this.state.modal}
          onClose={this.handleDi}
          aria-labelledby="form-dialog-title"
          fullWidth
          style={{ zIndex: "6" }}
        >
          <DialogTitle id="form-dialog-title">Bloqueio de Sistema</DialogTitle>
          <DialogContent>
            <Grid container spacing={16} justify="center">
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <Fade bottom when={!this.state.after}>
                  <Card>
                    <CardBody>
                      <Typography gutterBottom variant="h5" component="h2">
                        Deseja bloquear agora?
                      </Typography>
                      <Button size="small" color="success" justify="center" onClick={this.blockNow}>
                        Escolher
                  </Button>
                    </CardBody>
                  </Card>
                </Fade>
              </Grid>

              <Grid item xs={12} sm={12} md={5} lg={5}>
                <Card>
                  <CardBody>
                    <Typography gutterBottom variant="h5" component="h2">
                      Deseja desbloquear?
                    </Typography>
                    <Button size="small" color="success" onClick={this.blockAfter}>
                      Escolher
                    </Button>
                  </CardBody>
                </Card>
              </Grid>
            </Grid>
            <Fade bottom when={this.state.after}>
              <Grid container spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="outlined-name"
                    label="Manter desbloqueada até"
                    name="data_inicial"
                    helperText={this.state.errors.data_inicial}
                    fullWidth
                    type="date"
                    error={this.state.errors.data_inicial}
                    value={moment(this.state.data_inicial).format("YYYY-MM-DD")}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={8} justify="flex-start">
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Button onClick={this.handleSave} color="info">
                    Confirmar
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Button onClick={this.blockAfter} color="danger">
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Fade>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDi} color="danger">
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    roles: state.user.roles
  }
}
HeaderLinks = connect(
  mapStateToProps
)(HeaderLinks)
HeaderLinks = withRouter(HeaderLinks);
export default withStyles(headerLinksStyle)(HeaderLinks);
