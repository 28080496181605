import React, { Component } from "react"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import LoadingContent from "../../../components/LoadingContent"
import Button from "../../../components/CustomButtons/Button"
import Table from "../../../components/Table/Table"
import logradouroStyle from "../../../assets/css/layout/logradouroStyle"
import Grid from "@material-ui/core/Grid"
import Lens from "@material-ui/icons/Lens"
import AddIcon from "@material-ui/icons/Add"
import Print from "@material-ui/icons/Print"
import numeral from "../../../util/numeral"
import moment from "moment"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import { withStyles, Paper } from "@material-ui/core"
import { get, print, destroy } from '../../../util/parcelasUnicasDeIPTU'
import { showNotification, hideWaiting, showWaiting } from "../../../util/Constants"
import ChromeReaderMode from "@material-ui/icons/ChromeReaderMode"
import Close from "@material-ui/icons/Close"
import { withRouter } from 'react-router-dom'
import TablePagination from "@material-ui/core/TablePagination";
import {searchBaixa as search} from "../../../util/baixaIptu";
class ParcelasUnicas extends Component {
    constructor(props) {
        super(props)
        this.state = {
            parcelas: [],
            parcelasUnificadas: [],
            modal: false
        }
        this.handleClose = this.handleClose.bind(this)
        this.filter = this.filter.bind(this)
        this.getCarne = this.getCarne.bind(this)
        this.getData = this.getData.bind(this)
        this.areUSure = this.areUSure.bind(this)
        this.closeRemove = this.closeRemove.bind(this)
        this.showParcelas = this.showParcelas.bind(this)
        this.deleteDam = this.deleteDam.bind(this)
    }

    componentDidMount() {
        this.getData()
    }

    showParcelas(key) {
        this.setState({
            modal: true,
            parcelasUnificadas: this.state.parcelas[key].parcelas
        })
    }
    handleChangePage = (event, page) => {
        var pagina;
        pagina = page + 1;
        this.setState({page: page})
        if (page < 0) {
            return;
        }
        this.filter({target: {value: this.state.searchText}}, pagina, page)
        this.getData(pagina)
    };
    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    handleClose = () => {
        this.setState({ modal: false })
    }

    filter(event, pagina) {
        let searchText = event.target.value
        let newState = {
            isLoading: true,
            searchText: searchText,
            searchActive: searchText !== ""
        }
        if (pagina == null) {
            newState["page"] = 0
        }
        this.setState(newState
            ,
            () => {
                if (this.state.searchActive) {

                    let filtersCheckbox = this.state.filterField || ""
                    search(searchText, filtersCheckbox, undefined, (error, data) => {
                        this.setState({isLoading: false})
                        if (error) {
                            showNotification(
                                "top",
                                "center",
                                "Ocorreram erros, verifique sua conexão com a internet",
                                "danger"
                            )
                        } else {
                            if (this.state.searchText) {
                                this.setState({
                                    lancamentos: data.data,
                                    pageCount: data.total,
                                    perPage: data.per_page
                                })
                            }
                        }
                    }, pagina)

                } else {
                    this.getData()
                }
            }
        )
    }
    getCarne(index) {
        showWaiting()
        print(
            this.state.parcelas[index].id,
            (error, data) => {
                hideWaiting()
                if (error) {
                    const reader = new FileReader()
                    reader.addEventListener("loadend", e => {
                        const text = e.srcElement.result
                        try {
                            let error = JSON.parse(text)
                            let status = error.status
                            showNotification("top", "center", error.message, "warning")
                        } catch (e) {
                            showNotification(
                                "top",
                                "center",
                                "Ocorreram erros, verifique sua conexão com a internet",
                                "danger"
                            )
                        }
                    })

                    reader.readAsText(error.response.data)
                } else {
                    var url = URL.createObjectURL(data)
                    let tempLink = document.createElement("a")
                    tempLink.setAttribute("target", "_blank")
                    tempLink.setAttribute("href", `${url}`)
                    tempLink.setAttribute("style", "display:none;")
                    document.body.appendChild(tempLink)
                    tempLink.click()
                    document.body.removeChild(tempLink)
                }
            }
        )
    }

    closeRemove() {
        this.setState({ modalRemove: false })
    }
    deleteDam() {
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                if (error.response.data.message) {

                    showNotification(
                        "top",
                        "center",
                        error.response.data.message,
                        "danger"
                    )
                } else {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros! Tente novamente",
                        "danger"
                    )
                }
                console.log(error);
            } else {
                showNotification(
                    "top",
                    "center",
                    "DAM removido com sucesso!",
                    "success"
                )
                this.closeRemove()
                this.getData()
            }
        }
        destroy(this.state.id, response)
    }
    getData(page) {
        if (!page) {
            this.toggleLoading()
        }
        get(page, (error, data) => {
            if (!page) {
                this.toggleLoading()
            }
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
            } else {
                console.log(data);
                this.setState({
                    parcelas: data.data,
                    pageCount: data.total,
                    perPage: data.per_page
                })
            }
        })
    }
    areUSure(key) {
        this.setState({
            modalRemove: true,
            id: this.state.parcelas[key].id
        })
    }
    render() {
        const { classes } = this.props
        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>Parcelas unificadas</h2>
                            </Grid>
                            <Grid item lg={2} xs={12}>
                                <Button block color="success" round onClick={() => { this.props.history.push('/parcelaUnica/create') }}>
                                    <AddIcon /> Unificar Parcelas
                                    </Button>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        {/* <Search
                            label="Pesquise aqui"
                            value={this.state.filterField}
                            onChangeText={this.filter}
                            onChangeFields={this.handleChangeFilters}
                            fields={fields}
                        /> */}
                        <LoadingContent isLoading={this.state.isLoading}>
                            <Grid
                                className={classes.legend}
                                container
                                direction="row"
                                style={{ marginBottom: "2%" }}
                            >
                                <Lens className={classes.dot + " " + classes.dotSuccess} />
                                Pago
                             <Lens className={classes.dot + " " + classes.dotDanger} />
                                Vencido
                            </Grid>
                            <Paper className={classes.tableWrapper}>
                                <Table
                                    getRowColor={(key, classes) => {
                                        if (this.state.parcelas[key].pago) {
                                            return classes.successTableRow
                                        } else if (this.state.parcelas[key].vencido) {
                                            return classes.warningTableRow
                                        } else {
                                            return ""
                                        }
                                    }
                                    }
                                    actions={[{
                                        title: "Imprimir",
                                        icon: (
                                            <Print
                                                className={
                                                    classes.tableActionButtonIcon +
                                                    " " +
                                                    classes.edit
                                                }
                                            />
                                        ),
                                        onClick: this.getCarne
                                    }, {
                                        title: "Visualizar Parcelas unificadas",
                                        icon: (
                                            <ChromeReaderMode
                                                className={
                                                    classes.tableActionButtonIcon +
                                                    " " +
                                                    classes.edit
                                                }
                                            />
                                        ),
                                        onClick: this.showParcelas
                                    }, {
                                        title: "Deletar",
                                        icon: (
                                            <Close
                                                className={
                                                    classes.tableActionButtonIcon +
                                                    " " +
                                                    classes.edit
                                                }
                                            />
                                        ),
                                        onClick: this.areUSure
                                    },
                                    ]}
                                    tableHeaderColor="primary"
                                    fullWidth
                                    tableHead={[
                                        "#",
                                        "Ano",
                                        "Inscrição",
                                        "Inscrição cartográfica",
                                        "Vencimento",
                                        "Valor",
                                        "Multa",
                                        "Juros",
                                        "Data do pagamento",
                                        "Data de Depósito"
                                    ]}
                                    tableData={this.state.parcelas.map((item, key) => {
                                        return [
                                            item.id + "",
                                            item.lancamento.ano + "",
                                            item.lancamento.imovel_id + "",
                                            item.lancamento.imovel.inscricao_cartografica + "",
                                            moment(item.vencimento).format("DD/MM/YYYY"),
                                            "R$" +
                                            numeral(parseFloat(item.valor))
                                                .format("0.0,00")
                                                .slice(0, -2),
                                            "R$" +
                                            numeral(parseFloat(item.multa))
                                                .format("0.0,00")
                                                .slice(0, -2),
                                            "R$" +
                                            numeral(parseFloat(item.juros))
                                                .format("0.0,00")
                                                .slice(0, -2),
                                            item.baixa
                                                ? moment(item.baixa.data_pagamento).format("DD/MM/YYYY")
                                                : "",
                                            item.baixa
                                                ? moment(item.baixa.data_deposito).format("DD/MM/YYYY")
                                                : "",
                                            key
                                        ]
                                    })}
                                />
                                {(
                                    <TablePagination
                                        component="div"
                                        rowsPerPageOptions={[]}
                                        count={this.state.pageCount}
                                        rowsPerPage={this.state.perPage ? this.state.perPage : 0}
                                        page={this.state.page ? this.state.page : 0}
                                        backIconButtonProps={{
                                            "aria-label": "Previous Page"
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "Next Page"
                                        }}
                                        SelectProps={{
                                            native: true
                                        }}

                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                )}
                            </Paper>
                        </LoadingContent>
                    </CardBody>
                </Card>
                <Dialog
                    open={this.state.modal}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth={'md'}
                    style={{ zIndex: "3" }}
                >
                    <DialogTitle id="form-dialog-title">Parcelas</DialogTitle>
                    <DialogContent>
                        <Grid
                            className={classes.legend}
                            container
                            direction="row"
                            style={{ marginBottom: "2%" }}
                        >
                            <Lens className={classes.dot + " " + classes.dotSuccess} />
                            Parcela Paga
                        </Grid>
                        <form onSubmit={this.handleSaveParcela}>
                            <Paper className={classes.tableWrapper}>
                                <Table
                                    getRowColor={(key, classes) => {
                                       let x = this.state.parcelasUnificadas.filter(item => item.pago === true)
                                        console.log(x)
                                        if (x.length > 0) {
                                            return classes.successTableRow
                                        } else {
                                            return ""
                                        }
                                    }
                                    }
                                    tableHeaderColor="primary"
                                    fullWidth
                                    tableHead={[
                                        "#",
                                        "Descrição",
                                        "Vencimento",
                                        "Valor",
                                        "Multa",
                                        "Juros",
                                    ]}
                                    tableData={this.state.parcelasUnificadas.map((item, key) => {
                                        console.log(this.state.parcelasUnificadas)
                                        return [
                                            item.id + "",
                                            item.tipo_parcela.descricao,
                                            moment(item.vencimento).format("DD/MM/YYYY"),
                                            "R$" +
                                            numeral(parseFloat(item.valor))
                                                .format("0.0,00")
                                                .slice(0, -2),
                                            "R$" +
                                            numeral(parseFloat(item.multa))
                                                .format("0.0,00")
                                                .slice(0, -2),
                                            "R$" +
                                            numeral(parseFloat(item.juros))
                                                .format("0.0,00")
                                                .slice(0, -2),
                                            key
                                        ]
                                    })}
                                />
                            </Paper>
                        </form>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.modalRemove}
                    onClose={this.closeRemove}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: "4" }}
                >
                    <DialogTitle id="alert-dialog-title">Você tem certeza que deseja remover essa Unificação?</DialogTitle>

                    <DialogActions>
                        <Button onClick={this.closeRemove} color="danger">
                            Cancelar
              </Button>
                        <Button onClick={this.deleteDam} color="success">
                            Confirmar
              </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

ParcelasUnicas = withRouter(ParcelasUnicas)
export default withStyles(logradouroStyle)(ParcelasUnicas)