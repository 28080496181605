import React from "react";
import Tab from "@material-ui/core/Tab/";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import List from "@material-ui/icons/List"
import menudashboardStyle from "../assets/css/layout/menudashboardStyle";
import { withStyles } from "@material-ui/core";

class MenuDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    const names = props.names;
    const { classes } = props;
    this.listItems = names.map((names, key) => (
      <Tab
        key={key}
        icon={names.icon || <List />}
        label={names.name}
        className={classes.cardTitleWhite}
      />
    ));
  }
  state = {
    value: 0
  };
  handleChange = (event, value) => {
    this.setState({ value }, () => {
      this.props.onChange(this.state.value);
    });
  };
  render() {
    const {classes} = this.props
    return (
        <AppBar position="static" color="primary">
          <Tabs
            value={this.props.value}
            onChange={this.handleChange}
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.displayNone,
              scrollButtons: classes.displayNone
            }}
          >
            {this.listItems}
          </Tabs>
        </AppBar>
    );
  }
}

export default withStyles(menudashboardStyle)(MenuDashboard);
