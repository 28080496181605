import axios from "axios"
import { defaultHeaders } from "./Constants"

export function get(page, callBack) {
    let config = {
      headers: defaultHeaders()
    }
    let url =  process.env.REACT_APP_API + `distritos${page ? `?page=${page}` : ""}`
    axios
      .get(url, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
}

export function post(params, callBack) {
    let config = {
      headers: defaultHeaders()
    }
    let url =  process.env.REACT_APP_API + "distritos"
    axios
      .post(url, params, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
  }

  export function put(id, params, callBack) {
    let config = {
      headers: defaultHeaders()
    }
    let url =  process.env.REACT_APP_API + "distritos/" + id
    axios
      .put(url, params, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
  }

  export function search(searchText, onStart, callBack) {
    let config = {
      headers: defaultHeaders(),
      data: {a:"a"}
    }
    let url =  process.env.REACT_APP_API + `search/distritos?text=${searchText}`
    if(onStart) {
      onStart()
    }
    return axios
      .get(url, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
  }