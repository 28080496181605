import React from "react"
import { withRouter } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import AddIcon from "@material-ui/icons/Add"
import Delete from "@material-ui/icons/Delete"
import AsyncSelect from "../../components/AsyncSelect"
import { showNotification, mapErrors } from "../../util/Constants"
import numeral from "../../util/numeral"
import { MenuItem, withStyles } from "@material-ui/core"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import secaoStyle from "../../assets/css/layout/secaoStyle"
import Button from "../../components/CustomButtons/Button"
import { showWaiting, hideWaiting } from "../../util/Constants"
import { post, getField, searchBaixa, destroy, searchImovel } from "../../util/baixaIptu"
import moment from "moment"
import CardFooter from "../../components/Card/CardFooter"

import Fade from "react-reveal/Fade"
import DialogCustom from "../DialogCustom"
import NumberFormatCustom from "../../components/NumberFormatCustom";

class BaixaIptuForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            imoveis: [],
            id_imovel: 0,
            errors: {},
            lancamentos: [],
            id_lancamentos: 1,
            parcelas: [],
            id_parcelas: 1,
            data_pagamento: "",
            data_deposito: "",
            agencia: "",
            conta: "",
            agentes: [],
            agente_recebedor_id: 0,
            arquivo_de_baixa: "",
            messages: [],

            modal: false,
            openConfirm: false
        }
        this.loadImovel = this.loadImovel.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.getImovelById = this.getImovelById.bind(this)
        this.getParcelaLancById = this.getParcelaLancById.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.getFields = this.getFields.bind(this)
        this.getAgenciaConta = this.getAgenciaConta.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.loadBaixa = this.loadBaixa.bind(this)
        this.handleOpenConfirm = this.handleOpenConfirm.bind(this)
        this.handleCloseConfirm = this.handleCloseConfirm.bind(this)

    }

    handleOpenConfirm() {
        this.setState({
            openConfirm: true
        })
    }

    handleCloseConfirm() {
        this.setState({
            openConfirm: false
        })
    }

    handleOpen() {
        this.setState({
            modal: true
        })
    }

    handleDelete() {
        showWaiting()
        let response = error => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                );
                // this.setState({
                //     errors: mapErrors(error)
                // });
            } else {
                showNotification(
                    "top",
                    "center",
                    `Baixa deletada com sucesso`,
                    "success"
                );
                this.handleClose()
                this.handleCloseConfirm()
            }
        };
        destroy(this.state.codigo.value, response)
    }

    handleClose() {
        this.setState({
            modal: false
        })
    }

    componentWillMount() {
        this.getFields()
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        let value = event.target.value
        errors[name] = undefined

        console.log("name: ", name)
        console.log("va: ", event.target.value)

        this.setState({
            [name]: value,
            errors: errors
        },
            () => {
                if (this.state.id_imovel && name === "id_imovel") {
                    let imovel = this.getImovelById(this.state.id_imovel.value)
                    this.setState({
                        lancamentos: imovel.lancamentos
                    })
                }
                if (this.state.id_lancamentos && name === "id_lancamentos") {
                    let parcelaLancamento = this.getParcelaLancById(
                        this.state.id_lancamentos
                    )
                    this.setState({
                        parcelas: parcelaLancamento.parcelas
                    })
                }
            }
        )
    }

    handleSave(event) {
        event.preventDefault()
        showWaiting()
        const params = {
            parcela_id: this.state.id_parcelas,
            agente_recebedor_id: this.state.agente_recebedor_id,
            data_pagamento: this.state.data_pagamento,
            data_deposito: this.state.data_deposito,
            valor_pago: this.state.valor_pago
        }

        let response = (error, data) => {
            if (error) {
                hideWaiting()
                if (error.response.status == 409) {
                    showNotification(
                        "top",
                        "center",
                        error.response.data,
                        "danger"
                    )
                } else if (error.response.status == 422) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique o formulário e tente novamente",
                        "warning"
                    )
                    this.setState({
                        errors: mapErrors(error)
                    })
                } else {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreu um erro inesperado, entre em contato com o suporte",
                        "danger"
                    )
                }
            } else if (!data["message"]) {
                var array = Object.keys(data).map(item => {
                    return data[item][0][0]
                })
                this.setState(
                    {
                        messages: array
                    },
                    () => {
                        hideWaiting()
                    }
                )
            } else {
                hideWaiting()
                showNotification(
                    "top",
                    "center",
                    "Baixa de IPTU realizada com sucesso",
                    "success"
                )
                this.props.history.push("/iptu/cadastro")
            }
        }
        post(params, response)
    }

    getFields() {
        let response = (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                this.setState({
                    agentes: data.agentes
                })

                this.setState(data)
            }
        }
        getField(response)
    }

    loadImovel(inputValue, callback) {
        searchImovel(inputValue, "", undefined, (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
                callback([])
            } else {
                let imoveis = data.map(imovel => {
                    return {
                        value: imovel.id,
                        label: `${imovel.id} - ${imovel.logradouro_correspondencia ||
                            ""} - ${imovel.bairro_correspondencia ||
                            ""} - ${imovel.cidade_correspondencia ||
                            ""} - ${imovel.cep_correspondencia || ""}`
                    }
                })
                this.setState({
                    imoveis: data
                })
                callback(imoveis)
            }
        })
    }

    getImovelById(id) {
        return this.state.imoveis.filter(item => {
            return id === item.id
        })[0]
    }

    getParcelaLancById(id) {
        return this.state.lancamentos.filter(item => {
            return id === item.id
        })[0]
    }

    getAgenciaConta(id) {
        return this.state.agentes.filter(item => {
            return id === item.id
        })[0]
    }

    loadBaixa(inputValue, callback) {
        searchBaixa(inputValue, this.state.filterField, undefined, (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
                callback([])
            } else {
                let baixa = data.data.map(item => {
                    return {
                        value: item.id,
                        label: `Código Baixa: ${item.parcela.baixa.id} - 
            Agência: ${item.agencia} Conta: ${item.conta} - 
            Dt.Pagamento: ${moment(item.parcela.baixa.data_pagamento).format("DD/MM/YYYY")} - 
            Dt.Deposito: ${moment(item.parcela.baixa.data_deposito).format("DD/MM/YYYY")}`
                    }
                })
                callback(baixa)
            }
        })
    }


    render() {
        const { classes } = this.props
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "imovel_id", label: "Código Imóvel" },
            { value: "ano", label: "Ano" },
            { value: "numero_parcela", label: "Nº Parcela" },
            { value: "data_pagamento", label: "Data Pagamento" },
            { value: "data_deposito", label: "Data Depósito" }
        ]
        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container direction="row">
                            <Grid item xs={12} sm={12} md={10} lg={10}>
                                <h2 className={classes.cardTitleWhite}>Baixa de IPTU</h2>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <AsyncSelect
                                        className={
                                            `form-control form-control-alternative form-select Select-menu-outer ${
                                            this.props.error ? "has-danger" : ""
                                            }`
                                        }
                                        value={this.state.id_imovel}
                                        name="id_imovel"
                                        onChange={this.handleChange}
                                        loadOptions={this.loadImovel}
                                        placeholder="Imóvel"
                                        message="Pesquise o Imóvel"
                                    />
                                </Grid>
                            </Grid>
                            <Fade bottom>
                                <Grid container direction="row" spacing={16}>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            required
                                            name="id_lancamentos"
                                            label="Lançamento"
                                            fullWidth
                                            value={this.state.id_lancamentos}
                                            error={this.state.errors.id_lancamentos}
                                            helperText={this.state.errors.id_lancamentos}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                        >
                                            {this.state.lancamentos.map((option, key) => (
                                                <MenuItem key={key} value={option.id}>
                                                    Ano: {option.ano} Valor IPTU: R$ {numeral(parseFloat(option.valor_iptu))
                                                        .format("0.0,00")
                                                        .slice(0, -2)}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    {this.state.parcelas.length > 0 && (
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <TextField
                                                id="outlined-select-currency"
                                                select
                                                required
                                                name="id_parcelas"
                                                label="Parcelas"
                                                fullWidth
                                                value={this.state.id_parcelas}
                                                error={this.state.errors.id_parcelas}
                                                helperText={this.state.errors.id_parcelas}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                variant="outlined"
                                            >
                                                {this.state.parcelas.map((option, key) => (
                                                    <MenuItem key={key} value={option.id}>
                                                        Parcela {option.tipo_parcela.ordem} -
                                                        Valor R$ {numeral(parseFloat(option.valor)).format("0.0,00").slice(0, -2)} -
                                                        Multa R$ {numeral(parseFloat(option.multa)).format("0.0,00").slice(0, -2)} -
                                                        Juros R$ {numeral(parseFloat(option.juros)).format("0.0,00").slice(0, -2)} -
                                                        Vencimento Original {moment(option.vencimento_original).format("DD/MM/YYYY")}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    )}
                                </Grid>

                                <Grid container direction="row" spacing={16}>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="date"
                                            label="Data do Pagamento"
                                            margin="normal"
                                            variant="outlined"
                                            type="date"
                                            value={this.state.data_pagamento}
                                            fullWidth
                                            helperText={this.state.errors.data_pagamento}
                                            error={this.state.errors.data_pagamento}
                                            defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                            name="data_pagamento"
                                            onChange={this.handleChange}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="date"
                                            label="Data do Depósito"
                                            margin="normal"
                                            variant="outlined"
                                            type="date"
                                            fullWidth
                                            value={this.state.data_deposito}
                                            helperText={this.state.errors.data_deposito}
                                            error={this.state.errors.data_deposito}
                                            defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                            name="data_deposito"
                                            onChange={this.handleChange}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={16}>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="outlined-select-currency"
                                            label="Valor Pago"
                                            fullWidth
                                            name="valor_pago"
                                            value={this.state.valor_pago}
                                            error={this.state.errors.valor_pago}
                                            helperText={this.state.errors.valor_pago}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                            defaultValue=""
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            required
                                            name="agente_recebedor_id"
                                            label="Agente Recebedor"
                                            fullWidth
                                            value={this.state.agente_recebedor_id}
                                            error={this.state.errors.agente_recebedor_id}
                                            helperText={this.state.errors.agente_recebedor_id}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                        >
                                            {this.state.agentes.map((option, key) => (
                                                <MenuItem key={key} value={option.id}>
                                                    {option.nome}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Fade>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Grid container justify="center">
                            <Grid item lg={2}>
                                <Button block color="info" round onClick={this.handleSave}>
                                    <AddIcon /> Finalizar
                                </Button>
                            </Grid>
                        </Grid>
                    </CardFooter>
                </Card>

                <DialogCustom
                    open={this.state.modal}
                    nameSearch="filterField"
                    valueSearch={this.state.filterField || ""}
                    onChangeFields={this.handleChange}
                    fields={fields}
                    onChange={this.handleChange}
                    optionsAsync={this.loadBaixa}
                    nameAsync="codigo"
                    message="Pesquise a Baixa de IPTU"
                    placeholder="Baixa IPTU"
                    handleClose={this.handleClose}
                    isDisabled={this.state.codigo ? false : true}
                    handleSave={this.handleDelete}
                    handleCloseConfirm={this.handleCloseConfirm}
                    handleOpenConfirm={this.handleOpenConfirm}
                    openConfirm={this.state.openConfirm}
                />

            </div>
        )
    }
}
BaixaIptuForm = withRouter(BaixaIptuForm)
export default withStyles(secaoStyle)(BaixaIptuForm)
