import {
  drawerWidth,
  transition,
  container,
  primaryColor,
  roseColor,
  dangerColor,
  defaultFont,
  successColor,
  warningColor,
  infoColor,
  purpleColor,
  grayColor,
  secondaryColor
} from "../material-dashboard-react"
import tooltipStyle from "../tooltipStyle"
const appStyle = theme => ({
  ...tooltipStyle,
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  selectcustom: {
    zIndex: "901",
    marginTop: "2%"
  },
  teste: {
    zIndex: "903",
  },
  painel: {
    height: "100%",
    overflow: "auto"
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    alignItems: "center",
    marginTop: "70px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)"
  },
  container,
  map: {
    marginTop: "70px"
  },
  dot: {
    margin: "0 10px"
  },
  dotSuccess: {
    color: successColor
  },
  dotDanger: {
    color: dangerColor
  },
  dotPurple: {
    color: purpleColor
  },
  dotPrimary: {
    color: primaryColor
  },
  dotGray: {
    color: grayColor
  },
  dotRose: {
    color: roseColor
  },
  dotWarning: {
    color: "#FFAB2E"
  },
  dotInfo: {
    color: infoColor
  },
  dotSecondary: {
    color: secondaryColor
  },
  textCent: {
    justifyContent: "center"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "10px",
    minHeight: "auto",
    fontWeight: "500",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  tableWrapper: {
    overflowX: "auto"
  },
  edit: {
    backgroundColor: "transparent",
    color: primaryColor,
    boxShadow: "none"
  },
  imprimir: {
    backgroundColor: "transparent",
    color: "#BCB6FF",
    boxShadow: "none"
  },
  print: {
    backgroundColor: "transparent",
    color: successColor,
    boxShadow: "none"
  },
  cancel: {
    backgroundColor: "transparent",
    color: "#161925",
    boxShadow: "none"
  },
  book: {
    backgroundColor: "transparent",
    color: warningColor,
    boxShadow: "none"
  },
  ficha: {
    backgroundColor: "transparent",
    color: infoColor,
    boxShadow: "none"
  },
  description: {
    backgroundColor: "transparent",
    color: roseColor,
    boxShadow: "none"
  },
  close: {
    backgroundColor: "transparent",
    color: dangerColor,
    boxShadow: "none"
  },
  tableActions: {
    display: "flex",
    border: "none",
    padding: "12px 8px !important",
    verticalAlign: "middle"
  },
  tableCell: {
    ...defaultFont,
    padding: "8px",
    border: "none",
    verticalAlign: "middle",
    lineHeight: "1.42857143",
    fontSize: "14px"
  },
  tableActionButton: {
    width: "27px",
    height: "27px",
    padding: "0"
  },
  tableActionButtonIcon: {
    width: "17px",
    height: "17px"
  },
  tableRow: {
    position: "relative",
    borderBottom: "1px solid #dddddd"
  },
  tableHeadCell: {
    color: "inherit",
    ...defaultFont,
    fontSize: "1em"
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 60,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: "25%",
    left: "25%",
    margin: "auto",
    transform: "translate(-25%, -25%)",
    padding: theme.spacing.unit * 4
  },
  legend: {
    padding: "20px 0"
  }
})

export default appStyle
