import React from "react"

import withStyles from "@material-ui/core/styles/withStyles"
import loginStyle from "../assets/css/layout/loginStyle"
import invalid from "../assets/img/invalid.png"
import verify from "../assets/img/verify.png"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Card from "../components/Card/Card"
import CardHeader from "../components/Card/CardHeader"
import CardBody from "../components/Card/CardBody"
import { getAlvara } from "../util/validacaotoken"
import { showNotification } from "../util/Constants"
import moment from "moment"

class ValidacaoAlvara extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            path: "",
            message: "",
            img: "",
            pdf:"",
            dam: [],
            alvara: [],
        }
        this.getInfo = this.getInfo.bind(this)
    }

    componentWillMount() {
        this.setState({
            path: this.props.location.pathname
        }, () => {
            let text = this.state.path
            let parts = text.split("/")
            let loc = parts.pop()
            this.getInfo(loc)
        })
    }

    getInfo(token) {
        let response = (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
            } else {
                let info = data.valido
                let mes = ""
                let img = ""
                if (info === false) {
                    mes = "Certidão Inválida"
                    img = invalid
                } else {
                    mes = "Certidão Válida"
                    img = verify
                }
                this.setState({
                    img: img,
                    dam: data.dam,
                    alvara: data.dam.alvara,
                    pdf: data.pdf
                })
                console.log(this.state.pdf)
        }
        }
        getAlvara(token, response)
    }



    render() {
        const { classes } = this.props
        return (
            <div className={classes.content}>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardHeader className={classes.textCenter} color="primary">
                                <h2 className={classes.cardTitleWhite}>
                                    Sistema de Arrecadação Municipal
                                </h2>
                            </CardHeader>
                            <CardBody>
                                    <div>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <h4><span> Código do Alvará: {this.state.alvara.id}</span>
                                                 <span> Competência: {this.state.dam.competencia}</span>
                                                <span> Validade: {moment(new Date(this.state.dam.validade)).format("DD/MM/YYYY")}</span></h4>
                                            </Grid>
                                            <Grid container direction="row" justify="center" alignItems="center">
                                                <img alt="" src={this.state.img} style={{ width: "100px", height: "120px", marginBottom: "2%" }} />
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" spacing={16}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Typography
                                                    className={classes.dividerFullWidth}
                                                    color="textPrimary"
                                                    variant="h4"
                                                    align="center"
                                                >
                                                    Alvará Válido
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" justify="center" alignItems="center">
                                            <object  data={'data:application/pdf;base64,' + this.state.pdf} type="application/pdf" width="100%" height="1000px" />                                        </Grid>
                                    </div>
                                <Grid container direction="row" spacing={16}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography
                                            className={classes.dividerFullWidth}
                                            color="textPrimary"
                                            variant="caption"
                                            align="center"
                                        >
                                            Em caso de dúvida, entre em contato com a Prefeitura de {process.env.REACT_APP_PREFEITURA}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardBody>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default withStyles(loginStyle)(ValidacaoAlvara)
