import React from "react"

import { withStyles } from "@material-ui/core"
import contribuinteStyle from "../../../assets/css/layout/contribuinteStyle"
import MenuDashboard from "../../MenuDashboard"

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import theme from "../../../assets/css/theme"
import { connect } from "react-redux"
import { relatoriosITBITabChanged } from "../../../actions"
import CallMade from "@material-ui/icons/CallMade"
import AttachMoney from "@material-ui/icons/AttachMoney"
import { bindActionCreators } from "redux"
import RelatorioDeEmissaoDeITBIForm from "./RelatorioDeEmissaoDeITBIForm"
import RelatorioArrecadacao from "./RelatorioArrecadacao"

const tab = [
  {
    name: "Emissão de ITBI",
    icon: <CallMade />
  }, {
    name: "Relatório de Arrecadação",
    icon: <AttachMoney />
  }
]

class ITBIRelatorios extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      pageCount: 0,
      perPage: 0,
      page: 1,
      contribuintes: [],
      field: 0
    }
    this.handleFieldChange = this.handleFieldChange.bind(this)
  }

  handlePageClick(data) { }

  handleChange = event => {
    this.setState({ value: event.target.value })
  }

  handleChangePage = (event, page) => {
    if ((page || 1) !== this.state.page) {
      this.setState({ page }, () => { this.getData(this.state.page) })
    }
  }

  handleFieldChange(value) {
    this.props.relatoriosITBITabChanged(value)
  }
  render() {
    const { classes, field } = this.props
    return (
      <div className={classes.container}>
        <MuiThemeProvider theme={theme}>
          <MenuDashboard
            names={tab}
            onChange={this.handleFieldChange}
            value={field}
          />
          {field === 0 && <RelatorioDeEmissaoDeITBIForm />}
          {field === 1 && <RelatorioArrecadacao />}
        </MuiThemeProvider>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    field: state.tabLayout.relatoriosITBITabSelected
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ relatoriosITBITabChanged }, dispatch)
}
ITBIRelatorios = connect(
  mapStateToProps,
  mapDispatchToProps
)(ITBIRelatorios)
export default withStyles(contribuinteStyle)(ITBIRelatorios)
