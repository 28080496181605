import axios from "axios"
import { defaultHeaders } from "./Constants"

export function gerarRemessa(exercicio, email, parcela, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url =
    process.env.REACT_APP_API +
    `arquivoDeRemessa?exercicio=${exercicio || ""}${email ? `&email=${email}` : ""}${parcela ? `&quantidade=${parcela}` : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
