import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Button from "../../../components/CustomButtons/Button"
import secaoStyle from "../../../assets/css/layout/secaoStyle"
import { withStyles } from "@material-ui/core"
import LoadingContent from "../../../components/LoadingContent"
import { search as searchContribuinte } from "../../../util/contribuinte"
import { showNotification, showWaiting, hideWaiting, removeMask } from "../../../util/Constants"
import { lancamentoDeDivida, getField } from "../../../util/dividas"
import AsyncSelect from "../../../components/AsyncSelect"
import SearchFields from "../../../components/SearchFields"
import TextField from "@material-ui/core/TextField"
import Warning from "@material-ui/icons/Warning"
import InputMask from "react-input-mask"
import SelectCustom from "../../../components/SelectCustom"

import housecall from "housecall"
class Lancamento extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: {},
            contribuintes: [],
            haveDebts: false,
            receitas: [],
            situacoes_divida: []

        }
        this.toggleLoading = this.toggleLoading.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.loadOptions = this.loadOptions.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.queue = housecall({ concurrency: 1, cooldown: 0 })
    }

    handleSave(event) {
        event.preventDefault()
        showWaiting()
        lancamentoDeDivida(
            this.state.contribuinte_id ? this.state.contribuinte_id.value : "",
            this.state.receita_id ? this.state.receita_id.value : "",
            this.state.competencia ? removeMask(this.state.competencia) : "",
            this.state.situacao_id ? this.state.situacao_id.value : "",
            (error, data) => {
                hideWaiting()
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros! Verifique o formulário e tente novamente!",
                        "danger"
                    )
                } else {
                    var url = URL.createObjectURL(data)
                    let tempLink = document.createElement("a")
                    tempLink.setAttribute("target", "_blank")
                    tempLink.setAttribute("href", `${url}`)
                    tempLink.setAttribute("style", "display:none;")
                    document.body.appendChild(tempLink)
                    tempLink.click()
                    document.body.removeChild(tempLink)
                }
            }
        )
    }

    componentWillMount() {
        this.receitas()
        this.situacoes()
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        this.setState(
            {
                [name]: event.target.value,
                errors: errors
            }
            , () => {
                if (this.state.contribuinte_id && name === "contribuinte_id") {
                    if (this.state.contribuintes.length > 0) {
                        let x = this.temDivida(this.state.contribuinte_id.value)
                        console.log(x)
                        if (x.dividas.length <= 0) {
                            this.setState({
                                haveDebts: true
                            })
                        } else {
                            this.setState({
                                haveDebts: false
                            })
                        }
                    }
                }
            }
        )
    }

    temDivida(value) {
        return this.state.contribuintes.filter(item => {
            return item.id === value
        })[0]
    }

    loadOptions(inputValue, callback) {
        searchContribuinte(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                    callback([])
                } else {
                    let contribuintes = data.data.map(contribuinte => {
                        return {
                            value: contribuinte.id,
                            label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                            ""} - ${contribuinte.cpf_cnpj || ""}`
                        }
                    })
                    this.setState({
                        contribuintes: data.data
                    })
                    callback(contribuintes)
                }
            }
        )
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    receitas() {
        getField((error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                let rec = data.receitas.map(receita => {
                    return {
                        value: receita.id,
                        label: `Nº Receita: ${receita.id} - ${receita.nome}`
                    }
                })
                this.setState({
                    receitas: rec
                })
            }
        })
    }

    situacoes() {
        getField((error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                let rec = data.situacoes_divida.map(situacao => {
                    return {
                        value: situacao.id,
                        label: `Nº Situacao: ${situacao.id} - ${situacao.nome}`
                    }
                })
                this.setState({
                    situacoes_divida: rec
                })
            }
        })
    }
    render() {
        const { classes } = this.props
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "Código do contribuinte" },
            { value: "cpf_cnpj", label: "Documento" },
            { value: "endereco", label: "Endereço" },
            { value: "nome_fantasia", label: "Nome Fantasia" },
            { value: "nome_razao_social", label: "Razão Social" }
        ]
        return (
            <div className={classes.container}>
                <LoadingContent isLoading={this.state.isLoading}>
                    <Card>
                        <CardHeader color="primary">
                            <Grid container direction="row">
                                <Grid item lg={10} xs={12}>
                                    <h2 className={classes.cardTitleWhite}>
                                        Lançamento na dívida ativa
                                    </h2>
                                </Grid>
                            </Grid>
                        </CardHeader>
                        <CardBody>
                            <Grid container direction="row" spacing={16}>
                                <Typography color="textSecondary" variant="caption">
                                    Para um lançamento completo, apenas deixe tudo vazio
                                </Typography>
                            </Grid>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <SearchFields
                                        name="filterField"
                                        value={this.state.filterField || ""}
                                        onChangeFields={this.handleChange}
                                        fields={fields}
                                    />
                                    <Typography color="textSecondary" variant="caption">
                                        Pesquise o Contribuinte
                                    </Typography>
                                    <AsyncSelect
                                        className={
                                            `form-control form-control-alternative form-select Select-menu-outer ${
                                                this.props.error ? "has-danger" : ""
                                            }`
                                        }
                                        value={this.state.contribuinte_id}
                                        onChange={this.handleChange}
                                        loadOptions={this.loadOptions}
                                        error={this.state.errors.contribuinte_id}
                                        helperText={this.state.errors.contribuinte_id}
                                        name="contribuinte_id"
                                        placeholder="Contribuinte"
                                        message="Pesquise o contribuinte"
                                    />
                                </Grid>
                            </Grid>
                            {this.state.haveDebts && (
                                <Grid className={classes.legend} container direction="row">
                                    <Warning className={classes.dot + " " + classes.dotDanger} />
                                    Este contribuinte não possui dívida ativa
                                </Grid>
                            )}
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                    <SelectCustom
                                        value={this.state.receita_id}
                                        name="receita_id"
                                        isMulti={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={this.handleChange}
                                        error={this.state.errors.receita_id}
                                        helperText={this.state.errors.receita_id}
                                        options={this.state.receitas}
                                        placeholder="Receita"
                                        message="Pesquise a Receita"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <InputMask
                                        mask="9999"
                                        value={this.state.competencia}
                                        onChange={this.handleChange}
                                    >
                                        {() => (
                                            <TextField
                                                id="outlined-name"
                                                label="Competência"
                                                name="competencia"
                                                helperText={this.state.errors.competencia}
                                                fullWidth
                                                error={this.state.errors.competencia}
                                                value={this.state.competencia}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                    <SelectCustom
                                        value={this.state.situacao_id}
                                        name="situacao_id"
                                        isMulti={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={this.handleChange}
                                        error={this.state.errors.situacao_id}
                                        helperText={this.state.errors.situacao_id}
                                        options={this.state.situacoes_divida}
                                        placeholder="Situação"
                                        message="Pesquise a Situação"
                                    />
                                </Grid>
                            </Grid>
                        </CardBody>
                        <CardFooter>
                            <Grid container justify="center" spacing={8}>
                                <Grid item xs={12} sm={12} md={2} lg={2}>
                                    <Button
                                        disabled={this.state.haveDebts}
                                        block
                                        round
                                        color="info"
                                        onClick={this.handleSave}>
                                        Imprimir
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardFooter>
                    </Card>
                </LoadingContent>
            </div>
        )
    }
}

export default withStyles(secaoStyle)(Lancamento)