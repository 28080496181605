import axios from "axios"
import { defaultHeaders } from "./Constants"

export function get(callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "usuarios"
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function getField(callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "usuarios/create"
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function post(params, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "usuarios"
    axios
        .post(url, params, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function edit(id, callBack) {
    let config = {
      headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + `usuarios/${id}/edit`
    axios
      .get(url, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
  }

export function put(id, params, callBack) {
    let config = {
      headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "usuarios/" + id
    axios
      .put(url, params, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
  }