import React from "react"

import { relatorioAlvaraVeiculo } from "../../../util/alvara"
import { get } from "../../../util/ultilizacaoDeVeiculo"
import { simpleSearch as searchContribuinte } from "../../../util/contribuinte"
import { showNotification, showWaiting, hideWaiting, mapErrors } from "../../../util/Constants"
import housecall from "housecall"
import Button from "../../../components/CustomButtons/Button"
import { withStyles } from "@material-ui/core"
import contribuinteStyle from "../../../assets/css/layout/contribuinteStyle"
import Grid from "@material-ui/core/Grid"
import SearchFields from "../../../components/SearchFields"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Typography from "@material-ui/core/Typography"
import AsyncSelect from "../../../components/AsyncSelect"
import SelectCustom from "../../../components/SelectCustom"
import LoadingContent from "../../../components/LoadingContent"

class RelatorioVeiculos extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            contribuintes: [],
            contribuinte_id: "",
            errors: {},
            ultilizacoes: [],
            ultilizacao_id: "",
            endereco: ""
        }
        this.handleChange = this.handleChange.bind(this)
        this.loadOptions = this.loadOptions.bind(this)
        this.getUltilizacoes = this.getUltilizacoes.bind(this)
        this.toggleLoading = this.toggleLoading.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.queue = housecall({ concurrency: 1, cooldown: 0 })
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        let value = event.target.value
        errors[name] = undefined

        this.setState(state => ({
            [name]: value,
            errors: { ...state.errors, [name]: undefined }
        }))
    }

    loadOptions(inputValue, callback) {
        searchContribuinte(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                    callback([])
                } else {
                    let contribuintes = data.data.map(contribuinte => {
                        return {
                            value: contribuinte.id,
                            label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                                ""} - ${contribuinte.cpf_cnpj || ""}`
                        }
                    })

                    this.setState({
                        contribuintes: data.data
                    }, () => {

                    })
                    callback(contribuintes)
                }
            }
        )
    }

    componentWillMount() {
        this.getUltilizacoes()
    }

    getUltilizacoes() {
        this.toggleLoading()
        let response = (error, data) => {
            this.toggleLoading()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
                this.setState({
                    errors: mapErrors(error)
                })
            } else {
                this.setState({
                    ultilizacoes: data.map(ultilizacao => {
                        return {
                            value: ultilizacao.id,
                            label: ultilizacao.nome
                        }
                    })
                })
            }
        }
        get(1, response)
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    handleSave(event) {
        event.preventDefault()
        showWaiting()
        let arrayParams = new Array()
        Object.entries({
            contribuinte_id: this.state.contribuinte_id ? this.state.contribuinte_id.value : "",
            ultilizacao_id: this.state.ultilizacao_id ? this.state.ultilizacao_id.value : ""
        }).forEach(([key, value]) => {
           if(value){ arrayParams.push(`${key}=${value}`)}
        })
        const params = `?${arrayParams.join("&")}`

        const response = (error, data) => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
            } else {
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        relatorioAlvaraVeiculo(params, response)
    }

    render() {
        const { classes } = this.props
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "Código do contribuinte" },
            { value: "cpf_cnpj", label: "Documento" },
            { value: "endereco", label: "Endereço" },
            { value: "nome_fantasia", label: "Nome Fantasia" },
            { value: "nome_razao_social", label: "Razão Social" }
        ]
        return (
            <div className={classes.container}>
                <LoadingContent isLoading={this.state.isLoading}>
                    <Card>
                        <CardHeader color="primary">
                            <Grid container direction="row">
                                <Grid item lg={10} xs={12}>
                                    <h2 className={classes.cardTitleWhite}>Relatório Alavará de Veículos</h2>
                                </Grid>
                            </Grid>
                        </CardHeader>
                        <CardBody>
                            <Grid container direction="row" style={{ marginTop: "2%" }}>
                                <Typography
                                    className={classes.dividerFullWidth}
                                    color="textSecondary"
                                    variant="caption"
                                >Filtro por Contribuinte</Typography>
                            </Grid>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <SearchFields
                                        name="filterField"
                                        value={this.state.filterField || ""}
                                        onChangeFields={this.handleChange}
                                        fields={fields}
                                    />
                                    <AsyncSelect
                                        className={
                                            `form-control form-control-alternative form-select ${this.props.error ? "has-danger" : ""
                                            }`
                                        }
                                        value={this.state.contribuinte_id}
                                        onChange={this.handleChange}
                                        noResultsText="Nenhum resultado"
                                        loadOptions={this.loadOptions}
                                        loadingMessage={() => "Carregando..."}
                                        error={this.state.errors.contribuinte_id}
                                        helperText={this.state.errors.contribuinte_id}
                                        name="contribuinte_id"
                                        placeholder="Contribuinte"
                                        message="Pesquise o contribuinte"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" style={{ marginTop: "2%" }}>
                                <Typography
                                    className={classes.dividerFullWidth}
                                    color="textSecondary"
                                    variant="caption"
                                >Filtro por Ultilização</Typography>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <SelectCustom
                                        name="ultilizacao_id"
                                        isMulti={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={this.handleChange}
                                        error={this.state.errors.ultilizacao_id}
                                        helperText={this.state.errors.ultilizacao_id}
                                        options={this.state.ultilizacoes}
                                        placeholder="Ultilizações"
                                        message="Pesquise a Ultilização de Veículo"
                                    />
                                </Grid>
                            </Grid>
                        </CardBody>
                        <CardFooter>
                            <Grid container justify="center">
                                <Grid item lg={2}>
                                    <Button block color="info" round onClick={this.handleSave}>
                                        Gerar
                                </Button>
                                </Grid>
                            </Grid>
                        </CardFooter>
                    </Card>
                </LoadingContent>
            </div>
        )
    }
}

export default withStyles(contribuinteStyle)(RelatorioVeiculos)