import axios from "axios"
import { defaultHeaders } from "./Constants"

export function lock(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "block"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function unlock(date, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `unlock/${date}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}