import React from "react"

import {withRouter} from "react-router-dom"

import logradouroStyle from "../../../assets/css/layout/logradouroStyle"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Button from "../../../components/CustomButtons/Button"
import { withStyles, MenuItem } from "@material-ui/core"
import InputMask from "react-input-mask"
import {showWaiting, hideWaiting, mapErrors, showNotification} from "../../../util/Constants"
import {post, getField} from "../../../util/livro"

import moment from "moment"

const initial = {
    ano: "",
    numero: "", 
    receita_id: "",
    receitas: [],
    data_de_inscricao: new Date(),
    competencia_inicial:  "",
    competencia_final: "",
    errors: {}
}

class LancamentoLivro extends React.Component {
    constructor(props) {
        super(props)
        this.state = initial
        this.handleSave = this.handleSave.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.init = this.init.bind(this)
        this.toggleLoad = this.toggleLoad.bind(this)
    }
    
    componentWillMount(){
      this.init()
    }

    toggleLoad() {
      this.setState(state => ({
        isLoading: !state.isLoading
      }))
    }

    handleSave(event) {
      event.preventDefault()
    showWaiting()
        let params = {
            ano: this.state.ano,
            numero: this.state.numero,
            data_de_inscricao: moment(this.state.data_de_inscricao).format("YYYY-MM-DD"),
            receita_id: this.state.receita_id,
            competencia_inicial: this.state.competencia_inicial,
            competencia_final: this.state.competencia_final
        }
    this.toggleLoad()
    let response = error => {
      this.toggleLoad()
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        console.log(error)
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Lançamento do Livro realizado com sucesso",
          "success"
        )
        this.props.history.push("/divida/processamento")
      }
    }
    post(params, response)
    }

    init() {
      let response = (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
        } else {
          this.setState({
           receitas: data.receitas
          })
        }
      }
      getField(response)
    }

    handleChange(event) {
      let name = event.target.name
      
      let errors = this.state.errors
      errors[name] = undefined
  
      this.setState({
        [name]: event.target.value,
        errors: errors
      })
    }
    
    render() {
        const { classes } = this.props
        return (
            <div className={classes.container}>
            <Card>
            <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>
                  Lançamento do Livro
                </h2>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
          <Grid container direction="row" spacing={16}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
                <InputMask
                  mask="9999"
                  value={this.state.ano}
                  onChange={this.handleChange}
                >
                  {() => (
                    <TextField
                      id="outlined-select-currency"
                      name="ano"
                      label="Ano"
                      fullWidth
                      error={this.state.errors.ano}
                      helperText={this.state.errors.ano}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                      id="outlined-select-currency"
                      name="numero"
                      label="Número"
                      fullWidth
                      type="number"
                      error={this.state.errors.numero}
                      helperText={this.state.errors.numero}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5}>
              <TextField
                        id="outlined-select-currency"
                        select
                        name="receita_id"
                        label="Receita"
                        fullWidth
                        value={this.state.receita_id}
                        error={this.state.errors.receita_id}
                        helperText={this.state.errors.receita_id}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      >
                        {this.state.receitas.map((option, key) => (
                          <MenuItem key={key} value={option.id}>
                            {option.id} - {option.nome}
                          </MenuItem>
                        ))}
                      </TextField>
              </Grid>
          </Grid>
          <Grid container direction="row" spacing={16}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
                      id="outlined-select-currency"
                      name="data_de_inscricao"
                      label="Data de Inscrição"
                      fullWidth
                      type="date"
                      defaultValue={moment(this.state.data_de_inscricao).format("YYYY-MM-DD")}
                      error={this.state.errors.data_de_inscricao}
                      helperText={this.state.errors.data_de_inscricao}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
          <InputMask
                  mask="9999"
                  value={this.state.competencia_inicial}
                  onChange={this.handleChange}
                >
                  {() => (
                    <TextField
                      id="outlined-select-currency"
                      name="competencia_inicial"
                      label="Competência Inicial"
                      fullWidth
                      error={this.state.errors.competencia_inicial}
                      helperText={this.state.errors.competencia_inicial}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                </InputMask>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
          <InputMask
                  mask="9999"
                  value={this.state.competencia_final}
                  onChange={this.handleChange}
                >
                  {() => (
                    <TextField
                      id="outlined-select-currency"
                      name="competencia_final"
                      label="Competência Final"
                      fullWidth
                      error={this.state.errors.competencia_final}
                      helperText={this.state.errors.competencia_final}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                </InputMask>
          </Grid>
          </Grid>
          </CardBody>
          <CardFooter>
              <Grid container justify="center">
                <Grid item lg={2}>
                  <Button block color="info" round onClick={this.handleSave}>
                    Lançar
                  </Button>
                </Grid>
              </Grid>
            </CardFooter>
            </Card>
            </div>
        )
    }
}
LancamentoLivro = withRouter(LancamentoLivro)
export default withStyles(logradouroStyle)(LancamentoLivro)