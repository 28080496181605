import React from "react"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import Grid from "@material-ui/core/Grid"
import AsyncSelect from "../components/AsyncSelect"
import SearchFields from "../components/SearchFields"
import Button from "../components/CustomButtons/Button"
import Typography from "@material-ui/core/Typography"

class DialogCustom extends React.Component {

    state = {
        open: false
    }

    openConfirm = () => {
        this.setState({ open: true })
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    maxWidth="md"
                    fullWidth
                    style={{ zIndex: "901" }}
                >
                    <DialogTitle>Deletar Baixa Manual</DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <SearchFields
                                    name={this.props.nameSearch}
                                    value={this.props.valueSearch}
                                    onChangeFields={this.props.onChangeFields}
                                    fields={this.props.fields}
                                />
                                <Typography color="textSecondary" variant="caption">
                                    Pesquise a Baixa
                                </Typography>
                                <AsyncSelect
                                    className={
                                        `form-control form-control-alternative form-select Select-menu-outer ${
                                        this.props.error ? "has-danger" : ""
                                        }`
                                    }
                                    value={this.props.valueAsync}
                                    onChange={this.props.onChange}
                                    loadOptions={this.props.optionsAsync}
                                    error={this.props.error}
                                    helperText={this.props.helperText}
                                    name={this.props.nameAsync}
                                    placeholder={this.props.placeholder}
                                    message={this.props.message}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="danger">
                            Cancelar
                        </Button>
                        <Button onClick={this.props.handleOpenConfirm} color="success" disabled={this.props.isDisabled}>
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    onClose={this.props.handleCloseConfirm}
                    aria-labelledby="customized-dialog-title"
                    open={this.props.openConfirm}
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        Tem certeza que deseja deletar esta baixa?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={this.props.handleSave} color="primary">
                            Deletar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
export default DialogCustom