import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
})

function DatePickers(props) {
  const { classes } = props

  return (
    <form className={classes.container} noValidate>
      <TextField
        id="date"
        label={props.title}
        type="date"
        margin="normal"
        variant="outlined"
        defaultValue={props.defaultValue}
        fullWidth
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        error={props.helperText !== undefined}
        helperText={props.helperText}
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
      />
    </form>
  )
}

DatePickers.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(DatePickers)