import axios from "axios"
import { defaultHeaders } from "./Constants"

export function get(page, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url =
    process.env.REACT_APP_API + `lancamentoIPTU${page ? `?page=${page}` : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function post(params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "lancamentoIPTU"
  axios
    .post(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function put(id, params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "lancamentoIPTU/" + id
  axios
    .put(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
export function search(searchText, field, onStart, callBack,pagina) {
  let config = {
    headers: defaultHeaders(),
    data: { a: "a" }
  }
  let url =
    process.env.REACT_APP_API +
    `search/lancamentoIPTU?text=${searchText}&fields=${field}`
  if(pagina != null){
    url += `&page=${pagina}`
  }
  if (onStart) {
    onStart()
  }
  return axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function remove(lancamento, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url =
    process.env.REACT_APP_API + `lancamentoIPTU/${lancamento}`
  axios
    .delete(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}