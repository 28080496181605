import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import Drawer from "@material-ui/core/Drawer"
import Hidden from "@material-ui/core/Hidden"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
// core components
import HeaderLinks from "../../components/Header/HeaderLinks.jsx"
import sidebarStyle from "../../assets/css/components/sidebarStyle.jsx"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Icon from "@material-ui/core/Icon"
import bg from "../../assets/img/bg.svg"

const Sidebar = ({ ...props }) => {
  function activeRoute(routeName) {
    return window.location.pathname
      .split("/")[1]
      .includes(routeName.split("/").join(""))
  }
  const { classes, color, image, logoText, routes } = props
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.path)
        })
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.path)
        })
        const sub = prop.subItems
        return (
          <li className={classes.listItem} key={key}>
            {sub ? (
              <ExpansionPanel classes={{ root: classes.expansion }}>
                <ExpansionPanelSummary
                  classes={{
                    root: classes.itemLink + " " + classes.expasionSummary,
                    content:
                      classes.expasionSummaryContent + " " + listItemClasses
                  }}
                  expandIcon={
                    <ExpandMoreIcon
                      classes={{
                        root: activeRoute(prop.path)
                          ? classes.itemIconActive
                          : classes.itemIcon
                      }}
                    />
                  }
                >
                  <ListItemIcon
                    className={classes.expansionIcon}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon>{prop.icon}</Icon>
                    ) : (
                        <prop.icon />
                      )}
                  </ListItemIcon>
                  <ListItemText
                    primary={prop.sidebarName}
                    className={classes.itemText}
                    disableTypography={true}
                  />
                </ExpansionPanelSummary>

                {sub.map((subProp, key) => (
                  <ExpansionPanelDetails
                    key={key}
                    classes={{ root: classes.expansionDetail }}
                  >
                    <Link to={subProp.path} className={classes.subItem}>
                      <ListItem button className={classes.subItemLink}>
                        <ListItemIcon
                          className={classes.expansionIcon + whiteFontClasses}
                        >
                          {typeof subProp.icon === "string" ? (
                            <Icon>{subProp.icon}</Icon>
                          ) : (
                              <subProp.icon />
                            )}
                        </ListItemIcon>
                        <ListItemText
                          primary={subProp.name}
                          className={classes.itemText + whiteFontClasses}
                          disableTypography={true}
                        />
                      </ListItem>
                    </Link>
                  </ExpansionPanelDetails>
                ))}
              </ExpansionPanel>
            ) : (
                <Link
                  to={prop.path}
                  className={classes.item}
                  key={key}
                >
                  <ListItem button className={classes.itemLink + listItemClasses}>
                    <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                      {typeof prop.icon === "string" ? (
                        <Icon>{prop.icon}</Icon>
                      ) : (
                          <prop.icon />
                        )}
                    </ListItemIcon>
                    <ListItemText
                      primary={prop.sidebarName}
                      className={classes.itemText + whiteFontClasses}
                      disableTypography={true}
                    />
                  </ListItem>
                </Link>
              )}
          </li>
        )
      })}
    </List>
  )
  var brand = (
    <div className={classes.logo}>
      <Link to="/dashboard" className={classes.logoLink}>
        {logoText}
      </Link>
    </div>
  )

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <HeaderLinks />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + bg + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  )
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(sidebarStyle)(Sidebar)
