import React from "react"

import {Link, withRouter} from "react-router-dom"
import {withStyles, Paper} from "@material-ui/core"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import Button from "../../components/CustomButtons/Button"
import AdBlockDetect from "react-ad-block-detect"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import ChromeReaderMode from "@material-ui/icons/ChromeReaderMode"
import Money from "@material-ui/icons/Money"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import AddIcon from "@material-ui/icons/Add"
import Grid from "@material-ui/core/Grid"
import Table from "../../components/Table/Table"
import TablePagination from "@material-ui/core/TablePagination"
import Edit from "@material-ui/icons/Edit"
import Print from "@material-ui/icons/Print"
import Lens from "@material-ui/icons/Lens"
import {get, search, guiaItbi, removeEmissao} from "../../util/emissaoitbi"
import LoadingContent from "../../components/LoadingContent"
import Search from "../../components/Search"
import {damItbi} from "../../util/dam"
import Close from "@material-ui/icons/Close"
import {
    showNotification,
    showWaiting,
    hideWaiting
} from "../../util/Constants"
import numeral from "../../util/numeral"
import housecall from "housecall"
import AlterarVencimento from "./AlterarVencimento"

class Emissao extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: "",
            error: "",
            pageCount: 0,
            perPage: 0,
            page: 0,
            isSearch:false,
            emissao: [],
            searchText: "",
            field: 0,
            modal: false,
            id: "",
            historico: "",
            guiaSelected: "",
            showGuiaModal: false,
            showAlterarVencimentoModal: false,
            modalRemove: false,
            errors: {}
        }
        this.handleFieldChange = this.handleFieldChange.bind(this)
        this.getData = this.getData.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.toggleLoading = this.toggleLoading.bind(this)
        this.filter = this.filter.bind(this)
        this.gerarGuia = this.gerarGuia.bind(this)
        this.queue = housecall({concurrency: 1, cooldown: 0})
        this.imprimirDam = this.imprimirDam.bind(this)
        this.getDam = this.getDam.bind(this)
        this.message = this.message.bind(this)
        this.messageDam = this.messageDam.bind(this)
        this.setLoadingFalse = this.setLoadingFalse.bind(this)
        this.deleteEmissao = this.deleteEmissao.bind(this)
        this.closeRemove = this.closeRemove.bind(this)
        this.areUSure = this.areUSure.bind(this)
    }

    areUSure(key) {
        this.setState({
            modalRemove: true,
            id: this.state.emissao[key].id
        })
    }

    closeRemove() {
        this.setState({modalRemove: false})
    }

    deleteEmissao() {
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    error.response.data.message?error.response.data.message:"Ocorreram erros! Tente novamente",
                    "danger"
                )
            } else {
                showNotification(
                    "top",
                    "center",
                    "DAM de ITBI removido com sucesso!",
                    "success"
                )
                this.closeRemove()
                this.getData()
            }
        }
        removeEmissao(this.state.id, response)
    }

    setLoadingFalse() {
        this.setState({
            isLoading: false
        })
    }

    componentWillMount() {
        this.getData()
    }

    handlePageClick(data) {
        //this.getData(data.selected + 1);
    }

    getDam(key) {
        if (this.state.emissao[key].pago) {
            this.messageDam(key)
        } else {
            this.setState({modal: true, damIndex: key})
        }
    }

    handleChange(event) {
        let name = event.target.name
        let value = event.target.value
        if (name.split(".").length > 1) {
            let oldFaixas = [...this.state.faixas]
            let components = name.split(".")
            name = components[0]
            oldFaixas[components[1]][components[2]] = value
            value = oldFaixas
        }
        this.setState(state => ({
            [name]: value,
            errors: {...state.errors, [name]: undefined}
        }))
    }

    handleChangeSearch = event => {
        this.setState({filterField: event.target.value}, () => {
            if (this.state.searchActive) {
                this.filter({target: {value: this.state.searchText}})
            }
        })
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    componentDidMount() {
        this.queue.on("idle", this.setLoadingFalse)
    }

    filter(event,pagina) {
        let searchText = event.target.value
        var newState =    {
            isLoading:true,
            searchText: searchText,
            searchActive: searchText !== ""
        };
        if(pagina == null){
            newState['page'] = 0;
        }
        this.setState(
            newState,
            () => {
                if (this.state.searchActive) {

                    let filtersCheckbox = this.state.filterField || ""

                        search(searchText, filtersCheckbox, undefined, (error, data) => {
                            this.setState({isLoading:false})
                            if (error) {
                                showNotification(
                                    "top",
                                    "center",
                                    "Ocorreram erros, verifique sua conexão com a internet",
                                    "danger"
                                )
                            } else {
                                if (this.state.searchText) {
                                    this.setState({

                                        emissao: data.data,
                                        pageCount: data.total,
                                        perPage: data.per_page,
                                    })
                                }
                            }
                        },pagina)

                } else {
                    this.getData()
                }
            }
        )
    }

    getData(page) {
        this.setState({isLoading:true})

        get(page, (error, data) => {
            this.setState({isLoading:false})
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                this.setState(
                    {
                        isSearch:false,
                        isLoading:false,
                        emissao: data.data,
                        pageCount: data.total,
                        perPage: data.per_page,

                    }
                )
            }
        })
    }
    handleChangePage = (event, page) => {
        var pagina;
        pagina = page + 1;
        this.setState({page: page})
        if(page < 0){
            return;
        }
        if (this.state.searchText.toString().trim() != "") {
            this.filter({target: {value: this.state.searchText}}, pagina, page)
            return;
        }
        this.getData(pagina)
    };


    handleEdit(key) {
        this.props.history.push("/emissao/edit/" + this.state.emissao[key].id)
    }

    handleFieldChange(value) {
        this.setState({
            field: value
        })
    }

    gerarGuia(key) {
        showWaiting()
        let response = (error, data) => {
            if (error) {
                const reader = new FileReader()
                reader.addEventListener("loadend", e => {
                    const text = e.srcElement.result
                    let error = JSON.parse(text)
                    hideWaiting()
                    showNotification(
                        "top",
                        "center",
                        error.message,
                        "warning"
                    )
                })
                reader.readAsText(error.response.data)
            } else {
                hideWaiting()
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        guiaItbi(this.state.emissao[key].id, response)
    }

    imprimirDam(event) {
        event.preventDefault()
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                const reader = new FileReader()
                reader.addEventListener("loadend", e => {
                    const text = e.srcElement.result
                    try {
                        let error = JSON.parse(text)

                        let status = error.status
                        if (status === "vencido") {
                            this.setState({
                                showAlterarVencimentoModal: true,
                                modal: false
                            })
                        }else{
                            showNotification(
                                "top",
                                "center",
                                `${error.message}`,
                                "warning"
                            )
                        }
                    } catch (e) {
                        showNotification(
                            "top",
                            "center",
                            `${error.message}`,
                            "warning"
                        )
                    }
                })

                reader.readAsText(error.response.data)
            } else {
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        damItbi(
            this.state.emissao[this.state.damIndex].id,
            this.state.historico,
            response
        )
    }

    message() {
        showNotification(
            "top",
            "center",
            `O DAM não está pago. Guia não disponível`,
            "warning"
        )
    }

    messageDam(damIndex) {
        showNotification(
            "top",
            "center",
            `O DAM está pago. DAM não disponível`,
            "warning"
        )
        this.setState(
            {
                guiaSelected: damIndex
            },
            () => {
                this.setState({
                    showGuiaModal: true
                })
            }
        )
    }

    handleClose = (name, status) => {
        this.setState({[name]: false}, () => {
            if (status === "success") {
                this.setState({
                    modal: true
                })
            }
        })
    }

    render() {
        const {classes} = this.props
        const fields = [
            {value: "", label: "Todos os campos"},
            {value: "id", label: "Código DAM ITBI"},
            {value: "transmitente_id", label: "Código do Transmitente"},
            {value: "adquirente_id", label: "Código do Adquirente"},
            {value: "transmitente_nome_razao_social", label: "Nome Transmitente"},
            {value: "adquirente_nome_razao_social", label: "Nome Adquirente"}
        ]
        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>Emissão de ITBI</h2>
                            </Grid>
                            <Grid item lg={2} xs={12}>
                                <Link to="/emissao/create" style={{textDecoration: "none"}}>
                                    <Button block color="success" round>
                                        <AddIcon/> Emitir
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <Search
                            label="Pesquise aqui"
                            value={this.state.filterField}
                            onChangeText={this.filter}
                            onChangeFields={this.handleChangeSearch}
                            fields={fields}
                        />
                        <Grid className={classes.legend} container direction="row">
                            <Lens className={classes.dot + " " + classes.dotDanger}/>
                            ITBI Lançado na dívida
                            <Lens className={classes.dot + " " + classes.dotSuccess}/>
                            ITBI Pago
                            <Lens className={classes.dot + " " + classes.dotWarning}/>
                            ITBI ainda não avaliado
                        </Grid>
                        <LoadingContent isLoading={this.state.isLoading}>
                            <Paper className={classes.tableWrapper}>
                                <Table
                                    getRowColor={(key, classes) => {
                                        if (this.state.emissao[key].lancado_na_divida) {
                                            return classes.dangerTableRow
                                        } else if (this.state.emissao[key].pago) {
                                            return classes.successTableRow
                                        } else if (!this.state.emissao[key].avaliado) {
                                            return classes.warningTableRow
                                        } else {
                                            return ""
                                        }
                                    }}
                                    tableHeaderColor="primary"
                                    actions={[
                                        {
                                            title: "Avaliar",
                                            icon: (
                                                <Money
                                                    className={
                                                        classes.tableActionButtonIcon +
                                                        " " +
                                                        classes.print
                                                    }
                                                />
                                            ),
                                            onClick: (key) => {
                                                this.props.history.push('/itbi/avaliar/' + this.state.emissao[key].id)
                                            }
                                        },
                                        {
                                            title: "Imprimir Guia",
                                            icon: (
                                                <Print
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.print
                                                    }
                                                />
                                            ),
                                            onClick: key => this.gerarGuia(key)
                                        },
                                        {
                                            title: "Imprimir DAM",
                                            icon: (
                                                <ChromeReaderMode
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            ),
                                            onClick: key => this.getDam(key)
                                        },
                                        {
                                            title: "Remover",
                                            icon: (
                                                <Close
                                                    className={
                                                        classes.tableActionButtonIcon +
                                                        " " +
                                                        classes.edit
                                                    }
                                                />
                                            ),
                                            onClick: this.areUSure
                                        },

                                    ]}
                                    tableHead={[
                                        "Código",
                                        "Código Transmitente",
                                        "Nome Transmitente",
                                        "Código Adquirente",
                                        "Nome Adquirente",
                                        "Valor"
                                    ]}
                                    tableData={this.state.emissao.map((item, key) => {
                                        return [
                                            item.id + "",
                                            item.transmitente_id,
                                            item.transmitente.nome_razao_social,
                                            item.adquirente_id,
                                            item.adquirente.nome_razao_social,
                                            "R$ " +
                                            numeral(parseFloat(item.valor))
                                                .format("0.0,00")
                                                .slice(0, -2),
                                            key
                                        ]
                                    })}
                                />
                                { (
                                    <TablePagination
                                        component="div"
                                        rowsPerPageOptions={[]}
                                        count={this.state.pageCount}
                                        rowsPerPage={this.state.perPage?this.state.perPage:0}
                                        page={this.state.page?this.state.page:0}
                                        backIconButtonProps={

                                                {"aria-label": "Previous Page"}}
                                        nextIconButtonProps={ {
                                            "aria-label": "Next Page",
                                        }}
                                        SelectProps={{
                                            native: true
                                        }}

                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                )}
                            </Paper>
                        </LoadingContent>
                    </CardBody>
                </Card>
                <AdBlockDetect>
                    <Dialog open={true} aria-labelledby="form-dialog-title">
                        <form onSubmit={this.handleSave}>
                            <DialogContent>
                                <Grid
                                    container
                                    justify="center"
                                    alignItems="center"
                                    direction="row"
                                >
                                    <Grid item xs={12} sm={12} md={8} lg={8}>
                                        <Typography variant="h5" gutterBottom>
                                            Por favor, desabilite o ADBlock para geração de BCI e
                                            impressão de carnês.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </form>
                    </Dialog>
                </AdBlockDetect>

                {/* dialog imprimir */}
                <Dialog
                    id="body"
                    open={this.state.modal}
                    maxWidth="md"
                    fullWidth
                    onClose={e => this.handleClose("modal")}
                    aria-labelledby="form-dialog-title"
                    style={{zIndex: "4"}}
                >
                    <DialogTitle id="form-dialog-title">Imprima seu DAM</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={16} justify="center">
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Button onClick={this.imprimirDam} color="success" fullWidth>
                                    Imprimir DAM
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="outlined-select-currency"
                                    multiline
                                    name="historico"
                                    label="Histórico"
                                    fullWidth
                                    rowsMax={7}
                                    error={this.state.errors.historico}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <Dialog
                    id="body"
                    open={this.state.showAlterarVencimentoModal}
                    maxWidth="md"
                    fullWidth
                    titleStyle={{textAlign: "center"}}
                    onClose={e => this.handleClose("showAlterarVencimentoModal")}
                    aria-labelledby="form-dialog-title"
                    style={{zIndex: "4"}}
                >
                    <DialogTitle id="form-dialog-title">
                        <Typography variant="h5" align="center">
                            O DAM Selecionado se encontra vencido, deseja alterar seu
                            vencimento?
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <AlterarVencimento
                            dam={this.state.emissao[this.state.damIndex]}
                            handleClose={status =>
                                this.handleClose("showAlterarVencimentoModal", status)
                            }
                            fromModal={true}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    id="body"
                    open={this.state.showGuiaModal}
                    maxWidth="sm"
                    fullWidth
                    titleStyle={{textAlign: "center"}}
                    onClose={e => this.handleClose("showGuiaModal")}
                    aria-labelledby="form-dialog-title"
                    style={{zIndex: "4"}}
                >
                    <DialogTitle id="form-dialog-title">
                        <Typography variant="h5" align="center">
                            O DAM Selecionado já está pago, deseja imprimir a guia de ITBI?
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={16} justify="center">
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Button
                                    onClick={e => this.gerarGuia(this.state.guiaSelected)}
                                    color="success"
                                    fullWidth
                                >
                                    Imprimir Guia
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>

                {/* dialog confirmacao */}
                <Dialog
                    open={this.state.modalRemove}
                    onClose={this.closeRemove}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{zIndex: "4"}}
                >
                    <DialogTitle id="alert-dialog-title">Você tem certeza que deseja remover?</DialogTitle>

                    <DialogActions>
                        <Button onClick={this.closeRemove} color="danger">
                            Cancelar
                        </Button>
                        <Button onClick={this.deleteEmissao} color="success">
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

Emissao = withRouter(Emissao)
export default withStyles(contribuinteStyle)(Emissao)
