import React from "react"

import {
  showNotification,
  showWaiting,
  hideWaiting
} from "../../../util/Constants"
import logradouroStyle from "../../../assets/css/layout/logradouroStyle"
import { withStyles } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import Button from "../../../components/CustomButtons/Button"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Grid from "@material-ui/core/Grid"
import AdBlockDetect from "react-ad-block-detect"
import { getRelatorio } from "../../../util/secaologradouro"
import { search } from "../../../util/logradouro"
import AsyncSelect from "../../../components/AsyncSelect"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"

class SecoesRelatorio extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      modal: false,
      nome: "",
      logradouros: [],
      id_logradouro: [],
      secoesLogradouro: [],
      cod_secao: "",
      group: "list",
      order: "secao"
    }
    this.handleChange = this.handleChange.bind(this)
    this.gerarRelatorio = this.gerarRelatorio.bind(this)
    this.loadLogradouro = this.loadLogradouro.bind(this)
  }
  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined

    if (name === "group") {
      this.setState({
        [name]: event.target.checked,
        errors: errors
      })
    } 
    if (name === "order") {
      this.setState({
        [name]: event.target.checked,
        errors: errors
      })
    }
    this.setState({
      [name]: event.target.value,
      errors: errors
    })
  }

  gerarRelatorio(event) {
    event.preventDefault()
    showWaiting()
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        hideWaiting()
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    getRelatorio(this.state.cod_secao, this.state.group, this.state.id_logradouro.label, this.state.id_logradouro.value, this.state.order, response)
  }

  loadLogradouro(inputValue, callback) {
    search(inputValue, undefined, undefined, (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
        callback([])
      } else {
        let lograd = data.map(logradouro => {
          return { value: logradouro.id, label: logradouro.nome }
        })
        this.setState({
          logradouros: data
        })
        callback(lograd)
      }
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>
                  Relatório de Seções de Logradouros
                </h2>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Grid container direction="row" style={{ marginTop: "2%" }}>
              <Divider />
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                variant="caption"
              >
                Filtrar relatório por nome de Seções ou deixe vazio para a opção
                de TODOS
              </Typography>
            </Grid>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-select-currency"
                  name="cod_secao"
                  label="Código da seção"
                  fullWidth
                  type="number"
                  error={this.state.errors.cod_secao}
                  helperText={this.state.errors.cod_secao}
                  value={this.state.cod_secao}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container direction="row" style={{ marginTop: "2%" }}>
              <Divider />
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                variant="caption"
              >
                Filtrar relatório por nome de Logradouros ou deixe vazio para a
                opção de TODOS
              </Typography>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AsyncSelect
                  classStyle={`form-control form-control-alternative form-select Select-menu-outer ${
                    this.props.error ? "has-danger" : ""
                  }`}
                  style={{ position: "relative" }}
                  value={this.state.id_logradouro}
                  onChange={this.handleChange}
                  loadOptions={this.loadLogradouro}
                  name="id_logradouro"
                  placeholder="Logradouro"
                  message="Pesquise o logradouro"
                />
              </Grid>
            </Grid>
            <Grid container direction="row" style={{ marginTop: "2%" }}>
              <Divider />
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                variant="caption"
              >
                Tipo
              </Typography>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <RadioGroup
                  aria-label="position"
                  name="group"
                  value={this.state.group}
                  onChange={this.handleChange}
                  row
                >
                  <FormControlLabel
                    value="list"
                    control={<Radio color="primary" />}
                    label="Listagem"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="grouped"
                    control={<Radio color="primary" />}
                    label="Agrupado por logradouro"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid container direction="row" style={{ marginTop: "2%" }}>
              <Divider />
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                variant="caption"
              >
                Ordernar por
              </Typography>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <RadioGroup
                  aria-label="position"
                  name="order"
                  value={this.state.order}
                  onChange={this.handleChange}
                  row
                >
                  <FormControlLabel
                    value="secao"
                    control={<Radio color="primary" />}
                    label="Código Seção"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="nome"
                    control={<Radio color="primary" />}
                    label="Seção"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </CardBody>
          <CardFooter>
            <Grid container justify="center">
              <Grid item lg={2}>
                <Button block color="info" round onClick={this.gerarRelatorio}>
                  Gerar
                </Button>
              </Grid>
            </Grid>
          </CardFooter>
        </Card>
        {/* MODAL */}

        <AdBlockDetect>
          <Dialog open={true} aria-labelledby="form-dialog-title">
            <form onSubmit={this.handleSave}>
              <DialogContent>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Typography variant="h5" gutterBottom>
                      Por favor, desabilite o ADBlock para que o relatório seja
                      emitido.
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
            </form>
          </Dialog>
        </AdBlockDetect>
      </div>
    )
  }
}

export default withStyles(logradouroStyle)(SecoesRelatorio)
