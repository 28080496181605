import React from "react"

import { Link, withRouter } from "react-router-dom"
import { withStyles, Paper } from "@material-ui/core"
import TextField from "@material-ui/core/TextField";
import logradouroStyle from "../../../assets/css/layout/logradouroStyle"
import Button from "../../../components/CustomButtons/Button"
import TablePagination from "@material-ui/core/TablePagination";

import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import AddIcon from "@material-ui/icons/Add"
import Grid from "@material-ui/core/Grid"
import Table from "../../../components/Table/Table"
import Edit from "@material-ui/icons/Edit"
import { get } from "../../../util/refis"
import { showNotification } from "../../../util/Constants"
import LoadingContent from "../../../components/LoadingContent"

class RefisCadastro extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      errors: {},
      nome: "",
      refis: [],
      refisFiltered: [],
      search: false,
      pageCount: 0,
      perPage: 0,
      page: 1,
      field: 0,
      modal: false,
      id: 0
    }
    this.getData = this.getData.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.searchFilter = this.searchFilter.bind(this)
  }

  searchFilter(value) {
    let filtered = this.state.refis
    if (value !== '') {
      this.setState({
        search: true
      })
    } else {
      this.setState({
        search: false
      })
    }
    filtered = filtered.filter((filter) => {
      if (String(filter.id) !== null) {
        return String(filter.id).indexOf(value.toUpperCase()) !== -1;
      } else {
        return null
      }
    })
    this.setState({ refisFiltered: filtered })
  }

  componentWillMount() {
    this.getData()
  }

  handlePageClick(data) {
    //this.getData(data.selected + 1);
  }

  handleChangePage = (event, page) => {
    if ((page || 1) !== this.state.page) {
      this.setState({ page }, () => { this.getData(this.state.page) })
    }
  };

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: event.target.value,
      errors: errors
    })
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  getData(page) {
    if (!page) {
      this.toggleLoading()
    }
    get(page, (error, data) => {
      if (!page) {
        this.toggleLoading()
      }
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        this.setState({
          refis: data.data,
          refisFiltered: data.data,
          pageCount: data.total,
          perPage: data.per_page,
          page: data.current_page
        })
      }
    })
  }

  handleEdit(key) {
    this.props.history.push(
      "/divida/refis/edit/" + this.state.refis[key].id
    )
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Cadastro de REFIS</h2>
              </Grid>
              <Grid item lg={2} xs={12}>
                <Link
                  to="/divida/refis/create"
                  style={{ textDecoration: "none" }}
                >
                  <Button block color="success" round>
                    <AddIcon /> Adicionar
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <TextField
              id="standard-search"
              label="Pesquisar por código refis"
              fullWidth
              type="search"
              name="search"
              onChange={(event) => this.searchFilter(event.target.value)}
              className={classes.textField}
              margin="normal"
            />
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  tableHeaderColor="primary"
                  actions={[
                    {
                      title: "Editar",
                      icon: (
                        <Edit
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      ),
                      onClick: this.handleEdit
                    }
                  ]}
                  tableHead={["Código", "Lei", ""]}
                  tableData={(this.state.search ? this.state.refisFiltered : this.state.refis).map((item, key) => {
                    return [item.id + "", item.lei, key]
                  })}
                />
                {this.state.searchText === "" && (
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25]}
                    count={this.state.pageCount}
                    rowsPerPage={parseInt(this.state.perPage)}
                    page={this.state.page}
                    backIconButtonProps={{
                      "aria-label": "Previous Page"
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page"
                    }}
                    SelectProps={{
                      native: true
                    }}
                    labelRowsPerPage="Quantidade por página"
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                )}
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>
      </div>
    )
  }
}
RefisCadastro = withRouter(RefisCadastro)
export default withStyles(logradouroStyle)(RefisCadastro)