import React from "react"
import { withRouter } from "react-router-dom"
import { withStyles, Paper, FormControlLabel, Checkbox } from "@material-ui/core"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import Button from "../../components/CustomButtons/Button"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import Print from "@material-ui/icons/Print"
import Grid from "@material-ui/core/Grid"
import Table from "../../components/Table/Table"
import TablePagination from "@material-ui/core/TablePagination"
import Lens from "@material-ui/icons/Lens"
import DoneAll from "@material-ui/icons/DoneAll"
import { get, search, getRelatorio, put } from "../../util/pendencias"
import LoadingContent from "../../components/LoadingContent"
import Search from "../../components/Search"
import Dialog from "@material-ui/core/Dialog"
import Typography from "@material-ui/core/Typography"
import DialogContent from "@material-ui/core/DialogContent"
import moment from "moment";
import {
  showNotification, showWaiting, hideWaiting, mapErrors
} from "../../util/Constants"
import housecall from "housecall"
import AdBlockDetect from "react-ad-block-detect"
import TextField from "@material-ui/core/TextField"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
class Pendencias extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      pageCount: 0,
      perPage: 0,
      page: 0,
      isSearch:false,
      pendencias: [],
      editingPendencia: {},
      errors: {},
      exibir_pagos: false,
      searchText: "",
      field: 0
    }
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.getData = this.getData.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.filter = this.filter.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
    this.setLoadingFalse = this.setLoadingFalse.bind(this)
    this.onSubmitRelatorio = this.onSubmitRelatorio.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.onSubmitPendencia = this.onSubmitPendencia.bind(this)
  }

  setLoadingFalse() {
    this.setState({
      isLoading: false
    })
  }

  componentWillMount() {
    this.getData()
  }

  handlePageClick(data) {
    this.getData(data.selected + 1);
  }
  handleInputChange(e) {
    let name = e.target.name
    let type = e.target.type
    let value = type === 'checkbox' ? e.target.checked : e.target.value
    let errors = { ...this.state.errors }
    errors[name] = undefined
    this.setState({
      [name]: value,
      errors: errors
    })
  }
  handleChange = event => {
    this.setState({ filterField: event.target.value }, () => {
      if (this.state.searchActive) {
        this.filter({ target: { value: this.state.searchText } })
      }
    })
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  componentDidMount() {
    this.queue.on("idle", this.setLoadingFalse)
  }
  filter(event,pagina) {
    let searchText = event.target.value
    var newState =    {
      isLoading:true,
      searchText: searchText,
      searchActive: searchText !== ""
    }
  if(pagina == null){
    newState["page"] = 0
  }
    this.setState(
      newState
   ,
      () => {
        if (this.state.searchActive) {
       
          let filtersCheckbox = this.state.filterField || ""
          this.queue.push(() =>
            search(searchText, filtersCheckbox, undefined, (error, data) => {
              this.setState({isLoading:false})
              if (error) {
                showNotification(
                  "top",
                  "center",
                  "Ocorreram erros, verifique sua conexão com a internet",
                  "danger"
                )
              } else {
                if (this.state.searchText) {
                  this.setState({
                    pendencias: data.data,
                    pageCount: data.total,
                    perPage: data.per_page,
                  
                  })
                }
              }
            },pagina)
          )
        } else {
          this.getData()
        }
      }
    )
  }
  getData(page) {
    this.setState({isLoading:true})
  
    get(page, (error, data) => {
      this.setState({isLoading:false})
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState({
          pendencias: data.data,
          pageCount: data.total,
          perPage: data.per_page,
          
        })
      }
    })
  }

  handleChangePage = (event, page) => {
    var pagina;
    pagina = page + 1;
    this.setState({page: page})
    if(page < 0){
      return;
    }
    if (this.state.searchText.toString().trim() != "") {
      this.filter({target: {value: this.state.searchText}}, pagina, page)
      return;
    }
    this.getData(pagina)
  };


  onSubmitRelatorio(e) {
    e.preventDefault()
    let data_inicial = this.state.data_inicial,
      data_final = this.state.data_final,
      exibirResolvidos = this.state.exibir_resolvidos
    showWaiting()
    getRelatorio(data_inicial, data_final, exibirResolvidos ? 1 : 0, (error, data) => {
      hideWaiting()
      if (error) {
        if (error.response.status === 422) {
          const reader = new FileReader()
          reader.addEventListener("loadend", e => {
            const text = e.srcElement.result
            let json = JSON.parse(text)
            this.setState({
              errors: mapErrors({ response: { data: json } })
            })
          })
          reader.readAsText(error.response.data)

        } else {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique o formulário e tente novamente",
            "danger"
          )
        }

      } else {
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    })

  }
  onSubmitPendencia(e) {
    e.preventDefault()
    let params = {
      solucao: this.state.solucao
    }
    put(this.state.editingPendencia.id, params, (error, data) => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          `Pendência fechada com sucesso`,
          "success"
        )
        this.closeModal('showCloseModal', () => {
          this.getData()
        })

      }
    })
  }
  handleFieldChange(value) {
    this.setState({
      field: value
    })
  }
  handleEdit(key) {
    let pendencia = this.state.pendencias[key]
    if (pendencia.resolved_at === null) {
      this.setState({ showCloseModal: true, editingPendencia: pendencia, solucao: '' })
    } else {
      showNotification(
        "top",
        "right",
        "Está pendência já está fechada",
        "warning"
      )
    }

  }
  closeModal(name, callBack) {
    this.setState({ [name]: false, editingPendencia: {} }, () => {
      if (callBack) {
        callBack()
      }
    })
  }
  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código da pendencia" },
      { value: "created_at", label: "Data da pendencia" },
      { value: "modulo", label: "Modulo" },
    ]
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container spacing={32} direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Pendências</h2>
              </Grid>
              <Grid item lg={2} xs={12}>
                <Button onClick={(e) => {
                  this.setState({ showModal: true })
                }} block color="success" round>
                  <Print /> Imprimir
                  </Button>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Search
              label="Pesquise aqui"
              value={this.state.filterField}
              onChangeText={this.filter}
              onChangeFields={this.handleChange}
              fields={fields}
            />
            <Grid className={classes.legend} container direction="row">
              <Lens className={classes.dot + " " + classes.dotSuccess} />
              Pendência resolvida
            </Grid>
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  getRowColor={(key, classes) => {
                    if (this.state.pendencias[key].resolved_at !== null) {
                      return classes.successTableRow
                    } else {
                      return ""
                    }
                  }}
                  tableHeaderColor="primary"
                  actions={[
                    {
                      title: "Fechar pendência",
                      icon: (
                        <DoneAll
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      ),
                      onClick: this.handleEdit
                    },
                  ]}
                  tableHead={[
                    "Código",
                    "Modulo",
                    "Descricao",
                    "Data",
                    "Solucionado em"
                  ]}
                  tableData={this.state.pendencias.map((item, key) => {
                    return [
                      item.id + "",
                      item.modulo,
                      item.descricao,
                      moment(item.created_at).format("DD/MM/YYYY"),
                      item.resolved_at ? moment(item.resolved_at).format("DD/MM/YYYY") : 'Não resolvido',
                      key
                    ]
                  })}
                />
                { (
                  <TablePagination
                    component="div"
                    handlePageClick={this.handlePageClick}
                    rowsPerPageOptions={[]}
                    count={this.state.pageCount}
                    rowsPerPage={parseInt(this.state.perPage)}
                    page={this.state.page}
                    backIconButtonProps={
                
                  {"aria-label": "Previous Page"}}
                    nextIconButtonProps={{
                      "aria-label": "Next Page",
                    }}

                    SelectProps={{
                      native: true
                    }}

                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                )}
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>
        <Dialog
          open={this.state.showModal}
          onClose={(e) => this.closeModal("showModal")}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          style={{ zIndex: "901" }}
          fullWidth
        >
          <form onSubmit={this.onSubmitRelatorio}>
            <DialogTitle id="form-dialog-title">
              Imprimir relatório de pendências
            </DialogTitle>
            <DialogContent>
              <Card>
                <CardBody>
                  <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        id="outlined-select-currency"
                        name="data_inicial"
                        label="Data inicial"
                        type='date'
                        fullWidth
                        error={this.state.errors.data_inicial}
                        helperText={this.state.errors.data_inicial}
                        value={this.state.data_inicial}
                        onChange={this.handleInputChange}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        id="outlined-select-currency"
                        name="data_final"
                        label="Data final"
                        type='date'
                        fullWidth
                        error={this.state.errors.data_final}
                        helperText={this.state.errors.data_final}
                        value={this.state.data_final}
                        onChange={this.handleInputChange}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    <Grid container direction="row" spacing={16}>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="secondary"
                              checked={this.state.exibir_resolvidos}
                              onChange={this.handleInputChange}
                              name="exibir_resolvidos"
                            />
                          }
                          label="Exibir resolvidos?"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => this.closeModal("showModal")} color="danger">
                Cancelar
              </Button>
              <Button type="submit" color="success">
                Imprimir
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          open={this.state.showCloseModal}
          onClose={this.closeModal}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          style={{ zIndex: "901" }}
          fullWidth
        >
          <form onSubmit={this.onSubmitPendencia}>
            <DialogTitle id="form-dialog-title">
              Fechar pendência
            </DialogTitle>
            <DialogContent>
              <Grid container direction="row" spacing={16}>
                <Grid item lg={4} xs={4}>
                  <h2 >Pendencia:{this.state.editingPendencia.id}</h2>
                  <h5 className={classes.cardTitleGray}>Gerada em:{moment(this.state.editingPendencia.created_at).format("DD/MM/YYYY")}</h5>
                  <h5 className={classes.cardTitleGray} >Modulo:{this.state.editingPendencia.modulo}</h5>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item lg={12} xs={12}>
                  <h5 className={classes.cardTitleGray}>{this.state.editingPendencia.descricao}</h5>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="outlined-select-currency"
                    name="solucao"
                    label="Solução"
                    required
                    multiline
                    rows={5}
                    fullWidth
                    error={this.state.errors.solucao}
                    helperText={this.state.errors.solucao}
                    value={this.state.solucao}
                    onChange={this.handleInputChange}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => this.closeModal("showCloseModal")} color="danger">
                Cancelar
              </Button>
              <Button type="submit" color="success">
                Fechar
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <AdBlockDetect>
          <Dialog open={true} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Typography variant="h5" gutterBottom>
                    Por favor, desabilite o ADBlock para geração de baixa.
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </AdBlockDetect>
      </div>
    )
  }
}
Pendencias = withRouter(Pendencias)
export default withStyles(contribuinteStyle)(Pendencias)
