import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import { showNotification, mapErrors } from "../../util/Constants";
import { withStyles } from "@material-ui/core";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import secaoStyle from "../../assets/css/layout/secaoStyle";
import Button from "../../components/CustomButtons/Button";
import { showWaiting, hideWaiting } from "../../util/Constants";
import { mudarVencimento } from "../../util/projeto";
import moment from "moment";
import CardFooter from "../../components/Card/CardFooter";

class AlterarVencModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      id_dam: this.props.dam.id,
      data_vencimentoAntiga: "",
      data_vencimentoNova: moment(new Date()).format("YYYY-MM-DD")
    };
    this.handleSave = this.handleSave.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    let name = event.target.name;
    let errors = this.state.errors;
    errors[name] = undefined;
    this.setState({
        [name]: event.target.value,
        errors: errors
      })
  }

  handleSave(event) {
    event.preventDefault();
    showWaiting();
    let response = (error, data) => {
        hideWaiting();
        if (error) {
            showNotification(
                "top",
                "center",
                "Ocorreram erros, verifique o formulário e tente novamente",
                "danger"
            );
            this.setState({
                errors: mapErrors(error)
            })
        } else {
            showNotification(
                "top",
                "center",
                `Data de vencimento alterada realizada com sucesso`,
                "success"
            )
            this.props.handleClose()
        }
    };
    let damid = this.props.dam.id
    mudarVencimento(damid, moment(this.state.data_vencimentoNova).format("YYYY-MM-DD"), response)
}

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Card>
          <CardBody>
            <Typography color="textSecondary" variant="caption">
              Altere a data de vencimento Dam de Projeto civil
            </Typography>
           
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="date"
                  label="Data do Vencimento"
                  margin="normal"
                  disabled
                  variant="outlined"
                  type="date"
                  value={this.props.dam.vencimento}
                  fullWidth
                  helperText={this.state.errors.data_vencimentoAntiga}
                  error={this.state.errors.data_vencimentoAntiga}
                  name="data_vencimentoAntiga"
                  onChange={this.handleChange}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="date"
                  label="Nova Data de Vencimento"
                  margin="normal"
                  variant="outlined"
                  type="date"
                  fullWidth
                  value={
                    this.state.data_vencimentoNova
                  }
                  helperText={this.state.errors.vencimento}
                  error={this.state.errors.vencimento}
                  name="data_vencimentoNova"
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>

          </CardBody>
          <CardFooter>
            <Grid container justify="center">
              <Grid item lg={2}>
                <Button
                  block
                  fullWidth
                  color="info"
                  round
                  onClick={this.handleSave}
                >
                        <AddIcon /> Alterar
                </Button>
              </Grid>
            </Grid>
          </CardFooter>
        </Card>

      </div>
    );
  }
}

export default withStyles(secaoStyle)(AlterarVencModal);
