import React from "react"

import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import { withStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import MenuItem from "@material-ui/core/MenuItem"
import housecall from "housecall"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import CardFooter from "../../components/Card/CardFooter"
import Button from "../../components/CustomButtons/Button"
import LoadingContent from "../../components/LoadingContent"
import AsyncSelect from "../../components/AsyncSelect"
import SearchFields from "../../components/SearchFields"

import { search as searchContribuinte } from "../../util/contribuinte"
import { getField, edit, put, post } from "../../util/projeto"

import { showNotification, mapErrors, showWaiting, hideWaiting } from "../../util/Constants"
import NumberFormatCustom from "../../components/NumberFormatCustom"
import DecimalFormatCustom from "../../components/DecimalFormatCustom"


class ProjetoForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      natureza_id: 0,
      categoria_id: 0,
      proprietario_id: 0,
      taxa_habitese_id: 0,
      taxa_alvara_id: 0,
      taxa_iss_id: 0,
      localizacao: "",
      area_terreno: "",
      area_construida: "",
      valor_da_obra: "",
      quantidade_de_pavimentos: "",

      contribuintes: [],
      endereco: "",
      bairro: "",
      cep: "",
      cidade: "",
      inscricao_municipal: "",

      categorias: [],
      naturezas: [],
      taxas: []
    }
    this.loadOptions = this.loadOptions.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getContribuinteById = this.getContribuinteById.bind(this)
    this.getFields = this.getFields.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
  }

  componentWillMount() {
    this.getFields()
  }

  getFields() {
    this.toggleLoading()
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState({
          categorias: data.categorias,
          naturezas: data.naturezas,
          taxas: data.taxas
        })
        if (this.props.match.params.id) {
          data.projeto.proprietario_id = {
            value: data.projeto.proprietario.id,
            label: `${data.projeto.proprietario.id} - ${
              data.projeto.proprietario.nome_razao_social
              } - ${data.projeto.proprietario.cpf_cnpj}`
          }

          data.projeto.endereco = data.projeto.proprietario.endereco
          data.projeto.bairro = data.projeto.proprietario.bairro
          data.projeto.cep = data.projeto.proprietario.cep
          data.projeto.contribuinte_baixa_renda = data.projeto.nis !== null
          data.projeto.cidade = data.projeto.proprietario.cidade
          data.projeto.inscricao_municipal =
            data.projeto.proprietario.inscricao_municipal
          data.projeto.area_construida = parseFloat(
            data.projeto.area_construida
          )
        }
        this.setState(data.projeto, () => this.toggleLoading())
      }
    }
    if (this.props.match.params.id) {
      edit(this.props.match.params.id, response)
    } else {
      getField(response)
    }
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let params = {
      natureza_id: this.state.natureza_id,
      categoria_id: this.state.categoria_id,
      proprietario_id: this.state.proprietario_id.value,
      taxa_habitese_id: this.state.taxa_habitese_id,
      taxa_alvara_id: this.state.taxa_alvara_id,
      taxa_iss_id: this.state.taxa_iss_id,
      localizacao: this.state.localizacao,
      area_terreno: this.state.area_terreno,
      area_construida: this.state.area_construida,
      valor_da_obra: this.state.valor_da_obra,
      nis: this.state.contribuinte_baixa_renda ? this.state.nis : null,
      quantidade_de_pavimentos: this.state.quantidade_de_pavimentos
    }
    let response = error => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          `Projeto ${
          this.props.match.params.id ? "editado" : "incluído"
          } com sucesso`,
          "success"
        )
        this.props.history.push("/projeto")
      }
    }
    this.props.match.params.id
      ? put(this.props.match.params.id, params, response)
      : post(params, response)
  }

  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  loadOptions(inputValue, callback) {
    searchContribuinte(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let contribuintes = data.data.map(contribuinte => {
            return {
              value: contribuinte.id,
              label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                ""} - ${contribuinte.cpf_cnpj || ""}`
            }
          })
          this.setState({
            contribuintes: data.data
          }, () => {

            callback(contribuintes)
          })
        }
      }
    )
  }

  handleChange(event) {
    let name = event.target.name
    let type = event.target.type
    let errors = this.state.errors
    errors[name] = undefined
    this.setState(
      {
        [name]: type === "checkbox" ? event.target.checked : event.target.value,
        errors: errors
      },
      () => {
        if (this.state.proprietario_id && name === "proprietario_id") {
          let contr = this.getContribuinteById(
            this.state.proprietario_id.value
          )
          this.setState({
            endereco: contr.endereco,
            bairro: contr.bairro,
            cep: contr.cep,
            cidade: contr.cidade,
            inscricao_municipal: contr.inscricao_municipal
          })
        }
      }
    )
  }
  getContribuinteById(id) {
    return this.state.contribuintes.filter(item => {
      return id === item.id
    })[0]
  }

  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do contribuinte" },
      { value: "cpf_cnpj", label: "Documento" },
      { value: "endereco", label: "Endereço" },
      { value: "nome_fantasia", label: "Nome Fantasia" },
      { value: "nome_razao_social", label: "Razão Social" }
    ]
    return (
      <div className={classes.container}>
        <LoadingContent isLoading={this.state.isLoading}>
          <Card>
            <CardHeader color="primary">
              <Grid container direction="row">
                <Grid item lg={10} xs={12}>
                  <h2 className={classes.cardTitleWhite}>
                    Cadastro de Projeto de Construção Civil
                  </h2>
                </Grid>
              </Grid>
            </CardHeader>
            <CardBody>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <SearchFields
                    name="filterField"
                    value={this.state.filterField || ""}
                    onChangeFields={this.handleChange}
                    fields={fields}
                  />
                  <AsyncSelect
                    className={
                      `form-control form-control-alternative form-select Select-menu-outer ${
                      this.props.error ? "has-danger" : ""
                      }`
                    }
                    value={this.state.proprietario_id}
                    onChange={this.handleChange}
                    loadOptions={this.loadOptions}
                    error={this.state.errors.proprietario_id}
                    helperText={this.state.errors.proprietario_id}
                    name="proprietario_id"
                    placeholder="Contribuinte"
                    message="Pesquise o contribuinte"
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <TextField
                    id="outlined-select-currency"
                    name="endereco"
                    label="Endereço"
                    disabled
                    fullWidth
                    value={this.state.endereco || ""}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    id="outlined-select-currency"
                    name="cep"
                    label="CEP"
                    disabled
                    fullWidth
                    value={this.state.cep || ""}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    id="outlined-select-currency"
                    name="inscricao_municipal"
                    label="Insc. Municipal"
                    disabled
                    fullWidth
                    value={this.state.inscricao_municipal || ""}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    id="outlined-select-currency"
                    name="bairro"
                    label="Bairro"
                    disabled
                    fullWidth
                    value={this.state.bairro || ""}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    id="outlined-select-currency"
                    name="cidade"
                    label="Cidade"
                    disabled
                    fullWidth
                    value={this.state.cidade || ""}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    label="Área Terreno"
                    name="area_terreno"
                    value={this.state.area_terreno}
                    defaultValue={this.state.area_terreno}
                    onChange={this.handleChange}
                    error={this.state.errors.area_terreno}
                    helperText={this.state.errors.area_terreno}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      inputComponent: DecimalFormatCustom
                    }}

                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    label="Área Construída"
                    name="area_construida"
                    defaultValue={this.state.area_construida}
                    value={this.state.area_construida}
                    onChange={this.handleChange}
                    error={this.state.errors.area_construida}
                    helperText={this.state.errors.area_construida}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      inputComponent: DecimalFormatCustom
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    label="Localização da obra"
                    name="localizacao"
                    value={this.state.localizacao}
                    error={this.state.errors.localizacao}
                    helperText={this.state.errors.localizacao}
                    onChange={this.handleChange}
                    fullWidth
                    multiline
                    rows="4"
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    name="categoria_id"
                    label="Categoria"
                    fullWidth
                    value={this.state.categoria_id}
                    error={this.state.errors.categoria_id}
                    helperText={this.state.errors.categoria_id}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  >
                    {this.state.categorias.map((option, key) => (
                      <MenuItem key={key} value={option.id}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    name="natureza_id"
                    label="Natureza"
                    fullWidth
                    value={this.state.natureza_id}
                    error={this.state.errors.natureza_id}
                    helperText={this.state.errors.natureza_id}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  >
                    {this.state.naturezas.map((option, key) => (
                      <MenuItem key={key} value={option.id}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    name="taxa_alvara_id"
                    label="Taxa Alvará"
                    fullWidth
                    error={this.state.errors.taxa_alvara_id}
                    helperText={this.state.errors.taxa_alvara_id}
                    value={this.state.taxa_alvara_id}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  >
                    {this.state.taxas.map((option, key) => (
                      <MenuItem key={key} value={option.id}>
                        {option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    name="taxa_habitese_id"
                    label="Taxa Habite-se"
                    error={this.state.errors.taxa_habitese_id}
                    helperText={this.state.errors.taxa_habitese_id}
                    fullWidth
                    value={this.state.taxa_habitese_id}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  >
                    {this.state.taxas.map((option, key) => (
                      <MenuItem key={key} value={option.id}>
                        {option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    label="Qtd. Pavimentos"
                    name="quantidade_de_pavimentos"
                    value={this.state.quantidade_de_pavimentos}
                    onChange={this.handleChange}
                    error={this.state.errors.quantidade_de_pavimentos}
                    helperText={this.state.errors.quantidade_de_pavimentos}
                    type="number"
                    min="0"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    name="taxa_iss_id"
                    label="Taxa ISS"
                    fullWidth
                    value={this.state.taxa_iss_id}
                    error={this.state.errors.taxa_iss_id}
                    helperText={this.state.errors.taxa_iss_id}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  >
                    {this.state.taxas.map((option, key) => (
                      <MenuItem key={key} value={option.id}>
                        {option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    label="Valor da obra"
                    name="valor_da_obra"
                    defaultValue={this.state.valor_da_obra}
                    value={this.state.valor_da_obra}
                    onChange={this.handleChange}
                    error={this.state.errors.valor_da_obra}
                    helperText={this.state.errors.valor_da_obra}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        checked={this.state.contribuinte_baixa_renda}
                        onChange={this.handleChange}
                        name="contribuinte_baixa_renda"
                      />
                    }
                    label="Baixa renda comprovada?"
                  />
                </Grid>
              </Grid>
              {this.state.contribuinte_baixa_renda &&
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="outlined-select-currency"
                      name="nis"
                      label="NIS"
                      onChange={this.handleChange}
                      fullWidth
                      defaultValue={this.state.nis || ""}
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                </Grid>
              }
            </CardBody>
            <CardFooter>
              <Grid container justify="center">
                <Grid item lg={2}>
                  <Button block color="info" round onClick={this.handleSave}>
                    {this.props.match.params.id ? "Atualizar" : "Adicionar"}
                  </Button>
                </Grid>
              </Grid>
            </CardFooter>
          </Card>
        </LoadingContent>
      </div>
    )
  }
}

export default withStyles(contribuinteStyle)(ProjetoForm)
