import { createMuiTheme } from "@material-ui/core/styles"

export default createMuiTheme({
  palette: {
    primary: {
      main: "#1D206F"
    },
    secondary: {
      main: "#EF6F6C"
    }
  }
  
})
