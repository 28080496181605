import React from "react"

import { withStyles } from "@material-ui/core"
import List from "@material-ui/icons/List"
import contribuinteStyle from "../../../assets/css/layout/contribuinteStyle"
import MenuDashboard from "../../MenuDashboard"
import GetApp from "@material-ui/icons/GetApp"

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import theme from "../../../assets/css/theme"

import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { cadastroDividaTabChanged } from "../../../actions"
import Refis from "./Refis"
import DividaMain from "./DividaMain"
import Refinanciamento from "./Refinanciamento"
import Pagamento from "./Pagamento"
import BaixaPagamento from "./BaixaPagamento"
import BaixaRefis from "./BaixaRefis"

const tab = [
  {
    name: "Dívidas Ativas",
    icon: <List />
  },
  {
    name: "Pagamento de dívida",
    icon: <List />
  },
  {
    name: "Baixa de Pagamento",
    icon: <GetApp />
  },
  {
    name: "REFIS",
    icon: <List />
  },
  {
    name: "Refinanciamento (REFIS)",
    icon: <List />
  },
  {
    name: "Baixa de Refinanciamento",
    icon: <GetApp />
  }
]

class Cadastro extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      field: 0
    }
    this.handleFieldChange = this.handleFieldChange.bind(this)
  }

  handleFieldChange(value) {
    this.props.cadastroDividaTabChanged(value)
  }
  render() {
    const { classes, field } = this.props
    return (
      <div className={classes.container}>
        <MuiThemeProvider theme={theme}>
          <MenuDashboard
            names={tab}
            onChange={this.handleFieldChange}
            value={field}
          />
          {field === 0 && <DividaMain />}
          {field === 1 && <Pagamento />}
          {field === 2 && <BaixaPagamento />}
          {field === 3 && <Refis />}
          {field === 4 && <Refinanciamento />}
          {field === 5 && <BaixaRefis />}
        </MuiThemeProvider>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    field: state.tabLayout.cadastroDividaTabSelected
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ cadastroDividaTabChanged }, dispatch)
}

Cadastro = connect(
  mapStateToProps,
  mapDispatchToProps
)(Cadastro)
export default withStyles(contribuinteStyle)(Cadastro)
