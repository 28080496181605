import React from "react"
import numeral from "../../../util/numeral"
import Warning from "@material-ui/icons/Warning"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"
import { search as searchImovel } from "../../../util/imovel"
import AsyncSelect from "../../../components/AsyncSelect"
import moment from "moment"
import SearchFields from "../../../components/SearchFields"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Button from "../../../components/CustomButtons/Button"

import contribuinteStyle from "../../../assets/css/layout/contribuinteStyle"
import { withStyles } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import { showNotification, mapErrors, showWaiting, hideWaiting } from "../../../util/Constants"
import { post, print } from "../../../util/parcelasUnicasDeIPTU"
import SelectCustom from "../../../components/SelectCustom"

class ParcelaUnicaForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            imoveis: [],
            id_imovel: 0,
            ids_parcelas: [],
            errors: {},
            lancamentos: [],
            id_lancamentos: 0,
            parcelas: [],
            id_parcelas: 0,
            vencimento: moment(new Date()).format("YYYY-MM-DD"),
            modal: false
        }
        this.loadImovel = this.loadImovel.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.getImovelById = this.getImovelById.bind(this)
        this.getParcelaLancById = this.getParcelaLancById.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.getParcela = this.getParcela.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.getCarne = this.getCarne.bind(this)
    }

    handleOpen() {
        this.setState({
            modal: true
        })
    }

    handleClose() {
        this.setState({
            modal: false
        }, () => {
            this.props.history.push("/iptu/movimentos")
        })
    }

    getCarne() {
        showWaiting()
        print(
            this.state.parcelaUnicaId,
            (error, data) => {
                hideWaiting()
                if (error) {
                    const reader = new FileReader()
                    reader.addEventListener("loadend", e => {
                        const text = e.srcElement.result
                        try {
                            let error = JSON.parse(text)
                            showNotification("top", "center", error.message, "warning")
                        } catch (e) {
                            showNotification(
                                "top",
                                "center",
                                "Ocorreram erros, verifique sua conexão com a internet",
                                "danger"
                            )
                        }
                    })
                    reader.readAsText(error.response.data)
                } else {
                    var url = URL.createObjectURL(data)
                    let tempLink = document.createElement("a")
                    tempLink.setAttribute("target", "_blank")
                    tempLink.setAttribute("href", `${url}`)
                    tempLink.setAttribute("style", "display:none;")
                    document.body.appendChild(tempLink)
                    tempLink.click()
                    document.body.removeChild(tempLink)
                }
            }
        )
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        errors[name] = undefined
        this.setState(
            {
                [name]: event.target.value,
                errors: errors
            },
            () => {
                if (this.state.id_imovel && name === "id_imovel") {
                    let imovel = this.getImovelById(this.state.id_imovel.value)
                    this.setState({
                        lancamentos: imovel.lancamentos.filter((lancamento) => {
                            return ((lancamento.ano + '') === ((new Date).getFullYear() + ''))
                        })
                    })
                }
                if (this.state.id_lancamentos && name === "id_lancamentos") {
                    let parcelaLancamento = this.getParcelaLancById(
                        this.state.id_lancamentos
                    )
                    if (parcelaLancamento.pago === true) {
                        showNotification(
                            "top",
                            "center",
                            `IPTU pago não pode ser unificado.`,
                            "danger"
                        )
                        this.setState({
                            parcelas: false,
                        })
                    }else{
                    this.setState({
                        parcelas: parcelaLancamento.parcelas,
                        lancadoNaDivida: parcelaLancamento.lancado_na_divida,
                        divida_id: parcelaLancamento.divida_id
                    })
                }}
                if (this.state.id_parcelas && name === "id_parcelas") {
                    let parcela = this.getParcela(this.state.id_parcelas)
                    this.setState({
                        data_vencimentoAntiga: parcela.vencimento
                    })
                }
            }
        )
    }

    handleSave(event) {
        event.preventDefault()
        showWaiting()
        let params = {
            vencimento: this.state.vencimento,
            parcelas: this.state.ids_parcelas.map((parcela) => parcela.value),
            lancamento_id: this.state.id_lancamentos
        }
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    `Ocorreram erros! ${error.response.data.message}`,
                    "danger"
                )
                const parcelasErrorKey = Object.keys(mapErrors(error)).filter((errorKey) => {
                    return errorKey.includes('parcelas.')
                })[0]
                var errors = mapErrors(error)
                errors['parcelas'] = errors[parcelasErrorKey];
                this.setState({
                    errors: errors
                })
            } else {
                showNotification(
                    "top",
                    "center",
                    `Parcelas unificadas com sucesso`,
                    "success"
                )
                this.setState({
                    parcelaUnicaId: data.id
                }, () => {
                    this.handleOpen()
                })

            }
        }
        post(params, response)
    }

    loadImovel(inputValue, callback) {
        searchImovel(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                    callback([])
                } else {
                    let imoveis = data.data.map(imovel => {
                        return {
                            value: imovel.id,
                            label: `Insc.:${
                                imovel.id
                                } - Endereço: ${imovel.logradouro_correspondencia ||
                                ""}, ${imovel.bairro_correspondencia ||
                                ""}, ${imovel.cidade_correspondencia ||
                                ""}, ${imovel.cep_correspondencia ||
                                ""} - Insc. Cart.: ${imovel.inscricao_cartografica ||
                                ""} - Contribuinte: ${imovel.contribuinte.nome_razao_social}`
                        }
                    })
                    this.setState({
                        imoveis: data.data
                    })
                    callback(imoveis)
                }
            }
        )
    }

    handleselectedFile = event => {
        this.setState({
            arquivo_de_baixa: event.target.files[0]
        })
    }

    getImovelById(id) {
        return this.state.imoveis.filter(item => {
            return id === item.id
        })[0]
    }

    getParcelaLancById(id) {
        return this.state.lancamentos.filter(item => {
            return id === item.id
        })[0]
    }

    getParcela(id) {
        return this.state.parcelas.filter(item => {
            return id === item.id
        })[0]
    }


    render() {
        const { classes } = this.props
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "Inscrição" },
            { value: "inscricao_cartografica", label: "Inscrição Cartográfica" },
            { value: "contribuinte_id", label: "Código do contribuinte" },
            {
                value: "contribuinte_nome_razao_social",
                label: "Nome/Razão Social do Contribuinte"
            },
            { value: "contribuinte_cpf_cnpj", label: "Documento do Contribuinte" },
            { value: "bairro", label: "Bairro" },
            { value: "contribuinte_endereco", label: "Endereço do Contribuinte" },
            { value: "logradouro_id", label: "Código do logradouro" },
            { value: "secao_id", label: "Código da Seção" },
            { value: "logradouro", label: "Logradouro" },
            { value: "complemento", label: "Complemento" },
            { value: "quadra", label: "Quadra" },
            { value: "lote", label: "Lote" },
            { value: "loteamento", label: "Loteamento" }
        ]
        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>
                                    Unificar parcelas de IPTU
                                </h2>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <Typography color="textSecondary" variant="caption">
                            Altere a data de vencimento de parcelas de lançamentos
            </Typography>
                        {!this.props.fromModal && (
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <AsyncSelect
                                        className={
                                            `form-control form-control-alternative form-select  ${
                                            this.props.error ? "has-danger" : ""
                                            }`
                                        }
                                        value={this.state.id_imovel}
                                        name="id_imovel"
                                        onChange={this.handleChange}
                                        loadOptions={this.loadImovel}
                                        placeholder="Imóvel"
                                        message="Pesquise o Imóvel"
                                    />
                                    <SearchFields
                                        name="filterField"
                                        value={this.state.filterField || ""}
                                        onChangeFields={this.handleChange}
                                        fields={fields}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        <Grid container direction="row" spacing={16}>
                            {(this.state.id_imovel !== 0 || this.props.fromModal) && (
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        required
                                        name="id_lancamentos"
                                        label="Lançamento"
                                        fullWidth
                                        value={this.state.id_lancamentos}
                                        error={this.state.errors.id_lancamentos}
                                        helperText={this.state.errors.id_lancamentos}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                    >
                                        {this.state.lancamentos.map((option, key) => (
                                            <MenuItem key={key} value={option.id}>
                                                Ano: {option.ano} Valor IPTU: R$
                        {numeral(parseFloat(option.valor_iptu))
                                                    .format("0.0,00")
                                                    .slice(0, -2)}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            )}

                            {this.state.parcelas.length > 0 && (
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <SelectCustom
                                        name="ids_parcelas"
                                        isMulti
                                        isClearable={true}
                                        onChange={this.handleChange}
                                        error={this.state.errors.parcelas}
                                        helperText={this.state.errors.parcelas}
                                        options={this.state.parcelas.filter((parcela) => {
                                            return (!parcela.pago && parcela.tipo_parcela.ordem != 0)
                                        }).map((parcela) => {
                                            return {
                                                value: parcela.id, label: parcela.tipo_parcela.descricao + " -  R$ " + parcela.valor
                                            }
                                        })}
                                        placeholder="Parcelas"
                                        message="Selecione as parcelas que deseja unificar"
                                    />
                                </Grid>
                            )}
                        </Grid>
                        {this.state.ids_parcelas.length > 1 && (
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                        id="date"
                                        label="Vencimento"
                                        margin="normal"
                                        variant="outlined"
                                        type="date"
                                        fullWidth
                                        value={this.state.vencimento}
                                        defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                                        helperText={this.state.errors.vencimento}
                                        error={this.state.errors.vencimento}
                                        name="vencimento"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </CardBody>
                    <CardFooter>
                        <Grid container justify="center">
                            <Grid item lg={this.state.lancadoNaDivida ? 6 : 2}>
                                <Button
                                    block
                                    color={this.state.lancadoNaDivida ? "danger" : "info"}
                                    disabled={this.state.lancadoNaDivida || this.state.imoveis.length === 0}
                                    round
                                    onClick={this.handleSave}
                                >
                                    {this.state.lancadoNaDivida ? (
                                        <div>
                                            <div>
                                                <Warning /> O Lançamento Selecionado foi lançado na
                                                dívida
                      </div>
                                            <div>Código da dívida ativa: {this.state.divida_id}</div>
                                        </div>
                                    ) : (
                                            <div>
                                                <AddIcon /> Unificar
                    </div>
                                        )}
                                </Button>
                            </Grid>
                        </Grid>
                    </CardFooter>
                </Card>

                <Dialog
                    open={this.state.modal}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    style={{ zIndex: "3" }}
                >
                    <DialogTitle id="form-dialog-title">Deseja imprimir o carnê de IPTU?</DialogTitle>
                    <DialogActions>
                        <Button block color="danger" round onClick={this.handleClose}>
                            Cancelar
            </Button>
                        <Button block color="success" round onClick={this.getCarne}>
                            Imprimir
            </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withStyles(contribuinteStyle)(ParcelaUnicaForm)
