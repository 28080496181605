import React from "react"
import { Grid } from "@material-ui/core";
import Error404 from "../assets/img/permissions/404.svg"
export class NotFound extends React.Component {
    render() {
        return (
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item justify="center" alignItems="center" lg={12} xs={12} className="text-center">
                    <img alt="" src={Error404} className="img img-fluid" style={{ height: 400 }} />
                </Grid>
                <h1 className="text-center mt-4">
                    Não existe página para essa URL
                </h1>
            </Grid>
        )
    }
}

export default NotFound