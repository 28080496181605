import React from "react"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import Radio from "@material-ui/core/Radio"
import AddIcon from "@material-ui/icons/Add"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import AsyncSelect from "../../../components/AsyncSelect"
import MobileStepper from "@material-ui/core/MobileStepper"
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DecimalFormatCustom from "../../../components/DecimalFormatCustom"
import Card from "../../../components/Card/Card"
import CardBody from "../../../components/Card/CardBody"
import secaoStyle from "../../../assets/css/layout/secaoStyle"
import { withStyles, AppBar, Tabs, Tab, FormLabel } from "@material-ui/core"
import Button from "../../../components/CustomButtons/Button"
import { getFields, post, edit, put } from "../../../util/imovel"
import { showNotification, mapErrors, showWaiting, hideWaiting } from "../../../util/Constants"
import { search as searchContribuinte } from "../../../util/contribuinte"
import { search as searchLogradouro } from "../../../util/logradouro"
import { search as searchBairro } from "../../../util/bairro"
import { post as transfDivida } from "../../../util/dividaTrans"
import SearchFields from "../../../components/SearchFields"
import LoadingContent from "../../../components/LoadingContent"

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import theme from "../../../assets/css/theme"
import { getCep } from "../../../util/cep"

import InputMask from "react-input-mask"
import housecall from "housecall"
import SelectCustom from "../../../components/SelectCustom"

const tab = [{ name: "Início" }, { name: "Localização imóvel" }, { name: "Imóvel" }, { name: "Informações do Lote" }, { name: "Medidas do Imóvel" }, { name: "Edificação" }, { name: "Unidades" }, { name: "Anotações" }]

class ImoveisForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      value: 0,
      bairros: [],
      //
      distritos: [],
      id_distritos: "",
      //

      status: [],
      id_status: "",

      //
      naturezas: [],
      id_naturezas: "",
      //

      benfeitorias: [],
      id_benfeitorias: "",
      //
      loteamentos: [],
      id_loteamento: "",
      //

      tipos_de_esquadria: [],
      id_tipos_de_esquadria: "",

      //
      tipos_abastecimento_de_agua: [],
      id_tipos_abastecimento_de_agua: "",

      //
      id_acabamento_interno: "",
      //
      tipos_de_reservatorio_de_agua: [],
      id_tipos_de_reservatorio_de_agua: "",

      //
      tipos_de_patrimonio: [],
      id_tipos_de_patrimonio: "",
      //
      posicoes_fiscais: [],
      id_posicoes_fiscais: "",
      //
      adequacoes_para_ocupacao: [],
      id_adequacoes_para_ocupacao: "",
      //
      situacoes_de_lote: [],
      id_situacoes_de_lote: "",
      //
      topografias: [],
      id_topografias: "",
      //
      tipos_de_ocupacao: [],
      id_tipos_de_ocupacao: "",
      //
      tipos_de_edificacao: [],
      id_tipos_de_edificacao: "",
      //
      tipos_de_acabamento: [],
      id_acabamento_externo: "",
      //
      situacao_edificacao: [],
      id_situacao_edificacao: "",
      //
      posicoes_patrimoniais: [],
      id_posicoes_patrimoniais: "",
      //
      tipos_de_sanitarios: [],
      id_tipos_de_sanitarios: "",
      //
      tipos_de_estrutura: [],
      id_tipos_de_estrutura: "",
      //
      situacoes_dos_lados: [],
      id_situacoes_dos_lados: "",
      //
      tipos_de_cobertura: [],
      id_tipos_de_cobertura: "",
      //
      classificacoes_arquitetonicas: [],
      id_classificacoes_arquitetonicas: "",
      //
      tipos_de_utilizacao: [],
      id_tipos_de_utilizacao: "",
      //
      estados_de_conservacao: [],
      id_estados_de_conservacao: "",
      //
      tipos_de_piso: [],
      id_tipos_de_piso: "",
      //
      tipos_de_forro: [],
      id_tipos_de_forro: "",
      //
      tipos_de_instalacao_eletrica: [],
      id_tipos_de_instalacao_eletrica: "",
      //
      tipos_de_instalacao_sanitaria: [],
      id_tipos_de_instalacao_sanitaria: "",
      //
      //
      contribuintes: [],
      id_contribuintes: "",
      logradouro_contribuinte: "",
      bairro_contribuinte: "",
      cep_contribuinte: "",
      cidade_contribuinte: "",
      uf_contribuinte: "",
      //
      radioEndereco: "",
      secoesLogradouro: [],
      id_secoes: "",
      id_bairro: "",
      id_logradouro: "",
      logradouros: [],
      cep_imovel: "",
      // loteamentos: [],
      // id_loteamento: 0,
      quadra: "",
      lote: "",
      id_acabamentoInterno: "",
      //
      salas: 0,
      quartos: 0,
      banheiros: 0,
      cozinhas: 0,
      pontos_de_agua: 0,
      inscricao_cartografica: "",
      inscricao_anterior: "",
      anotacoes: "",
      complemento: "",
      testada_principal: "",
      fundos: "",
      area_lote: "",
      area_privativa: "",
      area_comum: "",
      area_total_edificada: "",
      numero: '',
      //id_atributos_especiais: [],
      //atributos_especiais: [],
      tipos_de_parede: [],
      id_tipos_de_parede: "",
      editar_observacao: false,

      modal: false,
      contribuinte_change: false,
      motivo_transf: "",
      shouldOpen: false,
      imov_edit: ""
    }
    this.getFields = this.getFields.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
    this.loadLogradouro = this.loadLogradouro.bind(this)
    this.loadBairro = this.loadBairro.bind(this)
    this.getContribuinteById = this.getContribuinteById.bind(this)
    this.getLogradouroById = this.getLogradouroById.bind(this)
    this.setBooleanValue = this.setBooleanValue.bind(this)
    this.handleTab = this.handleTab.bind(this)
    this.getInfoCep = this.getInfoCep.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.transfDivida = this.transfDivida.bind(this)

  }

  componentWillMount() {
    this.getFields()
  }

  handleOpen() {
    this.setState({ modal: true });
  };

  handleClose() {
    this.setState({ modal: false }, () => { this.props.history.push("/iptu/cadastro") });
  };

  handleNext = () => {
    this.setState(state => ({
      value: state.value + 1,
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      value: state.value - 1,
    }));
  };

  loadOptions(inputValue, callback) {
    searchContribuinte(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {

          let contribuintes = data.data.map(contribuinte => {
            return {
              value: contribuinte.id,
              label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                ""} - ${contribuinte.cpf_cnpj || ""}`
            }
          })
          this.setState({
            contribuintes: data.data
          }, () => {
            callback(contribuintes)
          })

        }
      }
    )
  }

  loadLogradouro(inputValue, callback) {
    searchLogradouro(inputValue, undefined, undefined, (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
        callback([])
      } else {
        let lograd = data.data.map(logradouro => {
          return { value: logradouro.id, label: logradouro.nome }
        })
        this.setState({
          logradouros: data.data
        })
        callback(lograd)
      }
    })
  }

  loadBairro(inputValue, callback) {
    searchBairro(inputValue, undefined, undefined, (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
        callback([])
      } else {
        let bairros = data.data.map(bairro => {
          return {
            value: bairro.id,
            label: bairro.nome
          }
        })
        callback(bairros)
      }
    })
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined

    console.log("s")
    this.setState(
      {
        [name]: event.target.value,
        errors: errors
      },
      () => {

        if (
          this.state.radioEndereco === "contribuinte" &&
          this.state.id_contribuintes
        ) {

          let contribuinte = this.getContribuinteById(
            this.state.id_contribuintes.value
          )
          if (contribuinte !== undefined) {

            this.setState({
              logradouro_contribuinte: contribuinte.endereco,
              bairro_contribuinte: contribuinte.bairro,
              cep_contribuinte: contribuinte.cep,
              cidade_contribuinte: contribuinte.cidade,
              uf_contribuinte: contribuinte.uf
            })
          }
        } else if (name === "radioEndereco") {
          this.setState({
            logradouro_contribuinte: "",
            bairro_contribuinte: "",
            cep_contribuinte: "",
            cidade_contribuinte: "",
            uf_contribuinte: ""
          })
        }
        if (this.state.id_logradouro && name === "id_logradouro") {
          let sec = this.getLogradouroById(this.state.id_logradouro.value)
          this.setState({
            secoesLogradouro: sec.secoes
          })
        }
        if (name === "cep_contribuinte") {
          (this.getInfoCep(this.state.cep_contribuinte))
        }
        if (this.props.match.params.id && name === "id_contribuintes") {
          this.setState({ contribuinte_change: true })
        }
      }
    )
  }

  handleTab(event, value) {
    this.setState({ value })
  }

  getContribuinteById(id) {
    return this.state.contribuintes.filter(item => {
      return id === item.id
    })[0]
  }

  getLogradouroById(id) {
    return this.state.logradouros.filter(item => {
      return id === item.id
    })[0]
  }
  mensagemError = (mensagem) => {
    showNotification(
      "top",
      "center",
      mensagem,
      "danger")
  }
  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let params = {
      inscricao_cagece: this.state.inscricao_cagece,
      inscricao_coelce: this.state.inscricao_coelce,
      setor_censitario: this.state.setor_censitario,
      arvores_no_passeio: this.state.arvores_no_passeio === "true" ? 1 : 0,
      matricula_cartorio: this.state.matricula_cartorio,
      lado_direito: this.state.lado_direito,
      lado_esquerdo: this.state.lado_esquerdo,
      largura_passeio: this.state.largura_passeio,
      afastamento_frontal: this.state.afastamento_frontal,
      pavimentos: this.state.pavimentos,
      frentes: this.state.frentes,
      unidades: this.state.unidades,
      terreno_impermeavel: this.state.terreno_impermeavel === "true" ? 1 : 0,

      salas: this.state.salas,
      quartos: this.state.quartos,
      banheiros: this.state.banheiros,
      cozinhas: this.state.cozinhas,
      pontos_de_agua: this.state.pontos_de_agua,

      status_id: this.state.id_status,
      benfeitoria_id: this.state.id_benfeitorias,
      loteamento_id: this.state.id_loteamento,
      posicao_patrimonial_id: this.state.id_posicoes_patrimoniais,
      tipo_edificacao_id: this.state.id_tipos_de_edificacao,
      tipo_acabamento_externo_id: this.state.id_acabamento_externo,
      tipo_acabamento_interno_id: this.state.id_acabamentoInterno,
      tipo_reservatorio_de_agua_id: this.state.id_tipos_de_reservatorio_de_agua,
      tipo_sanitario_id: this.state.id_tipos_de_sanitarios,
      tipo_esquadria_id: this.state.id_tipos_de_esquadria,
      tipo_abastecimento_de_agua_id: this.state.id_tipos_abastecimento_de_agua,

      inscricao_cartografica: this.state.inscricao_cartografica,
      inscricao_anterior: this.state.inscricao_anterior,
      contribuinte_id: this.state.id_contribuintes.value,
      logradouro_correspondencia: this.state.logradouro_contribuinte,
      bairro_correspondencia: this.state.bairro_contribuinte,
      cep_correspondencia: this.state.cep_contribuinte,
      cidade_correspondencia: this.state.cidade_contribuinte,
      uf_correspondencia: this.state.uf_contribuinte,
      logradouro_id: this.state.id_logradouro.value,
      secao_id: this.state.id_secoes,
      bairro_id: this.state.id_bairro.value,
      distrito_id: this.state.id_distritos,
      quadra: this.state.quadra,
      lote: this.state.lote,
      numero: this.state.numero,
      natureza_id: this.state.id_naturezas,
      tipo_patrimonio_id: this.state.id_tipos_de_patrimonio,
      posicao_fiscal_id: this.state.id_posicoes_fiscais,
      adequacao_para_ocupacao_id: this.state.id_adequacoes_para_ocupacao,
      situacao_lote_id: this.state.id_situacoes_de_lote,
      topografia_id: this.state.id_topografias,
      ocupacao_id: this.state.id_tipos_de_ocupacao,
      testada_principal: this.state.testada_principal,
      fundos: this.state.fundos,
      area_lote: this.state.area_lote,
      area_privativa: this.state.area_privativa,
      area_comum: this.state.area_comum,
      area_total_edificada: this.state.area_total_edificada,
      situacao_edificacao_id: this.state.id_situacao_edificacao,
      tipo_estrutura_id: this.state.id_tipos_de_estrutura,
      situacao_lados_id: this.state.id_situacoes_dos_lados,
      tipo_cobertura_id: this.state.id_tipos_de_cobertura,
      classificacao_arquitetonica_id: this.state.id_classificacoes_arquitetonicas,
      tipo_utilizacao_id: this.state.id_tipos_de_utilizacao,
      estado_conservacao_id: this.state.id_estados_de_conservacao,
      tipo_piso_id: this.state.id_tipos_de_piso,
      tipo_instalacao_eletrica_id: this.state.id_tipos_de_instalacao_eletrica,
      tipo_instalacao_sanitaria_id: this.state.id_tipos_de_instalacao_sanitaria,
      tipo_forro_id: this.state.id_tipos_de_forro,
      tipo_de_parede_id: this.state.id_tipos_de_parede,
      anotacoes: this.state.anotacoes,
      complemento: this.state.complemento,
      complemento_correspondencia: this.state.complemento_correspondencia,

      atributos_especiais: (this.state.id_atributos_especiais || []).map(atributos => {
        return atributos.value
      }),
      tipos_passeio_pedestre: (this.state.id_tipos_passeio || []).map(item => {
        return item.value
      }),

    }
    let response = (error, data) => {
      var a = {
        "inscricao_cartografica": " Inscrição cartográfica",
        "status_id": " Situação",
        "contribuinte_id": " Contribuinte",
        "logradouro_correspondencia": " Logradouro da Correspondencia",
        "bairro_correspondencia": " Bairro da Correspondencia",
        "cep_correspondencia": " Cep da Correspondencia",
        "cidade_correspondencia": " Cidade da Correspondencia",
        "uf_correspondencia": " UF da Correspondencia",
        "logradouro_id": " Logradouro do Imovel",
        "secao_id": " Seção do Imovel",
        "bairro_id": " Bairro do Imovel",
        "distrito_id": " Distrito do Imovel",
        "natureza_id": " Naturezas do Imovel",
        "tipo_patrimonio_id": " Patrimonio do Imovel",
        "posicao_patrimonial_id": " Posição Patriomonial",
        "posicao_fiscal_id": " Posição fiscal do Imovel",
        "adequacao_para_ocupacao_id": " Adequeção para ocupação do Lote",
        "situacao_lote_id": " Situação do Lote",
        "topografia_id": " Topografia do Lote",
        "benfeitoria_id": " Benfeitoria do Lote",
        "ocupacao_id": " Ocupação do Lote",
        "area_lote": " Área do Lote",
        "frentes": " Numero de frentes do Imovel"

      }
      hideWaiting()
      if (error) {
        let ObjErrors = Object.keys(error.response.data.errors)
        ObjErrors.map(key => {
          this.mensagemError(a[key] + " " + error.response.data.errors[key].join())
        })
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        showNotification(
          "top",
          "center",
          `Imóvel ${
          this.props.match.params.id ? "editado" : "incluído"
          } com sucesso`,
          "success"
        )
        let shouldTra = data.deve_transferir
        if (this.state.id_posicoes_fiscais === 3) {
          this.props.history.push("/iptu/cadastro")
        } else {
          if (this.props.match.params.id && this.state.contribuinte_change === true && shouldTra === true) {
            this.handleOpen()
          } else {
            this.props.history.push("/iptu/cadastro")
          }
        }


      }
    }
    this.props.match.params.id
      ? put(this.props.match.params.id, params, response)
      : post(params, response)
  }

  setBooleanValue(value) {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true
      if (value.toLowerCase() === "false") return false
    }
    return value
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  getFields() {
    this.toggleLoading()
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        if (this.props.match.params.id) {
          this.setState({
            editar_observacao: true
          })

          if (data.imovel.bairro != null) {
            data.imovel.id_bairro = {
              value: data.imovel.bairro.id,
              label: data.imovel.bairro.nome
            }
          }

          if (data.imovel.logradouro != null) {
            data.imovel.id_logradouro = {
              value: data.imovel.logradouro.id,
              label: data.imovel.logradouro.nome
            }
            data.imovel.logradouro.secoesLogradouro = data.imovel.logradouro.secoes
            data.imovel.logradouro_contribuinte = data.imovel.logradouro_correspondencia
            data.imovel.secoesLogradouro = data.imovel.logradouro.secoes
          }

          if (data.imovel.contribuinte != null) {
            data.imovel.id_contribuintes = {
              value: data.imovel.contribuinte.id,
              label: `${data.imovel.contribuinte.id} - ${data.imovel.contribuinte.nome_razao_social ||
                ""} - ${data.imovel.contribuinte.cpf_cnpj || ""}`,
            }
          }

          data.imovel.bairro_contribuinte = data.imovel.bairro_correspondencia
          data.imovel.cep_contribuinte = data.imovel.cep_correspondencia
          data.imovel.cidade_contribuinte = data.imovel.cidade_correspondencia
          data.imovel.uf_contribuinte = data.imovel.uf_correspondencia
          data.imovel.id_secoes = data.imovel.secao_id
          data.imovel.id_distritos = data.imovel.distrito_id
          data.imovel.id_situacoes_dos_lados = data.imovel.situacao_lados_id
          data.imovel.id_tipos_de_cobertura = data.imovel.tipo_cobertura_id
          data.imovel.id_tipos_de_estrutura = data.imovel.tipo_estrutura_id
          data.imovel.id_loteamento = data.imovel.loteamento_id
          data.imovel.id_naturezas = data.imovel.natureza_id
          data.imovel.id_tipos_de_patrimonio = data.imovel.tipo_patrimonio_id
          data.imovel.id_tipos_de_ocupacao = data.imovel.ocupacao_id
          data.imovel.id_adequacoes_para_ocupacao = data.imovel.adequacao_para_ocupacao_id
          data.imovel.id_benfeitorias = data.imovel.benfeitoria_id
          data.imovel.id_estados_de_conservacao =
            data.imovel.estado_conservacao_id
          data.imovel.id_tipos_de_piso = data.imovel.tipo_piso_id
          data.imovel.id_topografias = data.imovel.topografia_id
          data.imovel.id_tipos_de_forro = data.imovel.tipo_forro_id
          data.imovel.id_classificacoes_arquitetonicas = data.imovel.classificacao_arquitetonica_id
          data.imovel.id_tipos_de_instalacao_eletrica = data.imovel.tipo_instalacao_eletrica_id
          data.imovel.id_tipos_de_instalacao_sanitaria = data.imovel.tipo_instalacao_sanitaria_id
          data.imovel.id_tipos_de_utilizacao = data.imovel.tipo_utilizacao_id
          data.imovel.id_tipos_de_forro = data.imovel.tipo_forro_id
          data.imovel.id_situacao_edificacao =
            data.imovel.situacao_edificacao_id
          data.imovel.id_situacoes_de_lote =
            data.imovel.situacao_lote_id
          data.imovel.id_status =
            data.imovel.status_id
          data.imovel.id_tipos_de_parede = data.imovel.tipo_de_parede_id

          data.imovel.id_posicoes_fiscais = data.imovel.posicao_fiscal_id
          data.imovel.terreno_impermeavel = data.imovel.terreno_impermeavel ? 'true' : 'false'
          data.imovel.arvores_no_passeio = data.imovel.arvores_no_passeio ? 'true' : 'false'
          data.imovel.id_posicoes_patrimoniais = data.imovel.posicao_patrimonial_id
          data.imovel.id_tipos_de_edificacao = data.imovel.tipo_edificacao_id
          data.imovel.id_acabamento_externo = data.imovel.tipo_acabamento_externo_id
          data.imovel.id_acabamentoInterno = data.imovel.tipo_acabamento_interno_id
          data.imovel.id_tipos_de_reservatorio_de_agua = data.imovel.tipo_reservatorio_de_agua_id
          data.imovel.id_tipos_de_sanitarios = data.imovel.tipo_sanitario_id
          data.imovel.id_tipos_de_esquadria = data.imovel.tipo_esquadria_id
          data.imovel.id_tipos_abastecimento_de_agua = data.imovel.tipo_abastecimento_de_agua_id
          if (data.imovel.tipos_passeio_pedestre != null) {
            data.imovel.id_tipos_passeio = (data.imovel.tipos_passeio_pedestre || []).map(
              item => {
                return { value: item.id, label: item.nome }
              }
            )
          }
          if (data.imovel.atributos_especiais != null) {
            data.imovel.id_atributos_especiais = (data.imovel.atributos_especiais || []).map(
              atrib => {
                return { value: atrib.id, label: atrib.nome }
              }
            )
          }
          delete data.imovel.atributos_especiais
          delete data.imovel.situacao_edificacao
          delete data.imovel.tipos_de_parede
        }

        this.setState({
          distritos: data.distritos,
          loteamentos: data.loteamentos,
          naturezas: data.naturezas,
          numero: data.numero,
          topografias: data.topografias,
          tipos_de_sanitarios: data.tipos_de_sanitarios,
          tipos_abastecimento_de_agua: data.tipos_abastecimento_de_agua,
          tipos_de_reservatorio_de_agua: data.tipos_de_reservatorio_de_agua,
          benfeitorias: data.benfeitorias,
          tipos_de_esquadria: data.tipos_de_esquadria,
          tipos_de_ocupacao: data.tipos_de_ocupacao,
          situacoes_de_lote: data.situacoes_de_lote,
          situacoes_dos_lados: data.situacoes_dos_lados,
          tipos_de_estrutura: data.tipos_de_estrutura,
          tipos_de_edificacao: data.tipos_de_edificacao,
          tipos_de_acabamento: data.tipos_de_acabamento,
          situacao_edificacao: data.situacao_edificacao,
          tipos_de_parede: data.tipos_de_parede,
          tipos_de_cobertura: data.tipos_de_cobertura,
          posicoes_fiscais: data.posicoes_fiscais,
          posicoes_patrimoniais: data.posicoes_patrimoniais,
          tipos_de_patrimonio: data.tipos_de_patrimonio,
          adequacoes_para_ocupacao: data.adequacoes_para_ocupacao,
          classificacoes_arquitetonicas: data.classificacoes_arquitetonicas,
          tipos_de_utilizacao: data.tipos_de_utilizacao,
          estados_de_conservacao: data.estados_de_conservacao,
          tipos_de_piso: data.tipos_de_piso,
          tipos_de_forro: data.tipos_de_forro,
          status: data.status,
          tipos_de_instalacao_eletrica: data.tipos_de_instalacao_eletrica,
          tipos_de_instalacao_sanitaria: data.tipos_de_instalacao_sanitaria
        })
        let tiposPasseio = data.tipos_passeio.map(passeio => {
          return { value: passeio.id, label: passeio.nome }
        })
        let atributosEspeciais = data.atributos_especiais.map(atributo => {
          return { value: atributo.id, label: atributo.nome }
        })

        let newState = {
          tipos_passeio: tiposPasseio,
          atributos_especiais: atributosEspeciais,
          ...data.imovel
        }

        this.setState(newState, () => {
          this.toggleLoading()
        })
      }
    }
    if (this.props.match.params.id) {
      edit(this.props.match.params.id, response)
    } else {
      getFields(response)
    }
  }

  getInfoCep(cep) {
    getCep(cep, (error, data) => {
      if (data) {
        this.setState({
          logradouro_contribuinte: data.logradouro,
          bairro_contribuinte: data.bairro,
          uf_contribuinte: data.uf,
          complemento: data.complemento,
          cidade_contribuinte: data.localidade
        })
      }
    })
  }

  transfDivida(event) {
    event.preventDefault()
    showWaiting()
    let params = {
      imovel_id: this.props.match.params.id,
      antigo_id: this.state.id_contribuintes.value,
      motivo: this.state.motivo_transf
    }
    let response = error => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        showNotification(
          "top",
          "center",
          `Dívidas Transferidas com sucesso`,
          "success"
        )
        this.handleClose()

      }
    }
    transfDivida(params, response)
  }

  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do contribuinte" },
      { value: "cpf_cnpj", label: "Documento" },
      { value: "endereco", label: "Endereço" },
      { value: "nome_fantasia", label: "Nome Fantasia" },
      { value: "nome_razao_social", label: "Razão Social" }
    ]
    return (
      <div className={classes.container}>
        <LoadingContent isLoading={this.state.isLoading}>
          <Card>
            <MuiThemeProvider theme={theme}>
              <AppBar position="static">
                <Tabs
                  value={this.state.value}
                  onChange={this.handleTab}
                  variant="scrollable"
                  scrollButtons="auto">
                  {tab.map((item, key) => (
                    <Tab key={key} label={item.name} />
                  ))}
                </Tabs>
              </AppBar>
              <CardBody>
                <form onSubmit={this.handleSave}>
                  {this.state.value === 0 && (
                    <div>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <InputMask
                            mask="9999999999999999"
                            value={this.state.inscricao_cartografica}
                            onChange={this.handleChange}
                          >
                            {() => (
                              <TextField

                                label="Inscrição Cartográfica"
                                name="inscricao_cartografica"
                                required
                                fullWidth
                                InputLabelProps={{
                                  shrink: true
                                }}
                                value={this.state.inscricao_cartografica}
                                onChange={this.handleChange}
                                error={this.state.errors.inscricao_cartografica}
                                helperText={this.state.errors.inscricao_cartografica}
                                margin="normal"
                                variant="outlined"
                              />
                            )}
                          </InputMask>

                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField

                            label="Inscrição Anterior"
                            name="inscricao_anterior"
                            type="number"
                            fullWidth
                            value={this.state.inscricao_anterior}
                            onChange={this.handleChange}
                            error={this.state.errors.inscricao_anterior}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_status"
                            label="Situação"
                            fullWidth
                            value={this.state.id_status}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            error={this.state.errors.status_id}
                            helperText={this.state.errors.status_id}
                          >
                            {this.state.status.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography color="textSecondary" variant="caption">
                            Pesquise o Contribuinte
                        </Typography>
                          <SearchFields
                            name="filterField"
                            value={this.state.filterField || ""}
                            onChangeFields={this.handleChange}
                            fields={fields}
                          />
                          <AsyncSelect
                            className={
                              `form-control form-control-alternative form-select Select-menu-outer ${
                              this.props.error ? "has-danger" : ""
                              }`
                            }
                            value={this.state.id_contribuintes}
                            onChange={this.handleChange}
                            loadOptions={this.loadOptions}
                            name="id_contribuintes"
                            placeholder="Contribuinte"
                            message="Pesquise o contribuinte"
                            error={this.state.errors.contribuinte_id}
                            helperText={this.state.errors.contribuinte_id}
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Divider />
                        <Typography color="textSecondary" variant="caption">
                          Endereço de correspondência
                      </Typography>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <RadioGroup
                            aria-label="position"
                            name="radioEndereco"
                            value={this.state.radioEndereco}
                            onChange={this.handleChange}
                            row
                          >
                            <FormControlLabel
                              value="contribuinte"
                              control={<Radio color="primary" />}
                              label="Contribuinte"
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value="imovel"
                              control={<Radio color="primary" />}
                              label="Imóvel"
                              labelPlacement="end"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField

                            label="Logradouro"
                            name="logradouro_contribuinte"
                            required
                            fullWidth
                            value={this.state.logradouro_contribuinte}
                            onChange={this.handleChange}
                            error={this.state.errors.logradouro_correspondencia}
                            helperText={this.state.errors.logradouro_correspondencia}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField

                            label="Bairro"
                            name="bairro_contribuinte"
                            required
                            fullWidth
                            value={this.state.bairro_contribuinte}
                            onChange={this.handleChange}
                            error={this.state.errors.bairro_correspondencia}
                            helperText={this.state.errors.bairro_correspondencia}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            label="CEP"
                            name="cep_contribuinte"
                            required
                            fullWidth
                            value={this.state.cep_contribuinte}
                            onChange={this.handleChange}
                            error={this.state.errors.cep_correspondencia}
                            helperText={this.state.errors.cep_correspondencia}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            label="Cidade"
                            name="cidade_contribuinte"
                            required
                            fullWidth
                            value={this.state.cidade_contribuinte}
                            onChange={this.handleChange}
                            error={this.state.errors.cidade_correspondencia}
                            helperText={this.state.errors.cidade_correspondencia}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <TextField

                            label="UF"
                            name="uf_contribuinte"
                            required
                            fullWidth
                            value={this.state.uf_contribuinte}
                            onChange={this.handleChange}
                            error={this.state.errors.uf_correspondencia}
                            helperText={this.state.errors.uf_correspondencia}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <TextField
                            label="Complemento de correspondencia"
                            name="complemento_correspondencia"
                            fullWidth
                            value={this.state.complemento_correspondencia || ""}
                            onChange={this.handleChange}
                            error={this.state.errors.complemento_correspondencia}
                            helperText={this.state.errors.complemento_correspondencia}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {this.state.value === 1 && (
                    <div>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <AsyncSelect
                            className={`form-control form-control-alternative form-select Select-menu-outer ${
                              this.props.error ? "has-danger" : ""
                              }`}
                            style={{ position: "relative" }}
                            value={this.state.id_logradouro}
                            onChange={this.handleChange}
                            loadOptions={this.loadLogradouro}
                            error={this.state.errors.logradouro_id}
                            helperText={this.state.errors.logradouro_id}
                            name="id_logradouro"
                            placeholder="Logradouro"
                            message="Pesquise o logradouro"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <AsyncSelect
                            className={
                              `form-control form-control-alternative form-select Select-menu-outer ${
                              this.props.error ? "has-danger" : ""
                              }`
                            }
                            value={this.state.id_bairro}
                            onChange={this.handleChange}
                            loadOptions={this.loadBairro}
                            name="id_bairro"
                            helperText={this.state.errors.bairro_id}
                            error={this.state.errors.bairro_id}
                            placeholder="Bairro"
                            message="Pesquise o bairro"
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_secoes"
                            label="Seção"
                            fullWidth
                            disabled={!(this.state.id_logradouro)}
                            value={this.state.id_secoes}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            error={this.state.errors.secao_id}
                            helperText={this.state.errors.secao_id}
                          >
                            {this.state.secoesLogradouro.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.secao}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_loteamento"
                            label="Loteamento"
                            fullWidth
                            value={this.state.id_loteamento}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            error={this.state.errors.loteamento_id}
                            helperText={this.state.errors.loteamento_id}
                          >
                            {this.state.loteamentos.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_distritos"
                            label="Distrito"
                            fullWidth
                            value={this.state.id_distritos}
                            error={this.state.errors.distrito_id}
                            helperText={this.state.errors.distrito_id}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.distritos.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="quadra"
                            label="Quadra"
                            type="text"
                            fullWidth
                            value={this.state.quadra}
                            error={this.state.errors.quadra}
                            helperText={this.state.errors.quadra}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="lote"
                            label="Lote"
                            type="text"
                            fullWidth
                            value={this.state.lote}
                            onChange={this.handleChange}
                            error={this.state.errors.lote}
                            helperText={this.state.errors.lote}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="numero"
                            label="Nº"
                            type="text"
                            fullWidth
                            value={this.state.numero}
                            onChange={this.handleChange}
                            error={this.state.errors.numero}
                            helperText={this.state.errors.numero}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>


                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="complemento"
                            label="Complemento"
                            type="text"
                            fullWidth
                            value={this.state.complemento}
                            onChange={this.handleChange}
                            error={this.state.errors.complemento}
                            helperText={this.state.errors.complemento}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {this.state.value === 2 && (
                    <div>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="inscricao_cagece"
                            label="Inscrição Cagece"
                            type="number"
                            fullWidth
                            value={this.state.inscricao_cagece}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="inscricao_coelce"
                            label="Inscrição Coelce"
                            type="number"
                            fullWidth
                            value={this.state.inscricao_coelce}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="setor_censitario"
                            label="Setor Censitário"
                            fullWidth
                            value={this.state.setor_censitario}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="matricula_cartorio"
                            label="Matrícula Cartório"
                            type="number"
                            fullWidth
                            value={this.state.matricula_cartorio}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_naturezas"
                            label="Natureza"
                            error={this.state.errors.natureza_id}
                            helperText={this.state.errors.natureza_id}
                            fullWidth
                            value={this.state.id_naturezas}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.naturezas.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_patrimonio"
                            label="Patrimônio"
                            fullWidth
                            error={this.state.errors.tipo_patrimonio_id}
                            helperText={this.state.errors.tipo_patrimonio_id}
                            value={this.state.id_tipos_de_patrimonio}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_patrimonio.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_posicoes_fiscais"
                            label="Posição Fiscal"
                            fullWidth
                            error={this.state.errors.posicao_fiscal_id}
                            helperText={this.state.errors.posicao_fiscal_id}
                            value={this.state.id_posicoes_fiscais}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.posicoes_fiscais.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_posicoes_patrimoniais"
                            label="Posição patrimonial"
                            fullWidth
                            error={this.state.errors.id_posicoes_patrimoniais}
                            helperText={this.state.errors.posicao_patrimonial_id}
                            value={this.state.id_posicoes_patrimoniais}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.posicoes_patrimoniais.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {this.state.value === 3 && (
                    <div>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_adequacoes_para_ocupacao"
                            label="Adequações p/ Ocupação"
                            fullWidth
                            error={this.state.errors.adequacao_para_ocupacao_id}
                            helperText={this.state.errors.adequacao_para_ocupacao_id}
                            value={this.state.id_adequacoes_para_ocupacao}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.adequacoes_para_ocupacao.map(
                              (option, key) => (
                                <MenuItem key={key} value={option.id}>
                                  {option.nome || "Nome não cadastrado"}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_benfeitorias"
                            label="Benfeitoria"
                            fullWidth
                            error={this.state.errors.id_benfeitorias}
                            value={this.state.id_benfeitorias}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.benfeitorias.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_situacoes_de_lote"
                            label="Situação do Lote"
                            fullWidth
                            error={this.state.errors.situacao_lote_id}
                            helperText={this.state.errors.situacao_lote_id}
                            value={this.state.id_situacoes_de_lote}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.situacoes_de_lote.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_topografias"
                            label="Topografias"
                            fullWidth
                            error={this.state.errors.topografia_id}
                            helperText={this.state.errors.topografia_id}
                            value={this.state.id_topografias}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.topografias.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                      </Grid>

                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            name="largura_passeio"
                            label="Largura do passeio"
                            fullWidth
                            error={this.state.errors.largura_passeio}
                            value={this.state.largura_passeio || ""}
                            defaultValue={this.state.largura_passeio || ""}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              inputComponent: DecimalFormatCustom
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_ocupacao"
                            label="Ocupação"
                            fullWidth
                            error={this.state.errors.ocupacao_id}
                            helperText={this.state.errors.ocupacao_id}
                            value={this.state.id_tipos_de_ocupacao}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_ocupacao.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <SelectCustom
                            value={this.state.id_tipos_passeio}
                            onChange={this.handleChange}
                            errors={this.state.errors.id_tipos_passeio}
                            options={this.state.tipos_passeio}
                            name="id_tipos_passeio"
                            placeholder="Passeio p/ Pedestre"
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <FormLabel component="legend">
                            Árvore no passeio
                        </FormLabel>
                          <RadioGroup
                            aria-label="arvore"
                            name="arvores_no_passeio"
                            value={this.state.arvores_no_passeio}
                            onChange={this.handleChange}
                            row
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio color="primary" />}
                              label="Sim"
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio color="primary" />}
                              label="Não"
                              labelPlacement="end"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {this.state.value === 4 && (
                    <div>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="testada_principal"
                            label="Testada Principal"
                            fullWidth
                            error={this.state.errors.testada_principal}
                            value={parseFloat(this.state.testada_principal)}
                            defaultValue={parseFloat(this.state.testada_principal)}
                            onChange={this.handleChange}
                            margin="normal"
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              inputComponent: DecimalFormatCustom
                            }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="lado_direito"
                            label="Lado direito"
                            fullWidth
                            error={this.state.errors.lado_direito}
                            helperText={this.state.errors.lado_direito}
                            value={parseFloat(this.state.lado_direito)}
                            defaultValue={parseFloat(this.state.lado_direito)}
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              inputComponent: DecimalFormatCustom
                            }}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="lado_esquerdo"
                            label="Lado Esquerdo"
                            fullWidth
                            error={this.state.errors.lado_esquerdo}
                            helperText={this.state.errors.lado_esquerdo}
                            value={parseFloat(this.state.lado_esquerdo)}
                            defaultValue={parseFloat(this.state.lado_esquerdo)}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              inputComponent: DecimalFormatCustom
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="fundos"
                            label="Fundos"
                            fullWidth
                            error={this.state.errors.fundos}
                            helperText={this.state.errors.fundos}
                            value={parseFloat(this.state.fundos)}
                            defaultValue={parseFloat(this.state.fundos)}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              inputComponent: DecimalFormatCustom
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="area_lote"
                            label="Área do Lote"
                            fullWidth
                            error={this.state.errors.area_lote}
                            helperText={this.state.errors.area_lote}
                            value={parseFloat(this.state.area_lote)}
                            defaultValue={parseFloat(this.state.area_lote)}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              inputComponent: DecimalFormatCustom
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="area_privativa"
                            label="Área Privativa"
                            fullWidth
                            error={this.state.errors.area_privativa}
                            helperText={this.state.errors.area_privativa}
                            value={parseFloat(this.state.area_privativa)}
                            defaultValue={parseFloat(this.state.area_privativa)}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              inputComponent: DecimalFormatCustom
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="area_comum"
                            label="Área Comum"
                            fullWidth
                            error={this.state.errors.area_comum}
                            helperText={this.state.errors.area_comum}
                            value={parseFloat(this.state.area_comum)}
                            defaultValue={parseFloat(this.state.area_comum)}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              inputComponent: DecimalFormatCustom
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            required

                            name="area_total_edificada"
                            label="Área Total Edificada"
                            fullWidth

                            error={this.state.errors.area_total_edificada}
                            helperText={this.state.errors.area_total_edificada}
                            value={parseFloat(this.state.area_total_edificada)}
                            defaultValue={parseFloat(this.state.area_total_edificada)}
                            onChange={this.handleChange}
                            margin="normal"
                            InputProps={{
                              inputComponent: DecimalFormatCustom
                            }}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="frentes"
                            label="Nº de frentes"
                            type="number"
                            fullWidth
                            error={this.state.errors.frentes}
                            helperText={this.state.errors.frentes}
                            value={parseFloat(this.state.frentes)}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="pavimentos"
                            label="Nº Pavimentos"
                            type="number"
                            fullWidth
                            error={this.state.errors.pavimentos}
                            helperText={this.state.errors.pavimentos}
                            value={parseFloat(this.state.pavimentos)}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="unidades"
                            label="Nº Unidades"
                            type="number"
                            fullWidth
                            error={this.state.errors.unidades}
                            helperText={this.state.errors.unidades}
                            value={parseFloat(this.state.unidades)}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="afastamento_frontal"
                            label="Afastamento Frontal"
                            fullWidth
                            error={this.state.errors.afastamento_frontal}
                            helperText={this.state.errors.afastamento_frontal}
                            value={parseFloat(this.state.afastamento_frontal)}
                            defaultValue={parseFloat(this.state.afastamento_frontal)}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              inputComponent: DecimalFormatCustom
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {this.state.value === 5 && (
                    <div>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_edificacao"
                            label="Tipo de edificação"
                            fullWidth
                            error={this.state.errors.tipo_edificacao_id}
                            helperText={this.state.errors.tipo_edificacao_id}
                            value={this.state.id_tipos_de_edificacao}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_edificacao.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}

                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_acabamento_externo"
                            label="Acabamento Externo"
                            fullWidth
                            error={this.state.errors.id_acabamento_externo}
                            value={this.state.id_acabamento_externo}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_acabamento.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_acabamentoInterno"
                            label="Acabamento Interno"
                            fullWidth
                            error={this.state.errors.id_acabamentoInterno}
                            value={this.state.id_acabamentoInterno}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_acabamento.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_situacao_edificacao"
                            label="Situação da Edificação"
                            fullWidth
                            error={this.state.errors.situacao_edificacao_id}
                            helperText={this.state.errors.situacao_edificacao_id}
                            value={this.state.id_situacao_edificacao}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.situacao_edificacao.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}

                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_sanitarios"
                            label="Sanitários"
                            fullWidth
                            error={this.state.errors.id_tipos_de_sanitarios}
                            value={this.state.id_tipos_de_sanitarios}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_sanitarios.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_situacoes_dos_lados"
                            label="Tipo (lados)"
                            fullWidth
                            error={this.state.errors.situacao_lados_id}
                            helperText={this.state.errors.situacao_lados_id}
                            value={this.state.id_situacoes_dos_lados}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.situacoes_dos_lados.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_abastecimento_de_agua"
                            label="Abastecimento de água"
                            fullWidth
                            error={
                              this.state.errors.tipo_abastecimento_de_agua_id
                            }
                            value={this.state.id_tipos_abastecimento_de_agua}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_abastecimento_de_agua.map(
                              (option, key) => (
                                <MenuItem key={key} value={option.id}>
                                  {option.nome || "Nome não cadastrado"}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_estrutura"
                            label="Estrutura"
                            fullWidth
                            error={this.state.errors.tipo_estrutura_id}
                            helperText={this.state.errors.tipo_estrutura_id}
                            value={this.state.id_tipos_de_estrutura}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_estrutura.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_cobertura"
                            label="Cobertura"
                            fullWidth
                            error={this.state.errors.tipo_cobertura_id}
                            helperText={this.state.errors.tipo_cobertura_id}
                            value={this.state.id_tipos_de_cobertura}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_cobertura.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_reservatorio_de_agua"
                            label="Reservatório de Água"
                            fullWidth
                            error={
                              this.state.errors.id_tipos_de_reservatorio_de_agua
                            }
                            value={this.state.id_tipos_de_reservatorio_de_agua}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_reservatorio_de_agua.map(
                              (option, key) => (
                                <MenuItem key={key} value={option.id}>
                                  {option.nome || "Nome não cadastrado"}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <FormLabel component="legend">
                            Terreno Impermeabilizado
                        </FormLabel>
                          <RadioGroup
                            aria-label="terreno"
                            name="terreno_impermeavel"
                            value={this.state.terreno_impermeavel}
                            onChange={this.handleChange}
                            row
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio color="primary" />}
                              label="Sim"
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio color="primary" />}
                              label="Não"
                              labelPlacement="end"
                            />
                          </RadioGroup>
                        </Grid>

                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <SelectCustom
                            value={this.state.id_atributos_especiais}
                            errors={this.state.errors.id_atributos_especiais}
                            onChange={this.handleChange}
                            options={this.state.atributos_especiais}
                            name="id_atributos_especiais"
                            placeholder="Atributos Especiais"
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </Grid>
                      </Grid>

                    </div>
                  )}
                  {this.state.value === 6 && (
                    <div>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_classificacoes_arquitetonicas"
                            label="Classif. arquitetônica"
                            fullWidth
                            helperText={this.state.errors.classificacao_arquitetonica_id}
                            error={
                              this.state.errors.classificacao_arquitetonica_id
                            }
                            value={this.state.id_classificacoes_arquitetonicas}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.classificacoes_arquitetonicas.map(
                              (option, key) => (
                                <MenuItem key={key} value={option.id}>
                                  {option.nome || "Nome não cadastrado"}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_utilizacao"
                            label="Utilização"
                            fullWidth
                            error={this.state.errors.tipo_utilizacao_id}
                            helperText={this.state.errors.tipo_utilizacao_id}
                            value={this.state.id_tipos_de_utilizacao}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_utilizacao.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_estados_de_conservacao"
                            label="Conservação"
                            fullWidth
                            error={this.state.errors.estado_conservacao_id}
                            helperText={this.state.errors.estado_conservacao_id}
                            value={this.state.id_estados_de_conservacao}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.estados_de_conservacao.map(
                              (option, key) => (
                                <MenuItem key={key} value={option.id}>
                                  {option.nome || "Nome não cadastrado"}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_piso"
                            label="Piso"
                            fullWidth
                            error={this.state.errors.tipo_piso_id}
                            helperText={this.state.errors.tipo_piso_id}
                            value={this.state.id_tipos_de_piso}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_piso.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>

                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_forro"
                            label="Forro"
                            fullWidth
                            error={this.state.errors.tipo_forro_id}
                            helperText={this.state.errors.tipo_forro_id}
                            value={this.state.id_tipos_de_forro}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_forro.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_instalacao_eletrica"
                            label="Instalação Elétrica"
                            fullWidth
                            error={
                              this.state.errors.tipo_instalacao_eletrica_id
                            }
                            helperText={
                              this.state.errors.tipo_instalacao_eletrica_id
                            }
                            value={this.state.id_tipos_de_instalacao_eletrica}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_instalacao_eletrica.map(
                              (option, key) => (
                                <MenuItem key={key} value={option.id}>
                                  {option.nome || "Nome não cadastrado"}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_instalacao_sanitaria"
                            label="Instalação Sanitária"
                            fullWidth
                            error={
                              this.state.errors.tipo_instalacao_sanitaria_id
                            }
                            helperText={
                              this.state.errors.tipo_instalacao_sanitaria_id
                            }
                            value={this.state.id_tipos_de_instalacao_sanitaria}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_instalacao_sanitaria.map(
                              (option, key) => (
                                <MenuItem key={key} value={option.id}>
                                  {option.nome || "Nome não cadastrado"}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_esquadria"
                            label="Esquadrias"
                            fullWidth
                            error={this.state.errors.id_tipos_de_esquadria}
                            value={this.state.id_tipos_de_esquadria}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_esquadria.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="quartos"
                            label="Nº Quartos"
                            fullWidth
                            type="number"
                            error={this.state.errors.quartos}
                            helperText={this.state.errors.quartos}
                            value={this.state.quartos}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={1} lg={1}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="salas"
                            label="Nº Sala"
                            fullWidth
                            type="number"
                            error={this.state.errors.salas}
                            helperText={this.state.errors.salas}
                            value={this.state.salas}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="banheiros"
                            label="Nº Banheiro"
                            fullWidth
                            type="number"
                            error={this.state.errors.banheiros}
                            helperText={this.state.errors.banheiros}
                            value={this.state.banheiros}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="cozinhas"
                            label="Nº Cozinha"
                            fullWidth
                            type="number"
                            error={this.state.errors.cozinhas}
                            helperText={this.state.errors.cozinhas}
                            value={this.state.cozinhas}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="pontos_de_agua"
                            label="Nº Ponto de Água"
                            fullWidth
                            type="number"
                            error={this.state.errors.pontos_de_agua}
                            helperText={this.state.errors.pontos_de_agua}
                            value={this.state.pontos_de_agua}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>


                    </div>
                  )}
                  {this.state.value === 7 && (
                    <div>
                      <Grid container direction="row" spacing={16}>
                        <Typography
                          style={{ marginTop: "2%" }}
                          color="textSecondary"
                          variant="caption"
                        >
                          Realize aqui suas anotações
                      </Typography>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            id="outlined-select-currency"
                            multiline
                            name="anotacoes"
                            label="Anotações"
                            fullWidth
                            rowsMax={7}
                            error={this.state.errors.anotacoes}
                            value={this.state.anotacoes}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid container alignItems="center" justify="center">
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Button
                            block
                            color="info"
                            round
                            type="submit"
                          >
                            <AddIcon />{" "}
                            {this.props.match.params.id
                              ? "Atualizar"
                              : "Finalizar"}
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  <MobileStepper
                    variant="progress"
                    steps={8}
                    position="static"
                    activeStep={this.state.value}
                    theme={theme}
                    nextButton={
                      <Button size="lg" onClick={this.handleNext} disabled={this.state.value === 7} color="primary">
                        Próximo
              </Button>
                    }
                    backButton={
                      <Button size="lg" onClick={this.handleBack} disabled={this.state.value === 0} color="primary">
                        Anterior
              </Button>
                    }
                  />
                </form>
              </CardBody>
            </MuiThemeProvider>
          </Card>
        </LoadingContent>

        <Dialog
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <form onSubmit={this.transfDivida}>
            <DialogTitle id="form-dialog-title">
              Deseja transferir as dívidas para o novo contribuinte?
            </DialogTitle>
            <DialogContent>
              <TextField
                id="outlined-select-currency"
                name="motivo_transf"
                label="Motivo"
                required
                fullWidth
                error={this.state.errors.motivo}
                helperText={this.state.errors.motivo}
                multiline
                rowsMax="3"
                value={this.state.motivo_transf}
                onChange={this.handleChange}
                margin="normal"
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="danger">
                Cancelar
              </Button>
              <Button type="submit" color="success">
                Transferir
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div >
    )
  }
}
export default withStyles(secaoStyle)(ImoveisForm)
