import React from "react"
import { withStyles, Paper } from "@material-ui/core"
import logradouroStyle from "../../assets/css/layout/logradouroStyle"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import Grid from "@material-ui/core/Grid"
import Table from "../../components/Table/Table"
import TablePagination from "@material-ui/core/TablePagination"
import Search from "../../components/Search"
import Lens from "@material-ui/icons/Lens"
import Close from "@material-ui/icons/Close"
import LoadingContent from "../../components/LoadingContent"
import { getDamConstrucao, searchDamProjeto, getDam, destroyDam } from "../../util/projeto"
import TextField from "@material-ui/core/TextField"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "../../components/CustomButtons/Button"
import {
    showNotification,
    showWaiting,
    hideWaiting
} from "../../util/Constants"
import Print from "@material-ui/icons/Print"
import housecall from "housecall"
import AdBlockDetect from "react-ad-block-detect"
import Dialog from "@material-ui/core/Dialog"
import Typography from "@material-ui/core/Typography"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import AlterarVencModal from "./AlterarVencModal"
class DamProjeto extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageCount: 0,
            perPage: 0,
            page: 0,
            dams: [],
            searchText: "",
            showAlterarVencimentoModal: false,
            showModalHistorico: false,
            errors: {}
        }
        this.getData = this.getData.bind(this)
        this.toggleLoading = this.toggleLoading.bind(this)
        this.filter = this.filter.bind(this)
        this.queue = housecall({ concurrency: 1, cooldown: 0 })
        this.setLoadingFalse = this.setLoadingFalse.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.openRemover = this.openRemover.bind(this)
        this.remover = this.remover.bind(this)
        this.handleChange = this.handleChange.bind(this)

    }

    openRemover(key) {
        this.setState({
            modalRemover: true,
            id: this.state.dams[key].id
        })
    }

    closeRemover() {
        this.setState({
            modalRemover: false
        })
    }

    remover() {
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros! Tente novamente",
                    "danger"
                )
            } else {
                showNotification(
                    "top",
                    "center",
                    "Dam de construção civil removido com sucesso!",
                    "success"
                )
                this.closeRemover()
                this.handleClose()
                this.getData()
            }
        }
        destroyDam(this.state.id, response)
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        errors[name] = undefined
        this.setState({
            [name]: event.target.value,
            errors: errors
        })
    }

    handleChangeFilters = event => {
        this.setState({ filterField: event.target.value }, () => {
            if (this.state.searchActive) {
                this.filter({ target: { value: this.state.searchText } })
            }
        })
    }

    setLoadingFalse() {
        this.setState({
            isLoading: false
        })
    }

    componentDidMount() {
        this.queue.on("idle", this.setLoadingFalse)
    }



    handleChangePage = (event, page) => {
        var pagina;
        pagina = page + 1;
        this.setState({page: page})
        if(page < 0){
            return;
        }
        if (this.state.searchText.toString().trim() != "") {
            this.filter({target: {value: this.state.searchText}}, pagina, page)
            return;
        }
        this.getData(pagina)
    };

    componentWillMount() {
        this.getData()
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    getData(page) {
        this.setState({isLoading:true})
        getDamConstrucao(page, (error, data) => {
            this.setState({isLoading:false})
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                this.setState({
                    dams: data.data,
                    pageCount: data.total,
                    perPage: data.per_page,

                })
            }
        })
    }

    handleClose() {
        this.setState({
            showAlterarVencimentoModal: false
        })
    }

    imprimirDam(key) {
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                const reader = new FileReader()
                reader.addEventListener("loadend", e => {
                    const text = e.srcElement.result
                    let error = JSON.parse(text)
                    let status = error.status
                    if (status === "vencido") {
                        this.setState({
                            showAlterarVencimentoModal: true,
                            damIndex: key
                        })
                    }
                    showNotification(
                        "top",
                        "center",
                        `Ocorreram erros! ${error.message}`,
                        "danger"
                    )
                })
                reader.readAsText(error.response.data)

            } else {
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        var dam = this.state.dams[key]
        getDam(dam.id, response)
    }

    filter(event,pagina) {
        let searchText = event.target.value
        var newState = {
            isLoading:true,
            searchText: searchText,
            searchActive: searchText !== ""
        }
        if(pagina == null){
            newState['page'] = 0;
        }
        this.setState(
         newState   ,
            () => {
                if (this.state.searchActive) {

                    let filtersCheckbox = this.state.filterField || ""
                    this.queue.push(() =>
                        searchDamProjeto(searchText, filtersCheckbox, undefined, (error, data) => {
                            this.setState({isLoading:false})
                            if (error) {
                                showNotification(
                                    "top",
                                    "center",
                                    "Ocorreram erros, verifique sua conexão com a internet",
                                    "danger"
                                )
                            } else {
                                if (this.state.searchText) {
                                    this.setState({
                                        dams: data.data,
                                        pageCount: data.total,
                                        perPage: data.per_page,
                                    })
                                }
                            }
                        },pagina)
                    )
                } else {
                    this.getData()
                }
            }
        )
    }

    render() {
        const { classes } = this.props
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "Código DAM" },
            { value: "contribuinte_id", label: "Código do contribuinte" },
            { value: "contribuinte_nome_razao_social", label: "Nome Razão Social" },
            { value: "tipo", label: "Tipo de Alvará" },
            { value: "competencia", label: "Competência" }
        ]
        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container spacing={32} direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>Dam's de Projeto</h2>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <Search
                            label="Pesquise aqui"
                            value={this.state.filterField}
                            onChangeText={this.filter}
                            onChangeFields={this.handleChangeFilters}
                            fields={fields}
                        />
                        <Grid className={classes.legend} container direction="row">
                            <Lens className={classes.dot + " " + classes.dotDanger} />
                            DAM Lançado na dívida
                            <Lens className={classes.dot + " " + classes.dotSuccess} />
                            DAM Pago
                        </Grid>
                        <LoadingContent isLoading={this.state.isLoading}>
                            <Paper className={classes.tableWrapper}>
                                <Table
                                    getRowColor={(key, classes) => {
                                        if (this.state.dams[key].lancado_na_divida) {
                                            return classes.dangerTableRow
                                        } else if (this.state.dams[key].pago) {
                                            return classes.successTableRow
                                        } else {
                                            return ""
                                        }
                                    }}
                                    tableHeaderColor="primary"
                                    actions={[
                                        {
                                            title: "Imprimir",
                                            icon: (
                                                <Print
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            ),
                                            onClick: key => this.imprimirDam(key)
                                        },
                                        {
                                            title: "Remover",
                                            icon: (
                                                <Close
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            ),
                                            onClick: key => this.openRemover(key)
                                        },
                                    ]}
                                    tableHead={[
                                        "Código do dam",
                                        "Código Projeto",
                                        "Tipo",
                                        "Código Proprietário",
                                        "Proprietário",
                                        "Competência",

                                    ]}
                                    tableData={this.state.dams.map((item, key) => {
                                        return [
                                            item.id + "",
                                            item.projeto_id,
                                            item.tipo.nome,
                                            item.projeto.proprietario_id,
                                            item.projeto.proprietario.nome_razao_social,
                                            item.competencia,
                                            key
                                        ]
                                    })}
                                />
                                { (
                                    <TablePagination
                                        component="div"
                                        handlePageClick={this.handlePageClick}
                                        rowsPerPageOptions={[]}
                                        count={this.state.pageCount}
                                        rowsPerPage={this.state.perPage?this.state.perPage:0}
                                        page={this.state.page?this.state.page:0}
                                        backIconButtonProps={
                                                {"aria-label": "Previous Page"}}

                                        nextIconButtonProps={  {
                                            "aria-label": "Next Page",
                                        }}

                                        SelectProps={{
                                            native: true
                                        }}

                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                )}
                            </Paper>
                        </LoadingContent>
                    </CardBody>
                </Card>

                <AdBlockDetect>
                    <Dialog open={true} aria-labelledby="form-dialog-title">
                        <DialogContent>
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                                direction="row"
                            >
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                    <Typography variant="h5" gutterBottom>
                                        Por favor, desabilite o ADBlock para geração de baixa.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </AdBlockDetect>
                <Dialog
                    id="body"
                    open={this.state.showAlterarVencimentoModal}
                    maxWidth="md"
                    fullWidth
                    titleStyle={{ textAlign: "center" }}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    style={{ zIndex: "4" }}
                >
                    <DialogTitle id="form-dialog-title">
                        <Typography variant="h5" align="center">
                            O DAM Selecionado se encontra vencido, deseja alterar seu
                            vencimento?
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <AlterarVencModal
                            dam={this.state.dams[this.state.damIndex]}
                            handleClose={this.handleClose}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    id="body"
                    open={this.state.showModalHistorico}
                    maxWidth="md"
                    fullWidth
                    titleStyle={{ textAlign: "center" }}
                    onClose={this.handleCloseHistorico}
                    aria-labelledby="form-dialog-title"
                    style={{ zIndex: "4" }}
                >
                    <DialogTitle id="form-dialog-title">
                        <Typography variant="h5" align="center">
                            Editar DAM
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container direction="row" spacing={16} justify="center">
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="outlined-select-currency"
                                    required
                                    name="historico_dam_selected"
                                    label="Histórico"
                                    fullWidth
                                    multiline
                                    rowsMax="3"
                                    error={this.state.errors.historico}
                                    helperText={this.state.errors.historico}
                                    value={this.state.historico_dam_selected}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={16} justify="center">
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="outlined-select-currency"
                                    required
                                    name="observacao_dam"
                                    label="Observação"
                                    fullWidth
                                    multiline
                                    rowsMax="3"
                                    error={this.state.errors.observacao}
                                    helperText={this.state.errors.observacao}
                                    value={this.state.observacao_dam}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseHistorico} color="danger">
                            Cancelar
                        </Button>
                        <Button onClick={this.editDam} color="success">
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* dialog confirmacao */}
                <Dialog
                    open={this.state.modalRemover}
                    onClose={this.closeRemover}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Você tem certeza que deseja remover esse Dam de construção
                        civil?</DialogTitle>

                    <DialogActions>
                        <Button onClick={this.closeRemover} color="danger">
                            Cancelar
                        </Button>
                        <Button onClick={this.remover} color="success">
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withStyles(logradouroStyle)(DamProjeto)