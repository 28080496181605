import axios from "axios"
import { defaultHeaders } from "./Constants"

export function getRelatorio(ano, tipo, imovel, contribuinte, callBack) {
  let config = {
    headers: defaultHeaders(),
    responseType: "blob"
  }
  let url =
    process.env.REACT_APP_API +
    `relatorios/iptus${ano ? `?ano=${ano}` : ""}${tipo ? `&tipo=${tipo}` : "" }${imovel ? `&imovel_id=${imovel}` : "" }${contribuinte ? `&contribuinte_id=${contribuinte}`: ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}