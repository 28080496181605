import React from "react"
import { Link, withRouter } from "react-router-dom"
import { withStyles, Paper } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import InputMask from "react-input-mask"
import moment from "moment"
import Lens from "@material-ui/icons/Lens"
import numeral from "../../util/numeral"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import Button from "../../components/CustomButtons/Button"
import FormLabel from "@material-ui/core/FormLabel"
import FormControl from "@material-ui/core/FormControl"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import AddIcon from "@material-ui/icons/Add"
import Delete from "@material-ui/icons/Delete"
import Grid from "@material-ui/core/Grid"
import Table from "../../components/Table/Table"
import TablePagination from "@material-ui/core/TablePagination"
import Edit from "@material-ui/icons/Edit"
import Print from "@material-ui/icons/Print"
import Class from "@material-ui/icons/Class"
import NewReleases from "@material-ui/icons/NewReleases"
import ChromeReaderMode from "@material-ui/icons/ChromeReaderMode"
import { get, search, getFieldDam, postDam, getDam, destroyDam, imprimirAlvara } from "../../util/projeto"
import LoadingContent from "../../components/LoadingContent"
import Search from "../../components/Search"
import {
  showNotification,
  hideWaiting,
  showWaiting,
  mapErrors,
  removeMask
} from "../../util/Constants"
import housecall from "housecall"

const newState = {
  value: "",
  error: "",
  pageCount: 0,
  perPage: 0,
  page: 0,
  isSearch:false,
  projetos: [],
  searchText: "",
  field: 0,
  modal: false,
  tipos: [],
  tipo_id: [],
  competencia: "",
  projeto_id: "",
  valor: "",
  juros: "",
  multa: "",
  vencimento_original: new Date(),
  errors: {},
  modalDam: false,
  dams: [],
  iss: true,
  habitese: true,
  alvara: true,
  showModalDam: false,
  arrayResponse: [],
  historico: ""
}

class ProjetoMain extends React.Component {
  constructor(props) {
    super(props)
    this.state = newState
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getData = this.getData.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.filter = this.filter.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
    this.toggleLoadingDialog = this.toggleLoadingDialog.bind(this)
    this.message = this.message.bind(this)
    this.lancar = this.lancar.bind(this)
    this.getFieldDialog = this.getFieldDialog.bind(this)
    this.gerarDam = this.gerarDam.bind(this)
    this.imprimirDam = this.imprimirDam.bind(this)
    this.setLoadingFalse = this.setLoadingFalse.bind(this)
    this.openRemover = this.openRemover.bind(this)
    this.closeRemover = this.closeRemover.bind(this)
    this.remover = this.remover.bind(this)
    this.handleCheckElement = this.handleCheckElement.bind(this)
    this.imprimirAlvaraDam = this.imprimirAlvaraDam.bind(this)
    this.openDam = this.openDam.bind(this)
    this.closeDam = this.closeDam.bind(this)
  }

  imprimirAlvaraDam(key) {
    let dam = this.state.dams[key]
    showWaiting()
    imprimirAlvara(dam.id, (error, data) => {
      hideWaiting()
      if (error) {
        const reader = new FileReader()
        reader.addEventListener("loadend", e => {
          const text = e.srcElement.result
          let error = JSON.parse(text)
          showNotification(
            "top",
            "center",
            `Ocorreram erros! ${error.message}`,
            "warning"
          )
        })

        reader.readAsText(error.response.data)
      } else {
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    })
  }

  setLoadingFalse() {
    this.setState({
      isLoading: false
    })
  }


  componentWillMount() {
    this.getData()
  }

  toggleLoadingDialog() {
    this.setState(state => ({
      isLoadingDialog: !state.isLoadingDialog
    }))
  }

  handlePageClick(data) {
    //this.getData(data.selected + 1);
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    let value = event.target.value
    errors[name] = undefined

    this.setState({
      [name]: value,
      errors: errors
    })
  }

  handleCheckElement(event) {
    let tipos = this.state.tipos
    let value = event.target.value
    tipos.forEach(tip => {
      if (tip.value === value) {
        tip.isChecked = event.target.checked
      }
    })
    this.setState({ tipos: tipos })
  }

  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  componentDidMount() {
    this.queue.on("idle", this.setLoadingFalse)
  }
  filter(event,pagina) {
    let searchText = event.target.value
    var newState = {
      isLoading:true,
      isSearch:true,
      searchText: searchText,
      searchActive: searchText !== ""
    }
    if(pagina == null){
      newState['page'] = 0
    }
    this.setState(
        newState
      ,
      () => {
        if (this.state.searchActive) {

          let filtersCheckbox = this.state.filterField || ""
          this.queue.push(() =>
            search(searchText, filtersCheckbox, undefined, (error, data) => {
              this.setState({isLoading:false,isSearch:false})
              if (error) {
                showNotification(
                  "top",
                  "center",
                  "Ocorreram erros, verifique sua conexão com a internet",
                  "danger"
                )
              } else {
                if (this.state.searchText) {
                  this.setState({
                    projetos: data.data,
                    pageCount: data.total,
                    perPage: data.per_page,
                  })
                }
              }
            },pagina)
          )
        } else {
          this.getData()
        }
      }
    )
  }

  handleChangeFilters = event => {
    this.setState({ filterField: event.target.value }, () => {
      if (this.state.searchActive) {
        this.filter({ target: { value: this.state.searchText } })
      }
    })
  }

  getData(page) {
    this.setState({isLoading:true,isSearch:true})

    get(page, (error, data) => {
      this.setState({isLoading:false,isSearch:false})
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState({
          projetos: data.data,
          pageCount: data.total,
          perPage: data.per_page,

        })
      }
    })
  }

  handleChangePage = (event, page) => {
    var pagina;
    pagina = page + 1;
    this.setState({page: page})
    if(page < 0){
      return;
    }
    if (this.state.searchText.toString().trim() != "") {
      this.filter({target: {value: this.state.searchText}}, pagina, page)
      return;
    }
    this.getData(pagina)
  };

  handleEdit(key) {
    this.props.history.push("/projetos/edit/" + this.state.projetos[key].id)
  }

  handleFieldChange(value) {
    this.setState({
      field: value
    })
  }

  getFieldDialog() {
    this.toggleLoadingDialog()
    getFieldDam((error, data) => {
      this.toggleLoadingDialog()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        let tipos = data.tipos.map(item => {
          return { id: item.id, value: item.nome, isChecked: true }
        })
        this.setState({
          tipos: tipos
        }, () => {
          this.setState({ modal: true, })
        })
      }
    })
  }

  handleClose = () => {
    this.setState({
      modal: false,
      modalDam: false,
      tipo_id: [],
      competencia: "",
      projeto_id: "",
      valor: "",
      vencimento: new Date()
    })
  }

  lancar(key) {
    this.setState(
      {
        projeto_id: this.state.projetos[key].id
      },
      () => {
        this.getFieldDialog()
      }
    )
  }

  gerarDam() {
    showWaiting()
    let tp = this.state.tipos.filter(item => {
      return item.isChecked
    })
    let params = {
      competencia: removeMask(this.state.competencia),
      projeto_id: this.state.projeto_id,
      tipos: tp.map(item => item.id),
      historico: this.state.historico,
      vencimento: moment(this.state.vencimento).format("YYYY-MM-DD"),
      numero_de_parcelas_iss: this.state.numero_de_parcelas_iss,
    }
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        if (error.response.data.errors) {
          this.setState({
            errors: mapErrors(error)
          })
        } else {
          showNotification(
            "top",
            "center",
            error.response.data.message,
            "danger"
          )
        }
      } else {
        hideWaiting()
        this.handleClose()
        this.openDam(data)
        showNotification("top", "center", `DAM emitido com sucesso`, "success")
        this.getData()
      }
    }
    postDam(params, response)
  }

  openDam(array) {
    this.setState({
      arrayResponse: array,
      showModalDam: true
    })
  }

  closeDam() {
    this.setState({
      showModalDam: false
    })
  }

  getDams(key) {
    this.setState({
      modalDam: true,
      dams: this.state.projetos[key].dams
    })
  }

  message() {
    this.handleClose()
    showNotification("top", "center", `DAM pago`, "warning")
  }

  imprimirDam(id, condicao) {
    let idRec = id
    if (condicao) {
      if (!this.state.dams[id].pago) {
        idRec = this.state.dams[id].id
      } else {
        return this.message()
      }
    }
    showWaiting()
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        const reader = new FileReader()
        reader.addEventListener("loadend", e => {
          const text = e.srcElement.result
          let error = JSON.parse(text)
          showNotification(
            "top",
            "center",
            `Ocorreram erros! ${error.message}`,
            "danger"
          )
        })

        reader.readAsText(error.response.data)
      } else {
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    getDam(idRec, response)
    console.log(this.state.dams[id])
  }
  openRemover(key) {
    this.setState({
      modalRemover: true,
      id: this.state.dams[key].id
    })
  }

  closeRemover() {
    this.setState({
      modalRemover: false
    })
  }

  remover() {
    showWaiting()
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros! Tente novamente",
          "danger"
        )
      } else {
        showNotification(
          "top",
          "center",
          "Dam de construção civil removido com sucesso!",
          "success"
        )
        this.closeRemover()
        this.handleClose()
        this.getData()
      }
    }
    destroyDam(this.state.id, response)
  }

  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do Projeto" },
      { value: "localizacao", label: "Localização" },
      { value: "proprietario_cpf_cnpj", label: "Documento" },
      { value: "proprietario_id", label: "Código do Proprietário" },
      { value: "proprietario_nome_razao_social", label: "Razão Social" }
    ]
    console.log(this.state.tipos);
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container spacing={32} direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Projetos</h2>
              </Grid>
              <Grid item lg={2} xs={12}>
                <Link to="/projetos/create" style={{ textDecoration: "none" }}>
                  <Button block color="success" round>
                    <AddIcon /> Adicionar
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Search
              label="Pesquise aqui"
              value={this.state.filterField}
              onChangeText={this.filter}
              onChangeFields={this.handleChangeFilters}
              fields={fields}
            />
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  tableHeaderColor="primary"
                  actions={[
                    {
                      title: "Editar",
                      icon: (
                        <Edit
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      ),
                      onClick: this.handleEdit
                    },
                    {
                      title: "Lançar DAM",
                      icon: (
                        <NewReleases
                          className={
                            classes.tableActionButtonIcon + " " + classes.ficha
                          }
                        />
                      ),
                      onClick: this.lancar
                    },
                    {
                      title: "Imprimir DAM",
                      icon: (
                        <ChromeReaderMode
                          className={
                            classes.tableActionButtonIcon + " " + classes.book
                          }
                        />
                      ),
                      onClick: key => this.getDams(key)
                    },
                  ]}
                  tableHead={[
                    "Código",
                    "Proprietário",
                    "Localização",
                    "Área Construída",
                    "Valor da obra"
                  ]}
                  tableData={this.state.projetos.map((item, key) => {
                    return [
                      item.id + "",
                      item.proprietario.nome_razao_social,
                      item.localizacao,
                      item.area_construida,
                      item.valor_da_obra,
                      key
                    ]
                  })}
                />
                { (
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[]}
                    count={this.state.pageCount}
                    rowsPerPage={this.state.perPage?this.state.perPage:0}
                    page={this.state.page?this.state.page:0}
                    backIconButtonProps={
                      this.state.isSearch ?
                          {"aria-label": "Previous Page", disabled: this.state.isSearch} :
                          {"aria-label": "Previous Page"}}
                    nextIconButtonProps={this.state.isSearch ? {
                      "aria-label": "Next Page",
                      disabled: this.state.isSearch
                    } : {
                      "aria-label": "Next Page",
                    }}
                    SelectProps={{
                      native: true
                    }}

                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                )}
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>

        {/* MODAL */}
        <Dialog
          open={this.state.modal}
          onClose={this.handleClose}
          maxWidth="md"
          aria-labelledby="form-dialog-title"
          fullWidth
          style={{ zIndex: "3" }}
        >
          <LoadingContent isLoading={this.state.isLoadingDialog}>
            <DialogTitle id="form-dialog-title">Lançamento</DialogTitle>
            <DialogContent>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel component="legend">
                      Selecione os tipos para lançamento
                    </FormLabel>
                    <FormGroup row>
                      {this.state.tipos.map((item, key) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="secondary"
                                checked={item.isChecked}
                                onChange={this.handleCheckElement}
                                value={item.value}
                              />
                            }
                            label={item.value}
                          />
                        )
                      })}
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={16} direction="row">
                {
                  (
                    this.state.tipos.filter((tipo) => {
                      console.log(tipo);
                      return (tipo.id === 2 && tipo.isChecked === true)
                    }
                    ).length > 0
                  ) &&
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="outlined-select-currency"
                      name="numero_de_parcelas_iss"
                      label="Numero de parcelas de ISS"
                      fullWidth
                      type="number"
                      min={0}
                      value={this.state.numero_de_parcelas_iss || ""}
                      error={this.state.errors.numero_de_parcelas_iss}
                      helperText={this.state.errors.numero_de_parcelas_iss}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                      defaultValue={this.state.numero_de_parcelas_iss}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                }

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <InputMask
                    mask="9999/99"
                    value={this.state.competencia}
                    onChange={this.handleChange}
                  >
                    {() => (
                      <TextField
                        id="outlined-name"
                        label="Competência"
                        name="competencia"
                        helperText={this.state.errors.competencia}
                        fullWidth
                        error={this.state.errors.competencia}
                        value={this.state.competencia}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="outlined-select-currency"
                    name="vencimento"
                    label="Data do Vencimento"
                    fullWidth
                    type="date"
                    value={this.state.vencimento || ""}
                    error={this.state.errors.vencimento}
                    helperText={this.state.errors.vencimento}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={16} direction="row">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="outlined-name"
                    label="Histórico"
                    name="historico"
                    helperText={this.state.errors.historico}
                    fullWidth
                    error={this.state.errors.historico}
                    value={this.state.historico}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    multiline
                    rowsMax="3"
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </LoadingContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="danger">
              Cancelar
            </Button>
            <Button onClick={this.gerarDam} color="success">
              Gerar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.modalDam}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="md"
          style={{ zIndex: "3" }}
        >
          <DialogTitle id="form-dialog-title">DAM</DialogTitle>
          <DialogContent>
            <Grid
                className={classes.legend}
              container
              direction="row"
              style={{ marginBottom: "2%" }}
            >
              <Lens className={classes.dot + " " + classes.dotDanger} />
              DAM Lançado na dívida
              <Lens className={classes.dot + " " + classes.dotSuccess} />
              DAM Pago
            </Grid>
            <form onSubmit={this.handleSaveParcela}>
              <Paper className={classes.tableWrapper}>
                <Table
                  getRowColor={(key, classes) => {
                    console.log(this.state.dams[key])
                    if (this.state.dams[key].lancado_na_divida) {
                      return classes.dangerTableRow
                    } else if (this.state.dams[key].pago) {
                      return classes.successTableRow
                    } else {
                      return ""
                    }
                  }}
                  tableHeaderColor="primary"
                  fullWidth
                  actions={[
                    {
                      title: "Imprimir",
                      icon: (
                        <Print
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      ),
                      onClick: key => this.imprimirDam(key, true)
                    },
                    {
                      title: "Imprimir Alvará",
                      icon: (
                        <Class
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      ),
                      onClick: key => this.imprimirAlvaraDam(key)
                    },
                    {
                      title: "Remover",
                      icon: (
                        <Delete
                          className={
                            classes.tableActionButtonIcon +
                            " " +
                            classes.close
                          }
                        />
                      ),
                      onClick: key => this.openRemover(key)
                    }
                  ]}
                  tableHead={["#", "Competência", "Tipo", "Valor", "Juros", "Multa", "Vencimento Original"]}
                  tableData={this.state.dams.map((item, key) => {
                    return [item.id + "", item.competencia, item.tipo.nome, "R$" +
                      numeral(parseFloat(item.valor))
                        .format("0.0,00")
                        .slice(0, -2), numeral(parseFloat(item.juros))
                        .format("0.0,00")
                        .slice(0, -2), numeral(parseFloat(item.multa))
                        .format("0.0,00")
                        .slice(0, -2), moment(item.vencimento_original).format("DD/MM/YYYY"), key]
                  })}
                />
              </Paper>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="danger">
              Voltar
            </Button>
          </DialogActions>
        </Dialog>
        {/* dialog confirmacao */}
        <Dialog
          open={this.state.modalRemover}
          onClose={this.closeRemover}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Você tem certeza que deseja remover esse Dam de construção civil?</DialogTitle>

          <DialogActions>
            <Button onClick={this.closeRemover} color="danger">
              Cancelar
              </Button>
            <Button onClick={this.remover} color="success">
              Confirmar
              </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.showModalDam}
          onClose={this.closeDam}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: "3" }}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title">Imprimir DAM's</DialogTitle>
          <DialogContent>
            {this.state.arrayResponse.map(item => {
              return (
                <Grid container spacing={16} direction="row" justify="center" alignItems="center">
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Button onClick={() => this.imprimirDam(item.id, false)} fullWidth color="info">
                      {item.tipo_id === 2 ? `Parcela ${item.numero_da_parcela} - ${item.tipo.nome}` : item.tipo.nome}
                    </Button>
                  </Grid>
                </Grid>
              )
            })}

          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDam} color="danger">
              Cancelar
              </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
ProjetoMain = withRouter(ProjetoMain)
export default withStyles(contribuinteStyle)(ProjetoMain)
