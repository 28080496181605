import React from "react"
import {Link, withRouter} from "react-router-dom"
import {withStyles, Paper} from "@material-ui/core"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import Button from "../../components/CustomButtons/Button"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import AddIcon from "@material-ui/icons/Add"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Table from "../../components/Table/Table"
import TablePagination from "@material-ui/core/TablePagination"
import Lens from "@material-ui/icons/Lens"
import TrendingDown from "@material-ui/icons/TrendingDown"
import Print from "@material-ui/icons/Print"
import Edit from "@material-ui/icons/Edit"
import Close from "@material-ui/icons/Close"
import FileCopy from "@material-ui/icons/FileCopy"
import {get, search, imprimir, removeDam, put, lancaNaDivida, getEdit} from "../../util/damavulso"
import LoadingContent from "../../components/LoadingContent"
import Search from "../../components/Search"
import numeral from "../../util/numeral"
import Dialog from "@material-ui/core/Dialog"
import Typography from "@material-ui/core/Typography"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import AlterarVencimento from "./AlterarVencimento"

import {
    showNotification,
    showWaiting,
    hideWaiting,
    mapErrors
} from "../../util/Constants"
import housecall from "housecall"
import AdBlockDetect from "react-ad-block-detect"
import moment from "moment"

class DamAvulso extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: "",
            error: "",
            pageCount: 0,
            perPage: 0,
            page: 0,
            isSearch: false,
            dams: [],
            searchText: "",
            field: 0,
            venc: "",
            showAlterarVencimentoModal: false,
            modalRemove: false,
            id: "",
            copy: {},
            mensagem: "",
            historico: "",
            modalEdit: false,
            data_de_inscricao: moment(new Date()).format('YYYY-MM-DD'),
            errors: {}
        }
        this.handleFieldChange = this.handleFieldChange.bind(this)
        this.getData = this.getData.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.toggleLoading = this.toggleLoading.bind(this)
        this.imprimirDam = this.imprimirDam.bind(this)
        this.filter = this.filter.bind(this)
        this.queue = housecall({concurrency: 1, cooldown: 0})
        this.setLoadingFalse = this.setLoadingFalse.bind(this)
        this.closeLancarNaDividaModal = this.closeLancarNaDividaModal.bind(this)
        this.deleteDam = this.deleteDam.bind(this)
        this.showLancarNaDividaModal = this.showLancarNaDividaModal.bind(this)
        this.lancarNaDivida = this.lancarNaDivida.bind(this)
        this.areUSure = this.areUSure.bind(this)
        this.closeRemove = this.closeRemove.bind(this)
        this.openReplicar = this.openReplicar.bind(this)
        this.openEdit = this.openEdit.bind(this)
        this.closeEdit = this.closeEdit.bind(this)
        this.edit = this.edit.bind(this)
        this.handleChangeField = this.handleChangeField.bind(this)
        this.getEdit = this.getEdit.bind(this)

    }

    edit(event) {
        event.preventDefault()
        showWaiting()
        let params = {
            historico: this.state.historico,
            mensagem: this.state.mensagem
        }
        put(this.state.copy.id, params, (error, data) => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros! Tente novamente",
                    "danger"
                )
                this.setState({
                    errors: mapErrors(error)
                })
            } else {
                showNotification(
                    "top",
                    "center",
                    "DAM Avulso editado com sucesso!",
                    "success"
                )
                this.closeEdit()
                this.getData()
            }
        })
    }

    getEdit(key) {
        let dam = this.getDamById(this.state.dams[key].id)
        getEdit(dam.id, (error, data) => {
            if (data.length === 0) {
                this.setState(this.openEdit(key))
            } else {
                console.log(data)
                this.setState({isLoading: true})
                this.props.history.push("/damavulso/edit/" + this.state.dams[key].id)
            }
            this.setState({isLoading: false})
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            }
        })
    }

    openEdit(key) {
        let dam = this.getDamById(this.state.dams[key].id)
        this.setState({
            modalEdit: true,
            mensagem: dam.mensagem,
            historico: dam.historico,
            copy: dam
        })
    }

    getDamById = (id) => {
        return this.state.dams.filter(item => {
            return item.id === id
        })[0]
    }

    closeEdit() {
        this.setState({
            modalEdit: false
        })
    }

    areUSure(key) {
        this.setState({
            modalRemove: true,
            id: this.state.dams[key].id
        })
    }

    closeRemove() {
        this.setState({modalRemove: false})
    }

    deleteDam() {
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros! Tente novamente",
                    "danger"
                )
            } else {
                showNotification(
                    "top",
                    "center",
                    "DAM removido com sucesso!",
                    "success"
                )
                this.closeRemove()
                this.getData()
            }
        }
        removeDam(this.state.id, response)
    }

    setLoadingFalse() {
        this.setState({
            isLoading: false
        })
    }

    componentWillMount() {
        this.getData()
    }

    handlePageClick(data) {
        this.getData(data.selected + 1);
    }

    imprimirDam(key) {
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                const reader = new FileReader()
                reader.addEventListener("loadend", e => {
                    const text = e.srcElement.result
                    let error = JSON.parse(text)
                    let status = error.status
                    if (status === "vencido") {
                        console.log("date: ", this.state.dams[key].vencimento)
                        this.setState({
                            showAlterarVencimentoModal: true,
                            damIndex: key
                        })
                    }
                    showNotification(
                        "top",
                        "center",
                        `Ocorreram erros! ${error.message}`,
                        "danger"
                    )
                })
                reader.readAsText(error.response.data)
            } else {
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        var dam = this.state.dams[key]
        imprimir(dam.id, response)
    }

    handleChange = event => {
        this.setState({filterField: event.target.value}, () => {
            if (this.state.searchActive) {
                this.filter({target: {value: this.state.searchText}})
            }
        })
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    componentDidMount() {
        this.queue.on("idle", this.setLoadingFalse)
    }

    filter(event, pagina) {
        let searchText = event.target.value
        var newState = {
            isLoading: true,

            searchText: searchText,
            searchActive: searchText !== ""
        }
        if (pagina == null) {
            newState['page'] = 0
        }

        this.setState(newState
            ,
            () => {

                if (this.state.searchActive) {

                    let filtersCheckbox = this.state.filterField || ""
                    search(searchText, filtersCheckbox, undefined, (error, data) => {
                        this.setState({isLoading: false})
                        if (error) {
                            showNotification(
                                "top",
                                "center",
                                "Ocorreram erros, verifique sua conexão com a internet",
                                "danger"
                            )
                        } else {
                            if (this.state.searchText) {
                                this.setState({
                                    dams: data.data,
                                    pageCount: data.total,
                                    perPage: data.per_page,
                                })
                            }
                        }
                    }, pagina)
                } else {
                    this.getData()
                }
            }
        )
    }

    getData(page) {
        this.setState({isLoading: true})
        get(page, (error, data) => {
            this.setState({isLoading: false})
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                this.setState({
                    dams: data.data,
                    pageCount: data.total,
                    perPage: data.per_page,

                })
            }
        })
    }

    handleChangeField(event) {
        let name = event.target.name
        let value = event.target.value
        this.setState(state => ({
            [name]: value,
            errors: {...state.errors, [name]: undefined}
        }))
    }

    handleChangePage = (event, page) => {
        var pagina;
        pagina = page + 1;
        this.setState({page: page})
        if (page < 0) {
            return;
        }
        if (this.state.searchText.toString().trim() !== "") {
            this.filter({target: {value: this.state.searchText}}, pagina, page)
            return;
        }
        this.getData(pagina)
    };

    handleEdit(key) {
        this.props.history.push("/damavulso/edit/" + this.state.dams[key].id)
    }

    handleFieldChange(value) {
        this.setState({
            field: value
        })
    }

    handleClose = (name, status) => {
        this.setState({showAlterarVencimentoModal: false})
    }

    openReplicar(key) {
        this.props.history.push({
            pathname: '/damavulso/create',
            state: {copy: this.state.dams[key]}
        })
    }

    showLancarNaDividaModal(key) {
        this.setState({
            showLancarNaDividaModal: true,
            id: this.state.dams[key].id
        })
    }

    closeLancarNaDividaModal() {
        this.setState({
            showLancarNaDividaModal: false
        }, () => {
            this.getData()
        })
    }

    lancarNaDivida() {
        showWaiting()
        lancaNaDivida(this.state.id, this.state.data_de_inscricao, (error) => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros! Tente novamente",
                    "danger"
                )
                this.setState({
                    errors: mapErrors(error)
                })
            } else {
                showNotification(
                    "top",
                    "center",
                    "DAM lançado na dívida com sucesso",
                    "success"
                )
                this.closeLancarNaDividaModal()
            }
        })
    }

    render() {
        const {classes} = this.props
        const fields = [
            {value: "", label: "Todos os campos"},
            {value: "id", label: "Código DAM"},
            {value: "contribuinte_id", label: "Código do contribuinte"},
            {value: "contribuinte_cpf_cnpj", label: "Documento Contribuinte"},
            {value: "contribuinte_nome_razao_social", label: "Nome Razão Social"},
            {value: "vencimento", label: "Vencimento"},
            {value: "created_at", label: "Data de criação"},
            {value: "receita_id", label: "Código Receita"},
            {value: "inscricao", label: "Inscrição"},
            {value: "competencia", label: "Competência"}
        ]
        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container spacing={32} direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>Dam Avulso</h2>
                            </Grid>
                            <Grid item lg={2} xs={12}>
                                <Link to="/damavulso/create" style={{textDecoration: "none"}}>
                                    <Button block color="success" round>
                                        <AddIcon/> Adicionar
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <Search
                            label="Pesquise aqui"
                            value={this.state.filterField}
                            onChangeText={this.filter}
                            onChangeFields={this.handleChange}
                            fields={fields}
                        />
                        <Grid className={classes.legend} container direction="row">
                            <Lens className={classes.dot + " " + classes.dotDanger}/>
                            DAM Lançado na dívida
                            <Lens className={classes.dot + " " + classes.dotSuccess}/>
                            DAM Pago
                        </Grid>
                        <LoadingContent isLoading={this.state.isLoading}>
                            <Paper className={classes.tableWrapper}>
                                <Table
                                    getRowColor={(key, classes) => {
                                        if (this.state.dams[key].lancado_na_divida) {
                                            return classes.dangerTableRow
                                        } else if (this.state.dams[key].pago) {
                                            return classes.successTableRow
                                        } else {
                                            return ""
                                        }
                                    }}
                                    tableHeaderColor="primary"
                                    actions={[
                                        {
                                            title: "Editar",
                                            icon: (
                                                <Edit
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            ),
                                            onClick: this.getEdit
                                        },
                                        {
                                            title: "Lançar na dívida",
                                            icon: (
                                                <TrendingDown
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.close
                                                    }
                                                />
                                            ),
                                            onClick: this.showLancarNaDividaModal
                                        },
                                        {
                                            title: "Imprimir",
                                            icon: (
                                                <Print
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.print
                                                    }
                                                />
                                            ),
                                            onClick: this.imprimirDam
                                        },
                                        {
                                            title: "Replicar",
                                            icon: (
                                                <FileCopy
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.book
                                                    }
                                                />
                                            ),
                                            onClick: this.openReplicar
                                        },
                                        {
                                            title: "Remover",
                                            icon: (
                                                <Close
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            ),
                                            onClick: this.areUSure
                                        },

                                    ]}
                                    tableHead={[
                                        "Código do dam",
                                        "Código Contribuinte",
                                        "Contribuinte",
                                        "Receita",
                                        "Competência",
                                        "Valor",
                                        "Histórico"
                                    ]}
                                    tableData={this.state.dams.map((item, key) => {
                                        return [
                                            item.id + "",
                                            item.contribuinte_id,
                                            item.contribuinte.nome_razao_social,
                                            item.receita.nome,
                                            item.competencia,
                                            `R$ ${numeral(parseFloat(item.valor)).format(
                                                "0.0,00"
                                            )}`.slice(0, -2),
                                            item.historico,
                                            key
                                        ]
                                    })}
                                />
                                {(
                                    <TablePagination
                                        component="div"
                                        handlePageClick={this.handlePageClick}
                                        rowsPerPageOptions={[]}
                                        count={this.state.pageCount}
                                        rowsPerPage={this.state.perPage ? this.state.perPage : 0}
                                        page={this.state.page ? this.state.page : 0}
                                        backIconButtonProps={

                                            {"aria-label": "Previous Page"}}
                                        nextIconButtonProps={{
                                            "aria-label": "Next Page",
                                        }}
                                        SelectProps={{
                                            native: true
                                        }}

                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                )}
                            </Paper>
                        </LoadingContent>
                    </CardBody>
                </Card>

                <Dialog
                    id="body"
                    open={this.state.showAlterarVencimentoModal}
                    maxWidth="md"
                    fullWidth
                    titleStyle={{textAlign: "center"}}
                    onClose={e => this.handleClose("showAlterarVencimentoModal")}
                    aria-labelledby="form-dialog-title"
                    style={{zIndex: "4"}}
                >
                    <DialogTitle id="form-dialog-title">
                        <Typography variant="h5" align="center">
                            O DAM Selecionado se encontra vencido, deseja alterar seu
                            vencimento?
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <AlterarVencimento
                            damAvulso={this.state.dams[this.state.damIndex]}
                            handleClose={status =>
                                this.handleClose("showAlterarVencimentoModal", status)
                            }
                            fromModal={true}
                        />
                    </DialogContent>
                </Dialog>

                <AdBlockDetect>
                    <Dialog open={true} aria-labelledby="form-dialog-title">
                        <DialogContent>
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                                direction="row"
                            >
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                    <Typography variant="h5" gutterBottom>
                                        Por favor, desabilite o ADBlock para geração de baixa.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </AdBlockDetect>

                {/* dialog confirmacao */}
                <Dialog
                    open={this.state.modalRemove}
                    onClose={this.closeRemove}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{zIndex: "4"}}
                >
                    <DialogTitle id="alert-dialog-title">Você tem certeza que deseja remover esse DAM?</DialogTitle>

                    <DialogActions>
                        <Button onClick={this.closeRemove} color="danger">
                            Cancelar
                        </Button>
                        <Button onClick={this.deleteDam} color="success">
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.modalEdit}
                    onClose={this.closeEdit}
                    maxWidth="md"
                    fullWidth
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{zIndex: "4"}}
                >
                    <DialogTitle id="alert-dialog-title">Editar DAM Avulso</DialogTitle>
                    <DialogContent>
                        <Card>
                            <CardBody>
                                <Grid container spacing={16} direction="row">
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <TextField
                                            id="outlined-select-currency"
                                            name="mensagem"
                                            label="Mensagem"
                                            fullWidth
                                            value={this.state.mensagem}
                                            error={this.state.errors.mensagem}
                                            helperText={this.state.errors.mensagem}
                                            onChange={this.handleChangeField}
                                            margin="normal"
                                            variant="outlined"
                                            defaultValue={this.state.mensagem}
                                        />

                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={16}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <TextField
                                            label="Histórico"
                                            margin="normal"
                                            multiline
                                            rowsMax={4}
                                            variant="outlined"
                                            fullWidth
                                            value={this.state.historico}
                                            helperText={this.state.errors.historico}
                                            error={this.state.errors.historico}
                                            name="historico"
                                            onChange={this.handleChangeField}
                                        />
                                    </Grid>
                                </Grid>
                            </CardBody>
                        </Card>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeEdit} color="danger">
                            Cancelar
                        </Button>
                        <Button onClick={this.edit} color="success">
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showLancarNaDividaModal}
                    onClose={this.closeLancarNaDividaModal}
                    maxWidth="md"
                    fullWidth
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{zIndex: "4"}}
                >
                    <DialogTitle id="alert-dialog-title">Lançar DAM avulso na dívida ativa</DialogTitle>
                    <DialogContent>
                        <Card>
                            <CardBody>
                                <Grid container spacing={16} direction="row">
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <TextField
                                            id="outlined-select-currency"
                                            name="data_de_inscricao"
                                            label="Data de inscrição"
                                            fullWidth
                                            type="date"
                                            value={this.state.data_de_inscricao || moment(new Date()).format('YYYY-MM-DD')}
                                            error={this.state.errors.data_de_inscricao}
                                            helperText={this.state.errors.data_de_inscricao}
                                            onChange={this.handleChangeField}
                                            margin="normal"
                                            variant="outlined"
                                            defaultValue={this.state.data_de_inscricao}
                                        />

                                    </Grid>
                                </Grid>
                            </CardBody>
                        </Card>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeEdit} color="danger">
                            Cancelar
                        </Button>
                        <Button onClick={this.lancarNaDivida} color="success">
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
}

DamAvulso = withRouter(DamAvulso)
export default withStyles(contribuinteStyle)(DamAvulso)
