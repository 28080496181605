const INITIAL_STATE = {
  cadastrosTabSelected: 0,
  movimentosTabSelected: 0,
  movimentosAlvaraTabSelected: 0,
  movimentosITBITabSelected: 0,
  relatoriosITBITabSelected: 0,
  cadastrosAlvaraTabSelected: 0,
  alvaraRelatoriosTabSelected: 0,
  cadastrosGeraisTabSelected: 0,
  baixasmanuaisTabSelected: 0,
  projetosTabSelected: 0,
  ocupacaoTabSelected: 0,
  processamentoTabSelected: 0,
  cadastroDividaTabSelected: 0,
  movimentoDividaTabSelected: 0,
  relatoriosDividaTabSelected: 0,
  damAvulsoTabSelected: 0,
  relatoriosAlvaraTabSelected: 0,
  relatoriosDamAvulsoTabSelected: 0,
  relatoriosGeraisTabSelected: 0
}
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "CADASTROS_TAB_CHANGED":
      return Object.assign({}, state, {
        cadastrosTabSelected: action.payload
      })
    case "MOVIMENTOS_TAB_CHANGED":
      return Object.assign({}, state, {
        movimentosTabSelected: action.payload
      })
    case "MOVIMENTOS_ALVARA_TAB_CHANGED":
      return Object.assign({}, state, {
        movimentosAlvaraTabSelected: action.payload
      })
    case "CADASTROS_ALVARA_TAB_CHANGED":
      return Object.assign({}, state, {
        cadastrosAlvaraTabSelected: action.payload
      })
    case "ALVARA_RELATORIOS_TAB_CHANGED":
      return Object.assign({}, state, {
        alvaraRelatoriosTabSelected: action.payload
      })
    case "MOVIMENTOS_ITBI_TAB_CHANGED":
      return Object.assign({}, state, {
        movimentosITBITabSelected: action.payload
      })

    case "CADASTROS_GERAIS_TAB_CHANGED":
      return Object.assign({}, state, {
        cadastrosGeraisTabSelected: action.payload
      })

    case "BAIXAS_MANUAIS_TAB_CHANGED":
      return Object.assign({}, state, {
        baixasmanuaisTabSelected: action.payload
      })

    case "PROJETOS_TAB_CHANGED":
      return Object.assign({}, state, {
        projetosTabSelected: action.payload
      })

    case "OCUPACAO_TAB_CHANGED":
      return Object.assign({}, state, {
        ocupacaoTabSelected: action.payload
      })

    case "PROCESSAMENTO_TAB_CHANGED":
      return Object.assign({}, state, {
        processamentoTabSelected: action.payload
      })

    case "CADASTRO_DIVIDA_TAB_CHANGED":
      return Object.assign({}, state, {
        cadastroDividaTabSelected: action.payload
      })

    case "MOVIMENTO_DIVIDA_TAB_CHANGED":
      return Object.assign({}, state, {
        movimentoDividaTabSelected: action.payload
      })

    case "RELATORIOS_DIVIDA_TAB_CHANGED":
      return Object.assign({}, state, {
        relatoriosDividaTabSelected: action.payload
      })

    case "DAM_AVULSO_TAB_CHANGED":
      return Object.assign({}, state, {
        damAvulsoTabSelected: action.payload
      })
    case "RELATORIOS_ITBI_TAB_CHANGED":
      return Object.assign({}, state, {
        relatoriosITBITabSelected: action.payload
      })
    case "RELATORIOS_ALVARA_TAB_CHANGED":
      return Object.assign({}, state, {
        relatoriosAlvaraTabSelected: action.payload
      })
    case "RELATORIOS_DAMAVULSO_TAB_CHANGED":
      return Object.assign({}, state, {
        relatoriosDamAvulsoTabSelected: action.payload
      })
    case "RELATORIOS_GERAIS_TAB_CHANGED":
      return Object.assign({}, state, {
        relatoriosGeraisTabSelected: action.payload
      })

    default:
      return state
  }
}
