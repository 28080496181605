import React from "react"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"
import Print from "@material-ui/icons/Print"
import { search as searchContribuinte } from "../../util/contribuinte"
import { post, getField, print } from "../../util/protocoloInterno"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import CardFooter from "../../components/Card/CardFooter"
import Button from "../../components/CustomButtons/Button"
import AsyncSelect from "../../components/AsyncSelect"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import { withStyles, MenuItem } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import { showNotification, mapErrors, showWaiting, hideWaiting } from "../../util/Constants"

import LoadingContent from "../../components/LoadingContent"
import SearchFields from "../../components/SearchFields"
import moment from "moment"

class ProtocoloForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      areasAdministrativas: [],
      assuntos: [],
      situacoes: [],
      orgaos: [],
      data: moment(new Date).format("YYYY-MM-DD"),
      hora: moment(new Date).format("HH:mm")
    }
    this.getFields = this.getFields.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.print = this.print.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
  }

  handleClose() {
    this.setState({
      showFichaModal: false
    }, () => {
      this.props.history.push("/protocolo")
    })
  }
  loadOptions(inputValue, callback) {
    searchContribuinte(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let contribuintes = data.data.map(contribuinte => {
            return {
              value: contribuinte.id,
              label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                ""} - ${contribuinte.cpf_cnpj || ""}`
            }
          })

          this.setState({
            contribuintes: data.data
          }, () => {

          })
          callback(contribuintes)
        }
      }
    )
  }
  print() {
    showWaiting()
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {

        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        this.props.history.push("/protocolo")
      }
    }
    let protocolo = this.state.protocolo_id
    print(protocolo, response)
  }
  componentWillMount() {
    this.getFields()
  }
  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState(
      {
        [name]: event.target.value,
        errors: errors
      }
    )
  }


  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let params = {
      contribuinte_id: this.state.contribuinte_id ? this.state.contribuinte_id.value : undefined,
      ano_protocolo: this.state.ano_protocolo,
      data: this.state.data,
      hora: this.state.hora,
      data_retorno: this.state.data_retorno,
      assunto_id: this.state.assunto_id,
      orgao_id: this.state.orgao_id,
      area_administrativa_id: this.state.area_administrativa_id,
      situacao_id: this.state.situacao_id,
      observacao: this.state.observacao,
    }
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        showNotification(
          "top",
          "center",
          "Protocolo aberto com sucesso",
          "success"
        )
        this.setState({
          protocolo_id: data.id,
          showFichaModal: true
        })
      }
    }
    post(params, response)
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }

  getFields() {
    this.toggleLoading()
    let response = (error, data) => {
      this.toggleLoading()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState({
          areasAdministrativas: data.areas_administrativas,
          assuntos: data.assuntos,
          orgaos: data.orgaos,
          situacoes: data.situacoes,
        })
      }
    }

    getField(response)

  }

  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do contribuinte" },
      { value: "cpf_cnpj", label: "Documento" },
      { value: "endereco", label: "Endereço" },
      { value: "nome_fantasia", label: "Nome Fantasia" },
      { value: "nome_razao_social", label: "Razão Social" }
    ]
    return (
      <div className={classes.container}>
        <LoadingContent isLoading={this.state.isLoading}>
          <Card>
            <form onSubmit={this.handleSave}>
              <CardHeader color="primary">
                <Grid container direction="row">
                  <Grid item lg={10} xs={12}>
                    <h2 className={classes.cardTitleWhite}>
                      Abrir protocolo
                    </h2>
                  </Grid>
                </Grid>
              </CardHeader>
              <CardBody>
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      id="outlined-name"
                      label="Ano"
                      name="ano_protocolo"
                      required
                      fullWidth
                      type="number"
                      value={this.state.ano_protocolo}
                      onChange={this.handleChange}
                      error={this.state.errors.ano_protocolo}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      name="assunto_id"
                      label="Assunto"
                      fullWidth
                      value={this.state.assunto_id}
                      error={this.state.errors.assunto_id}
                      InputLabelProps={{ shrink: true }}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    >
                      {this.state.assuntos.map((option, key) => (
                        <MenuItem key={key} value={option.id}>
                          {option.nome}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      name="area_administrativa_id"
                      label="Área administrativa"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={this.state.area_administrativa_id}
                      error={this.state.errors.area_administrativa_id}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    >
                      {this.state.areasAdministrativas.map((option, key) => (
                        <MenuItem key={key} value={option.id}>
                          {option.nome}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      name="orgao_id"
                      label="Orgão"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={this.state.orgao_id}
                      error={this.state.errors.orgao_id}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    >
                      {this.state.orgaos.map((option, key) => (
                        <MenuItem key={key} value={option.id}>
                          {option.nome}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      name="situacao_id"
                      label="Situação"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={this.state.situacao_id}
                      error={this.state.errors.situacao_id}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    >
                      {this.state.situacoes.map((option, key) => (
                        <MenuItem key={key} value={option.id}>
                          {option.nome}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <TextField
                      id="outlined-name"
                      label="Data de abertura"
                      name="data"
                      required
                      fullWidth
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.data}
                      onChange={this.handleChange}
                      error={this.state.errors.data}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <TextField
                      id="outlined-name"
                      label="Hora de abertura"
                      name="hora"
                      required
                      fullWidth
                      type="time"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.hora}
                      onChange={this.handleChange}
                      error={this.state.errors.hora}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <TextField
                      id="outlined-name"
                      label="Data de retorno"
                      name="data_retorno"
                      required
                      fullWidth
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.data_retorno}
                      onChange={this.handleChange}
                      error={this.state.errors.data_retorno}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={16}>
                  <Grid container direction="row" style={{ marginTop: "2%" }}>
                    <Divider />
                    <Typography
                      className={classes.dividerFullWidth}
                      color="textSecondary"
                      variant="caption"
                    >
                      Filtro por Contribuinte
                            </Typography>
                  </Grid>
                  <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <SearchFields
                        name="filterField"
                        value={this.state.filterField || ""}
                        onChangeFields={this.handleChange}
                        fields={fields}
                      />
                      <AsyncSelect
                        className={
                          `form-control form-control-alternative form-select ${
                          this.props.error ? "has-danger" : ""
                          }`
                        }
                        value={this.state.contribuinte_id}
                        onChange={this.handleChange}
                        noResultsText="Nenhum resultado"
                        loadOptions={this.loadOptions}
                        loadingMessage={() => "Carregando..."}
                        error={this.state.errors.contribuinte_id}
                        helperText={this.state.errors.contribuinte_id}
                        name="contribuinte_id"
                        placeholder="Contribuinte"
                        message="Pesquise o contribuinte"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="outlined-select-currency"
                      name="observacao"
                      label="Observação"
                      fullWidth
                      error={this.state.errors.observacao}
                      helperText={this.state.errors.observacao}
                      multiline
                      rowsMax="4"
                      value={this.state.observacao}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardBody>
              <CardFooter>
                <Grid container justify="center">
                  <Grid item lg={2}>
                    <Button block color="info" round type="submit">
                      <AddIcon />{" "}
                      {this.props.match.params.id ? "Atualizar" : "Adicionar"}
                    </Button>
                  </Grid>
                </Grid>
              </CardFooter>
            </form>
          </Card>
          <Dialog
            open={this.state.showFichaModal}
            onClose={this.closeModal}
            aria-labelledby="form-dialog-title"
            fullWidth
            style={{ zIndex: "901" }}
          >
            <DialogTitle id="form-dialog-title">Deseja imprimir o protocolo?</DialogTitle>
            <DialogContent>
              <DialogContent>
                <Grid container justify="center">
                  <Grid item lg={4}>
                    <Button
                      block
                      color="info"
                      round
                      onClick={this.print}
                    >
                      <Print /> Imprimir protocolo
                      </Button>
                  </Grid>
                </Grid>
              </DialogContent>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="danger">
                Voltar
            </Button>
            </DialogActions>
          </Dialog>
        </LoadingContent>
      </div>
    )
  }
}

export default withStyles(contribuinteStyle)(ProtocoloForm)
