import React from "react"

import Grid from "@material-ui/core/Grid"
import numeral from "../util/numeral"

class ValoresDemonstrativo extends React.Component {
    shouldComponentUpdate(nextProps) {
        var shouldUpdate = false

        Object.keys(nextProps).forEach(key => {
            var currentProp = this.props[key]
            var nextProp = nextProps[key]
            if (shouldUpdate === false) {
                shouldUpdate = !(nextProp === currentProp)
            }
        })
        return shouldUpdate
    }

    render() {
        return (
            <div>
                <Grid alignItems="center" justify="flex-center" container direction="row" spacing={8} >
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <strong className="text-black">Receita: </strong><span className="text-black font-regular">{this.props.divida.receita_id} - {this.props.divida.receita.nome} </span><br />
                        <strong className="text-black">Competência: </strong><span className="text-black font-regular">{this.props.divida.competencia}  </span> <br />
                        <strong className="text-black">Código de origem: </strong><span className="text-black font-regular">{this.props.divida.codigo_de_origem}  </span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <strong className="text-black">Valor original: </strong><span className="text-black font-regular">{"R$" +
                            numeral(parseFloat(this.props.divida.valor))
                                .format("0.0,00")
                                .slice(0, -2)}</span><br />
                        <strong className="text-black">Valor pago: </strong><span className="text-black font-regular">{"R$" +
                            numeral(parseFloat(this.props.divida.valor_pago))
                                .format("0.0,00")
                                .slice(0, -2)}</span><br />
                        <strong className="text-black">Saldo devedor: </strong><span className="text-black font-regular">{"R$" +
                            numeral(parseFloat(this.props.divida.saldo_devedor))
                                .format("0.0,00")
                                .slice(0, -2)}</span><br />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <strong className="text-black">Correção monetária: </strong><span className="text-black font-regular">{"R$" +
                            numeral(parseFloat(this.props.divida.correcao_monetaria))
                                .format("0.0,00")
                                .slice(0, -2)}</span><br />
                        <strong className="text-black">Multa: </strong><span className="text-black font-regular">{"R$" +
                            numeral(parseFloat(this.props.divida.multa))
                                .format("0.0,00")
                                .slice(0, -2)}</span><br />
                        <strong className="text-black">Juros: </strong><span className="text-black font-regular">{"R$" +
                            numeral(parseFloat(this.props.divida.juros))
                                .format("0.0,00")
                                .slice(0, -2)}</span><br />
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2}>
                        <h3>Total a pagar: {"R$" + numeral(parseFloat(this.props.divida.saldo_devedor) + parseFloat(this.props.divida.correcao_monetaria) + parseFloat(this.props.divida.multa) + parseFloat(this.props.divida.juros)).format("0.0,00")
                            .slice(0, -2)}</h3>
                    </Grid>
                </Grid>
            </div >
        )
    }
}

export default ValoresDemonstrativo