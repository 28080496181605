import React from "react"

import { withRouter } from 'react-router-dom';

import { withStyles } from "@material-ui/core"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import MenuDashboard from "../MenuDashboard"

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import theme from "../../assets/css/theme"
import { connect } from "react-redux"
import { cadastrosGeraisTabChanged } from "../../actions"
import Subject from "@material-ui/icons/Subject"
import AssignmentLate from "@material-ui/icons/AssignmentLate"
import { bindActionCreators } from "redux"
import CertidaoNegativa from "./CertidaoNegativa";
import CertidaoNegativaIptu from "../Iptu/Movimento/CertidaoNegativaIptu";
import CertidaoPositiva from "./CertidaoPositiva";
import Pendencias from "./Pendencias";
import RelatorioAverbacao from "./RelatorioAverbacao";

const tab = [
  {
    name: "Certidão Negativa de Débito",
    icon: <Subject />
  },
  {
    name: "Certidão Negativa de IPTU",
    icon: <Subject />
  },
  {
    name: "Certidão Positiva",
    icon: <Subject />
  },
  {
    name: "Relatório de Averbação",
    icon: <Subject />
  },
  {
    name: "Pendencias",
    icon: <AssignmentLate />
  }
]

class MovimentosGerais extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      field: 0
    }
    this.handleFieldChange = this.handleFieldChange.bind(this)
  }

  handlePageClick(data) { }

  handleChange = event => {
    this.setState({ value: event.target.value })
  }

  handleChangePage = (event, page) => {
    if ((page || 1) !== this.state.page) {
      this.setState({ page }, () => { this.getData(this.state.page) })
    }
  }

  handleFieldChange(value) {
    this.props.cadastrosGeraisTabChanged(value)
  }
  render() {
    const { classes, field } = this.props
    return (
      <div className={classes.container}>
        <MuiThemeProvider theme={theme}>
          <MenuDashboard
            names={tab}
            onChange={this.handleFieldChange}
            value={field}
          />
          {field === 0 && <CertidaoNegativa />}
          {field === 1 && <CertidaoNegativaIptu />}
          {field === 2 && <CertidaoPositiva />}
          {field === 3 && <RelatorioAverbacao />}
          {field === 4 && <Pendencias />}
        </MuiThemeProvider>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    field: state.tabLayout.cadastrosGeraisTabSelected
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ cadastrosGeraisTabChanged }, dispatch)
}

MovimentosGerais = withRouter(MovimentosGerais)
MovimentosGerais = connect(
  mapStateToProps,
  mapDispatchToProps
)(MovimentosGerais)
export default withStyles(contribuinteStyle)(MovimentosGerais)
