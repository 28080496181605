import React from "react";
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom";
import { withStyles, Paper } from "@material-ui/core";
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle";
import Button from "../../components/CustomButtons/Button";
import TextField from "@material-ui/core/TextField"

import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Table from "../../components/Table/Table";
import Edit from "@material-ui/icons/Edit";
import { get, search } from "../../util/taxa";
import LoadingContent from "../../components/LoadingContent";
import { showNotification } from "../../util/Constants";
import housecall from "housecall";
class Taxas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      error: "",
      pageCount: 0,
      perPage: 0,
      page: 1,
      taxas: [],
      taxasFiltered: [],
      searchText: "",
      field: 0
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.getData = this.getData.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.filter = this.filter.bind(this);
    this.queue = housecall({ concurrency: 1, cooldown: 0 });
    this.searchFilter = this.searchFilter.bind(this)
    this.setLoadingFalse = this.setLoadingFalse.bind(this)
  }
  setLoadingFalse() {
    this.setState({
      isLoading: false
    })
  }

  componentWillMount() {
    this.getData();
  }
  searchFilter(value) {
    let filtered = this.state.taxas
    if (value !== '') {
      this.setState({
        search: true
      })
    } else {
      this.setState({
        search: false
      })
    }
    filtered = filtered.filter((filter) => {
      if (filter.descricao !== null) {
        return filter.descricao.indexOf(value.toUpperCase()) !== -1;
      } else {
        return null
      }
    })
    this.setState({ taxasFiltered: filtered })
  }

  handleChange = event => {
    this.setState({ filterField: event.target.value }, () => {
      if (this.state.searchActive) {
        this.filter({ target: { value: this.state.searchText } });
      }
    });
  };
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }));
  }
  componentDidMount() {
    this.queue.on("idle", this.setLoadingFalse);
  }
  filter(event) {
    let searchText = event.target.value;
    this.setState(
      {
        searchText: searchText,
        searchActive: searchText !== ""
      },
      () => {
        if (this.state.searchActive) {
          if (!this.state.isLoading) {
            this.toggleLoading();
          }
          let filtersCheckbox = this.state.filterField || "";
          this.queue.push(() =>
            search(searchText, filtersCheckbox, undefined, (error, data) => {
              if (error) {
                showNotification(
                  "top",
                  "center",
                  "Ocorreram erros, verifique sua conexão com a internet",
                  "danger"
                );
              } else {
                this.setState({
                  taxas: data
                });
              }
            })
          );
        } else {
          this.getData();
        }
      }
    );
  }
  getData(page) {
    if (!page) {
      this.toggleLoading();
    }
    get(page, (error, data) => {
      if (!page) {
        this.toggleLoading();
      }
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        this.setState({
          taxas: data,
        });
      }
    });
  }

  handleEdit(key) {
    this.props.history.push(
      "/taxas/edit/" + (this.state.search ? this.state.taxasFiltered : this.state.taxas)[key].id
    );
  }

  handleFieldChange(value) {
    this.setState({
      field: value
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Taxas</h2>
              </Grid>
              <Grid item lg={2} xs={12}>
                <Link
                  to="/taxas/create"
                  style={{ textDecoration: "none" }}
                >
                  <Button block color="success" round>
                    <AddIcon /> Adicionar
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <form noValidate autoComplete="off">
              <TextField
                id="standard-search"
                label="Pesquisar"
                fullWidth
                type="search"
                name="search"
                onChange={(event) => this.searchFilter(event.target.value)}
                className={classes.textField}
                margin="normal"
              />
            </form>
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  tableHeaderColor="primary"
                  actions={[
                    {
                      title: "Editar",
                      icon: (
                        <Edit
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      ),
                      onClick: this.handleEdit
                    }
                  ]}
                  tableHead={["#", "Descrição"]}
                  tableData={(this.state.search ? this.state.taxasFiltered : this.state.taxas).map((item, key) => {
                    return [item.id + "", item.descricao, key];
                  })}
                />
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>
      </div>
    );
  }
}
Taxas = withRouter(Taxas)
export default withStyles(contribuinteStyle)(Taxas);
