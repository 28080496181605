import React from "react";

import { withStyles } from "@material-ui/core";
import contribuinteStyle from "../../../assets/css/layout/contribuinteStyle";
import MenuDashboard from "../../MenuDashboard";

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import theme from "../../../assets/css/theme";
import LogradouroRelatorio from "./LogradouroRelatorio";
import { connect } from "react-redux";
import { cadastrosTabChanged } from "../../../actions";
import Home from "@material-ui/icons/Home";
import Assessment from "@material-ui/icons/Assessment";
import NewRealeses from "@material-ui/icons/NewReleases"
import Gavel from "@material-ui/icons/Gavel";
import Assignment from "@material-ui/icons/Assignment";
import AttachMoney from "@material-ui/icons/AttachMoney"
import Subject from "@material-ui/icons/Subject";
import PictureInPicture from "@material-ui/icons/PictureInPicture";
import { bindActionCreators } from "redux";
import SecoesRelatorio from "./SecoesRelatorio";
import LoteamentoRelatorio from "./LoteamentoRelatorio";
import MotivosRelatorio from "./MotivosRelatorio";
import CarneLancadoRelatorio from "./CarneLancadoRelatorio";
import ImoveisRelatorio from "./ImoveisRelatorio";
import IptuPagoRelatorio from "./IptuPagoRelatorio";

const tab = [
  {
    name: "Logradouros",
    icon: <Subject />
  },
  {
    name: "Seção de Logradouros",
    icon: <Assignment />
  },
  {
    name: "Imóveis",
    icon: <Home />
  },
  {
    name: "IPTU Pago",
    icon: <AttachMoney />
  },
  {
    name: "Loteamentos",
    icon: <PictureInPicture />
  },
  {
    name: "Carnês Lançados",
    icon: <NewRealeses />
  },
  {
    name: "Parâmetros do carnê",
    icon: <Assessment />
  },
  {
    name: "Motivos de IPTU",
    icon: <Gavel />
  }
];

class Relatorio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      error: "",
      pageCount: 0,
      perPage: 0,
      page: 1,
      contribuintes: [],
      field: 0
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);
  }

  handlePageClick(data) { }

  handleChange = event => {
    this.setState({ value: event.target.value });
  };

  handleChangePage = (event, page) => {
    if ((page || 1) !== this.state.page) {
      this.setState({ page }, () => { this.getData(this.state.page) })
    }
  };

  handleFieldChange(value) {
    this.props.cadastrosTabChanged(value);
  }
  render() {
    const { classes, field } = this.props;
    return (
      <div className={classes.container}>
        <MuiThemeProvider theme={theme}>
          <MenuDashboard
            names={tab}
            onChange={this.handleFieldChange}
            value={field}
          />
          {field === 0 && <LogradouroRelatorio />}
          {field === 1 && <SecoesRelatorio />}
          {field === 2 && <ImoveisRelatorio />}
          {field === 3 && <IptuPagoRelatorio />}
          {field === 4 && <LoteamentoRelatorio />}
          {field === 5 && <CarneLancadoRelatorio />}
          {field === 7 && <MotivosRelatorio />}
        </MuiThemeProvider>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    field: state.tabLayout.cadastrosTabSelected
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ cadastrosTabChanged }, dispatch);
}
Relatorio = connect(
  mapStateToProps,
  mapDispatchToProps
)(Relatorio);
export default withStyles(contribuinteStyle)(Relatorio);
