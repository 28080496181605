import React from "react"

import withStyles from "@material-ui/core/styles/withStyles"
import loginStyle from "../../assets/css/layout/loginStyle"

import CustomInput from "../../components/CustomInput/CustomInput.jsx"
import Button from "../../components/CustomButtons/Button"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import CardFooter from "../../components/Card/CardFooter"
import GridContainer from "../../components/Grid/GridContainer"
import GridItem from "../../components/Grid/GridItem"

import { login } from "../../util/authApi"
import LoadingContent from "../../components/LoadingContent"
import { showNotification } from "../../util/Constants"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { userChanged } from "../../actions"

export class Login extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.ActionLink = this.ActionLink.bind(this)
    this.state = {
      username: "",
      password: "",
      error: ""
    }
  }
  toggleLoad() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  handleChange(e) {
    let name = e.target.name
    let value = e.target.value
    this.setState({
      [name]: value,
      error: ""
    })
  }
  ActionLink(e) {
    e.preventDefault()
    let params = {
      username: this.state.username,
      password: this.state.password
    }
    this.toggleLoad()
    login(params, error => {
      this.toggleLoad()
      if (error) {
        showNotification("top", "center", "Credenciais inválidas", "danger")
      } else {
        let user = JSON.parse(localStorage.me)
        this.props.userChanged(user)
        this.props.history.push("/dashboard")
      }
    })
  }
  render() {
    const { classes } = this.props
    return (
      <div className={classes.content}>
        <div className={classes.background} />
        <div>
          <form onSubmit={this.ActionLink}>
            <GridContainer alignItems="center" justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <CardHeader className={classes.textCenter} color="primary">
                    <h2 className={classes.cardTitleWhite}>
                      Sistema de Arrecadação Municipal
                    </h2>
                    <small className={classes.header}>
                      Digite suas credenciais
                    </small>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Usuário"
                          id="username"
                          inputProps={{
                            disabled: this.state.isLoading,
                            onChange: this.handleChange,
                            name: "username"
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Senha"
                          id="password"
                          inputProps={{
                            disabled: this.state.isLoading,
                            onChange: this.handleChange,
                            name: "password",
                            type: "password"
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter className={classes.textCent}>
                    <LoadingContent isLoading={this.state.isLoading}>
                      <Button color="primary" type="submit">
                        Entrar
                      </Button>
                    </LoadingContent>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          </form>
        </div>
      </div>
    )
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ userChanged }, dispatch)
}
Login = connect(
  null,
  mapDispatchToProps
)(Login)
export default withStyles(loginStyle)(Login)
