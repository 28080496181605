import React, { Component } from "react"
import AsyncSelect from "react-select/lib/Async"

export default class SelectCustom extends Component {
  shouldComponentUpdate(nextProps) {
    var shouldUpdate = false

    Object.keys(nextProps).forEach(key => {
      var currentProp = this.props[key]
      var nextProp = nextProps[key]
      if (shouldUpdate === false) {
        shouldUpdate = !(nextProp === currentProp)
      }
    })
    return shouldUpdate
  }

  render() {
    return (
      <div className="form-group">
        <label className="form-control-label" htmlFor={this.props.name}>
          {this.props.label}
        </label>
        <AsyncSelect
          className={this.props.className}
          style={this.props.customStyle}
          isDisabled={this.props.disabled}
          isClearable="true"
          placeholder={this.props.placeholder}
          noResultsText="Nenhum resultado"
          loadOptions={this.props.loadOptions}
          value={this.props.value}
          loadingMessage={() => "Carregando..."}
          noOptionsMessage={() => this.props.message}
          isMulti={this.props.isMulti || false}
          isSearchable={this.props.isSearchable || true}
          onChange={selected =>
            this.props.onChange({
              target: { value: selected, name: this.props.name }
            })
          }
        />

        {this.props.error && (
          <span className="text-danger error-span">{this.props.error}</span>
        )}
      </div>
    )
  }
}
