import React from "react"
import TextField from "@material-ui/core/TextField"
import { withStyles, Paper } from "@material-ui/core"
import logradouroStyle from "../../assets/css/layout/logradouroStyle"
import Button from "../../components/CustomButtons/Button"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import AddIcon from "@material-ui/icons/Add"
import Grid from "@material-ui/core/Grid"
import { showNotification, mapErrors } from "../../util/Constants"
import Table from "../../components/Table/Table"
import TablePagination from "@material-ui/core/TablePagination"
import Edit from "@material-ui/icons/Edit"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { get, post, put } from "../../util/agentes"
import LoadingContent from "../../components/LoadingContent"
import housecall from "housecall"

class Agentes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      errors: {},
      nome: "",
      banco: "",
      agencia: "",
      conta: "",
      pageCount: 0,
      perPage: 0,
      page: 1,
      agentes: [],
      agentesFiltered: [],
      search: false,
      field: 0,
      modal: false,
      id: 0
    }
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.getData = this.getData.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.searchFilter = this.searchFilter.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
  }

  searchFilter(value) {
    let filtered = this.state.agentes
    if(value !== ''){
      this.setState({
        search: true
      })
    } else {
      this.setState({
        search: false
      })
    }
    filtered = filtered.filter((filter) => {
      if(filter.nome !== null) {
        return filter.nome.indexOf(value.toUpperCase()) !== -1;
      } else {
        return null
      }
    })
    this.setState({ agentesFiltered: filtered })
  }

  componentWillMount() {
    this.getData()
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: event.target.value,
      errors: errors
    })
  }

  handleChangeFilters = event => {
    this.setState({ filterField: event.target.value }, () => {
      if (this.state.searchActive) {
        this.filter({ target: { value: this.state.searchText } })
      }
    })
  }

  componentDidMount() {
    this.queue.on("idle", this.toggleLoading)
  }

  handleOpen = () => {
    this.setState({ modal: true })
  }

  handleClose = () => {
    this.setState(
      { id: 0, modal: false, nome: "", banco: "", conta: "", agencia: "" },
      () => {
        this.getData(this.state.page)
      }
    )
  }

  handleSave(event) {
    event.preventDefault()
    let params = {
      nome: this.state.nome,
      banco: this.state.banco,
      agencia: this.state.agencia,
      conta: this.state.conta
    }
    let response = error => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        showNotification(
          "top",
          "center",
          `Agentes ${this.state.id ? "editado" : "incluído"} com sucesso`,
          "success"
        )
        this.handleClose()
      }
    }
    this.state.id
      ? put(this.state.id, params, response)
      : post(params, response)
  }

  getData(page) {
    if (!page) {
      this.toggleLoading()
    }
    get(page, (error, data) => {
      if (!page) {
        this.toggleLoading()
      }
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        this.setState({
          agentes: data.data,
          agentesFiltered: data.data,
          pageCount: data.total,
          perPage: data.per_page
        })
      }
    })
  }
  handleChangePage = (event, page) => {
    if ((page || 1) !== this.state.page) {
      this.setState({ page }, () => { this.getData(this.state.page) })
    }
  }

  handleEdit(key) {
    this.setState(
      {
        nome: this.state.agentes[key].nome,
        banco: this.state.agentes[key].banco,
        agencia: this.state.agentes[key].agencia,
        conta: this.state.agentes[key].conta,
        id: this.state.agentes[key].id
      },
      () => {
        this.handleOpen()
      }
    )
  }

  handleFieldChange(value) {
    this.setState({
      field: value
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Agentes Recebedores</h2>
              </Grid>
              <Grid item lg={2} xs={12}>
                <Button block color="success" round onClick={this.handleOpen}>
                  <AddIcon /> Adicionar
                </Button>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
          <TextField
                id="standard-search"
                label="Pesquisar por ano"
                fullWidth
                type="search"
                name="search"
                onChange={(event) => this.searchFilter(event.target.value)}
                className={classes.textField}
                margin="normal"
              />
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  tableHeaderColor="primary"
                  actions={[
                    {
                      title: "Editar",
                      icon: (
                        <Edit
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      ),
                      onClick: this.handleEdit
                    }
                  ]}
                  tableHead={["#", "Nome", "Banco", "Agência", "Conta"]}
                  tableData={(this.state.search ? this.state.agentesFiltered : this.state.agentes).map((item, key) => {
                    return [
                      item.id + "",
                      item.nome,
                      item.banco,
                      item.agencia,
                      item.conta,
                      key
                    ]
                  })}
                />
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[]}
                  count={this.state.pageCount}
                  rowsPerPage={parseInt(this.state.perPage)}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page"
                  }}
                  SelectProps={{
                    native: true
                  }}
                 
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>

        <Dialog
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth
        >
          <form onSubmit={this.handleSave}>
            <DialogTitle id="form-dialog-title">
              {this.state.id ? "Atualizar" : "Adicionar"} Agentes Recebedores
            </DialogTitle>
            <DialogContent>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="outlined-select-currency"
                    name="nome"
                    label="Nome"
                    required
                    fullWidth
                    error={this.state.errors.nome}
                    helperText={this.state.errors.nome}
                    value={this.state.nome}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="outlined-select-currency"
                    name="banco"
                    label="Banco"
                    required
                    fullWidth
                    type="number"
                    error={this.state.errors.banco}
                    helperText={this.state.errors.banco}
                    value={this.state.banco}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="outlined-select-currency"
                    name="agencia"
                    label="Agência"
                    required
                    fullWidth
                    type="number"
                    error={this.state.errors.agencia}
                    helperText={this.state.errors.agencia}
                    value={this.state.agencia}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="outlined-select-currency"
                    name="conta"
                    label="Conta"
                    required
                    fullWidth
                    type="number"
                    error={this.state.errors.conta}
                    helperText={this.state.errors.conta}
                    value={this.state.conta}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="danger">
                Cancelar
              </Button>
              <Button type="submit" color="success">
                {this.state.id ? "Atualizar" : "Adicionar"}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(logradouroStyle)(Agentes)
