import axios from "axios"
import { defaultHeaders } from "./Constants"


export function get(page, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + `parcelasUnicas${page ? `?page=${page}` : ""}`
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}
export function post(params, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "parcelasUnicas"
    axios
        .post(url, params, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function print(id, callBack) {
    let headers = defaultHeaders()
    let config = {
        headers: headers,
        responseType: "blob"
    }
    let url =
        process.env.REACT_APP_API +
        `parcelasUnicas/${id}`
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function destroy(id, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "parcelasUnicas/" + id
    axios
        .delete(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}