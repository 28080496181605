import React from "react"
import secaoStyle from "../../../assets/css/layout/secaoStyle"
import { withStyles } from "@material-ui/core"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import { relatorio } from "../../../util/damavulso"
import { showWaiting, hideWaiting, showNotification, mapErrors } from "../../../util/Constants"
import Button from "../../../components/CustomButtons/Button"
import moment from "moment"

class RelatorioDamAvulso extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data_inicial: new Date(),
            data_final: new Date(),
            errors: {}
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        let value = event.target.value
        errors[name] = undefined

        this.setState({
            [name]: value,
            errors: errors
        })
    }

    handleSave(event) {
        event.preventDefault()
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            console.log("error: ", error)
            console.log("data", data)
            if (error) {
                const reader = new FileReader()
                reader.addEventListener("loadend", e => {
                    const text = e.srcElement.result
                    try {
                        let error = JSON.parse(text)
                        var ob = {
                            response: {
                                data: error
                            }
                        }
                        this.setState({
                            errors: mapErrors(ob)
                        })
                    } catch (e) {
                        console.log(e)
                        showNotification(
                            "top",
                            "center",
                            `Ocorreram erros! ${error.message}`,
                            "danger"
                        )
                    }
                })
                reader.readAsText(error.response.data)

            } else {
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        relatorio(moment(this.state.data_inicial).format("YYYY-MM-DD"), moment(this.state.data_final).format("YYYY-MM-DD"), response)
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>Relatório de Arrecadação de DAM Avulso</h2>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <Grid container direction="row" style={{ marginTop: "2%" }} spacing={16}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    id="outlined-select-currency"
                                    type="date"
                                    name="data_inicial"
                                    label="Data Inicial"
                                    fullWidth
                                    error={this.state.errors.data_inicial}
                                    helperText={this.state.errors.data_inicial}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue={moment(this.state.data_inicial).format("YYYY-MM-DD")}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    id="outlined-select-currency"
                                    type="date"
                                    name="data_final"
                                    label="Data Final"
                                    fullWidth
                                    error={this.state.errors.data_final}
                                    helperText={this.state.errors.data_final}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue={moment(this.state.data_final).format("YYYY-MM-DD")}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                    </CardBody>
                    <CardFooter>
                        <Grid container justify="center">
                            <Grid item lg={2}>
                                <Button block color="info" round onClick={this.handleSave}>
                                    Gerar
                            </Button>
                            </Grid>
                        </Grid>
                    </CardFooter>
                </Card>
            </div>
        )
    }
}

export default withStyles(secaoStyle)(RelatorioDamAvulso)