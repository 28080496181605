import axios from "axios"
import { defaultHeaders, makeRequestCreator } from "./Constants"

export function get(page, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url =
        process.env.REACT_APP_API + `protocolo${page ? `?page=${page}` : ""}`
    return axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

const searchGet = makeRequestCreator()
export function search(searchText, field, onStart, callBack,pagina) {
    let url =
        process.env.REACT_APP_API +
        `search/protocolo?text=${searchText}&fields=${field}`
    if(pagina != undefined){
        url += `&page=${pagina}`
    }

    return searchGet(url, callBack)
}


export function destroy(id, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "protocolo/" + id
    axios
        .delete(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}


export function print(id, callBack) {
    let headers = defaultHeaders()
    let config = {
        headers: headers,
        responseType: "blob"
    }
    let url =
        process.env.REACT_APP_API + `protocolo/${id}`
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function getField(callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "protocolo/create"
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function post(params, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "protocolo"
    axios
        .post(url, params, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}