import React from "react"
import { Link } from "react-router-dom"
import { withStyles, Paper } from "@material-ui/core"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import Button from "../../components/CustomButtons/Button"
import Close from "@material-ui/icons/Close"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import AddIcon from "@material-ui/icons/Add"
import Grid from "@material-ui/core/Grid"
import Table from "../../components/Table/Table"
import TablePagination from "@material-ui/core/TablePagination"
import Print from "@material-ui/icons/Print"
import { get, search, destroy, print } from "../../util/protocoloInterno"
import LoadingContent from "../../components/LoadingContent"
import Search from "../../components/Search"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import {
  showNotification,
  hideWaiting,
  showWaiting
} from "../../util/Constants"
import housecall from "housecall"
import moment from "moment"
class Protocolos extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      pageCount: 0,
      perPage: 0,
      page: 0,
      isSearch:false,
      protocolos: [],
      searchText: "",
      field: 0,
      modalRemover: false,
      id: ""
    }
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.print = this.print.bind(this)
    this.getData = this.getData.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.filter = this.filter.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
    this.setLoadingFalse = this.setLoadingFalse.bind(this)
    this.openRemover = this.openRemover.bind(this)
    this.closeRemover = this.closeRemover.bind(this)
    this.remover = this.remover.bind(this)
  }

  remover() {
    showWaiting()
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros! Tente novamente",
          "danger"
        )
      } else {
        showNotification(
          "top",
          "center",
          "Protocolo removido com sucesso!",
          "success"
        )
        this.closeRemover()
        this.getData()
      }
    }
    destroy(this.state.id, response)
  }

  openRemover(key) {
    this.setState({
      modalRemover: true,
      id: this.state.protocolos[key].id
    })
  }
  print(key) {
    showWaiting()
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {

        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    let protocolo = this.state.protocolos[key].id
    print(protocolo, response)
  }
  closeRemover() {
    this.setState({
      modalRemover: false
    })
  }

  componentWillMount() {
    this.getData()
  }

  handlePageClick(data) {
    //this.getData(data.selected + 1);
  }

  handleChange = event => {
    this.setState({ filterField: event.target.value }, () => {
      if (this.state.searchActive) {
        this.filter({ target: { value: this.state.searchText } })
      }
    })
  }


  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  setLoadingFalse() {
    this.setState({
      isLoading: false
    })
  }

  filter(event,pagina) {
    let searchText = event.target.value
    var newState =     {
      isLoading:true,
      searchText: searchText,
      searchActive: searchText !== ""
    }
    if(pagina == null){
      newState["page"] = 0
    }
    this.setState(
      newState
  ,
      () => {
        if (this.state.searchActive) {
          let filtersCheckbox = this.state.filterField || ""
          search(searchText, filtersCheckbox, undefined, (error, data) => {
            this.setState({isLoading:false})
            if (error) {
              showNotification(
                "top",
                "center",
                "Ocorreram erros, verifique sua conexão com a internet",
                "danger"
              )
            } else {
              if (this.state.searchText !== "") {
                this.setState({
                  protocolos: data.data,
                  pageCount: data.total,
                  perPage: data.per_page,
                
                })
              }
            }
          },pagina)
        } else {
          this.getData()
        }
      }
    )
  }
  getData(page) {
    this.setState({isLoading:true})
  
    get(page, (error, data) => {
      this.setState({isLoading:false})
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState({
          protocolos: data.data,
          pageCount: data.total,
          perPage: data.per_page,
         
        })
      }
    })
  }
  handleChangePage = (event, page) => {
    var pagina;
    pagina = page + 1;
    this.setState({page: page})
    if(page < 0){
      return;
    }
    if (this.state.searchText.toString().trim() != "") {
      this.filter({target: {value: this.state.searchText}}, pagina, page)
      return;
    }
    this.getData(pagina)
  };


  handleFieldChange(value) {
    this.setState({
      field: value
    })
  }

  render() {
    const { classes } = this.props
    // assunto_nome, area_administrativa_id, area_administrativa_nome, contribuinte_nome_razao_social, data, situacao_id, situacao_nome
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do protocolo" },
      { value: "contribuinte_id", label: "Código do contribuinte" },
      // { value: "orgao_id", label: "Endereço" },
      { value: "orgao_nome", label: "Nome do orgão" },
      // { value: "assunto_id", label: "Razão Social" },
      { value: "assunto_nome", label: "Assunto" },
      // { value: "area_administrativa_id", label: "Razão Social" },
      { value: "area_administrativa_nome", label: "Área administrativa" },
      { value: "contribuinte_nome_razao_social", label: "Nome/razão social" },
      { value: "data", label: "Data de abertura (dd/mm/aaaa)" },
      // { value: "situacao_id", label: "Razão Social" },
      { value: "situacao_nome", label: "Situação" },
    ]
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container spacing={32} direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Protocolo Interno</h2>
              </Grid>
              <Grid item lg={2} xs={12}>
                <Link
                  to="/protocolo/create"
                  style={{ textDecoration: "none" }}
                >
                  <Button block color="success" round>
                    <AddIcon /> Novo
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Search
              label="Pesquise aqui"
              value={this.state.filterField}
              onChangeText={this.filter}
              onChangeFields={this.handleChange}
              fields={fields}
            />
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  tableHeaderColor="primary"
                  actions={[
                    {
                      title: "Remover",
                      icon: (
                        <Close
                          className={
                            classes.tableActionButtonIcon + " " + classes.close
                          }
                        />
                      ),
                      onClick: this.openRemover
                    }, {
                      title: "Imprimir protocolo",
                      icon: (
                        <Print
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      ),
                      onClick: this.print
                    },
                  ]}
                  tableHead={[
                    "Código",
                    "Ano",
                    "Número",
                    "Data de abetura",
                    "Hora de abertura",
                    "Data de retorno",
                    "Contribuinte",
                    "Assunto",
                    "Ações"
                  ]}
                  tableData={this.state.protocolos.map((item, key) => {
                    return [
                      item.id + "",
                      item.ano_protocolo,
                      item.numero_protocolo,
                      moment(item.data).format('DD/MM/YYYY'),
                      item.hora,
                      moment(item.data_retorno).format('DD/MM/YYYY'),
                      item.contribuinte_id + " - " + item.contribuinte.nome_razao_social,
                      item.assunto.nome,
                      key
                    ]
                  })}
                />
                { (
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[]}
                    count={this.state.pageCount}
                    rowsPerPage={parseInt(this.state.perPage)}
                    page={this.state.page}
                    backIconButtonProps={
                    
                          {"aria-label": "Previous Page"}}
                    nextIconButtonProps={
                    {
                      "aria-label": "Next Page",
                    }}

                    SelectProps={{
                      native: true
                    }}

                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                )}
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>

        {/* dialog confirmacao */}
        <Dialog
          open={this.state.modalRemover}
          onClose={this.closeRemover}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: "3" }}
        >
          <DialogTitle id="alert-dialog-title">Você tem certeza que deseja remover esse protocolo?</DialogTitle>
          <DialogActions>
            <Button onClick={this.closeRemover} color="danger">
              Cancelar
            </Button>
            <Button onClick={this.remover} color="success">
              Remover
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(contribuinteStyle)(Protocolos)

