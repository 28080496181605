import axios from "axios"
import { defaultHeaders, makeRequestCreator } from "./Constants"

export function get(page, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url =
    process.env.REACT_APP_API + `contribuintes${page ? `?page=${page}` : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
export function unificar(contribuinte, contribuinteAntigo, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "unificarContribuintes/" + contribuinte
  axios
    .put(url, { contribuinte_antigo_id: contribuinteAntigo }, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getDividas(idContribuinte, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url =
    process.env.REACT_APP_API + `listarDividasEmAbertoSemPagamento/${idContribuinte}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
export function getFieldContribuinte(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "contribuintes/create"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function post(params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "contribuintes"
  axios
    .post(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function put(id, params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "contribuintes/" + id
  axios
    .put(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function edit(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `contribuintes/${id}/edit`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
export function destroy(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "contribuintes/" + id
  axios
    .delete(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
const searchGet = makeRequestCreator()
export function search(searchText, field, onStart, callBack, pagina) {
  let url =
    process.env.REACT_APP_API +
    `search/contribuintes?text=${searchText}&fields=${field}`

  if (pagina != undefined) {
    url += `&page=${pagina}`
  }

  return searchGet(url, callBack)
}
export function simpleSearch(searchText, field, onStart, callBack, pagina) {
  let url =
    process.env.REACT_APP_API +
    `simpleSearch/contribuintes?text=${searchText}&fields=${field}`

  if (pagina != undefined) {
    url += `&page=${pagina}`
  }

  return searchGet(url, callBack)
}
export function ficha(id, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url =
    process.env.REACT_APP_API + `getFichaDeContribuinte${id ? `/${id}` : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function debitos(id, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url =
    process.env.REACT_APP_API + `debitos/${id}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function demonstrativoDeDivida(id, origem, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url =
    process.env.REACT_APP_API + `relatorios/demonstrativoDeDivida?contribuinte_id=${id}${origem ? `&origem=${origem}` : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
