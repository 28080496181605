var meJson = { nome: "" }
try {
  meJson = JSON.parse(localStorage.me)
} catch (e) {
  //Do Nothing
}

const INITIAL_STATE = {
  nome: meJson.nome,
  roles: meJson.roles
}
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "USER_CHANGED":
    console.log("return: ", action.payload)
      return Object.assign({}, state, {
        nome: action.payload.nome,
        roles: action.payload.roles
      })
    default:
      return state
  }
}
