import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles, Paper, InputAdornment } from "@material-ui/core";
import logradouroStyle from "../../assets/css/layout/logradouroStyle";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";

import { showNotification, mapErrors } from "../../util/Constants";
import InputMask from "react-input-mask"

// import Table from "@material-ui/core/Table";
import Table from "../../components/Table/Table";
import TablePagination from "@material-ui/core/TablePagination";
import Edit from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { get, post, put } from "../../util/juros";
import LoadingContent from "../../components/LoadingContent";
import housecall from "housecall";

import numeral from "numeral";

class Juros extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      error: "",
      errors: {},
      mes: "",
      ano: "",
      percentual: "",
      pageCount: 0,
      perPage: 0,
      page: 1,
      juros: [],
      field: 0,
      modal: false,
      jurosFiltered: [],
      search: false,
      id: 0
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.getData = this.getData.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.searchFilter = this.searchFilter.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 });
    this.setLoadingFalse = this.setLoadingFalse.bind(this)
  }

  searchFilter(value) {
    let filtered = this.state.juros
    if (value !== '') {
      this.setState({
        search: true
      })
    } else {
      this.setState({
        search: false
      })
    }
    filtered = filtered.filter((filter) => {
      if (filter.ano !== null) {
        return filter.ano.indexOf(value.toUpperCase()) !== -1;
      } else {
        return null
      }
    })
    this.setState({ jurosFiltered: filtered })
  }

  setLoadingFalse() {
    this.setState({
      isLoading: false
    })
  }

  componentWillMount() {
    this.getData();
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }));
  }
  handleChange(event) {
    let name = event.target.name;
    let errors = this.state.errors;
    errors[name] = undefined;
    this.setState({
      [name]: event.target.value,
      errors: errors
    });
  }

  handleChangeFilters = event => {
    this.setState({ filterField: event.target.value }, () => {
      if (this.state.searchActive) {
        this.filter({ target: { value: this.state.searchText } });
      }
    });
  };

  componentDidMount() {
    this.queue.on("idle", this.setLoadingFalse);
  }

  handleOpen = () => {
    this.setState({ modal: true });
  };

  handleClose = () => {
    this.setState({ id: 0, modal: false, ano: "", mes: "", percentual: "" }, () => {
      this.getData(this.state.page);
    });
  };

  handleSave(event) {
    event.preventDefault();
    let params = {
      mes: this.state.mes,
      ano: this.state.ano,
      percentual: this.state.percentual
    };
    let response = error => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        );
        this.setState({
          errors: mapErrors(error)
        });
      } else {
        showNotification(
          "top",
          "center",
          `Juros ${this.state.id ? "editado" : "incluído"} com sucesso`,
          "success"
        );
        this.handleClose();
      }
    };
    this.state.id
      ? put(this.state.id, params, response)
      : post(params, response);
  }

  getData(page) {
    if (!page) {
      this.toggleLoading();
    }
    get(page, (error, data) => {
      if (!page) {
        this.toggleLoading();
      }
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        );
      } else {
        this.setState({
          juros: data.data,
          jurosFiltered: data.data,
          pageCount: data.total,
          perPage: data.per_page
        });
      }
    });
  }
  handleChangePage = (event, page) => {
    if ((page || 1) !== this.state.page) {
      this.setState({ page }, () => { this.getData(this.state.page) })
    }
  };

  handleEdit(key) {
    this.setState(
      {
        mes: this.state.juros[key].mes,
        ano: this.state.juros[key].ano,
        percentual: this.state.juros[key].percentual,
        id: this.state.juros[key].id
      },
      () => {
        this.handleOpen();
      }
    );
  }

  handleFieldChange(value) {
    this.setState({
      field: value
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Juros</h2>
              </Grid>
              <Grid item lg={2} xs={12}>
                <Button block color="success" round onClick={this.handleOpen}>
                  <AddIcon /> Adicionar
                </Button>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <TextField
              id="standard-search"
              label="Pesquisar por ano"
              fullWidth
              type="search"
              name="search"
              onChange={(event) => this.searchFilter(event.target.value)}
              className={classes.textField}
              margin="normal"
            />
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  tableHeaderColor="primary"
                  actions={[
                    {
                      title: "Editar",
                      icon: (
                        <Edit
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      ),
                      onClick: this.handleEdit
                    }
                  ]}
                  tableHead={["#", "Ano", "Mês", "Percentual"]}
                  tableData={(this.state.search ? this.state.jurosFiltered : this.state.juros).map((item, key) => {
                    return [
                      item.id + "",
                      item.ano,
                      item.mes,
                      numeral(parseFloat(item.percentual))
                        .divide(100)
                        .format("0%"),
                      key
                    ];
                  })}
                />
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[]}
                  count={this.state.pageCount}
                  rowsPerPage={parseInt(this.state.perPage)}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page"
                  }}
                  SelectProps={{
                    native: true
                  }}
            
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>

        <Dialog
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
          fullWidth
        >
          <form onSubmit={this.handleSave}>
            <DialogTitle id="form-dialog-title">
              {this.state.id ? "Atualizar" : "Adicionar"} Juros
            </DialogTitle>
            <DialogContent>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <InputMask
                    mask="99"
                    value={this.state.mes}
                    onChange={this.handleChange}
                    maskChar=" "
                  >
                    {() => (
                      <TextField
                        id="outlined-select-currency"
                        name="mes"
                        label="Mês"
                        required
                        fullWidth
                        error={this.state.errors.mes}
                        helperText={this.state.errors.mes}
                        value={this.state.mes}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <InputMask
                    mask="9999"
                    value={this.state.ano}
                    onChange={this.handleChange}
                    maskChar=" "
                  >
                    {() => (
                      <TextField
                        id="outlined-select-currency"
                        name="ano"
                        label="Ano"
                        required
                        fullWidth
                        error={this.state.errors.ano}
                        helperText={this.state.errors.ano}
                        value={this.state.ano}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                  </InputMask>

                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    id="outlined-select-currency"
                    name="percentual"
                    label="Percentual"
                    required
                    fullWidth
                    type="number"
                    error={this.state.errors.percentual}
                    helperText={this.state.errors.percentual}
                    value={this.state.percentual}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="danger">
                Cancelar
              </Button>
              <Button type="submit" color="success">
                {this.state.id ? "Atualizar" : "Adicionar"}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(logradouroStyle)(Juros);
