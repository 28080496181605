import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

export default class LoadingContent extends Component {
  render() {
    return this.props.isLoading ? (
      <Grid container direction="row" style={{ marginTop: "2%" }}>
        <div style={{ minHeight: 50 }} className="col-lg-12 text-center">
          <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </Grid>
    ) : (
      this.props.children
    );
  }
}
