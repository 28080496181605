import React from "react"

import secaoStyle from "../../assets/css/layout/secaoStyle"
import { withStyles, MenuItem } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import FormHelperText from "@material-ui/core/FormHelperText"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import AddIcon from "@material-ui/icons/Add"
import Delete from "@material-ui/icons/Delete"

import AsyncSelect from "../../components/AsyncSelect"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import CardFooter from "../../components/Card/CardFooter"
import Button from "../../components/CustomButtons/Button"
import SearchFields from "../../components/SearchFields"
import SelectCustom from "../../components/SelectCustom"

import { search as searchContribuinte } from "../../util/contribuinte"
import { getField, post, edit, put } from "../../util/emissaoitbi"
import { showNotification, mapErrors, showWaiting, hideWaiting } from "../../util/Constants"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import moment from "moment"

class EmissaoForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      transmitente_contribuintes: [],
      transmitente_id: "",
      adquirente_id: "",
      imoveis: [],
      observacoes: "",
      id_imoveis: [],
      imoveis_all: [],
      natureza: [],
      id_natureza: 0,
      vencimento: moment(new Date()).format("YYYY-MM-DD"),
      modal: false,
      historico: "",
      rural: false,
      info_imovel: [],
      imoveis_rurais: []
    }
    this.loadOptions = this.loadOptions.bind(this)
    this.getContribuinteById = this.getContribuinteById.bind(this)
    this.getImovelById = this.getImovelById.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.getFields = this.getFields.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)

    this.addItem = this.addItem.bind(this)
    this.removeItem = this.removeItem.bind(this)
  }

  addItem() {
    var oldRurais = this.state.imoveis_rurais

    oldRurais.push({
      financiamento: false,
      valor_venal: 0,
      valor_financiado: 0,
      valor_declarado: 0,
      valor_avaliado: 0,
      valor_a_vista: 0
    })
    this.setState({
      imoveis_rurais: oldRurais
    })
  }

  removeItem() {
    this.setState(state => ({
      imoveis_rurais: state.imoveis_rurais.filter((_, key) => {
        return key !== state.imoveis_rurais.length - 1
      })
    }))
  }


  loadOptions(inputValue, callback) {
    searchContribuinte(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let contribuintes = data.data.map(contribuinte => {
            return {
              value: contribuinte.id,
              label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                ""} - ${contribuinte.cpf_cnpj || ""}`
            }
          })
          this.setState({
            transmitente_contribuintes: data.data
          })
          callback(contribuintes)
        }
      }
    )
  }

  componentWillMount() {
    this.getFields()
  }

  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }

  getFields() {
    this.toggleLoading()
    let response = (error, data) => {
      this.toggleLoading()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState({
          natureza: data.natureza
        })
        if (this.props.match.params.id) {
          data.dam.adquirente_id = {
            value: data.dam.adquirente.id,
            label: data.dam.adquirente.nome_razao_social
          }
          data.dam.transmitente_id = {
            value: data.dam.transmitente.id,
            label: data.dam.transmitente.nome_razao_social
          }
          let imoveis = data.dam.imoveis.map(imovel => {
            return { value: imovel.id, label: imovel.inscricao_cartografica }
          })

          let info = data.dam.imoveis.map(imovel => {
            let financ = false
            if (imovel.valores.valor_financiado !== "0.00") {
              financ = true
            }
            return {
              financiamento: financ,
              imovel_id: imovel.id,
              valor_venal: imovel.valores.valor_venal,
              valor_financiado: imovel.valores.valor_financiado,
              valor_declarado: imovel.valores.valor_declarado,
              valor_avaliado: imovel.valores.valor_avaliado,
              valor_a_vista: imovel.valores.valor_a_vista
            }
          })

          data.dam.info_imovel = info
          data.dam.id_imoveis = imoveis
          data.dam.id_natureza = data.dam.natureza_id
        }
        this.setState(data.dam)
      }
    }
    if (this.props.match.params.id) {
      edit(this.props.match.params.id, response)
    } else {
      getField(response)
    }
  }

  getContribuinteById(id) {
    return this.state.transmitente_contribuintes.filter(item => {
      return id === item.id
    })[0]
  }

  getImovelById(id) {
    return this.state.imoveis_all.filter(item => {
      return id === item.id
    })[0]
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    let value = event.target.value
    errors[name] = undefined

    if (event.target.type === "checkbox") {
      value = event.target.checked
    }

    this.setState(
      {
        [name]: value,
        errors: errors
      }
      , () => {
        if (this.state.transmitente_id && name === "transmitente_id") {
          let contr = this.getContribuinteById(
            this.state.transmitente_id.value
          )
          let select = contr.imoveis.map(atrib => {
            return {
              value: atrib.id,
              label:
                "Insc. Cart.: " +
                atrib.inscricao_cartografica +
                " Logr.: " +
                ((atrib.logradouro || {}).nome || "") +
                "Nº: " + (atrib.numero || "S/N")
            }
          })
          this.setState({
            imoveis: select,
            imoveis_all: contr.imoveis
          })
        }
      })
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let params = {
      transmitente_id: this.state.transmitente_id.value,
      adquirente_id: this.state.adquirente_id.value,
      natureza_id: this.state.id_natureza,
      vencimento: this.state.vencimento,
      observacoes: this.state.observacoes,
      imoveis: this.state.id_imoveis.map((imovel) => imovel.value),
      imoveis_rurais: this.state.imoveis_rurais
    }
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        }, () => { hideWaiting() })
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "ITBI emitido com sucesso",
          "success"
        )
        this.props.history.push("/itbi/movimentos")
      }
    }
    this.props.match.params.id
      ? put(this.props.match.params.id, params, response)
      : post(params, response)
  }

  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do contribuinte" },
      { value: "cpf_cnpj", label: "Documento" },
      { value: "endereco", label: "Endereço" },
      { value: "nome_fantasia", label: "Nome Fantasia" },
      { value: "nome_razao_social", label: "Razão Social" }
    ]
    console.log(this.state.errors)
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Emissão de ITBI</h2>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Grid container direction="row" style={{ marginTop: "2%" }}>
              <Divider />
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                variant="caption"
              >
                Pesquisa contribuinte por
              </Typography>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <SearchFields
                  name="filterField"
                  value={this.state.filterField || ""}
                  onChangeFields={this.handleChange}
                  fields={fields}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <AsyncSelect
                  className={`form-control form-control-alternative form-select  ${
                    this.props.error ? "has-danger" : ""
                    }`}
                  value={this.state.transmitente_id}
                  onChange={this.handleChange}
                  loadOptions={this.loadOptions}
                  error={this.state.errors.contribuinte_id}
                  helperText={
                    this.state.errors.contribuinte_id || "Transmitente"
                  }
                  name="transmitente_id"
                  placeholder="Transmitente"
                  message="Pesquise o contribuinte"
                />
                <FormHelperText>Escolha o Transmitente</FormHelperText>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <AsyncSelect
                  className={`form-control form-control-alternative form-select Select-menu-outer ${
                    this.props.error ? "has-danger" : ""
                    }`}
                  value={this.state.adquirente_id}
                  onChange={this.handleChange}
                  loadOptions={this.loadOptions}
                  error={this.state.errors.adquirente_id}
                  helperText={this.state.errors.adquirente_id}
                  name="adquirente_id"
                  placeholder="Adquirente"
                  message="Pesquise o contribuinte"
                />
                <FormHelperText>Escolha o Adquirente</FormHelperText>
              </Grid>
              <Grid
                container
                direction="row"
                spacing={16}
                style={{ marginTop: "2%" }}
              >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    name="id_natureza"
                    label="Natureza da Operação"
                    fullWidth
                    value={this.state.id_natureza}
                    error={this.state.errors.id_natureza}
                    helperText={this.state.errors.natureza_id}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  >
                    {this.state.natureza.map((option, key) => (
                      <MenuItem key={key} value={option.id}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="outlined-select-currency"
                    name="vencimento"
                    label="Data do Vencimento"
                    fullWidth
                    type="date"
                    value={this.state.vencimento}
                    error={this.state.errors.vencimento}
                    helperText={this.state.errors.vencimento}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <FormControlLabel
              control={
                <Switch
                  name="rural"
                  defaultChecked={this.state.rural}
                  checked={
                    this.state.rural
                  }
                  onChange={this.handleChange}
                  value={this.state.rural}
                />
              }
              label="Adicionar imóvel rural?"
            />
            {this.state.rural && (
              <>
                <Grid container direction="row" spacing={16}>
                  {this.state.imoveis_rurais.map((item, key) => {
                    return (
                      <>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <Card>
                            <CardBody >
                              <strong>IMÓVEL RURAL Adicionado</strong>
                            </CardBody>
                          </Card>
                        </Grid>
                      </>
                    )
                  })}
                </Grid>
                <Grid
                  container
                  direction="row"
                  style={{ marginTop: "2%" }}
                  spacing={16}
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Button
                      type="button"
                      fullWidth
                      onClick={() => this.removeItem()}
                      color="danger"
                    >
                      <Delete /> Remover Imóvel
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <Button
                      fullWidth
                      type="button"
                      onClick={this.addItem}
                      color="success"
                    >
                      <AddIcon /> Adicionar Imóvel
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}

            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <SelectCustom
                  error={this.state.errors["imoveis.0"]}
                  value={this.state.id_imoveis}
                  onChange={this.handleChange}
                  options={this.state.imoveis}
                  name="id_imoveis"
                  placeholder="Imóveis"
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
                <FormHelperText>Selecione os imóveis</FormHelperText>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-select-currency"
                  multiline
                  name="observacoes"
                  label="Observações"
                  fullWidth
                  rowsMax={7}
                  error={this.state.errors.observacoes}
                  value={this.state.observacoes}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardBody>
          <CardFooter>
            <Grid container justify="center">
              <Grid item lg={2}>
                <Button block color="info" round onClick={this.handleSave}>
                  Emitir
                </Button>
              </Grid>
            </Grid>
          </CardFooter>
        </Card>
      </div>
    )
  }
}
export default withStyles(secaoStyle)(EmissaoForm)
