  import React from "react"
import { withRouter } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import AsyncSelect from "../../components/AsyncSelect"
import Typography from "@material-ui/core/Typography"
import { search as searchContribuinte } from "../../util/contribuinte"
import { showNotification, mapErrors } from "../../util/Constants"
import SearchFields from "../../components/SearchFields"
import { withStyles } from "@material-ui/core"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import secaoStyle from "../../assets/css/layout/secaoStyle"
import Button from "../../components/CustomButtons/Button"
import { showWaiting, hideWaiting } from "../../util/Constants"
import { post } from "../../util/certidaonegativa"
import CardFooter from "../../components/Card/CardFooter"
import AdBlockDetect from "react-ad-block-detect"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"

class CertidaoNegativa extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id_contribuinte: "",
      observacao: "",
      errors: {},
      messages: []
    }
    this.loadOptions = this.loadOptions.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.imprimir = this.imprimir.bind(this)
  }


  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: event.target.value,
      errors: errors
    })
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        const reader = new FileReader()

        reader.addEventListener("loadend", e => {
          const text = e.srcElement.result
          let error = JSON.parse(text)
          hideWaiting()
          showNotification(
            "top",
            "center",
            "Ocorreram erros! Verifique o formulário.",
            "danger"
          )
          this.setState({
            errors: mapErrors({ response: { data: error } })
          }, () => {
            let array = Object.keys(this.state.errors).map((keyName, i) => {
              return this.state.errors[keyName]
            })
            this.setState({
              messages: array
            })
          })
        })
        reader.readAsText(error.response.data)

        console.log(error.response)
        console.log(error)

      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Certidão emitida com sucesso",
          "success"
        )
        this.imprimir(data)
      }
    }
    post(this.state.id_contribuinte.value, this.state.observacao, response)
  }

  imprimir(data) {
    var url = window.URL.createObjectURL(data)
    let tempLink = document.createElement("a")
    tempLink.setAttribute("target", "_blank")
    tempLink.setAttribute("href", `${url}`)
    tempLink.setAttribute("style", "display:none;")
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)

  }

  loadOptions(inputValue, callback) {
    searchContribuinte(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let contribuintes = data.data.map(contribuinte => {
            return {
              value: contribuinte.id,
              label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                ""} - ${contribuinte.cpf_cnpj || ""}`
            }
          })
          callback(contribuintes)
        }
      }
    )
  }


  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do contribuinte" },
      { value: "cpf_cnpj", label: "Documento" },
      { value: "endereco", label: "Endereço" },
      { value: "nome_fantasia", label: "Nome Fantasia" },
      { value: "nome_razao_social", label: "Razão Social" }
    ]
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Certidão Negativa de Débito</h2>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <SearchFields
                  name="filterField"
                  value={this.state.filterField || ""}
                  onChangeFields={this.handleChange}
                  fields={fields}
                />
                <AsyncSelect
                className={
                  `form-control form-control-alternative form-select select-custom ${
                    this.props.error ? "has-danger" : ""
                    }`
                }
                  value={this.state.id_contribuinte}
                  name="id_contribuinte"
                  onChange={this.handleChange}
                  error={this.state.errors.contribuinte_id}
                  helperText={this.state.errors.contribuinte_id}
                  loadOptions={this.loadOptions}
                  placeholder="Contribuinte"
                  message="Pesquise o contribuinte"
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-select-currency"
                  multiline
                  required={this.state.observacao}
                  name="observacao"
                  label="Observações"
                  fullWidth
                  rowsMax={7}
                  error={this.state.errors.observacao}
                  helperText={this.state.errors.observacao}
                  value={this.state.observacao}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {this.state.messages.map((item, key) => (
                  <Typography key={key} variant="overline" gutterBottom color="error">
                    {item}
                  </Typography>
                )
                )}
              </Grid>
            </Grid>
          </CardBody>
          <CardFooter>
            <Grid container justify="center">
              <Grid item lg={2}>
                <Button block color="info" round onClick={this.handleSave}>
                  Gerar
                  </Button>
              </Grid>
            </Grid>
          </CardFooter>
        </Card>
        <AdBlockDetect>
          <Dialog open={true} aria-labelledby="form-dialog-title">
            <form onSubmit={this.handleSave}>
              <DialogContent>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Typography variant="h5" gutterBottom>
                      Por favor, desabilite o ADBlock para impressão de certidão.
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
            </form>
          </Dialog>
        </AdBlockDetect>
      </div>
    )
  }
}
CertidaoNegativa = withRouter(CertidaoNegativa)
export default withStyles(secaoStyle)(CertidaoNegativa)