import axios from "axios"
import { defaultHeaders } from "./Constants"

export function put(id, params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "descontos/" + id
  axios
    .put(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function destroy(id, callBack) {
    let config = {
      headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "descontos/" + id
    axios
      .delete(url, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
  }