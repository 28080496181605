import React, { Component } from "react"
import Layout from "./Layout"
import axios from "axios"
import Error403 from "../../assets/img/permissions/403.svg"
import Error500 from "../../assets/img/permissions/500.svg"
import Error404 from "../../assets/img/permissions/404.svg"
import { Grid } from "@material-ui/core"
const LayoutWrapper = Children =>
  class AnimatedWrapper extends Component {
    constructor() {
      super()
      this.state = {
        errorImage: ""
      }
      axios.interceptors.response.use((response) => {
        return response
      }, (error) => {
        if (error.response) {
          switch (error.response.status) {
            case 403:
              this.setState({ errorImage: Error403, errorMessage: "Você não tem permissão para executar esta tarefa" })
              break
            case 402:
              this.setState({ errorImage: Error403, errorMessage: "O sistema se encontra bloqueado, entre em contato com o suporte da AS Sistemas" })
              break
            case 404:
              this.setState({ errorImage: Error404, errorMessage: "Não existe página para essa URL" })
              break
            case 500:
              this.setState({ errorImage: Error500, errorMessage: "Ocorreu um erro no servidor, entre em contato com o suporte da AS Sistemas" })
              break
            default:
              break
          }
        }

        return Promise.reject(error)
      })
    }


    render() {
      return (
        <Layout>
          {
            this.state.errorImage ? (
              <Grid container direction="row" justify="center" alignItems="center">
                <Grid item justify="center" alignItems="center" lg={12} xs={12} className="text-center">
                  <img alt="" src={this.state.errorImage} className="img img-fluid" style={{ height: 400 }} />
                </Grid>
                <h1 className="text-center mt-4">
                  {this.state.errorMessage}
                </h1>
              </Grid>
            ) : (<Children {...this.props} />)

          }
        </Layout>
      )
    }
  }
export default LayoutWrapper