import axios from "axios"
import { defaultHeaders } from "./Constants"

export function get(page, callBack) {
    let config = {
      headers: defaultHeaders()
    }
    let url =  process.env.REACT_APP_API + "motivosDeIsencaoIPTU"
    axios
      .get(url, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
}

export function post(params, callBack) {
    let config = {
      headers: defaultHeaders()
    }
    let url =  process.env.REACT_APP_API + "motivosDeIsencaoIPTU"
    axios
      .post(url, params, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
  }

  export function put(id, params, callBack) {
    let config = {
      headers: defaultHeaders()
    }
    let url =  process.env.REACT_APP_API + "motivosDeIsencaoIPTU/" + id
    axios
      .put(url, params, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
  }

  export function edit(id, callBack) {
    let config = {
      headers: defaultHeaders()
    }
    let url =  process.env.REACT_APP_API + `motivosDeIsencaoIPTU/${id}/edit`
    axios
      .get(url, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
  }

  export function getRelatorio(motivos, order, callBack) {
    let config = {
      headers: defaultHeaders(),
      responseType: "blob"
    }
    let url = process.env.REACT_APP_API + `relatorios/motivos${order ? `?order=${order}`: ""}${motivos ? `&text=${motivos}` : ""}`
    axios
      .get(url, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
}