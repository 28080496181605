import React from "react"

import secaoStyle from "../../../assets/css/layout/secaoStyle"
import { withStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import { showWaiting, hideWaiting, showNotification, mapErrors, removeMask } from "../../../util/Constants"
import Button from "../../../components/CustomButtons/Button"
import { getField, relatorioEmissao } from "../../../util/damavulso"
import { search as searchContribuinte } from "../../../util/contribuinte"
import SelectCustom from "../../../components/SelectCustom"
import InputMask from "react-input-mask"
import AsyncSelect from "../../../components/AsyncSelect"
import SearchFields from "../../../components/SearchFields"
import Typography from "@material-ui/core/Typography"
import LoadingContent from "../../../components/LoadingContent"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import Divider from "@material-ui/core/Divider"

class RelatorioEmissaoDamAvulso extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            receitas: [],
            receita_id: "",
            competencia: "",
            historico: "",
            contribuinte_id: "",
            contribuintes: [],
            errors: {},
            options: false
        }
        this.getData = this.getData.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.toggleLoading = this.toggleLoading.bind(this)
        this.loadOptions = this.loadOptions.bind(this)
    }

    componentWillMount() {
        this.getData()
    }

    getData() {
        this.toggleLoading()
        let response = (error, data) => {
            this.toggleLoading()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                let rec = data.receitas.map(receita => {
                    return {
                        value: receita.id,
                        label: `Nº Receita: ${receita.id} - ${receita.nome}`
                    }
                })
                this.setState({
                    receitas: rec
                })
            }
        }
        getField(response)

    }

    handleSave(event) {
        event.preventDefault()
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            console.log("error: ", error)
            console.log("data", data)
            if (error) {
                const reader = new FileReader()
                reader.addEventListener("loadend", e => {
                    const text = e.srcElement.result
                    try {
                        let error = JSON.parse(text)
                        var ob = {
                            response: {
                                data: error
                            }
                        }
                        this.setState({
                            errors: mapErrors(ob)
                        })
                    } catch (e) {
                        console.log(e)
                        showNotification(
                            "top",
                            "center",
                            `Ocorreram erros! ${error.message}`,
                            "danger"
                        )
                    }
                })
                reader.readAsText(error.response.data)

            } else {
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        relatorioEmissao(this.state.receita_id, removeMask(this.state.competencia), this.state.historico, this.state.contribuinte_id.value, response)
    }

    handleChange(event) {
        let name = event.target.name
        let value = event.target.value

        if(name === "options") {
            value = event.target.checked
        }

        this.setState(state => ({
            [name]: value,
            errors: { ...state.errors, [name]: undefined }
        }))
    }

    toggleLoading() {
        this.setState(state => ({
          isLoading: !state.isLoading
        }))
    }

    loadOptions(inputValue, callback) {
        searchContribuinte(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                    callback([])
                } else {
                    let contribuintes = data.data.map(contribuinte => {
                        return {
                            value: contribuinte.id,
                            label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                                ""} - ${contribuinte.cpf_cnpj || ""}`
                        }
                    })
                    callback(contribuintes)
                }
            }
        )
    }

    render() {
        const { classes } = this.props
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "Código do contribuinte" },
            { value: "cpf_cnpj", label: "Documento" },
            { value: "endereco", label: "Endereço" },
            { value: "nome_fantasia", label: "Nome Fantasia" },
            { value: "nome_razao_social", label: "Razão Social" }
        ]
        return (
            <div className={classes.container}>
                <LoadingContent isLoading={this.state.isLoading}>
                    <Card>
                        <CardHeader color="primary">
                            <Grid container direction="row">
                                <Grid item lg={10} xs={12}>
                                    <h2 className={classes.cardTitleWhite}>DAM Avulso</h2>
                                </Grid>
                            </Grid>
                        </CardHeader>
                        <CardBody>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.options}
                                        name="options"
                                        onChange={this.handleChange}
                                        value={true}
                                    />
                                }
                                label="Ativar Filtros"
                            />
                        </FormGroup>
                        <Grid container direction="row" style={{ marginTop: "2%" }}>
                            <Divider />
                            <Typography
                                className={classes.dividerFullWidth}
                                color="textSecondary"
                                variant="caption"
                            >
                                Para relatório geral, não adicione os filtros
                            </Typography>
                        </Grid>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                    <SelectCustom
                                        disabled={!this.state.options}
                                        value={this.state.id_imovel}
                                        name="receita_id"
                                        isMulti={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={this.handleChange}
                                        error={this.state.errors.receita_id}
                                        helperText={this.state.errors.receita_id}
                                        options={this.state.receitas}
                                        placeholder="Receita"
                                        message="Pesquise a Receita"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <InputMask
                                        mask="9999/99"
                                        disabled={!this.state.options}
                                        value={this.state.competencia}
                                        onChange={this.handleChange}
                                    >
                                        {() => (
                                            <TextField
                                                label="Competência"
                                                name="competencia"
                                                helperText={this.state.errors.competencia}
                                                fullWidth
                                                error={this.state.errors.competencia}
                                                value={this.state.competencia}
                                                onChange={this.handleChange}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <SearchFields
                                        name="filterField"
                                        value={this.state.filterField || ""}
                                        onChangeFields={this.handleChange}
                                        fields={fields}
                                    />
                                    <Typography color="textSecondary" variant="caption">
                                        Pesquise o Contribuinte
                                    </Typography>
                                    <AsyncSelect
                                        disabled={!this.state.options}
                                        className={
                                            `form-control form-control-alternative form-select select-custom2 ${
                                            this.props.error ? "has-danger" : ""
                                            }`
                                        }
                                        value={this.state.contribuinte_id}
                                        onChange={this.handleChange}
                                        loadOptions={this.loadOptions}
                                        error={this.state.errors.contribuinte_id}
                                        helperText={this.state.errors.contribuinte_id}
                                        name="contribuinte_id"
                                        placeholder="Contribuinte"
                                        message="Pesquise o contribuinte"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        disabled={!this.state.options}
                                        label="Histórico"
                                        name="historico"
                                        fullWidth
                                        helperText={this.state.errors.historico}
                                        error={this.state.errors.historico}
                                        value={this.state.historico}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </CardBody>
                        <CardFooter>
                        <Grid container justify="center">
                            <Grid item lg={2}>
                                <Button block color="info" round onClick={this.handleSave}>
                                    Gerar
                            </Button>
                            </Grid>
                        </Grid>
                    </CardFooter>
                    </Card>
                </LoadingContent>
            </div>
        )
    }
}
export default withStyles(secaoStyle)(RelatorioEmissaoDamAvulso)