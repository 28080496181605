import axios from "axios"
import { defaultHeaders } from "./Constants"


export function getField(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "relatorios/divida/filtros"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getDivida(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url =
    process.env.REACT_APP_API + `getDividas/${id}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getDividaComFiltros(id, params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `getDividas/${id}/${params}`
  axios.get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getDividaEmAbertoComFiltros(id, params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `listarDividasEmAbertoSemPagamento/${id}/${params}`
  axios.get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function levantamentoDeDivida(contribuinte, receita, competencia, origem, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url =
    process.env.REACT_APP_API + `relatorios/levantamentoDeDivida?contribuinte_id=${contribuinte}&receita_id=${receita}&competencia=${competencia}&origem=${origem}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function lancamentoDeDivida(contribuinte, receita, competencia, situacao, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url =
    process.env.REACT_APP_API + `relatorios/divida/lancamentos?contribuinte_id=${contribuinte}&receita_id=${receita}&competencia=${competencia}&situacao_id=${situacao}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getReceitas(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url =
    process.env.REACT_APP_API + "receitas"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function demonstrativoDeDivida(params, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url =
    process.env.REACT_APP_API + `relatorios/demonstrativoDeDivida${params}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}