import React from "react";


import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import Button from "../../components/CustomButtons/Button";
import secaoStyle from "../../assets/css/layout/secaoStyle";
import {withStyles, MenuItem} from "@material-ui/core";
import {post, edit, put, getCreate} from "../../util/taxa";
import {showNotification, mapErrors, showWaiting, hideWaiting} from "../../util/Constants";
import NumberFormatCustom from "../../components/NumberFormatCustom";
import LoadingContent from "../../components/LoadingContent";
import {NumberFormatSemPrefix} from "../../components/NumberFormatCustom";
import {put as putFaixa, edit as editFaixa} from "../../util/faixas"

class TaxasForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            descricao: "",
            modal: false,
            grupoTaxas: [],
            id_grupo: 1,
            valor: '',
            valor_basico: '',
            valor_excedente: '',
            unidade_de_medida: '',
            //dialog
            area_minima: "",
            area_maxima: "",
            valorDialog: "",
            valor_excedenteDialog: "",
            id_taxa: 0,
            //
            faixas: [
                {
                    area_minima: "",
                    area_maxima: "",
                    valor: "",
                    valor_excedente: ""
                }
            ],
            edit: false,
            currentFaixas: [],
            editingItem: 0,
            isLoadingDialog: false
        };
        this.init = this.init.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.removeCurrentFaixa = this.removeCurrentFaixa.bind(this)
        this.toggleFaixa = this.toggleFaixa.bind(this)
        this.handleSaveFaixa = this.handleSaveFaixa.bind(this)
        this.getFaixa = this.getFaixa.bind(this)
        this.toggleLoadingDialog = this.toggleLoadingDialog.bind(this)
    }

    handleSaveFaixa(event) {
        event.preventDefault()
        showWaiting()
        let paramsF = {
            id: this.state.editingItem,
            area_minima: this.state.area_minima,
            area_maxima: this.state.area_maxima,
            valor: this.state.valorDialog,
            valor_excedente: this.state.valor_excedenteDialog,
            taxa_id: this.state.id_taxa
        }
        let response = error => {
            if (error) {
                hideWaiting()
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                );
                this.setState({
                    errors: mapErrors(error)
                });
            } else {
                hideWaiting()
                showNotification(
                    "top",
                    "center",
                    `Faixa editada com sucesso`,
                    "success"
                );
                this.handleClose()
                //this.reloadFaixa(paramsF)
                this.init()
            }
        };
        putFaixa(this.state.editingItem, paramsF, response)
    }

    componentWillMount() {
        this.init();
    }

    addItem() {
        var oldFaixas = this.state.faixas;
        oldFaixas.push({
            area_minima: "",
            area_maxima: "",
            valor: "",
            valor_excedente: ""
        });
        this.setState({
            faixas: oldFaixas
        });
    }

    handleOpen = () => {
        this.setState({modal: true});
    };

    handleClose = () => {
        this.setState({modal: false});
    };

    getFaixa() {
        let response = (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                );
            } else {
                this.setState({
                    area_minima: data.area_minima,
                    area_maxima: data.area_maxima,
                    valorDialog: data.valor,
                    valor_excedenteDialog: data.valor_excedente,
                    id_taxa: data.taxa_id
                }, () => {
                    this.toggleLoadingDialog()
                })
            }
        };
        this.toggleLoadingDialog()
        editFaixa(this.state.editingItem, response)
    }

    toggleFaixa(event, index, itemID) {
        this.setState({
            editingItem: itemID,
            modal: !this.state.modal
        }, () => {
            this.getFaixa()
        });
    }

    removeItem() {
        this.setState(state => ({
            faixas: state.faixas.filter((_, key) => {
                return key !== state.faixas.length - 1;
            })
        }));
    }

    removeCurrentFaixa(event, index, item) {
        this.setState(state => ({
            currentFaixas: state.currentFaixas.filter((_, key) => {
                return key !== state.currentFaixas.length - 1;
            })
        }));
    }

    reloadFaixa(newFaixa) {
        var oldCurrentFaixas = [...this.state.currentFaixas];
        oldCurrentFaixas.forEach((faixa, key) => {
            if (faixa.id === newFaixa.id) {
                oldCurrentFaixas[key] = newFaixa;
            }
        });
        this.setState({
            currentFaixas: oldCurrentFaixas
        })
        //this.toggleFaixa()
    }

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        if (name.split(".").length > 1) {
            let oldFaixas = [...this.state.faixas];
            let components = name.split(".");
            name = components[0];
            oldFaixas[components[1]][components[2]] = value;
            value = oldFaixas;
        }
        this.setState(state => ({
            [name]: value,
            errors: {...state.errors, [name]: undefined}
        }));
    }

    handleSave(event) {
        showWaiting()
        event.preventDefault();
        let params = {
            descricao: this.state.descricao,
            tipo_id: this.state.id_grupo,
            valor: this.state.valor,
            valor_basico: this.state.valor_basico,
            valor_excedente: this.state.valor_excedente,
            unidade_de_medida: this.state.unidade_de_medida,
            faixas: this.state.faixas
        };
        let response = error => {
            if (error) {
                hideWaiting()
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                );
                this.setState({
                    errors: mapErrors(error)
                });
            } else {
                hideWaiting()
                showNotification(
                    "top",
                    "center",
                    `Taxa ${
                        this.props.match.params.id ? "editada" : "incluída"
                    } com sucesso`,
                    "success"
                );
                this.props.history.push("/alvara/cadastro");
            }
        };
        this.props.match.params.id
            ? put(this.props.match.params.id, params, response)
            : post(params, response);
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }));
    }

    toggleLoadingDialog() {
        this.setState(state => ({
            isLoadingDialog: !state.isLoadingDialog
        }));
    }

    init() {
        let response = (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                );
            } else {
                this.setState({
                    grupoTaxas: data.tipos,
                })
                if (this.props.match.params.id) {
                    this.setState({
                        descricao: data.taxa.descricao,
                        valor: data.taxa.valor,
                        valor_basico: data.taxa.valor_basico,
                        valor_excedente: data.taxa.valor_excedente,
                        id_grupo: data.taxa.tipo_id,
                        unidade_de_medida: data.taxa.unidade_de_medida,
                        currentFaixas: data.taxa.faixas,
                        faixas: []
                    }, () => {
                        this.toggleLoading();
                    });
                }
                this.setState(data);
            }
        };
        if (this.props.match.params.id) {
            this.toggleLoading();
            edit(this.props.match.params.id, response);
        } else {
            getCreate(response)
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.container}>
                <LoadingContent isLoading={this.state.isLoading}>
                    <Card>
                        <form onSubmit={this.handleSave}>
                            <CardHeader color="primary">
                                <Grid container direction="row">
                                    <Grid item lg={10} xs={12}>
                                        <h2 className={classes.cardTitleWhite}>
                                            Cadastro de Taxas
                                        </h2>
                                    </Grid>
                                </Grid>
                            </CardHeader>
                            <CardBody>
                                <Grid container direction="row" spacing={16}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <TextField
                                            id="outlined-select-currency"
                                            label="Descrição"
                                            fullWidth
                                            required
                                            value={this.state.descricao}
                                            onChange={this.handleChange}
                                            name="descricao"
                                            error={this.state.errors.descricao}
                                            helperText={this.state.errors.descricao}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={16}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            required
                                            name="id_grupo"
                                            label="Grupo de taxas"
                                            fullWidth
                                            value={this.state.id_grupo}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                        >
                                            {this.state.grupoTaxas.map((option, key) => (
                                                <MenuItem key={key} value={option.id}>
                                                    {option.descricao}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={16}>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="outlined-select-currency"
                                            label="Valor"
                                            fullWidth
                                            required
                                            value={12}
                                            onChange={this.handleChange}
                                            name="valor"
                                            defaultValue={this.state.valor}
                                            error={this.state.errors.valor}
                                            helperText={this.state.errors.valor}
                                            margin="normal"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="outlined-select-currency"
                                            label="Valor básico"
                                            fullWidth
                                            required
                                            value={this.state.valor_basico}
                                            onChange={this.handleChange}
                                            name="valor_basico"
                                            defaultValue={this.state.valor_basico}
                                            error={this.state.errors.valor_basico}
                                            helperText={this.state.errors.valor_basico}
                                            margin="normal"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" spacing={16}>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="outlined-select-currency"
                                            label="Valor Excedente"
                                            fullWidth
                                            required
                                            value={this.state.valor_excedente}
                                            onChange={this.handleChange}
                                            name="valor_excedente"
                                            defaultValue={parseFloat(this.state.valor_excedente)}
                                            error={this.state.errors.valor_excedente}
                                            helperText={this.state.errors.valor_excedente}
                                            margin="normal"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="outlined-select-currency"
                                            label="Unidade de medida"
                                            fullWidth
                                            required
                                            value={this.state.unidade_de_medida}
                                            onChange={this.handleChange}
                                            name="unidade_de_medida"
                                            error={this.state.errors.unidade_de_medida}
                                            helperText={this.state.errors.unidade_de_medida}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" style={{marginTop: "2%"}}>
                                    <Divider/>
                                    <Typography
                                        className={classes.dividerFullWidth}
                                        color="textSecondary"
                                        variant="caption"
                                    >
                                        Faixas
                                    </Typography>
                                </Grid>

                                {this.state.currentFaixas.map((faixa, key) => {
                                    return (
                                        <div>
                                            <Grid container spacing={16} key={key}>
                                                <Grid item xs={12} sm={12} md={2} lg={4}>

                                                        <TextField
                                                            fullWidth
                                                            disabled
                                                            margin="normal"
                                                            variant="outlined"
                                                            label="Área mínima"
                                                            type="text"
                                                            value={(parseFloat(faixa.area_minima).toLocaleString('pt-BR'))}
                                                        />

                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={2}>
                                                    <TextField
                                                        fullWidth
                                                        disabled
                                                        margin="normal"
                                                        variant="outlined"
                                                        label="Área máxima"
                                                        type="text"
                                                        value={(parseFloat(faixa.area_maxima).toLocaleString('pt-BR'))}

                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={2}>
                                                    <TextField
                                                        fullWidth
                                                        disabled
                                                        margin="normal"
                                                        variant="outlined"
                                                        label="Valor"
                                                        type="text"
                                                        defaultValue={faixa.valor}
                                                        value={faixa.valor}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        InputProps={{
                                                            inputComponent: NumberFormatCustom
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={3} lg={2}>
                                                    <TextField
                                                        fullWidth
                                                        disabled
                                                        margin="normal"
                                                        variant="outlined"
                                                        label="Valor Excedente"
                                                        type="text"
                                                        value={faixa.valor_excedente}
                                                        defaultValue={faixa.valor_excedente}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        InputProps={{
                                                            inputComponent: NumberFormatCustom
                                                        }}
                                                    />
                                                </Grid>

                                            </Grid>
                                            <Grid container spacing={16}>
                                                <Grid item xs={12} sm={12} md={4} lg={2}>
                                                    <Button
                                                        type="button"
                                                        fullWidth
                                                        onClick={event =>
                                                            this.removeCurrentFaixa(event, key, faixa.id)
                                                        }
                                                        color="danger"
                                                    >
                                                        <Delete/> Remover Faixa
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={4} lg={2}>
                                                    <Button
                                                        fullWidth
                                                        type="button"
                                                        onClick={event =>
                                                            this.toggleFaixa(event, key, faixa.id)
                                                        }
                                                        color="primary"
                                                    >
                                                        <Edit/> Editar Parcela
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    );
                                })}

                                {this.state.faixas.map((faixa, key) => {
                                    return (
                                        <Grid container spacing={16} key={key}>
                                            <Grid item xs={12} sm={12} md={2} lg={4}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    margin="normal"
                                                    variant="outlined"
                                                    label="Descrição"
                                                    type="text"
                                                    name={`faixa.${key}.descricao`}
                                                    onChange={this.handleChange}
                                                    error={this.state.errors[`faixa.${key}.descricao`]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={2}>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    required
                                                    label="Área mínima"
                                                    type="text"
                                                    onChange={this.handleChange}
                                                    name={`faixa.${key}.area_minima`}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    InputProps={{
                                                        inputComponent: NumberFormatSemPrefix,
                                                        endAdornment: (
                                                            <InputAdornment position="end">m²</InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={2}>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    label="Área máxima"
                                                    error={this.state.errors[`faixa.${key}.area_maxima`]}
                                                    type="text"
                                                    name={`faixa.${key}.area_maxima`}
                                                    onChange={this.handleChange}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    InputProps={{
                                                        inputComponent: NumberFormatSemPrefix,
                                                        endAdornment: (
                                                            <InputAdornment position="end">m²</InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={2}>
                                                <TextField
                                                    error={
                                                        this.state.errors[`faixa.${key}.valor`]
                                                    }
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    label="Valor"
                                                    name={`faixa.${key}.valor`}
                                                    helperText={this.state.errors[`faixas.${key}.valor`]}
                                                    type="text"
                                                    onChange={event =>
                                                        this.handleChange({
                                                            target: {
                                                                name: `faixa.${key}.valor`,
                                                                value: event.target.value
                                                            }
                                                        })
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    InputProps={{
                                                        inputComponent: NumberFormatCustom
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={2}>
                                                <TextField
                                                    error={
                                                        this.state.errors[`faixa.${key}.valor_excedente`]
                                                    }
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    label="Valor Excedente"
                                                    helperText={this.state.errors[`faixas.${key}.valor_excedente`]}
                                                    name={`faixa.${key}.valor_excedente`}
                                                    type="text"
                                                    onChange={event =>
                                                        this.handleChange({
                                                            target: {
                                                                name: `faixa.${key}.valor_excedente`,
                                                                value: event.target.value
                                                            }
                                                        })
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    InputProps={{
                                                        inputComponent: NumberFormatCustom
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                                <Grid
                                    container
                                    direction="row"
                                    style={{marginTop: "2%"}}
                                    spacing={16}
                                    justify="flex-end"
                                    alignItems="center"
                                >
                                    <Grid item xs={12} sm={12} md={4} lg={2}>
                                        <Button
                                            type="button"
                                            fullWidth
                                            onClick={() => this.removeItem()}
                                            color="danger"
                                        >
                                            <Delete/> Remover Faixa
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={2}>
                                        <Button
                                            fullWidth
                                            type="button"
                                            onClick={this.addItem}
                                            color="success"
                                        >
                                            <AddIcon/> Adicionar Faixa
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardBody>
                            <CardFooter>
                                <Grid container justify="center">
                                    <Grid item lg={2}>
                                        <Button block color="info" round type="submit">
                                            <AddIcon/> Adicionar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardFooter>
                        </form>
                    </Card>
                </LoadingContent>
                {/* MODAL */}
                <Dialog
                    open={this.state.modal}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                    <form onSubmit={this.handleSaveFaixa}>
                        <DialogTitle id="form-dialog-title">Atualiza Faixa</DialogTitle>
                        <LoadingContent isLoading={this.state.isLoadingDialog}>
                            <DialogContent>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <div className="money" data-mask='false'>
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            label="Área mínima"
                                            type="text"
                                            error={this.state.errors.area_minima}
                                            helperText={this.state.errors.area_minima}
                                            name="area_minima"
                                            onKeyUp={e=>{
                                                let value = parseFloat(this.removerMoneyMask(e.target.value))
                                                this.setState({
                                                    area_minima:value
                                                })
                                            }}
                                            defaultValue={(parseFloat(this.state.area_minima).toLocaleString('pt-BR'))}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">m²</InputAdornment>
                                                )
                                            }}
                                        />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                      <div class='money' data-mask='false'>
                                          <TextField
                                              fullWidth
                                              margin="normal"
                                              variant="outlined"
                                              label="Área máxima"
                                              type="text"
                                              error={this.state.errors.area_maxima}
                                              helperText={this.state.errors.area_maxima}
                                              name="area_maxima"
                                              onKeyUp={e=>{
                                                  let value = parseFloat(this.removerMoneyMask(e.target.value))
                                                  this.setState({
                                                      area_maxima:value
                                                  })
                                              }}
                                              defaultValue={(parseFloat(this.state.area_maxima).toLocaleString('pt-BR'))}

                                              InputProps={{
                                                  endAdornment: (
                                                      <InputAdornment position="end">m²</InputAdornment>
                                                  )
                                              }}
                                          />
                                      </div>

                                    </Grid>
                                </Grid>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            error={this.state.errors.valor}
                                            helperText={this.state.errors.valor}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            label="Valor"
                                            name="valorDialog"
                                            type="text"
                                            onChange={this.handleChange}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom
                                            }}
                                            defaultValue={this.state.valorDialog}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            error={this.state.errors.valor_excedenteDialog}
                                            helperText={this.state.errors.valor_excedenteDialog}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            label="Valor Excedente"
                                            name="valor_excedenteDialog"
                                            type="text"
                                            onChange={this.handleChange}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom
                                            }}
                                            defaultValue={this.state.valor_excedenteDialog}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </LoadingContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="danger">
                                Cancelar
                            </Button>
                            <Button type="submit" color="success">
                                Atualizar
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        );
    }


    removerMoneyMask = (value) => {
        return value.split(".").join("").split(",").join(".")

    }
}

export default withStyles(secaoStyle)(TaxasForm);
