import axios from "axios"
import { defaultHeaders } from "./Constants"

export function post(params, callBack) {
    let defaultHeader = defaultHeaders()
    if (!typeof params === "object") {
      defaultHeader["Content-Type"] = "multipart/form-data"
    }
    let config = { headers: defaultHeader }
    let url = process.env.REACT_APP_API + "baixaAutomatica"
    axios
      .post(url, params, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
  }