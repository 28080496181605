import React from "react"
import { Link, withRouter } from "react-router-dom"
import { withStyles, Paper, MenuItem } from "@material-ui/core"
import logradouroStyle from "../../assets/css/layout/logradouroStyle"
import Button from "../../components/CustomButtons/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import TextField from "@material-ui/core/TextField"
import Cancel from "@material-ui/icons/CancelOutlined"
import CheckBox from "@material-ui/icons/CheckBox"
import moment from "moment"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import CardFooter from "../../components/Card/CardFooter"
import AddIcon from "@material-ui/icons/Add"
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn"
import Grid from "@material-ui/core/Grid"
import Table from "../../components/Table/Table"
import TablePagination from "@material-ui/core/TablePagination"
import Edit from "@material-ui/icons/Edit"
import Print from "@material-ui/icons/Print"
import Close from "@material-ui/icons/Close"
import ChromeReaderMode from "@material-ui/icons/ChromeReaderMode"
import Book from "@material-ui/icons/Book"
import FileCopy from "@material-ui/icons/FileCopy"
import {
  get, search, imprimirAlvaraBy, imprimirBCE, getMotivoCancelamento,
  cancelamento, removeDamAlvara, certidaoDeBaixa, reativar
} from "../../util/alvara"
import { post } from "../../util/geracaoDam"
import LoadingContent from "../../components/LoadingContent"
import Search from "../../components/Search"
import housecall from "housecall"
import InputMask from "react-input-mask"
import Lens from "@material-ui/icons/Lens"
import AlterarVencModal from "./AlterarVencModal"
import {
  showNotification,
  showWaiting,
  hideWaiting,
  mapErrors
} from "../../util/Constants"

import Typography from "@material-ui/core/Typography"
import AdBlockDetect from "react-ad-block-detect"

import { getDams, dam } from "../../util/dam"
import { put } from "../../util/damAlvara"

class Alvara extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      historico_dam: "",
      value: "",
      error: "",
      pageCount: 0,
      perPage: 0,
      page: 0,
      isSearch:false,
      alvaras: [],
      id_alvara: 0,
      searchText: "",
      field: 0,
      dams: [],
      id_dam: 0,
      modal: false,
      nome: false,
      modalRemover: false,

      //campos emitir dam
      mes: "",
      ano: "",
      data_vencimento: "",
      historico: "",
      data_validade: "",
      //dialog cancel
      id_motivo: 1,
      motivos: [],
      data_da_baixa: new Date(),
      showAlterarVencimentoModal: false,
      showModalHistorico: false,

    }
    this.handleCloseHistorico = this.handleCloseHistorico.bind(this)
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.imprimirCertidaoDeBaixa = this.imprimirCertidaoDeBaixa.bind(this)
    this.getData = this.getData.bind(this)
    this.openReativar = this.openReativar.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.reativarInscricao = this.reativarInscricao.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.filter = this.filter.bind(this)
    this.getDam = this.getDam.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
    this.handleClose = this.handleClose.bind(this)
    this.toggleLoadingDialog = this.toggleLoadingDialog.bind(this)
    this.gerarDam = this.gerarDam.bind(this)
    this.imprimirAlvaraByDam = this.imprimirAlvaraByDam.bind(this)
    this.postEmitirDam = this.postEmitirDam.bind(this)
    this.openEmitir = this.openEmitir.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.openDam = this.openDam.bind(this)
    this.imprimirDam = this.imprimirDam.bind(this)
    this.imprimirBce = this.imprimirBce.bind(this)
    this.setLoadingFalse = this.setLoadingFalse.bind(this)
    this.openModalCancel = this.openModalCancel.bind(this)
    this.cancelar = this.cancelar.bind(this)
    this.deleteDam = this.deleteDam.bind(this)
    this.handleCloseAlter = this.handleCloseAlter.bind(this)
    this.openEditDam = this.openEditDam.bind(this)
    this.editDam = this.editDam.bind(this)
    this.openRemover = this.openRemover.bind(this)
    this.closeRemover = this.closeRemover.bind(this)

  }
  reativarInscricao() {
    reativar(this.state.alvara_id, (error) => {
      if (error) {
        if (error.response)
          showNotification(
            "top",
            "center",
            error.response.data.message,
            "danger"
          )
      } else {
        showNotification(
          "top",
          "center",
          "Inscrição reativada com sucesso!",
          "success"
        )
        this.setState({
          modalReativar: false
        }, () => {

          this.getData()
        })
      }
    })
  }
  editDam() {
    showWaiting()
    let params = {
      historico: this.state.historico_dam_selected,
      observacao: this.state.observacao_dam
    }
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        showNotification(
          "top",
          "center",
          "Histórico atualizado com sucesso!",
          "success"
        )
        this.handleCloseHistorico()
        this.handleClose()
        this.getData()
      }
    }
    put(this.state.id_dam, params, response)
  }

  openRemover(key) {
    this.setState({
      modalRemover: true,
      id: this.state.dams[key].id
    })
  }
  openReativar(key) {
    this.setState({
      modalReativar: true,
      alvara_id: this.state.alvaras[key].id
    })
  }
  closeRemover() {
    this.setState({
      modalRemover: false
    })
  }

  openEditDam(key) {
    this.setState({
      id_dam: this.state.dams[key].id,
      historico_dam_selected: this.state.dams[key].historico,
      observacao_dam: this.state.dams[key].observacao
    }, () => {
      this.setState({
        showModalHistorico: true
      })
    })
  }

  handleCloseHistorico() {
    this.setState({
      showModalHistorico: false
    })
  }

  handleCloseAlter() {
    this.setState({
      showAlterarVencimentoModal: false
    })
  }

  componentWillMount() {
    this.getData()
  }

  imprimirBce(key) {
    showWaiting()
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        hideWaiting()
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    imprimirBCE(this.state.alvaras[key].id, response)
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: event.target.value,
      errors: errors
    })
  }

  handleClose(callBack) {
    this.setState({ modal: false }, () => {
      if (callBack) {
        callBack()
      }
    })
  }

  handlePageClick(data) {
    //this.getData(data.selected + 1);
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  componentDidMount() {
    this.queue.on("idle", this.setLoadingFalse)
  }

  openModalCancel(key, name) {
    if (this.state.alvaras[key].cancelado) {
      showNotification(
        "top",
        "center",
        "Alvará selecionado já está cancelado!",
        "warning"
      );
    } else {
      let response = (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros. Tente Novamente!",
            "danger"
          );
          this.setState({
            errors: mapErrors(error)
          });
        } else {
          this.setState({
            motivos: data
          }, () => this.toggleLoadingDialog())
        }
      }
      this.toggleLoadingDialog()
      getMotivoCancelamento(response)
      this.setState({ modal: true, nome: name, id_alvara: this.state.alvaras[key].id })
    }

  }

  cancelar() {
    showWaiting()
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          `Ocorreram errors. ${error.response.data.message}. Tente novamente!`,
          "warning"
        );
      } else {
        showNotification("top", "center", `Alvará cancelado com sucesso`, "success")
        this.handleClose(() => {
          this.getData()
        })

      }
    }
    cancelamento(
      this.state.id_alvara,
      this.state.id_motivo,
      moment(this.state.data_da_baixa).format("YYYY-MM-DD"),
      this.state.descricao_baixa, this.state.protocolo_de_baixa, response)
  }
  gerarDam(key) {
    showWaiting()
    let response = (error, data) => {
      if (error) {
        const reader = new FileReader()

        reader.addEventListener("loadend", e => {
          const text = e.srcElement.result
          let error = JSON.parse(text)
          hideWaiting()
          let status = error.status
          if (status === "vencido") {
            this.setState({
              showAlterarVencimentoModal: true,
              damIndex: key
            })
          }
          showNotification(
            "top",
            "center",
            `Ocorreram erros! ${error.message}`,
            "danger"
          )
        })

        reader.readAsText(error.response.data)
      } else {
        hideWaiting()
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    dam(this.state.dams[key].id, response)
  }

  getDam(key, name) {
    this.setState({ modal: true, nome: name })
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        //let filter = data.filter(item => item.pago === true)
        this.setState(
          {
            dams: data
          },
          () => {
            this.toggleLoadingDialog()
          }
        )
      }
    }

    if (this.state.alvaras[key].isento && name === 'dam') {
      showNotification(
        "top",
        "center",
        "Não é possível imprimir DAM's para inscrições isentas",
        "warning"
      )
      this.setState({ modal: false })
    } else {
      this.toggleLoadingDialog()
      getDams(this.state.alvaras[key].id, response)
    }
  }

  toggleLoadingDialog() {
    this.setState(state => ({
      isLoadingDialog: !state.isLoadingDialog
    }))
  }

  filter(event,pagina) {
    let searchText = event.target.value
    var newState ={
      isLoading:true,
      searchText: searchText,
      searchActive: searchText !== ""
    }
    if(pagina == undefined){
      newState['page'] = 0;
    }
    this.setState(newState,
      () => {
        if (this.state.searchActive) {
          let filtersCheckbox = this.state.filterField || ""
          search(searchText, filtersCheckbox, undefined, (error, data) => {
            this.setState({isLoading:false})
            if (error) {
              showNotification(
                "top",
                "center",
                "Ocorreram erros, verifique sua conexão com a internet",
                "danger"
              )
            } else {
              if (this.state.searchText !== "") {
                this.setState({

                  alvaras: data.data,
                  pageCount: data.total,
                  perPage: data.per_page,
                })
              }
            }
          },pagina)
        } else {
          this.getData()
        }
      }
    )
  }

  imprimirAlvaraByDam(key) {
    showWaiting()
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        const reader = new FileReader()
        reader.addEventListener("loadend", e => {
          const text = e.srcElement.result
          let error = JSON.parse(text)

          showNotification(
            "top",
            "center",
            `Ocorreram erros! ${error.message}`,
            "danger"
          )
        })
        reader.readAsText(error.response.data)
      } else {
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    imprimirAlvaraBy(this.state.dams[key].id, response)
  }

  openEmitir(key, name) {
    this.setState({
      modal: true,
      nome: name,
      id_alvara: this.state.alvaras[key].id
    })
  }

  postEmitirDam() {
    showWaiting()
    let params = {
      mes: this.state.mes,
      ano: this.state.ano,
      vencimento: this.state.data_vencimento,
      historico: this.state.historico_dam,
      observacao: this.state.observacao_dam,
      validade: this.state.data_validade
    }
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        if (error.response.data.errors) {
          this.setState({
            errors: mapErrors(error)
          })
        } else {
          showNotification(
            "top",
            "center",
            error.response.data.message,
            "danger"
          )
        }
      } else {
        hideWaiting()
        showNotification("top", "center", `DAM emitido com sucesso`, "success")
        this.handleClose()
        this.openDam(data.id)
      }
    }
    post(params, response, this.state.id_alvara)
  }

  handleChangeFilters = event => {
    this.setState({ filterField: event.target.value }, () => {
      if (this.state.searchActive) {
        this.filter({ target: { value: this.state.searchText } })
      }
    })
  }

  getData(page) {
    this.setState({isLoading:true})

    get(page, (error, data) => {
      this.setState({isLoading:false})
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState({

          alvaras: data.data,
          pageCount: data.total,
          perPage: data.per_page,

        })
      }
    })
  }

  handleChangePage = (event, page) => {
    var pagina;
    pagina = page + 1;
    this.setState({page: page})
    if(page < 0){
      return;
    }
    if (this.state.searchText.toString().trim() != "") {
      this.filter({target: {value: this.state.searchText}}, pagina, page)
      return;
    }
    this.getData(pagina)
  };


  handleEdit(key) {
    if (this.state.alvaras[key].cancelado) {
      showNotification(
        "top",
        "center",
        "Alvará já está cancelado. Não é possível editar",
        "warning"
      )
    } else {
      this.props.history.push("/alvara/edit/" + this.state.alvaras[key].id)
    }

  }

  handleFieldChange(value) {
    this.setState({
      field: value
    })
  }
  openDam(id) {
    this.setState({
      modal: true,
      nome: "imprimir",
      id_dam: id
    })
  }

  imprimirCertidaoDeBaixa(key) {
    showWaiting()
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        const reader = new FileReader()
        reader.addEventListener("loadend", e => {
          const text = e.srcElement.result
          try {
            let error = JSON.parse(text)
            let status = error.status
            showNotification("top", "center", error.message, "warning")
          } catch (e) {
            showNotification(
              "top",
              "center",
              "Ocorreram erros, verifique sua conexão com a internet",
              "danger"
            )
          }
        })
        reader.readAsText(error.response.data)
      } else {
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    certidaoDeBaixa(this.state.alvaras[key].id, response)
  }

  imprimirDam() {
    showWaiting()
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        hideWaiting()
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    dam(this.state.id_dam, response)
  }
  setLoadingFalse() {
    this.setState({
      isLoading: false
    })
  }

  deleteDam(key) {
    showWaiting()
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros! Tente novamente",
          "danger"
        )
      } else {
        showNotification(
          "top",
          "center",
          "DAM removido com sucesso!",
          "success"
        )
        this.handleClose()
        this.closeRemover()
      }
    }
    removeDamAlvara(this.state.id, response)
  }

  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do Alvará" },
      { value: "contribuinte_id", label: "Código do Contribuinte" },
      { value: "contribuinte_nome_fantasia", label: "Nome Fantasia" },
      { value: "contribuinte_nome_razao_social", label: "Razão Social" },
      { value: "contribuinte_cpf_cnpj", label: "CPF/CNPJ" },
      {
        value: "dados_estabelecimento_endereco",
        label: "Endereço Estabelecimento"
      },
      { value: "dados_veiculo_numero_da_vaga", label: "Nº da Vaga" }
    ]
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Alvará</h2>
              </Grid>
              <Grid item lg={2} xs={12}>
                <Link to="/alvara/create" style={{ textDecoration: "none" }}>
                  <Button block color="success" round>
                    <AddIcon /> Adicionar
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Search
              label="Pesquise aqui"
              value={this.state.filterField}
              onChangeText={this.filter}
              onChangeFields={this.handleChangeFilters}
              fields={fields}
            />
            <LoadingContent isLoading={this.state.isLoading}>
              <Grid
                className={classes.legend}
                container
                direction="row"
                style={{ marginBottom: "2%" }}
              >
                <Lens className={classes.dot + " " + classes.dotDanger} />
                Cancelado
                <Lens className={classes.dot + " " + classes.dotWarning} />
                Isento
                <Lens className={classes.dot + " " + classes.dotPrimary} />
                Baixado com débitos
                  </Grid>
              <Paper className={classes.tableWrapper}>
                <Table
                  getRowColor={(key, classes) => {
                    if (this.state.alvaras[key].isento) {
                      return classes.warningTableRow
                    } else if (this.state.alvaras[key].motivo_de_baixa_id === 3) {
                      return classes.primaryTableRow
                    } else if (this.state.alvaras[key].cancelado) {
                      return classes.dangerTableRow
                    } else {
                      return ""
                    }
                  }}
                  tableHeaderColor="primary"
                  actions={[
                    {
                      title: "Ficha do Alvará",
                      icon: (
                        <FileCopy
                          className={
                            classes.tableActionButtonIcon + " " + classes.ficha
                          }
                        />
                      ),
                      onClick: key => this.imprimirBce(key)
                    }, {
                      title: "Certidão de baixa",
                      icon: (
                        <AssignmentTurnedIn
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      ),
                      onClick: this.imprimirCertidaoDeBaixa
                    },
                    {
                      title: "Imprimir Alvará",
                      icon: (
                        <ChromeReaderMode
                          className={
                            classes.tableActionButtonIcon +
                            " " +
                            classes.imprimir
                          }
                        />
                      ),
                      onClick: key => this.getDam(key, "alvara")
                    },
                    {
                      title: "Emitir DAM",
                      icon: (
                        <Book
                          className={
                            classes.tableActionButtonIcon + " " + classes.book
                          }
                        />
                      ),
                      onClick: key => this.openEmitir(key, "emitir")
                    },
                    {
                      title: "Gerar DAM",
                      icon: (
                        <Print
                          className={
                            classes.tableActionButtonIcon + " " + classes.print
                          }
                        />
                      ),
                      onClick: key => this.getDam(key, "dam")
                    },
                    {
                      title: "Editar",
                      icon: (
                        <Edit
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      ),
                      onClick: this.handleEdit
                    },
                    {
                      title: "Baixar inscrição",
                      icon: (
                        <Cancel
                          className={
                            classes.tableActionButtonIcon + " " + classes.cancel
                          } />
                      ),
                      onClick: key => this.openModalCancel(key, "cancelar")
                    },
                    {
                      title: "Reativar inscrição",
                      icon: (
                        <CheckBox
                          className={
                            classes.tableActionButtonIcon + " " + classes.print
                          } />
                      ),
                      onClick: this.openReativar
                    }
                  ]}
                  tableHead={[
                    "Inscrição",
                    "Nome Razão Social",
                    "Tipo",
                    "Nome Fantasia",
                    "Nº Documento",
                    "Data da baixa",

                  ]}
                  tableData={this.state.alvaras.map((item, key) => {
                    return [
                      item.id + "",
                      item.contribuinte_id + " - " + item.contribuinte.nome_razao_social,
                      item.tipo.nome,
                      item.contribuinte.nome_fantasia,
                      item.contribuinte.cpf_cnpj,
                      item.data_da_baixa ? moment(item.data_da_baixa).format('DD/MM/YYYY') : '',
                      key
                    ]
                  })}
                />
                { (
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[]}
                    count={this.state.pageCount}
                    rowsPerPage={this.state.perPage?this.state.perPage:0}
                    page={this.state.page?this.state.page:0}
                    backIconButtonProps={

                          {"aria-label": "Previous Page"}}
                    nextIconButtonProps={{
                      "aria-label": "Next Page",
                    }}
                    SelectProps={{
                      native: true
                    }}

                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                )}
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>
        {/* DIALOG TABLE DAM */}
        <Dialog
          open={this.state.modal}
          onClose={(e) => this.handleClose()}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={'md'}
          style={{ zIndex: "3" }}
        >
          <LoadingContent isLoading={this.state.isLoadingDialog}>
            {this.state.nome === "dam" && (
              <div>
                <DialogTitle id="form-dialog-title">DAM</DialogTitle>
                <DialogContent>
                  <Grid
                    className={classes.legend}
                    container
                    direction="row"
                    style={{ marginBottom: "2%" }}
                  >
                    <Lens className={classes.dot + " " + classes.dotDanger} />
                    DAM Lançado na dívida
                    <Lens className={classes.dot + " " + classes.dotSuccess} />
                    DAM Pago
                  </Grid>
                  <form onSubmit={this.handleSaveParcela}>
                    <Paper className={classes.tableWrapper}>
                      <Table
                        getRowColor={(key, classes) => {
                          if (this.state.dams[key].lancado_na_divida) {
                            return classes.dangerTableRow
                          } else if (this.state.dams[key].pago) {
                            return classes.successTableRow
                          } else {
                            return ""
                          }
                        }}
                        tableHeaderColor="primary"
                        fullWidth
                        maxWidth={'md'}
                        actions={[
                          {
                            title: "Editar",
                            icon: (
                              <Edit
                                className={
                                  classes.tableActionButtonIcon + " " + classes.edit
                                }
                              />
                            ),
                            onClick: key => this.openEditDam(key)
                          },
                          {
                            title: "Imprimir",
                            icon: (
                              <Print
                                className={
                                  classes.tableActionButtonIcon +
                                  " " +
                                  classes.edit
                                }
                              />
                            ),
                            onClick: this.gerarDam
                          },
                          {
                            title: "Remover",
                            icon: (
                              <Close
                                className={
                                  classes.tableActionButtonIcon +
                                  " " +
                                  classes.edit
                                }
                              />
                            ),
                            onClick: this.openRemover
                          }
                        ]}
                        tableHead={["#", "Competência","Valor","Juros","Multa"]}
                        tableData={this.state.dams.map((item, key) => {
                          return [item.id + "",item.competencia,this.floaToCurrecy(item.valor),this.floaToCurrecy(item.juros),this.floaToCurrecy(item.multa), key]
                        })}
                      />
                    </Paper>
                  </form>
                </DialogContent>
              </div>
            )}
            {this.state.nome === "alvara" && (
              <div>
                <DialogTitle id="form-dialog-title">Alvarás</DialogTitle>
                <DialogContent>
                  <Paper className={classes.tableWrapper}>
                    <Table
                      tableHeaderColor="primary"
                      fullWidth
                      actions={[
                        {
                          title: "Imprimir",
                          icon: (
                            <Print
                              className={
                                classes.tableActionButtonIcon +
                                " " +
                                classes.edit
                              }
                            />
                          ),
                          onClick: key => this.imprimirAlvaraByDam(key)
                        }
                      ]}
                      tableHead={["Número", "Competência", "Dam Pago", "Validade"]}
                      tableData={this.state.dams.map((item, key) => {
                        return [item.alvara_id  + "/" + item.competencia, item.competencia, item.pago == true ? "Sim" : "Não", moment(item.validade).format("DD/MM/YYYY"), key]
                      })}
                    />
                  </Paper>
                </DialogContent>
              </div>
            )}
            {this.state.nome === "emitir" && (
              <div>
                <DialogTitle id="form-dialog-title">Emitir DAM</DialogTitle>
                <DialogContent>
                  <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputMask
                        mask="9999"
                        value={this.state.ano}
                        onChange={this.handleChange}
                      >
                        {() => (
                          <TextField
                            id="outlined-name"
                            required
                            label="Ano"
                            name="ano"
                            helperText={this.state.errors.ano}
                            fullWidth
                            error={this.state.errors.ano}
                            value={this.state.ano}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        )}
                      </InputMask>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputMask
                        mask="99"
                        value={this.state.mes}
                        onChange={this.handleChange}
                      >
                        {() => (
                          <TextField
                            id="outlined-name"
                            label="Mês"
                            name="mes"
                            helperText={this.state.errors.mes}
                            fullWidth
                            error={this.state.errors.mes}
                            value={this.state.mes}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        )}
                      </InputMask>
                    </Grid>
                  </Grid>
                  <Grid container spacing={16}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="date"
                        label="Data do Vencimento"
                        margin="normal"
                        required
                        variant="outlined"
                        type="date"
                        value={this.state.data_vencimento}
                        fullWidth
                        helperText={this.state.errors.vencimento}
                        error={this.state.errors.data_vencimento}
                        defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                        name="data_vencimento"
                        onChange={this.handleChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="date"
                        label="Data da Validade"
                        margin="normal"
                        variant="outlined"
                        type="date"
                        required
                        value={this.state.data_validade}
                        fullWidth
                        helperText={this.state.errors.data_validade}
                        error={this.state.errors.data_validade}
                        defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                        name="data_validade"
                        onChange={this.handleChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid> */}
                  </Grid>
                  <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        label="Histórico do DAM"
                        margin="normal"
                        multiline
                        rowsMax={4}
                        variant="outlined"
                        fullWidth
                        helperText={this.state.errors.historico}
                        error={this.state.errors.historico}
                        name="historico_dam"
                        onChange={this.handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={16} justify="center">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        id="outlined-select-currency"
                        name="observacao_dam"
                        label="Observação"
                        fullWidth
                        multiline
                        rowsMax="3"
                        error={this.state.errors.observacao}
                        helperText={this.state.errors.observacao}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <CardFooter>
                    <Grid container justify="center">
                      <Grid item lg={4}>
                        <Button
                          block
                          color="info"
                          round
                          onClick={this.postEmitirDam}
                        >
                          <AddIcon /> Gerar
                        </Button>
                      </Grid>
                    </Grid>
                  </CardFooter>
                </DialogContent>
              </div>
            )}
            {this.state.nome === "imprimir" && (
              <div>
                <DialogTitle id="form-dialog-title">Imprimir DAM</DialogTitle>
                <DialogContent>
                  <Grid container justify="center">
                    <Grid item lg={4}>
                      <Button
                        block
                        color="info"
                        round
                        onClick={this.imprimirDam}
                      >
                        <AddIcon /> Imprimir DAM
                      </Button>
                    </Grid>
                  </Grid>
                </DialogContent>
              </div>
            )}
            {this.state.nome === "cancelar" && (
              <div>
                <DialogTitle id="form-dialog-title">Baixa de Alvará</DialogTitle>
                <DialogContent>

                  <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        required
                        name="id_motivo"
                        label="Motivo da baixa"
                        fullWidth
                        value={this.state.id_motivo}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      >
                        {this.state.motivos.map((option, key) => (
                          <MenuItem key={key} value={option.id}>
                            {option.nome}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        id="outlined-select-currency"
                        required
                        type="date"
                        name="data_da_baixa"
                        label="Data da Baixa"
                        fullWidth
                        value={moment(this.state.data_da_baixa).format("YYYY-MM-DD")}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        id="outlined-select-currency"
                        required
                        type="text"
                        name="protocolo_de_baixa"
                        label="Número do protocolo"
                        fullWidth
                        value={this.state.protocolo_de_baixa}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        id="outlined-select-currency"
                        required
                        type="text"
                        multiline
                        rowsMax={5}
                        name="descricao_baixa"
                        label="Descrição"
                        fullWidth
                        value={this.state.descricao_baixa}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Grid container direction="row" spacing={16} justify="center">
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <Button onClick={this.cancelar} color="success">
                        Confirmar Cancelamento
                    </Button>
                    </Grid>
                  </Grid>

                </DialogContent>
              </div>
            )}
          </LoadingContent>
          <DialogActions>
            <Button onClick={(e) => this.handleClose()} color="danger">
              Voltar
            </Button>
          </DialogActions>
        </Dialog>
        <AdBlockDetect>
          <Dialog open={true} aria-labelledby="form-dialog-title">
            <form onSubmit={this.handleSave}>
              <DialogContent>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Typography variant="h5" gutterBottom>
                      Por favor, desabilite o ADBlock para geração impressão de
                      alvarás e geração de DAM.
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
            </form>
          </Dialog>
        </AdBlockDetect>

        <Dialog
          id="body"
          open={this.state.showAlterarVencimentoModal}
          maxWidth="md"
          fullWidth
          titleStyle={{ textAlign: "center" }}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          style={{ zIndex: "4" }}
        >
          <DialogTitle id="form-dialog-title">
            <Typography variant="h5" align="center">
              O DAM Selecionado se encontra vencido, deseja alterar seu
              vencimento?
                        </Typography>
          </DialogTitle>
          <DialogContent>
            <AlterarVencModal
              dam={this.state.dams[this.state.damIndex]}
              handleClose={this.handleCloseAlter}
            />
          </DialogContent>
        </Dialog>

        {/* DIALOG HISTORICO DAM */}
        <Dialog
          id="body"
          open={this.state.showModalHistorico}
          maxWidth="md"
          fullWidth
          titleStyle={{ textAlign: "center" }}
          onClose={this.handleCloseHistorico}
          aria-labelledby="form-dialog-title"
          style={{ zIndex: "4" }}
        >
          <DialogTitle id="form-dialog-title">
            <Typography variant="h5" align="center">
              Editar DAM
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container direction="row" spacing={16} justify="center">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-select-currency"
                  required
                  name="historico_dam_selected"
                  label="Histórico"
                  fullWidth
                  multiline
                  rowsMax="3"
                  error={this.state.errors.historico}
                  helperText={this.state.errors.historico}
                  value={this.state.historico_dam_selected}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16} justify="center">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-select-currency"
                  required
                  name="observacao_dam"
                  label="Observação"
                  fullWidth
                  multiline
                  rowsMax="3"
                  error={this.state.errors.observacao}
                  helperText={this.state.errors.observacao}
                  value={this.state.observacao_dam}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseHistorico} color="danger">
              Cancelar
            </Button>
            <Button onClick={this.editDam} color="success">
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.modalRemover}
          onClose={this.closeRemover}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: "3" }}
        >
          <DialogTitle id="alert-dialog-title">Você tem certeza que deseja remover esse DAM?</DialogTitle>
          <DialogActions>
            <Button onClick={this.closeRemover} color="danger">
              Cancelar
            </Button>
            <Button onClick={this.deleteDam} color="success">
              Remover
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.modalReativar}
          onClose={this.closeRemover}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ zIndex: "3" }}
        >
          <DialogTitle id="alert-dialog-title">Você tem certeza que deseja reativar essa inscrição?</DialogTitle>
          <DialogActions>
            <Button onClick={this.closeRemover} color="danger">
              Cancelar
            </Button>
            <Button onClick={this.reativarInscricao} color="success">
              Reativar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  floaToCurrecy = (valor)=>{
    if(valor == null || valor == 0) {
      return "R$ 0,00"
    }
    return "R$ "+(parseFloat(valor)).toLocaleString('pt-BR')
  }
}
Alvara = withRouter(Alvara)
export default withStyles(logradouroStyle)(Alvara)
