import React from "react"

import { withStyles } from "@material-ui/core"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import MenuDashboard from "../MenuDashboard"

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import theme from "../../assets/css/theme"
import GetApp from "@material-ui/icons/GetApp"
import BaixaManualAlvara from "./BaixaManualAlvara"
import BaixaIptu from "./BaixaIptu"
import BaixaManualItbi from "./BaixaManualItbi"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { baixasManuaisTabChanged } from "../../actions"
import BaixaDamAvulso from "./BaixaDamAvulso"
import BaixaDamOcupacao from "./BaixaDamOcupacao"
import BaixaRefinanciamento from "./BaixaRefinanciamento"
import BaixaManualProjeto from "./BaixaManualProjeto";
import BaixaManualPagamento from "./BaixaManualPagamento";

const tab = [

]

class BaixaManual extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      field: 0
    }
    this.handleFieldChange = this.handleFieldChange.bind(this)
  }

  handleFieldChange(value) {
    this.props.baixasManuaisTabChanged(value)
  }
  render() {
    const { classes, field } = this.props
    return (
      <div className={classes.container}>
        <MuiThemeProvider theme={theme}>
          <MenuDashboard
            names={tab}
            onChange={this.handleFieldChange}
            value={field}
          />

        </MuiThemeProvider>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    field: state.tabLayout.baixasmanuaisTabSelected
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ baixasManuaisTabChanged }, dispatch)
}

BaixaManual = connect(
  mapStateToProps,
  mapDispatchToProps
)(BaixaManual)
export default withStyles(contribuinteStyle)(BaixaManual)
