import React from "react"

import { Link, withRouter } from "react-router-dom"
import { withStyles, Paper } from "@material-ui/core"
import logradouroStyle from "../../../assets/css/layout/logradouroStyle"
import Button from "../../../components/CustomButtons/Button"

import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import AddIcon from "@material-ui/icons/Add"
import Grid from "@material-ui/core/Grid"
import Table from "../../../components/Table/Table"
import Edit from "@material-ui/icons/Edit"
import { get } from "../../../util/parametro"
import { showNotification } from "../../../util/Constants"
import LoadingContent from "../../../components/LoadingContent"

class Parametros extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      errors: {},
      nome: "",
      motivos: [],
      field: 0,
      modal: false,
      id: 0
    }

    this.getData = this.getData.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
  }

  componentWillMount() {
    this.getData()
  }

  handlePageClick(data) {
    //this.getData(data.selected + 1);
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: event.target.value,
      errors: errors
    })
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  getData(page) {
    if (!page) {
      this.toggleLoading()
    }
    get(page, (error, data) => {
      if (!page) {
        this.toggleLoading()
      }
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        this.setState({
          motivos: data
        })
      }
    })
  }

  handleEdit(key) {
    this.props.history.push(
      "/parametroscarne/edit/" + this.state.motivos[key].id
    )
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Parâmetros do carne</h2>
              </Grid>
              <Grid item lg={2} xs={12}>
                <Link
                  to="/parametroscarne/create"
                  style={{ textDecoration: "none" }}
                >
                  <Button block color="success" round>
                    <AddIcon /> Adicionar
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  tableHeaderColor="primary"
                  actions={[
                    {
                      title: "Editar",
                      icon: (
                        <Edit
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      ),
                      onClick: this.handleEdit
                    }
                  ]}
                  tableHead={["#", "Exercício", "Mensagem"]}
                  tableData={this.state.motivos.map((item, key) => {
                    return [item.id + "", item.exercicio, item.mensagem, key]
                  })}
                />
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>
      </div>
    )
  }
}
Parametros = withRouter(Parametros)
export default withStyles(logradouroStyle)(Parametros)
