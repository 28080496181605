import React from "react"

import secaoStyle from "../../../assets/css/layout/secaoStyle"
import { withStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import FormHelperText from "@material-ui/core/FormHelperText"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"

import AsyncSelect from "../../../components/AsyncSelect"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Button from "../../../components/CustomButtons/Button"
import SearchFields from "../../../components/SearchFields"
import { search as searchContribuinte } from "../../../util/contribuinte"
import { post, imprimirTodasNotificacoes } from "../../../util/notificacao"
import { showNotification, mapErrors, showWaiting, hideWaiting } from "../../../util/Constants"

class Notificacao extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id_contribuinte: [],
      contribuintes: [],
      errors: {},
      messages: [],
      modal: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
    this.filterArray = this.filterArray.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.handlePrint = this.handlePrint.bind(this)
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let params = {
      contribuintes: this.filterArray(this.state.id_contribuinte)
    }
    let response = error => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        }, () => {
          let array = Object.keys(this.state.errors).map((keyName, i) => {
            return this.state.errors[keyName]
          })
          this.setState({
            messages: array
          })
        })
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Notificação gerada com sucesso",
          "success"
        )
        // this.props.history.push("/divida/processamento")
        this.setState({
          modal: true
        })
      }
    }
    post(params, response)
  }

  handleBack(event) {
    event.preventDefault()
    this.props.history.push("/divida/processamento")
  }

  handlePrint(event) {
    event.preventDefault()
    showWaiting()
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        hideWaiting()
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    let array = this.filterArray(this.state.id_contribuinte)
    imprimirTodasNotificacoes(array.join(","), response)
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined

    this.setState({
      messages: [],
      [name]: event.target.value,
      errors: errors
    })
  }

  loadOptions(inputValue, callback) {
    searchContribuinte(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let contribuintes = data.data.map(contribuinte => {
            return {
              value: contribuinte.id,
              label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                ""} - ${contribuinte.cpf_cnpj || ""}`
            }
          })

          callback(contribuintes)
        }
      }
    )
  }

  filterArray(array) {
    return array.map(item => item.value)
  }

  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do contribuinte" },
      { value: "cpf_cnpj", label: "Documento" },
      { value: "endereco", label: "Endereço" },
      { value: "nome_fantasia", label: "Nome Fantasia" },
      { value: "nome_razao_social", label: "Razão Social" }
    ]
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Notificação de Contribuinte</h2>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormHelperText>Selecione o(s) Contribuinte(s)</FormHelperText>
                <SearchFields
                  name="filterField"
                  value={this.state.filterField || ""}
                  onChangeFields={this.handleChange}
                  fields={fields}
                />
                <AsyncSelect
                  className={
                    `form-control form-control-alternative form-select Select-menu-outer ${
                    this.props.error ? "has-danger" : ""
                    }`
                  }
                  value={this.state.id_contribuinte}
                  onChange={this.handleChange}
                  loadOptions={this.loadOptions}
                  error={this.state.errors.contribuinte_id}
                  helperText={
                    this.state.errors.contribuintes || "Contribuinte"
                  }
                  isMulti="true"
                  name="id_contribuinte"
                  placeholder="Contribuinte"
                  message="Pesquise o contribuinte"
                />

              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {this.state.messages.map((item, key) => (
                  <Typography key={key} variant="overline" gutterBottom color="error">
                    {this.state.id_contribuinte[key].label}: {item}
                  </Typography>
                )
                )}
              </Grid>
            </Grid>
          </CardBody>
          <CardFooter>
            <Grid container justify="center">
              <Grid item lg={2}>
                <Button block disabled={this.state.id_contribuinte.length === 0} color="info" round onClick={this.handleSave}>
                  Gerar
                    </Button>
              </Grid>
            </Grid>
          </CardFooter>
        </Card>
        <Dialog
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          style={{ zIndex: "901" }}
        >
          <DialogTitle id="form-dialog-title">Deseja imprimir a notificação?</DialogTitle>
          <DialogActions>
            <Button onClick={this.handleBack} color="danger">
              Apenas Voltar
            </Button>
            <Button onClick={this.handlePrint} color="primary">
              Imprimir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(secaoStyle)(Notificacao)