import React from "react"

import {withStyles} from "@material-ui/core"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import MenuDashboard from "../MenuDashboard"

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import theme from "../../assets/css/theme"
import {connect} from "react-redux"
import {movimentosITBITabChanged} from "../../actions"
import CallMade from "@material-ui/icons/CallMade"
import {bindActionCreators} from "redux"
import Update from "@material-ui/icons/Update"
import GetApp from "@material-ui/icons/GetApp"
import Emissao from "./Emissao"
import AlterarVencimento from "./AlterarVencimento"
import BaixaItbi from "./BaixaItbi";

const tab = [
    {
        name: "Emissão",
        icon: <CallMade/>
    },
    {
        name: "Alterar Vencimento",
        icon: <Update/>
    },
    {
        name: "Baixas",
        icon: <GetApp />
    }
]

class Itbi extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: "",
            error: "",
            pageCount: 0,
            perPage: 0,
            page: 1,
            contribuintes: [],
            field: 0
        }
        this.handleFieldChange = this.handleFieldChange.bind(this)
    }

    handlePageClick(data) {
    }

    handleChange = event => {
        this.setState({value: event.target.value})
    }

    handleChangePage = (event, page) => {
        if ((page || 1) !== this.state.page) {
            this.setState({page}, () => {
                this.getData(this.state.page)
            })
        }
    }

    handleFieldChange(value) {
        this.props.movimentosITBITabChanged(value)
    }

    render() {
        const {classes, field} = this.props
        return (
            <div className={classes.container}>
                <MuiThemeProvider theme={theme}>
                    <MenuDashboard
                        names={tab}
                        onChange={this.handleFieldChange}
                        value={field}
                    />
                    {field === 0 && <Emissao/>}
                    {field === 1 && <AlterarVencimento/>}
                    {field === 2 && <BaixaItbi />}
                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        field: state.tabLayout.movimentosITBITabSelected
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({movimentosITBITabChanged}, dispatch)
}

Itbi = connect(
    mapStateToProps,
    mapDispatchToProps
)(Itbi)
export default withStyles(contribuinteStyle)(Itbi)
