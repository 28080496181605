import axios from "axios"
import { defaultHeaders } from "./Constants"

export function get(page, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url =
    process.env.REACT_APP_API + `projetos${page ? `?page=${page}` : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getField(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "projetos/create"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function post(params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "projetos"
  axios
    .post(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function put(id, params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "projetos/" + id
  axios
    .put(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function edit(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `projetos/${id}/edit`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
export function destroy(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "projetos/" + id
  axios
    .delete(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function search(searchText, field, onStart, callBack,pagina) {
  let config = {
    headers: defaultHeaders(),
    data: { a: "a" }
  }
  let url =
    process.env.REACT_APP_API +
    `search/projetos?text=${searchText}&fields=${field}`
  if(pagina != null){
    url += `&page=${pagina}`
  }
  if (onStart) {
    onStart()
  }
  return axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function searchDamProjeto(searchText, field, onStart, callBack,pagina) {
  let config = {
    headers: defaultHeaders(),
    data: { a: "a" }
  }
  let url =
    process.env.REACT_APP_API +
    `search/damsConstrucao?text=${searchText}&fields=${field}`
  if(pagina != null){
    url += `&page=${pagina}`
  }
  if (onStart) {
    onStart()
  }
  return axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getFieldDam(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "damsConstrucao/create"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function postDam(params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "damsConstrucao"
  axios
    .post(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getDam(id, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `damsConstrucao/${id}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function mudarVencimento(id, vencimento, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `mudarVencimentoConstrucao/${id}?vencimento=${vencimento}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function putAlterarVenc(id, params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "mudarVencimentoConstrucao/" + id
  axios
      .put(url, params, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
}


export function getDamConstrucao(page, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url =
    process.env.REACT_APP_API + `damsConstrucao${page ? `?page=${page}` : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function destroyDam(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "damsConstrucao/" + id
  axios
    .delete(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function imprimirAlvara(id, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `alvaraDeConstrucao/${id}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}


