import axios from "axios"
import { defaultHeaders, makeRequestCreator } from "./Constants"

export function get(page, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `imoveis${page ? `?page=${page}` : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getFields(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "imoveis/create"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getDeveTransferir(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `deveTransferir/${id}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function post(params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "imoveis"
  axios
    .post(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function put(id, params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "imoveis/" + id
  axios
    .put(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}


export function cancelar(id, motivo, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "cancelarImovel/" + id
  axios
    .put(url, { motivo: motivo }, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}


export function edit(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `imoveis/${id}/edit`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function bci(id, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `imoveis/${id}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
export function carne(id, logradouro, contribuinte, email, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url =
    process.env.REACT_APP_API +
    `getCarne?imovel_id=${id}${
    logradouro ? `&logradouro_id=${logradouro}` : ""
    }${contribuinte ? `&contribuinte_id=${contribuinte}` : ""}${
    email ? `&email=${email}` : ""
    }`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function demonstrativoDeCalculoDeIPTU(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `demonstrativoDeCalculoIPTU/${id}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function bciVazio(callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + "bciVazio"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
const searchGet = makeRequestCreator()
export function search(searchText, field, onStart, callBack, pagina) {
  let url =
    process.env.REACT_APP_API +
    `search/imoveis?text=${searchText}&fields=${field}`
  if (pagina != undefined) {
    url += `&page=${pagina}`

  }

  return searchGet(url, callBack)
}


export function relatorioDeImoveis(contribuinte_id, secao_id, logradouro_id, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `relatorios/imoveis?${contribuinte_id ? `contribuinte_id=${contribuinte_id}&` : ""}${secao_id ? `secao_id=${secao_id}&` : ""}${logradouro_id ? `logradouro_id=${logradouro_id}&` : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}
export function CNIPTU(inscricao, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `certidaoDeImovelPorInscricao/${inscricao}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function imprimirPublico(inscricao, callBack) {
  let headers = defaultHeaders()
  let config = {
    headers: headers,
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `imprimirCarne/${inscricao}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function advancedImovelSearchRequest(params, callBack) {
  const config = {
    headers: defaultHeaders(),
  }
  let url = process.env.REACT_APP_API + `advancedSearch/imoveis`
  axios
    .post(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}