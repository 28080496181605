import React from 'react'
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import Grid from "@material-ui/core/Grid";
import CardBody from "../../components/Card/CardBody";
import TextField from "@material-ui/core/TextField";

import InputMask from 'react-input-mask'
import {get as geFechamentoArrecadacao} from '../../util/fechamentoArrecadacao'
import CardFooter from "../../components/Card/CardFooter";
import Button from "../../components/CustomButtons/Button";
import {hideWaiting, mapErrors, showNotification, showWaiting} from "../../util/Constants";

export default class FechamentoArrecadacao extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            competencia: '',
            errors: {
                competencia: null
            }
        }
    }

    onChangeCompetencia = (event) => {

        this.setState({
            competencia: event.target.value
        })
    }
    mensagem = (mensagem, classe) => {
        showNotification(
            "top",
            "center",
            mensagem,
            classe
        )
    }
    submit = () => {
        this.setState({errors:[]},()=>{
            showWaiting()
            geFechamentoArrecadacao({competencia: this.state.competencia}, (error, resp) => {
                hideWaiting()
                console.log(error)
                if (error) {
                    const reader = new FileReader()
                    reader.addEventListener("loadend", e => {

                        const text = e.srcElement.result

                        try {
                            let error = JSON.parse(text)
                            var ob = {
                                response: {
                                    data: error
                                }
                            }
                            this.setState({
                                errors: mapErrors(ob)
                            })
                        } catch (e) {
                            showNotification(
                                "top",
                                "center",
                                `Ocorreram erros! ${error.message}`,
                                "danger"
                            )
                        }
                    })
                    console.log(reader.readAsText(error.response.data))
                    return;
                }
                console.log(resp)
                var url = URL.createObjectURL(resp)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
                this.setState({errors:[]})
            })
        })

    }

    render() {
        const {classes} = this.props
        return <div>
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>
                                    Fechamento da Arrecadação
                                </h2>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>

                                    <InputMask
                                        mask="9999"
                                        value={this.state.competencia}
                                        onChange={this.onChangeCompetencia}
                                    >
                                        {() => (
                                            <TextField
                                                id="outlined-name"
                                                required
                                                label="Competência"
                                                name="competencia"
                                                helperText={this.state.errors.competencia}
                                                error={this.state.errors.competencia}
                                                fullWidth
                                                value={this.state.competencia}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardBody>
                    <CardFooter>
                        <Grid container justify="center">
                            <Grid item lg={2}>
                                <Button block color="info" round onClick={this.submit}>
                                    Gerar
                                </Button>
                            </Grid>
                        </Grid>
                    </CardFooter>
                </Card>
            </div>
        </div>
    }
}
