export function cadastrosTabChanged(tabIndex) {
  return {
    type: "CADASTROS_TAB_CHANGED",
    payload: tabIndex
  }
}
export function cadastrosAlvaraTabChanged(tabIndex) {
  return {
    type: "CADASTROS_ALVARA_TAB_CHANGED",
    payload: tabIndex
  }
}
export function movimentosTabChanged(tabIndex) {
  return {
    type: "MOVIMENTOS_TAB_CHANGED",
    payload: tabIndex
  }
}

export function movimentosAlvaraTabChanged(tabIndex) {
  return {
    type: "MOVIMENTOS_ALVARA_TAB_CHANGED",
    payload: tabIndex
  }
}

export function movimentosITBITabChanged(tabIndex) {
  return {
    type: "MOVIMENTOS_ITBI_TAB_CHANGED",
    payload: tabIndex
  }
}

export function relatoriosITBITabChanged(tabIndex) {
  return {
    type: "RELATORIOS_ITBI_TAB_CHANGED",
    payload: tabIndex
  }
}

export function cadastrosGeraisTabChanged(tabIndex) {
  return {
    type: "CADASTROS_GERAIS_TAB_CHANGED",
    payload: tabIndex
  }
}

export function baixasManuaisTabChanged(tabIndex) {
  return {
    type: "BAIXAS_MANUAIS_TAB_CHANGED",
    payload: tabIndex
  }
}

export function projetosTabChanged(tabIndex) {
  return {
    type: "PROJETOS_TAB_CHANGED",
    payload: tabIndex
  }
}

export function ocupacaoTabChanged(tabIndex) {
  return {
    type: "OCUPACAO_TAB_CHANGED",
    payload: tabIndex
  }
}

export function processamentoTabChanged(tabIndex) {
  return {
    type: "PROCESSAMENTO_TAB_CHANGED",
    payload: tabIndex
  }
}

export function cadastroDividaTabChanged(tabIndex) {
  return {
    type: "CADASTRO_DIVIDA_TAB_CHANGED",
    payload: tabIndex
  }
}

export function movimentoDividaTabChanged(tabIndex) {
  return {
    type: "MOVIMENTO_DIVIDA_TAB_CHANGED",
    payload: tabIndex
  }
}

export function damAvulsoTabChanged(tabIndex) {
  return {
    type: "DAM_AVULSO_TAB_CHANGED",
    payload: tabIndex
  }
}

export function userChanged(newUserName) {
  return {
    type: "USER_CHANGED",
    payload: newUserName
  }
}

export function dividaRelatoriosTabChanged(tabIndex) {
  return {
    type: "RELATORIOS_DIVIDA_TAB_CHANGED",
    payload: tabIndex
  }
}

export function alvaraRelatoriosTabChanged(tabIndex) {
  return {
    type: "ALVARA_RELATORIOS_TAB_CHANGED",
    payload: tabIndex
  }
}

export function contribuinteChanged(contribuinte) {
  return {
    type: "CONTRIBUINTE_CHANGED",
    payload: contribuinte
  }
}
export function damAvulsoRelatoriosTabChanged(tabIndex) {
  return {
    type: "RELATORIOS_DAMAVULSO_TAB_CHANGED",
    payload: tabIndex
  }
}
export function relatoriosGeraisTabChanged(tabIndex) {
  return {
    type: "RELATORIOS_GERAIS_TAB_CHANGED",
    payload: tabIndex
  }
}




