import axios from "axios"

export function getCep(cep, callBack) {
    axios
      .get("https://viacep.com.br/ws/" + cep + "/json/")
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
  }