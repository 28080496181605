import React from "react"

import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"

import { withStyles, InputAdornment } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"

import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import CardFooter from "../../components/Card/CardFooter"
import Button from "../../components/CustomButtons/Button"
import LoadingContent from "../../components/LoadingContent"
import AsyncSelect from "../../components/AsyncSelect"
import SearchFields from "../../components/SearchFields"

import { search as searchContribuinte } from "../../util/contribuinte"
import { getField, edit, put, post } from "../../util/ocupacao"

import { showNotification, mapErrors, showWaiting, hideWaiting } from "../../util/Constants"
import moment from "moment"

class OcupacaoForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      contribuinte_id: [],
      data_do_contrato: moment(new Date()).format("YYYY-MM-DD"),
      dia_de_vencimento: "",
      data_do_fim_do_contrato: moment(new Date()).format("YYYY-MM-DD"),
      area_m2: "",
      descricao: "",
      historico: "",

      tipos: [],
      tipo_id: 0,
      area: [],
      area_id: 0
    }
    this.loadOptions = this.loadOptions.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getContribuinteById = this.getContribuinteById.bind(this)
    this.getFields = this.getFields.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  componentWillMount() {
    this.getFields()
  }

  getFields() {
    this.toggleLoading()
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState({
          tipos: data.tipos,
          area: data.areas
        })
        if (this.props.match.params.id) {
            data.local.contribuinte_id = {
              value: data.local.contribuinte.id,
              label: `${data.local.contribuinte.id} - ${
                data.local.contribuinte.nome_razao_social
              } - ${data.local.contribuinte.cpf_cnpj}`
            }
          delete data.local.area
          delete data.local.tipos
        }
        this.setState(data.local, () => this.toggleLoading())
      }
    }
    if (this.props.match.params.id) {
      edit(this.props.match.params.id, response)
    } else {
      getField(response)
    }
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let params = {
        contribuinte_id: this.state.contribuinte_id.value,
        tipo_id: this.state.tipo_id,
        area_id: this.state.area_id,
        data_do_contrato: this.state.data_do_contrato,
        dia_de_vencimento: this.state.dia_de_vencimento,
        data_do_fim_do_contrato: this.state.data_do_fim_do_contrato,
        area_m2: this.state.area_m2,
        descricao: this.state.descricao,
        historico: this.state.historico
    }
    let response = error => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          `Local ${
            this.props.match.params.id ? "editado" : "incluído"
          } com sucesso`,
          "success"
        )
        this.props.history.push("/ocupacao")
      }
    }
    this.props.match.params.id
      ? put(this.props.match.params.id, params, response)
      : post(params, response)
  }

  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  loadOptions(inputValue, callback) {
    searchContribuinte(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let contribuintes = data.data.map(contribuinte => {
            return {
              value: contribuinte.id,
              label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                ""} - Nº DOC: ${contribuinte.cpf_cnpj || ""}`
            }
          })
          this.setState({
            contribuintes: data.data
          })
          callback(contribuintes)
        }
      }
    )
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState(
      {
        [name]: event.target.value,
        errors: errors
      },
      () => {
        if (this.state.proprietario_id && name === "proprietario_id") {
          let contr = this.getContribuinteById(
            this.state.proprietario_id.value
          )
          this.setState({
            endereco: contr.endereco,
            bairro: contr.bairro,
            cep: contr.cep,
            cidade: contr.cidade,
            inscricao_municipal: contr.inscricao_municipal
          })
        }
      }
    )
  }
  getContribuinteById(id) {
    return this.state.contribuintes.filter(item => {
      return id === item.id
    })[0]
  }

  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do contribuinte" },
      { value: "cpf_cnpj", label: "Documento" },
      { value: "endereco", label: "Endereço" },
      { value: "nome_fantasia", label: "Nome Fantasia" },
      { value: "nome_razao_social", label: "Razão Social" }
    ]
    return (
      <div className={classes.container}>
        <LoadingContent isLoading={this.state.isLoading}>
          <Card>
            <CardHeader color="primary">
              <Grid container direction="row">
                <Grid item lg={10} xs={12}>
                  <h2 className={classes.cardTitleWhite}>
                    Cadastro de Locais de Ocupação
                  </h2>
                </Grid>
              </Grid>
            </CardHeader>
            <CardBody>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <SearchFields
                    name="filterField"
                    value={this.state.filterField || ""}
                    onChangeFields={this.handleChange}
                    fields={fields}
                  />
                  <AsyncSelect
                  className={
                    `form-control form-control-alternative form-select Select-menu-outer ${
                      this.props.error ? "has-danger" : ""
                      }`
                  }
                    value={this.state.contribuinte_id}
                    onChange={this.handleChange}
                    loadOptions={this.loadOptions}
                    error={this.state.errors.contribuinte_id}
                    helperText={this.state.errors.contribuinte_id}
                    name="contribuinte_id"
                    placeholder="Contribuinte"
                    message="Pesquise o contribuinte"
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    name="tipo_id"
                    label="Tipo do Imóvel"
                    fullWidth
                    value={this.state.tipo_id}
                    error={this.state.errors.tipo_id}
                    helperText={this.state.errors.tipo_id}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  >
                    {this.state.tipos.map((option, key) => (
                      <MenuItem key={key} value={option.id}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    name="area_id"
                    label="Área"
                    fullWidth
                    value={this.state.area_id}
                    error={this.state.errors.area_id}
                    helperText={this.state.errors.area_id}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  >
                    {this.state.area.map((option, key) => (
                      <MenuItem key={key} value={option.id}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="outlined-select-currency"
                    required
                    type="number"
                    name="area_m2"
                    label="Unidade (Área)"
                    fullWidth
                    value={this.state.area_m2}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    error={this.state.errors.area_m2}
                    helperText={this.state.errors.area_m2}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m²</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="outlined-select-currency"
                    type="number"
                    name="dia_de_vencimento"
                    label="Dia do Vencimento"
                    fullWidth
                    value={this.state.dia_de_vencimento}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    error={this.state.errors.dia_de_vencimento}
                    helperText={this.state.errors.dia_de_vencimento}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  name="data_do_contrato"
                  label="Data do Contrato"
                  fullWidth
                  type="date"
                  value={this.state.data_do_contrato || ""}
                  error={this.state.errors.data_do_contrato}
                  helperText={this.state.errors.data_do_contrato}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  defaultValue={this.state.data_do_contrato}
                />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  name="data_do_fim_do_contrato"
                  label="Data do Fim Contrato"
                  fullWidth
                  type="date"
                  value={this.state.data_do_fim_do_contrato || ""}
                  error={this.state.errors.data_do_fim_do_contrato}
                  helperText={this.state.errors.data_do_fim_do_contrato}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  defaultValue={this.state.data_do_fim_do_contrato}
                />
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    label="Descrição"
                    name="descricao"
                    value={this.state.descricao}
                    error={this.state.errors.descricao}
                    helperText={this.state.errors.descricao}
                    onChange={this.handleChange}
                    fullWidth
                    multiline
                    rows="4"
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    label="Histórico"
                    name="historico"
                    value={this.state.historico}
                    error={this.state.errors.historico}
                    helperText={this.state.errors.historico}
                    onChange={this.handleChange}
                    fullWidth
                    multiline
                    rows="4"
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardBody>
            <CardFooter>
              <Grid container justify="center">
                <Grid item lg={2}>
                  <Button block color="info" round onClick={this.handleSave}>
                    {this.props.match.params.id ? "Atualizar" : "Adicionar"}
                  </Button>
                </Grid>
              </Grid>
            </CardFooter>
          </Card>
        </LoadingContent>
      </div>
    )
  }
}

export default withStyles(contribuinteStyle)(OcupacaoForm)
