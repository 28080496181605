import React, { Component } from "react"
import TextField from "@material-ui/core/TextField"
import SearchFields from "./SearchFields"
import contribuinteStyle from "../assets/css/layout/contribuinteStyle"
import { withStyles } from "@material-ui/core"
class Search extends Component {
  render() {
    const { classes } = this.props
    return (
      <form
        onSubmit={e => {
          e.preventDefault()
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="standard-search"
          label={this.props.label}
          fullWidth
          type="text"
          onChange={this.props.onChangeText}
          className={classes.textField}
          margin="normal"
        />
        <SearchFields
          name={this.props.name}
          value={this.props.value}
          onChangeFields={this.props.onChangeFields}
          fields={this.props.fields}
        />
      </form>
    )
  }
}
export default withStyles(contribuinteStyle)(Search)
