import React, { Component } from "react"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"

export default class FastSelect extends Component {
  render() {
    return (
      <TextField
        id="outlined-select-currency"
        select
        required={this.props.required || false}
        name={this.props.name}
        label={this.props.label}
        fullWidth
        value={this.props.value || ""}
        error={this.props.error}
        helperText={this.props.error}
        onChange={this.props.onChange}
        margin="normal"
        variant="outlined"
        InputLabelProps={{
          shrink: true
        }}
      >
        {(this.props.options || []).map((option, key) => {
          return (
            <MenuItem key={key} value={option.value}>
              {option.label}
            </MenuItem>
          )
        })}
      </TextField>
    )
  }
}
