import React from "react"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import AddIcon from "@material-ui/icons/Add"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Button from "../../../components/CustomButtons/Button"
import secaoStyle from "../../../assets/css/layout/secaoStyle"
import { withStyles, MenuItem, Typography } from "@material-ui/core"
import { post, edit, put, getField, declaracao } from "../../../util/isencaoiptu"
import DatePickerCustom from "../../../components/DatePickerCustom"
import { search as searchImovel } from "../../../util/imovel"
import AsyncSelect from "../../../components/AsyncSelect"
import moment from "moment"
import SearchFields from "../../../components/SearchFields"
import LoadingContent from "../../../components/LoadingContent"

import {
  showNotification,
  mapErrors,
  showWaiting,
  hideWaiting
} from "../../../util/Constants"

class IsencaoIptuForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      numero_processo: "",
      ano_processo: "",
      data_processo: "",
      exercicio: "",
      motivos: [],
      motivo_id: 1,
      observacoes: "",
      imovel_id: "",
      modal: false,
      id: ""
    }

    this.init = this.init.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.imprimirCertidao = this.imprimirCertidao.bind(this)
  }

  componentWillMount() {
    this.init()
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: event.target.value,
      errors: errors
    })
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let params = {
      numero_processo: this.state.numero_processo,
      ano_processo: this.state.ano_processo,
      data_processo: this.state.data_processo,
      exercicio: this.state.exercicio,
      motivo_id: this.state.motivo_id,
      imovel_id: this.state.imovel_id.value,
      observacoes: this.state.observacoes
    }
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        this.setState({
          id: data.id
        })
        hideWaiting()
        showNotification(
          "top",
          "center",
          `Isenção ${
          this.props.match.params.id ? "editada" : "incluída"
          } com sucesso`,
          "success"
        )
        this.setState({ modal: true })
      }
    }
    this.props.match.params.id
      ? put(this.props.match.params.id, params, response)
      : post(params, response)
  }

  loadOptions(inputValue, callback) {
    searchImovel(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let imoveis = data.data.map(imovel => {
            return {
              value: imovel.id,
              label: `Insc. Cart.: ${imovel.inscricao_cartografica} Logr.: ${imovel.logradouro == null ? "" : imovel.logradouro.nome} Nº: ${imovel.numero}`
            }
          })
          callback(imoveis)
        }
      }
    )
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  init() {
    this.toggleLoading()
    let response = (error, data) => {
      this.toggleLoading()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        if (this.props.match.params.id) {
          data.isencao.imovel_id = {
            value: data.isencao.imovel.id,
            label: `Insc.:${data.isencao.imovel.id} - Endereço: ${data.isencao
              .imovel.logradouro_correspondencia || ""}, ${data.isencao.imovel
                .bairro_correspondencia || ""}, ${data.isencao.imovel
                  .cidade_correspondencia || ""}, ${data.isencao.imovel
                    .cep_correspondencia || ""} - Insc. Cart.: ${data.isencao.imovel
                      .inscricao_cartografica || ""} - Contribuinte: ${
              data.isencao.imovel.contribuinte.nome_razao_social
              }`
          }

          this.setState({
            numero_processo: data.isencao.numero_processo,
            ano_processo: data.isencao.ano_processo,
            data_processo: data.isencao.data_processo,
            exercicio: data.isencao.exercicio,
            motivo_id: data.isencao.motivo_id,
            imovel_id: data.isencao.imovel_id,
            observacoes: data.isencao.observacoes
          })
        }

        this.setState({ data, motivos: data.motivos })
      }
    }
    if (this.props.match.params.id) {
      edit(this.props.match.params.id, response)
    } else {
      getField(response)
    }
  }

  imprimirCertidao() {
    showWaiting()
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        hideWaiting()
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)

        this.props.history.push("/iptu/movimentos")
      }
    }
    declaracao(this.state.id, response)
  }

  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Inscrição" },
      { value: "inscricao_cartografica", label: "Inscrição Cartográfica" },
      { value: "contribuinte_id", label: "Código do contribuinte" },
      {
        value: "contribuinte_nome_razao_social",
        label: "Nome/Razão Social do Contribuinte"
      },
      { value: "contribuinte_cpf_cnpj", label: "Documento do Contribuinte" },
      { value: "bairro", label: "Bairro" },
      { value: "contribuinte_endereco", label: "Endereço do Contribuinte" },
      { value: "logradouro_id", label: "Código do logradouro" },
      { value: "secao_id", label: "Código da Seção" },
      { value: "logradouro", label: "Logradouro" },
      { value: "complemento", label: "Complemento" },
      { value: "quadra", label: "Quadra" },
      { value: "lote", label: "Lote" },
      { value: "loteamento", label: "Loteamento" }
    ]
    return (
      <div className={classes.container}>
        <LoadingContent isLoading={this.state.isLoading}>
          <Card>
            <form onSubmit={this.handleSave}>
              <CardHeader color="primary">
                <Grid container direction="row">
                  <Grid item lg={10} xs={12}>
                    <h2 className={classes.cardTitleWhite}>
                      Cadastro de Isenção de Iptu
                    </h2>
                  </Grid>
                </Grid>
              </CardHeader>
              <CardBody>
                <Grid container direction="row" spacing={16}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-select-currency"
                      label="Nº do Processo"
                      fullWidth
                      required
                      value={this.state.numero_processo}
                      onChange={this.handleChange}
                      name="numero_processo"
                      error={this.state.errors.numero_processo}
                      helperText={this.state.errors.numero_processo}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-select-currency"
                      label="Ano do Processo"
                      fullWidth
                      required
                      value={this.state.ano_processo}
                      onChange={this.handleChange}
                      name="ano_processo"
                      error={this.state.errors.ano_processo}
                      helperText={this.state.errors.ano_processo}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      id="outlined-select-currency"
                      fullWidth
                      required
                      label="Exercício"
                      value={this.state.exercicio}
                      onChange={this.handleChange}
                      name="exercicio"
                      error={this.state.errors.exercicio}
                      helperText={this.state.errors.exercicio}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={16}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <DatePickerCustom
                      id="date"
                      fullWidth
                      type="date"
                      title="Data do Processo"
                      margin="normal"
                      variant="outlined"
                      error={this.state.errors.data_processo}
                      name="data_processo"
                      helperText={this.state.errors.data_processo}
                      value={this.state.data_processo}
                      defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <TextField
                      id="outlined-select-currency"
                      select
                      name="motivo_id"
                      label="Motivo"
                      fullWidth
                      value={this.state.motivo_id}
                      error={this.state.errors.motivo_id}
                      helperText={this.state.errors.motivo_id}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    >
                      {this.state.motivos.map((option, key) => (
                        <MenuItem key={key} value={option.id}>
                          {option.descricao}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography color="textSecondary" variant="caption">
                      Selecione o campo do imóvel que deseja pesquisar
                    </Typography>
                    <SearchFields
                      name="filterField"
                      value={this.state.filterField || ""}
                      onChangeFields={this.handleChange}
                      fields={fields}
                    />
                    <AsyncSelect
                      className={
                        `form-control form-control-alternative form-select Select-menu-outer ${
                        this.props.error ? "has-danger" : ""
                        }`
                      }
                      value={this.state.imovel_id || ""}
                      onChange={this.handleChange}
                      loadOptions={this.loadOptions}
                      error={this.state.errors.imovel_id}
                      helperText={this.state.errors.imovel_id}
                      name="imovel_id"
                      placeholder="Pesquisar"
                      message="Pesquise o Imóvel"
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="outlined-select-currency"
                      fullWidth
                      label="Observação"
                      value={this.state.observacoes}
                      onChange={this.handleChange}
                      name="observacoes"
                      error={this.state.errors.observacoes}
                      helperText={this.state.errors.observacoes}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardBody>
              <CardFooter>
                <Grid container justify="center">
                  <Grid item lg={2}>
                    <Button block color="info" round type="submit">
                      <AddIcon />{" "}
                      {this.props.match.params.id ? "Atualizar" : "Adicionar"}
                    </Button>
                  </Grid>
                </Grid>
              </CardFooter>
            </form>
          </Card>
        </LoadingContent>

        {/* MODAL */}
        <Dialog
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <form onSubmit={this.handleSaveParcela}>
            <DialogTitle id="form-dialog-title">Certidão</DialogTitle>
            <LoadingContent isLoading={this.state.isLoadingDialog}>
              <DialogContent>
                <Button fullWidth onClick={this.imprimirCertidao} color="info">
                  Imprimir Certidão
                </Button>
              </DialogContent>
            </LoadingContent>
          </form>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(secaoStyle)(IsencaoIptuForm)
