import {
    drawerWidth,
    transition,
    container
  } from "../material-dashboard-react"
  
  const appStyle = theme => ({
    wrapper: {
      position: "relative",
      top: "0",
      height: "100vh"
    },
    root: {
      flexGrow: 1,
      width: "100%",
    },
    mainPanel: {
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${drawerWidth}px)`
      },
      // overflow: "auto",
      position: "relative",
      float: "right",
      ...transition,
      maxHeight: "100%",
      width: "100%",
      overflowScrolling: "touch",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
    },
    content: {
      marginTop: "70px",
      padding: "30px 15px",
      minHeight: "calc(100vh - 123px)"
    },
    container,
    map: {
      marginTop: "70px"
    },
    menuDash: {
      backgroundColor: "#1D206F"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      minHeight: "auto",
      fontWeight: "500",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontWeight: "400",
        lineHeight: "1"
      }
    },
    

  })
  
  export default appStyle
  