import axios from 'axios'
import "./style.css";
export function removeMask(value) {
  return value
    .split("(")
    .join("")
    .split(")")
    .join("")
    .split("-")
    .join("")
    .split("/")
    .join("")
    .split(".")
    .join("")
    .split("_")
    .join("")
}

export function telefones(valor) {
  valor = removeMask(valor)

  if ((valor || "").length > 11) {
    valor = valor.substr(0, valor.length - 1)
  }

  valor = valor.replace(/\D/g, "")
  valor = valor.replace(/^(\d{2})(\d)/g, "($1)$2")
  valor = valor.slice(0, 13).replace(/(\d)(\d{4})$/, "$1-$2")

  return valor
}

export function defaultHeaders() {
  var token = localStorage.getItem("token")
  return {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
    Accept: "application/json"
  }
}

function getCorrectColor(color) {
  switch (color) {
    case "success":
      return "#25a26a"
    case "danger":
      return "#ed213a"
    default:
      break
  }
}

export function mapErrors(error) {
  var errorData = error.response.data.errors

  return Object.keys(errorData).reduce((previous, key) => {
    previous[key] = errorData[key][0]
    return previous
  }, {})
}

export function showNotification(from, align, message, color) {
  const receivedColor = getCorrectColor(color)
  window.$.notify(
    {
      icon:
        color === "success"
          ? "fas fa-clipboard-check"
          : "fas fa-exclamation-circle",
      message: message,
      title: color === "success" ? " Sucesso - " : " Aviso - "
    },
    {
      allow_dismiss: true,
      delay: 3000,
      type: color,
      timer: 1,
      placement: {
        from: from,
        align: align
      },
      animate: {
        enter: "animated bounceInDown",
        exit: "animated bounceOutUp"
      },
      template:
        `<div style="width:30%;background-color:${receivedColor}" class="text-white card shadow alert alert-{0} notificacao" role="alert">` +
        "<button type=\"button\" aria-hidden=\"true\" class=\"close\" data-notify=\"dismiss\">×</button>" +
        "<span style=\"margin-right: 10px;font-size: 20px;\" data-notify=\"icon\" class=\"ftext-white alert-inner--icon\"></span>" +
        "<span  class=\"alert-inner--text\"><strong data-notify=\"title\">{1}</strong></span><span class=\"font-weight-bold \" data-notify=\"message\">{2}</span>" +
        "</div>"
    }
  )
}
export function showWaiting() {
  let element = `<div class="loader-backdrop "><div class="sk-circle">
  <div class="sk-circle1 sk-child"></div>
  <div class="sk-circle2 sk-child"></div>
  <div class="sk-circle3 sk-child"></div>
  <div class="sk-circle4 sk-child"></div>
  <div class="sk-circle5 sk-child"></div>
  <div class="sk-circle6 sk-child"></div>
  <div class="sk-circle7 sk-child"></div>
  <div class="sk-circle8 sk-child"></div>
  <div class="sk-circle9 sk-child"></div>
  <div class="sk-circle10 sk-child"></div>
  <div class="sk-circle11 sk-child"></div>
  <div class="sk-circle12 sk-child"></div>
</div>
<h1 class="loader-text">Aguarde um instante...</h1>
</div>`
  window.$(document.body).append(element)
}
export function hideWaiting() {
  window.$(".loader-backdrop").remove()
}
export function makeRequestCreator() {
  var call;
  return function (url, callBack) {
    if (call) {
      call.cancel();
    }
    call = axios.CancelToken.source();
    let config = {
      headers: defaultHeaders(),
      data: { a: "a" },
      cancelToken: call.token
    }
    return axios
      .get(url, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          callBack(undefined, { data: [] })
        } else {
          callBack(error)
        }

      })
  }
}