import React from "react"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"

import Card from "../components/Card/Card"
import CardHeader from "../components/Card/CardHeader"
import CardFooter from "../components/Card/CardFooter"
import CardBody from "../components/Card/CardBody"
import Button from "../components/CustomButtons/Button"
import CardAvatar from "../components/Card/CardAvatar"
import LoadingContent from "../components/LoadingContent"
import logradouroStyle from "../assets/css/layout/logradouroStyle"
import { withStyles } from "@material-ui/core"
import { showNotification, mapErrors } from "../util/Constants"
import { editProfile, post } from "../util/configProfile"
import { showWaiting, hideWaiting, telefones, removeMask } from "../util/Constants"
import InputMask from "react-input-mask"
import axios from "axios"

class ConfigProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      prefeitura: "",
      orgao: "",
      cnpj: "",
      sigla: "",
      gestor: "",
      cargo: "",

      endereco: "",
      cidade: "",
      cep: "",
      bairro: "",
      uf: "",
      telefone1: "",
      telefone2: "",
      fax: "",
      email: "",
      maskCnpj: "99.999.999/9999-99",
      mask: "(99) 9999 9999",
      value: 0,

      logo: "",
      logoPreviewUrl: "",
      marca_dagua: "",
      marca_daguaPreviewUrl: "",
      assinatura: "",
      assinaturaPreviewUrl: ""
    }
    this.handleChange = this.handleChange.bind(this)
    this.getFields = this.getFields.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.createFileFromURL = this.createFileFromURL.bind(this)
    this.beforeMaskedValueChange = this.beforeMaskedValueChange.bind(this)
  }

  componentWillMount() {
    this.getFields()
  }

  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }

  getFields() {
    this.toggleLoading()
    let response = (error, data) => {
      this.toggleLoading()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.createFileFromURL(data.logo, "logo")
        this.createFileFromURL(data.marca_dagua, "marca_dagua")
        this.createFileFromURL(data.assinatura, "assinatura")

        data.telefone1 !== null ? data.telefone1 = telefones(data.telefone1) : data.telefone1 = telefones("")
        data.telefone2 !== null ? data.telefone2 = telefones(data.telefone2) : data.telefone2 = telefones("")

        this.setState({
          ...data
        })


      }
    }
    editProfile(this.props.match.params.id, response)
  }

  createFileFromURL(src, name) {
    axios
      .get(src, { responseType: "blob" })
      .then(response => {
        this.setState(
          {
            [name]: response.data
          },
          () => {
            let reader = new FileReader()
            reader.onloadend = () => {
              this.setState({
                [name + "PreviewUrl"]: reader.result
              })
            }

            reader.readAsDataURL(response.data)
          }
        )
      })
      .catch(error => {
        console.log(error)
      })
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()

    if ((removeMask(this.state.telefone1) || "").length > 11) {
      this.setState({
        telefone1: this.state.telefone1.substr(0, this.state.telefone1.length - 1)  
      }) 
    }
    
    if ((removeMask(this.state.telefone2) || "").length > 11) {
      this.setState({
        telefone2: this.state.telefone2.substr(0, this.state.telefone2.length - 1)
      })
    }

    let params = new FormData()

    params.append("prefeitura", this.state.prefeitura)
    params.append("logo", this.state.logo)
    params.append("marca_dagua", this.state.marca_dagua)
    params.append("assinatura", this.state.assinatura)
    params.append("orgao", this.state.orgao)
    params.append("cnpj", this.state.cnpj)
    params.append("sigla", this.state.sigla)
    params.append("endereco", this.state.endereco)
    params.append("cidade", this.state.cidade)
    params.append("bairro", this.state.bairro || "")
    params.append("cep", this.state.cep)
    params.append("uf", this.state.uf)
    params.append("telefone1", this.state.telefone1 !== null ? removeMask(this.state.telefone1) : "")
    params.append("telefone2", this.state.telefone2 !== null ? removeMask(this.state.telefone2) : "")
    params.append("fax", this.state.fax || "")
    params.append("gestor", this.state.gestor)
    params.append("cargo", this.state.cargo)
    params.append("email", this.state.email || "")

    let response = (error) => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )

        this.setState({
          errors: mapErrors(error)
        })
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Atualização realizada com sucesso",
          "success"
        )
      }
    }
    post(params, response)
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: event.target.value,
      errors: errors
    })
  }

  beforeMaskedValueChange(newState) {
    var { value } = newState
    var selection = newState.selection

    value
      .split("(")
      .join("")
      .split(")")
      .join("")
      .split("-")
      .join("")
      .split("/")
      .join("")
      .split(".")
      .join("")

    return {
      value,
      selection
    }
  }

  handleImageChange(e) {
    e.preventDefault()
    let reader = new FileReader()
    let img = e.target.files[0]
    let name = e.target.name

    reader.onloadend = () => {
      this.setState({
        [name]: img,
        [name + "PreviewUrl"]: reader.result
      })
    }

    reader.readAsDataURL(img)
  }

  render() {
    let { logoPreviewUrl } = this.state
    let $imagePreview = null
    if (logoPreviewUrl) {
      $imagePreview = <img alt="logo" src={logoPreviewUrl} />
    }
    let { marca_daguaPreviewUrl } = this.state
    let $imagePreview2 = null
    if (marca_daguaPreviewUrl) {
      $imagePreview2 = <img alt="marcadagua" src={marca_daguaPreviewUrl} />
    }

    let { assinaturaPreviewUrl } = this.state
    let $imagePreview3 = null
    if (assinaturaPreviewUrl) {
      $imagePreview3 = <img alt="assinatura" src={assinaturaPreviewUrl} />
    }

    const { classes } = this.props
    return (
      <div>
        <LoadingContent isLoading={this.state.isLoading}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Configuração de Conta</h4>
            </CardHeader>
            <CardBody>
              <Grid
                container
                spacing={16}
                direction="row"
                style={{ marginTop: "2%" }}
              >
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Card profile>
                    {$imagePreview && (
                      <CardAvatar profile>{$imagePreview}</CardAvatar>
                    )}
                    <CardFooter>
                      <Grid container justify="center">
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Button
                            block
                            color="primary"
                            round
                            onClick={() => this.upload1.click()}
                          >
                            <input
                              accept=".jpg,.png,.jpeg"
                              onChange={e => this.handleImageChange(e)}
                              name="logo"
                              ref={ref => (this.upload1 = ref)}
                              type="file"
                              style={{ display: "none" }}
                            />
                            Alterar Logomarca
                          </Button>
                        </Grid>
                      </Grid>
                    </CardFooter>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Card profile>
                    {$imagePreview2 && (
                      <CardAvatar profile>{$imagePreview2}</CardAvatar>
                    )}
                    <CardFooter>
                      <Grid container justify="center">
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Button
                            block
                            color="primary"
                            round
                            onClick={() => this.upload2.click()}
                          >
                            <input
                              accept=".jpg,.png,.jpeg"
                              onChange={e => this.handleImageChange(e)}
                              name="marca_dagua"
                              ref={ref => (this.upload2 = ref)}
                              type="file"
                              style={{ display: "none" }}
                            />
                            Alterar Marca D'Água
                          </Button>
                        </Grid>
                      </Grid>
                    </CardFooter>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Card profile>
                    {$imagePreview3 && (
                      <CardAvatar profile>{$imagePreview3}</CardAvatar>
                    )}
                    <CardFooter>
                      <Grid container justify="center">
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Button
                            block
                            color="primary"
                            round
                            onClick={() => this.upload3.click()}
                          >
                            <input
                              accept=".jpg,.png,.jpeg"
                              onChange={e => this.handleImageChange(e)}
                              name="assinatura"
                              ref={ref => (this.upload3 = ref)}
                              type="file"
                              style={{ display: "none" }}
                            />
                            Alterar Assinatura
                          </Button>
                        </Grid>
                      </Grid>
                    </CardFooter>
                  </Card>
                </Grid>
              </Grid>
              <Grid container spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    label="Prefeitura"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    value={this.state.prefeitura}
                    helperText={this.state.errors.prefeitura}
                    error={this.state.errors.prefeitura}
                    name="prefeitura"
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    label="Órgão"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    value={this.state.orgao}
                    helperText={this.state.errors.orgao}
                    error={this.state.errors.orgao}
                    name="orgao"
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={16}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <InputMask
                    mask={this.state.maskCnpj}
                    value={this.state.cnpj}
                    onChange={this.handleChange}
                    maskChar={null}
                    beforeMaskedValueChange={this.beforeMaskedValueChange}
                  >
                    {() => (
                      <TextField
                        label="CNPJ"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        helperText={this.state.errors.cnpj}
                        error={this.state.errors.cnpj}
                        name="cnpj"
                        onChange={this.handleChange}
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    label="Sigla"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    value={this.state.sigla}
                    helperText={this.state.errors.sigla}
                    error={this.state.errors.sigla}
                    name="sigla"
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    label="E-mail"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    value={this.state.email || ""}
                    helperText={this.state.errors.email}
                    error={this.state.errors.email}
                    name="email"
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    label="Nome do Gestor"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    value={this.state.gestor}
                    helperText={this.state.errors.gestor}
                    error={this.state.errors.gestor}
                    name="gestor"
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    label="Cargo do Gestor"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    value={this.state.cargo}
                    helperText={this.state.errors.cargo}
                    error={this.state.errors.cargo}
                    name="cargo"
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" style={{ marginTop: "2%" }}>
                <Divider />
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  variant="caption"
                >
                  Informações Adicionais
                </Typography>
              </Grid>

              <Grid container spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    label="Endereço"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    value={this.state.endereco}
                    helperText={this.state.errors.endereco}
                    error={this.state.errors.endereco}
                    name="endereco"
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <TextField
                    label="Cidade"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    value={this.state.cidade}
                    helperText={this.state.errors.cidade}
                    error={this.state.errors.cidade}
                    name="cidade"
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <TextField
                    label="CEP"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    value={this.state.cep}
                    helperText={this.state.errors.cep}
                    error={this.state.errors.cep}
                    name="cep"
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={1} lg={1}>
                  <TextField
                    label="UF"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    value={this.state.uf}
                    helperText={this.state.errors.uf}
                    error={this.state.errors.uf}
                    name="uf"
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={16}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <TextField
                    label="Bairro"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    value={this.state.bairro}
                    helperText={this.state.errors.bairro}
                    error={this.state.errors.bairro}
                    name="bairro"
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <InputMask
                    value={telefones(this.state.telefone1)}
                    onChange={this.handleChange}
                  >
                    {() => (
                      <TextField
                        label="Telefone 1"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        helperText={this.state.errors.telefone1}
                        error={this.state.errors.telefone1}
                        name="telefone1"
                        onChange={this.handleChange}
                        value={telefones(this.state.telefone1)}
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <InputMask
                    value={telefones(this.state.telefone2)}
                    onChange={this.handleChange}
                  >
                    {() => (
                      <TextField
                        label="Telefone 2"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        helperText={this.state.errors.telefone2}
                        error={this.state.errors.telefone2}
                        name="telefone2"
                        onChange={this.handleChange}
                        value={telefones(this.state.telefone2)}
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <InputMask
                    mask={this.state.mask}
                    value={this.state.fax}
                    onChange={this.handleChange}
                    maskChar={null}
                    beforeMaskedValueChange={this.beforeMaskedValueChange}
                  >
                    {() => (
                      <TextField
                        label="Fax"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        helperText={this.state.errors.fax}
                        error={this.state.errors.fax}
                        name="fax"
                        onChange={this.handleChange}
                      />
                    )}
                  </InputMask>
                </Grid>
              </Grid>
            </CardBody>
            <CardFooter>
              <Grid container justify="center">
                <Grid item lg={2}>
                  <Button block color="info" round onClick={this.handleSave}>
                    Atualizar
                  </Button>
                </Grid>
              </Grid>
            </CardFooter>
          </Card>
        </LoadingContent>
      </div>
    )
  }
}

export default withStyles(logradouroStyle)(ConfigProfile)
